import { IconObject } from '../../../types/IconOrgTypes';
import {
  ChatIcon,
  LoaderIcon,
  CheckedCheckboxIcon,
  CalendarIcon,
  NavigationChevronRightIcon,
  NavigationChevronLeftIcon,
  NavigationChevronDownIcon,
  StatusWarningFilledIcon,
  ToolsShowIcon,
  ToolsHideIcon,
  ToolsTickIcon,
  ToggleLockedIcon,
  IBMSecurityIcon,
  UserAdminIcon,
  TechnologyMobileIcon,
  NavigationCloseIcon,
  NavigationCloseFilledIcon,
  StatusWarningIcon,
  StatusWarningAltIcon,
  StatusCheckmarkOutlineIcon,
  StatusCheckmarkIcon,
  StatusUnCheckmarkIcon,
  EllipseBlankIcon,
  FormattingEditIcon,
  ProgressStepIcon,
  SocialProofIcon,
  AndroidPlayStoreBadgesIcon,
  IOSAppStoreBadgesIcon,
  PlanningTimerIcon,
  FileFolderAddIcon,
  StatusCheckmarkFilledIcon,
  PageIllustrationIcon,
  OperationsSearchIcon,
  GenericErrorIcon,
  UploadDocWelcomeIcon,
  DocumentUploadedIcon,
  PageNotFoundIcon,
  StatusErrorIcon,
  HelpEmailIcon,
  HelpMobileIcon,
  HelpCloseIcon,
  RadioButtonCheckedIcon,
  CheckmarkOffIcon,
  HelpFaqIcon,
  HelpMapIcon,
  IndividualIcon,
  BusinessIcon,
  PersonUserIcon
} from '../whitelabel/commonIcons';

// CD specific icons
import { ReactComponent as CDLogoIcon } from './assets/icons/logo.svg';
import { ReactComponent as CDLogoLightIcon } from './assets/icons/logo-light.svg';
import { ReactComponent as CDLogoLightMobileIcon } from './assets/icons/logo-light-mobile.svg';
import { ReactComponent as CDSearchIcon } from './assets/icons/search-icon.svg';
import { ReactComponent as CDWelcomeEmailDarkIcon } from './assets/icons/welcome-email-dark-icon.svg';
import { ReactComponent as CDWelcomeCardInHandIcon } from './assets/icons/welcome-card-in-hand-icon.svg';
import { ReactComponent as CDLogoDarkIcon } from './assets/icons/logo-dark.svg';
import { ReactComponent as CDLogoDarkMobileIcon } from './assets/icons/logo-dark-mobile.svg';
import { ReactComponent as LocationWarningIcon } from './assets/icons/location-warning-icon.svg';
import { ReactComponent as LocationLockedIcon } from './assets/icons/location-locked-icon.svg';
import { ReactComponent as MaintenancePageIcon } from './assets/icons/page-maintenance-icon.svg';
import { ReactComponent as AccountErrorIcon } from './assets/icons/account-error-icon.svg';
import { ReactComponent as SessionLogoutIcon } from './assets/icons/session-logout-icon.svg';
import { ReactComponent as SwitcherIcon } from './assets/icons/currency-switcher-icon.svg';
import { ReactComponent as TransactionIcon } from './assets/icons/transactions-icon.svg';
import { ReactComponent as AgentOnlineIcon } from './assets/icons/agent-online-icon.svg';
import { ReactComponent as MobileDownloadQR } from './assets/icons/mobile-download-qr.svg';
import { ReactComponent as VerifyIdIcon } from './assets/icons/verify-id-icon.svg';

export const CDIconObject: IconObject = {
  search: CDSearchIcon,
  chat: ChatIcon,
  loader: LoaderIcon,
  checkedCheckbox: CheckedCheckboxIcon,
  calendar: CalendarIcon,
  navigationChevronRightIcon: NavigationChevronRightIcon,
  navigationChevronLeftIcon: NavigationChevronLeftIcon,
  navigationChevronDownIcon: NavigationChevronDownIcon,
  statusWarningFilled: StatusWarningFilledIcon,
  toolsShow: ToolsShowIcon,
  toolsHide: ToolsHideIcon,
  toolsTick: ToolsTickIcon,
  toggleLocked: ToggleLockedIcon,
  ibmSecurity: IBMSecurityIcon,
  userAdmin: UserAdminIcon,
  technologyMobile: TechnologyMobileIcon,
  navigationClose: NavigationCloseIcon,
  navigationCloseFilled: NavigationCloseFilledIcon,
  logo: CDLogoIcon,
  logoLight: CDLogoLightIcon,
  logoLightMobile: CDLogoLightMobileIcon,
  statusWarning: StatusWarningIcon,
  statusWarningAlt: StatusWarningAltIcon,
  statusCheckmarkOutlineIcon: StatusCheckmarkOutlineIcon,
  statusCheckmarkIcon: StatusCheckmarkIcon,
  statusUnCheckmarkIcon: StatusUnCheckmarkIcon,
  ellipseBlank: EllipseBlankIcon,
  formattingEdit: FormattingEditIcon,
  progressStep: ProgressStepIcon,
  socialProof: SocialProofIcon,
  welcomeEmailDark: CDWelcomeEmailDarkIcon,
  androidPlayStoreBadges: AndroidPlayStoreBadgesIcon,
  iOSAppStoreBadges: IOSAppStoreBadgesIcon,
  planningTimer: PlanningTimerIcon,
  fileFolderAdd: FileFolderAddIcon,
  checkmarkFilled: StatusCheckmarkFilledIcon,
  PageIllustration: PageIllustrationIcon,
  magnifyingGlass: OperationsSearchIcon,
  welcomeCardInHand: CDWelcomeCardInHandIcon,
  genericError: GenericErrorIcon,
  logoDark: CDLogoDarkIcon,
  logoDarkMobile: CDLogoDarkMobileIcon,
  uploadDocWelcome: UploadDocWelcomeIcon,
  documentUploaded: DocumentUploadedIcon,
  pageNotFound: PageNotFoundIcon,
  MaintenancePage: MaintenancePageIcon,
  locationWarning: LocationWarningIcon,
  locationLocked: LocationLockedIcon,
  statusError: StatusErrorIcon,
  helpEmail: HelpEmailIcon,
  helpMobile: HelpMobileIcon,
  helpClose: HelpCloseIcon,
  agentOnline: AgentOnlineIcon,
  radioButtonChecked: RadioButtonCheckedIcon,
  checkmarkOff: CheckmarkOffIcon,
  accountError: AccountErrorIcon,
  sessionLogout: SessionLogoutIcon,
  helpFaq: HelpFaqIcon,
  helpMap: HelpMapIcon,
  switcher: SwitcherIcon,
  transactionIcon: TransactionIcon,
  landingPageIndividualIcon: IndividualIcon,
  landingPageBusinessIcon: BusinessIcon,
  cfsbCurrenciesDirectLogo: CDLogoIcon,
  personUserIcon: PersonUserIcon,
  mobileDownloadQR: MobileDownloadQR,
  verifyIdIcon: VerifyIdIcon
};

import { ImageObject } from '../../../types/ImageOrgTypes';

// TorFX specific images
import MobileAppImg from './assets/images/mobile-app-img.png';
import LandingPageImg from './assets/images/landing-page.png';

export const TorFxImageObject: ImageObject = {
  mobileApp: MobileAppImg,
  landingPage: LandingPageImg
};

import { CoralogixRum } from '@coralogix/browser';
import { CoralogixBrowserSdkConfig } from '@coralogix/browser/src/types';

// Utils
import { getConfigProperty } from '../../EnvironmentConfig/EnvironmentConfig';
// Constants
import { MASK_CLASS, REDACT_CLASS } from '../Constants/Constants';

const maskFn = (text: string, element: HTMLElement | null): string => {
  const canMask = element?.classList.contains(MASK_CLASS);
  return canMask ? '-'.repeat(text.length) : text;
};

const maskInputFn = (text: string, element: HTMLElement): string => {
  return maskFn(text, element);
};

const maskTextFn = (text: string, element: HTMLElement | null): string => {
  return maskFn(text, element);
};

export const initCoraLogix = () => {
  try {
    !CoralogixRum.isInited && CoralogixRum.init(applyConfig());
  } catch (e) {
    console.error('Failed to initialise Coralogix RUM');
  }
};

export const addUserContext = (email: string): void => {
  CoralogixRum.setUserContext({
    user_id: '',
    user_name: '',
    user_email: email
  });
};

const applyConfig = (): CoralogixBrowserSdkConfig => {
  const coralogixConfigObject = getConfigProperty('coralogixConfig') as CoralogixBrowserSdkConfig;
  return {
    public_key: coralogixConfigObject.public_key,
    application: coralogixConfigObject.application,
    coralogixDomain: coralogixConfigObject.coralogixDomain,
    version: process.env.REACT_APP_VERSION as string,
    maskInputTypes: ['password'],
    maskClass: MASK_CLASS,
    sessionRecordingConfig: {
      enable: true,
      autoStartSessionRecording: true,
      recordConsoleEvents: true,
      maskInputOptions: {
        password: true,
        text: true
      },
      maskTextClass: MASK_CLASS,
      blockClass: REDACT_CLASS,
      maskInputFn,
      maskTextFn
    },
    beforeSend: (event) => {
      const element = event.interaction_context?.target_element;
      if (element && (element === 'DIV' || element === 'FORM')) {
        if (event.interaction_context) {
          event.interaction_context.target_element_inner_text = `***`;
        }
      }
      return event;
    }
  };
};
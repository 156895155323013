import { FC, useContext } from 'react';
import { styled } from '@mui/material/styles';

// Contexts
import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
import RPButton from '../../atoms/RPButton';
import {
  ActionBtnContainer,
  IconContainer,
  StyledBody,
  ServiceModalProps,
  StyledHeading
} from '../RPModals/ServiceModalCommon';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { USA, CAN } from '../../../core/utils/Constants/Constants';

// Interfaces
interface NoServiceProps extends ServiceModalProps {
  selectedCountry: string;
}

// Styled components
const ExpandServiceText = styled(RPText)(() => {
  return {
    textAlign: 'center'
  };
});

const NoService: FC<NoServiceProps> = (props: NoServiceProps) => {
  const { tenant } = useContext(TenantContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { handleClose, selectedState, size = 'normal', selectedCountry, isFromLandingOrRegister = false } = props;

  const isSmallSize: boolean = size === 'small';

  // Icons
  const LocationLockedIcon = getIcon(tenant, 'locationLocked');

  // Translations
  const canadaCountryTxt: string = translate('modal.noService.canadaCountryText');
  const headingLine1: string = translate('modal.noService.heading.line1');
  const headingLine2: string = translate('modal.noService.heading.line2', {
    state: selectedState
  });
  const descriptionLine1: string = translate('modal.noService.description.line1');
  const descriptionLine2: string = translate('modal.noService.description.line2', {
    country: selectedCountry === CAN.toUpperCase() ? canadaCountryTxt : USA.toUpperCase()
  });
  const closeButtonText: string = translate('modal.noService.closeButtonText');
  const registerPageBackBtnText: string = translate('registerPage.backButtonText');

  const buttonText: string = isFromLandingOrRegister ? registerPageBackBtnText : closeButtonText;

  const headingVariant: 'body1' = 'body1';
  const headingType: 'bold' = 'bold';

  const heading = !isSmallSize ? (
    <StyledHeading
      variant={headingVariant}
      type={headingType}
      text={`${headingLine1} ${headingLine2}`}
      hasMarginBottom={true}
    />
  ) : (
    <>
      <StyledHeading variant={headingVariant} type={headingType} text={headingLine1} hasMarginBottom={false} />
      <StyledHeading variant={headingVariant} type={headingType} text={headingLine2} hasMarginBottom={true} />
    </>
  );

  const description = isSmallSize ? (
    <ExpandServiceText variant="body2" type="normal" text={`${descriptionLine1} ${descriptionLine2}`} />
  ) : (
    <>
      <ExpandServiceText variant="body2" type="normal" text={descriptionLine1} />
      <ExpandServiceText variant="body2" type="normal" text={descriptionLine2} />
    </>
  );

  return (
    <StyledBody size={size} isFromLandingOrRegister={isFromLandingOrRegister} data-testid="rp-no-service-modal-body">
      <IconContainer size={size}>
        <LocationLockedIcon />
      </IconContainer>

      {heading}
      {description}
      <ActionBtnContainer size={size}>
        <RPButton
          onClick={handleClose}
          size={isSmallSize ? 'small' : 'large'}
          name="NoServiceCloseModalButton"
          data-testid="rp-button-modal-no-service-close"
        >
          {buttonText}
        </RPButton>
      </ActionBtnContainer>
    </StyledBody>
  );
};

export default NoService;

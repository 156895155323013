import { createAsyncThunk } from '@reduxjs/toolkit';

// Types
import { EmploymentIndustryType } from '../../core/types/EmploymentFieldTypes';
// RTK Slice
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
// API Wrappers
import axiosWrapper, { ObjectMapping } from './axiosWrapper';
// Utils
import GetBasicDetails from '../../core/utils/GetBasicDetails';
import EmploymentIndustryMockData from '../../core/utils/MockData/EmploymentIndustryMockData.json';

type getEmploymentIndustryDataRequest = {
  locale: string;
};

const getEmploymentIndustryData = createAsyncThunk(
  '/reference-data/employment-industry',
  async (data: getEmploymentIndustryDataRequest, thunkAPI) => {
    const { locale } = data;
    const { orgCodeID, localeId } = GetBasicDetails(locale);

    const dataToInsert: ObjectMapping = {
      orgCodeID: orgCodeID + '',
      localeId: localeId + ''
    };

    try {
      const response = await axiosWrapper('getEmploymentIndustryData', 'GET', thunkAPI, null, dataToInsert);
      const formattedResponse: EmploymentIndustryType[] = response.data.data.reduce(
        (empIndustryData: EmploymentIndustryType[], currentObj: any) =>
          currentObj.isActive
            ? [
                ...empIndustryData,
                {
                  name: currentObj.name,
                  displayName: currentObj.displayName
                }
              ]
            : empIndustryData,
        []
      );
      return formattedResponse;
    } catch (error: any) {
      console.error('Error fetching employment status data:', error);
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }

    // try {
    //   // TODO: REG-210 - Replace with actual API call
    //   const formattedResponse: EmploymentIndustryType[] | [] = EmploymentIndustryMockData.data.reduce(
    //     (empIndustryData: EmploymentIndustryType[], currentObj: any) =>
    //       currentObj.isActive
    //         ? (empIndustryData.push({
    //             name: currentObj.name,
    //             displayName: currentObj.displayName
    //           }),
    //           empIndustryData)
    //         : empIndustryData,
    //     []
    //   );

    //   return formattedResponse;
    // } catch (error: any) {
    //   thunkAPI.dispatch(getServicesErrorObj(error));
    //   return thunkAPI.rejectWithValue({ error: error.response.data.message });
    // }
  }
);

export default getEmploymentIndustryData;

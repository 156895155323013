import { useState } from 'react';

import RPDatePicker from '../../atoms/RPDatePicker';

const RPDatePickerVariants = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  return (
    <>
      {/* Normal Datepicker */}
      <RPDatePicker
        name="normalDatePicker"
        value={selectedDate}
        placeholderLabel="Date of expiry"
        handleOnChange={setSelectedDate}
      />

      {/* Normal Datepicker with error */}
      <RPDatePicker
        name="normalDatePickerWithError"
        value={selectedDate}
        placeholderLabel="Date of expiry"
        handleOnChange={setSelectedDate}
        error="Invalid date"
      />

      {/* Disabled Normal Datepicker*/}
      <RPDatePicker
        name="normalDisabledDatepicker"
        value={selectedDate}
        placeholderLabel="Date of expiry"
        handleOnChange={setSelectedDate}
        isDisabled={true}
      />

      {/* Small Datepicker*/}
      <RPDatePicker
        name="smallDatePicker"
        value={selectedDate}
        placeholderLabel="Date of expiry"
        handleOnChange={setSelectedDate}
        size="small"
      />

      {/* Small Datepicker with error */}
      <RPDatePicker
        name="smallDatePickerWithError"
        value={selectedDate}
        placeholderLabel="Date of expiry"
        handleOnChange={setSelectedDate}
        error="Invalid date"
        size="small"
      />

      {/* Disabled Small Datepicker*/}
      <RPDatePicker
        name="smallDisabledDatepicker"
        value={selectedDate}
        placeholderLabel="Date of expiry"
        handleOnChange={setSelectedDate}
        isDisabled={true}
        size="small"
      />
    </>
  );
};

export default RPDatePickerVariants;

import { Theme } from '@mui/material/styles';

const GetOnFidoCustomUITheme = (theme: Theme) => {
  const {
    colours: {
      text: { text, textWeak }
    },
    customTypography: {
      fontFamily,
      h2: {
        bold: { fontWeight: h2BoldFontWeight },
        normal: { fontSize: h2NormalFontSize }
      },
      body2: {
        medium: { fontWeight: body2MediumFontWeight },
        normal: { fontSize: body2NormalFontSize }
      }
    }
  } = theme;

  return {
    fontFamilyTitle: fontFamily,
    fontFamilySubtitle: fontFamily,
    fontFamilyBody: fontFamily,
    fontSizeTitle: `${h2NormalFontSize}px`,
    fontWeightTitle: h2BoldFontWeight,
    colorContentTitle: text,
    fontSizeSubtitle: `${body2NormalFontSize}px`,
    fontWeightSubtitle: body2MediumFontWeight,
    colorContentSubtitle: textWeak,
    fontSizeBody: `${body2NormalFontSize}px`,
    fontWeightBody: body2MediumFontWeight,
    colorContentBody: textWeak
  };
};

export default GetOnFidoCustomUITheme;

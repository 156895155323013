import { FC, useState, ChangeEvent, FocusEvent, KeyboardEvent, useContext, useMemo, RefObject } from 'react';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import RPLoader from '../RPLoader';
import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import {
  getBody2NormalStyles,
  getBodyExtraSmallNormalStyles
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import RPValidationErrorMessage from '../RPValidationErrorMessage/RPValidationErrorMessage';
// Constants
import { MASK_CLASS } from '../../../core/utils/Constants/Constants';

type InputTypes = 'text' | 'password' | 'email' | 'number';

interface SizeProps {
  size: 'normal' | 'small';
}

interface InputProps {
  name: string;
  value: string;
  placeholderLabel: string;
  size?: SizeProps['size'];
  type?: InputTypes;
  error?: string;
  autocomplete?: 'on' | 'off';
  isDisabled?: boolean;
  maxLength?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  inputRef?: RefObject<HTMLInputElement>;
  showLoader?: boolean;
  isPopperOpen?: boolean;
  isCoralogixMasked?: boolean;
}

interface StyledFormControlProps {
  isPopperOpen: boolean;
}

interface StyledInputAdornmentProps {
  showLoader?: boolean;
}

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'isPopperOpen'
})<StyledFormControlProps>(({ theme, error, disabled, isPopperOpen, size }) => {
  const {
    colours: {
      borders: { borderInput, borderSelectionHover, borderInputActive, borderError, borderInputFocus }
    }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT: HexColour = borderInput;
  const COLOUR_BORDER_BORDER_SELECTION_HOVER: HexColour = borderSelectionHover;
  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borderInputActive;
  const COLOUR_BORDER_BORDER_ERROR: HexColour = borderError;
  const COLOUR_BORDER_BORDER_INPUT_FOCUS: HexColour = borderInputFocus;

  let borderColor: string = 'transparent';
  let borderHoverColor: string = COLOUR_BORDER_BORDER_SELECTION_HOVER;
  let borderFocusColor: string = COLOUR_BORDER_BORDER_INPUT_FOCUS;
  let boxShadowColor: string = COLOUR_BORDER_BORDER_INPUT;
  let boxShadowHoverFocusColor: string = 'none';
  let isBorderBottomNotApplicable: boolean = false;

  if (error) {
    borderColor = COLOUR_BORDER_BORDER_ERROR;
    borderHoverColor = COLOUR_BORDER_BORDER_ERROR;
    borderFocusColor = COLOUR_BORDER_BORDER_ERROR;
  } else if (disabled) {
    borderColor = COLOUR_BORDER_BORDER_INPUT;
    borderHoverColor = COLOUR_BORDER_BORDER_INPUT;
  } else if (isPopperOpen) {
    borderColor = COLOUR_BORDER_BORDER_INPUT_ACTIVE;
    borderFocusColor = COLOUR_BORDER_BORDER_INPUT_ACTIVE;
    borderHoverColor = COLOUR_BORDER_BORDER_INPUT_ACTIVE;
    isBorderBottomNotApplicable = true;
  }

  const isSmallSize: boolean = size === 'small';

  return {
    height: isSmallSize ? 52 : 60,
    boxSizing: 'border-box',
    border: `${disabled ? '1px' : '2px'} solid transparent`,
    ...(!disabled &&
      !error && {
        boxShadow: `${boxShadowColor} 0px 0px 0px 1px`
      }),
    borderColor: borderColor,
    borderRadius: '4px',
    width: '100%',
    transition: 'all 0.3s ease-in-out 0s',

    '&:hover': {
      borderColor: borderHoverColor,
      boxShadow: boxShadowHoverFocusColor
    },

    '&:focus-within': {
      border: `2px solid ${borderFocusColor}`,
      boxShadow: boxShadowHoverFocusColor
    },

    ...(isBorderBottomNotApplicable && {
      borderBottom: 'none',
      boxShadow: 'transparent 0px 0px 0px 1px'
    })
  };
});

const StyledInputLabel = styled(InputLabel, {
  shouldForwardProp: (prop) => prop !== 'size'
})<SizeProps>(({ theme, error, size }) => {
  const {
    colours: {
      text: { textError, textInputLabel, textInputDisabled }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_ERROR: HexColour = textError;
  const COLOUR_TEXT_TEXT_INPUT_LABEL: HexColour = textInputLabel;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: HexColour = textInputDisabled;

  const isSmallSize: boolean = size === 'small';

  return {
    ...getBody2NormalStyles(theme),
    color: error ? COLOUR_TEXT_TEXT_ERROR : COLOUR_TEXT_TEXT_INPUT_LABEL,
    paddingLeft: '6px',
    transform: isSmallSize ? 'translate(12px, 14px) scale(1)' : 'translate(12px, 18px) scale(1)',

    '&.MuiFormLabel-filled': {
      ...getBodyExtraSmallNormalStyles(theme),
      color: error ? COLOUR_TEXT_TEXT_ERROR : COLOUR_TEXT_TEXT_INPUT_LABEL,
      transform: `translate(12px, ${isSmallSize ? '5px' : '9px'})`
    },

    '&.Mui-focused': {
      ...getBodyExtraSmallNormalStyles(theme),
      color: error ? COLOUR_TEXT_TEXT_ERROR : COLOUR_TEXT_TEXT_INPUT_LABEL,
      paddingLeft: '6px',
      transform: `translate(12px, ${isSmallSize ? '5px' : '9px'})`
    },

    '&.Mui-disabled': {
      color: COLOUR_TEXT_TEXT_INPUT_DISABLED
    }
  };
});

const StyledInput = styled(FilledInput)(({ theme, size }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt, backgroundInputDisabled },
      text: { textInputDisabled }
    }
  } = theme;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: HexColour = textInputDisabled;
  const COLOUR_BG_BACKGROUND_INPUT_DISABLED: HexColour = backgroundInputDisabled;

  const isSmallSize = size === 'small';

  return {
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    padding: isSmallSize ? '0 16px' : '0 18px',
    borderRadius: 'inherit',

    ':before, :after': {
      borderBottom: 'none',
      transition: 'none'
    },

    '&.Mui-focused': {
      backgroundColor: COLOUR_BG_BACKGROUND_ALT
    },

    '&:hover': {
      backgroundColor: COLOUR_BG_BACKGROUND_ALT,
      '&:not(.Mui-disabled)': {
        '&:before': {
          borderBottom: 'none'
        }
      }
    },

    '&.Mui-disabled': {
      backgroundColor: COLOUR_BG_BACKGROUND_INPUT_DISABLED,
      color: 'transparent'
    },

    '> input': {
      ...getBody2NormalStyles(theme),
      paddingLeft: 0,
      paddingTop: isSmallSize ? '20px' : '25px',
      paddingBottom: isSmallSize ? '5px' : '8px',

      ':disabled': {
        color: COLOUR_TEXT_TEXT_INPUT_DISABLED
      },

      '&[type="password"]::-ms-reveal': {
        display: 'none'
      }
    }
  };
});

const StyledInputAdornment = styled(InputAdornment, {
  shouldForwardProp: (prop) => prop !== 'showLoader'
})<StyledInputAdornmentProps>(({ showLoader }) => {
  return {
    ...(showLoader && {
      '> div': {
        display: 'flex'
      }
    })
  };
});

const ErrorIconContainer = styled('div')(({ theme }) => {
  const {
    colours: {
      text: { textError }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_ERROR: HexColour = textError;

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '> svg > path': {
      fill: COLOUR_TEXT_TEXT_ERROR
    }
  };
});

const StyledIconButton = styled(IconButton)(({ theme }) => {
  const {
    colours: {
      text: { textInputLabel, textInputIcon, textInputFocus }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INPUT_LABEL: HexColour = textInputLabel;
  const COLOUR_TEXT_TEXT_INPUT_ICON: HexColour = textInputIcon;
  const COLOUR_TEXT_TEXT_INPUT_FOCUS: HexColour = textInputFocus;

  return {
    padding: 0,

    ':hover': {
      backgroundColor: 'transparent'
    },

    '> svg > path': {
      fill: COLOUR_TEXT_TEXT_INPUT_LABEL
    },

    '&.Mui-focusVisible': {
      outline: `${COLOUR_TEXT_TEXT_INPUT_FOCUS} solid 1px`,
      borderRadius: '4%',
      outlineOffset: 4,

      '> svg > path': {
        fill: COLOUR_TEXT_TEXT_INPUT_ICON
      }
    }
  };
});

const RPInput: FC<InputProps> = (props: InputProps) => {
  const {
    name,
    value,
    placeholderLabel,
    size = 'normal',
    type = 'text',
    error,
    autocomplete = 'off',
    isDisabled = false,
    maxLength,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    inputRef,
    showLoader,
    isPopperOpen,
    isCoralogixMasked
  } = props;

  const { tenant } = useContext(TenantContext);

  const ErrorIcon = useMemo(() => getIcon(tenant, 'statusWarningFilled'), [tenant]);
  const HiddenDataIcon = useMemo(() => getIcon(tenant, 'toolsShow'), [tenant]);
  const VisibleDataIcon = useMemo(() => getIcon(tenant, 'toolsHide'), [tenant]);

  const isInputTypeNumber: boolean = type === 'number';
  const currentType: string = isInputTypeNumber ? 'text' : type;

  const [inputType, setInputType] = useState(currentType);

  const toggleInputHandler = () => {
    setInputType(inputType === 'text' ? 'password' : 'text');
  };

  const passwordIcon = () => (inputType === 'password' ? <HiddenDataIcon /> : <VisibleDataIcon />);

  return (
    <div>
      <StyledFormControl
        variant="filled"
        error={!!error}
        disabled={isDisabled}
        isPopperOpen={!!isPopperOpen}
        size={size === 'normal' ? 'medium' : size}
        data-testid="form-control"
      >
        {placeholderLabel && (
          <StyledInputLabel htmlFor={name} error={!!error} data-testid="input-label" disabled={isDisabled} size={size}>
            {placeholderLabel}
          </StyledInputLabel>
        )}
        <StyledInput
          name={name}
          defaultValue={!onChange ? value : undefined}
          value={!!onChange ? value : undefined}
          type={inputType}
          size={size === 'normal' ? 'medium' : size}
          autoComplete={autocomplete}
          disabled={isDisabled}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          inputRef={inputRef}
          inputProps={{
            maxLength: maxLength,
            ...(isInputTypeNumber && { inputMode: 'decimal' }),
            ...(isCoralogixMasked && { className: MASK_CLASS })
          }}
          endAdornment={
            <StyledInputAdornment position="end" showLoader={showLoader}>
              {!showLoader && !!error && type.trim().toLowerCase() !== 'password' ? (
                <ErrorIconContainer>
                  <ErrorIcon data-testid="error-icon" />
                </ErrorIconContainer>
              ) : (
                type.trim().toLowerCase() === 'password' && (
                  <StyledIconButton
                    aria-label="toggle visibility"
                    onClick={toggleInputHandler}
                    disableRipple
                    name="toggleVisibilityButton"
                    data-testid="rp-button-toggle-visibility"
                  >
                    {passwordIcon()}
                  </StyledIconButton>
                )
              )}
              {showLoader && <RPLoader type="secondary" size="small" />}
            </StyledInputAdornment>
          }
        />
      </StyledFormControl>
      {!!error && <RPValidationErrorMessage message={error} />}
    </div>
  );
};

export default RPInput;

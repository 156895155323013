import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';

// Components - Atoms, Molecules, Organisms, Pages
import RPMobileHeader from '../../molecules/RPMobileHeader';
import RPMobileMenu from '../../molecules/RPMobileMenu';
// Hooks
import { ScrollDirectionType } from '../../../core/hooks/useScrollDirection';
import { useStepTypeData } from '../../../core/hooks';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { OptionType } from '../../../core/types/SelectTypes';
import { BaseRegistrationStep } from '../../../core/types/StepProgressTrackerTypes';
// RTK Slice
import { getRegistrationSteps } from '../../../redux/modules/registrationStepsSlice';
import { getRegistrationDetails } from '../../../redux/modules/registrationDetailsSlice';

interface RPMobileMenuContainerProps {
  languageOptions: OptionType[] | [];
  scrollDirection: ScrollDirectionType;
  hideAdditionalInteractions?: boolean;
  isFromLandingOrRegister: boolean;
}

interface MobileHeaderProps {
  scrollDirection: ScrollDirectionType;
}

const MobileHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'scrollDirection'
})<MobileHeaderProps>(({ theme, scrollDirection }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  return {
    width: '100%',
    height: '72px',
    position: 'fixed',
    top: scrollDirection === 'down' ? '-72px' : 0,
    zIndex: 200,
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '500ms'
  };
});

const Container = styled('div')(({ theme }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  return {
    height: 'calc(100svh - 72px)',
    width: 'inherit',
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    overflow: 'auto',
    display: 'flex'
  };
});

const RPMobileMenuContainer: FC<RPMobileMenuContainerProps> = (props: RPMobileMenuContainerProps) => {
  const { languageOptions, scrollDirection, hideAdditionalInteractions, isFromLandingOrRegister } = props;

  const { steps } = useSelector(getRegistrationSteps);
  const { shouldHideBurgerMenu } = useSelector(getRegistrationDetails);

  const { nextStepType } = useStepTypeData('set-preferences');

  const [showMenu, setShowMenu] = useState(false);

  const confirmingYouStepDetails: BaseRegistrationStep | undefined = steps.find(
    (obj: BaseRegistrationStep) => obj.type === nextStepType
  );

  //To hide the menu icon on confirming you screen
  const hideBurgerMenuIcon: boolean =
    !!(
      confirmingYouStepDetails?.isLoading &&
      !confirmingYouStepDetails?.showEditIcon &&
      confirmingYouStepDetails?.status === 'active'
    ) || shouldHideBurgerMenu;

  const isHideAdditionalInteractions: boolean = hideAdditionalInteractions || hideBurgerMenuIcon;

  return (
    <MobileHeader scrollDirection={showMenu ? null : scrollDirection}>
      <RPMobileHeader
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        hideAdditionalInteractions={isHideAdditionalInteractions}
      />
      <Slide direction="left" in={showMenu} mountOnEnter timeout={350}>
        <Container data-testid="mobile-menu-container">
          <RPMobileMenu
            languageOptions={languageOptions}
            setShowMenu={setShowMenu}
            isFromLandingOrRegister={isFromLandingOrRegister}
          />
        </Container>
      </Slide>
    </MobileHeader>
  );
};

export default RPMobileMenuContainer;

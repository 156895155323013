import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper, { ObjectMapping } from './axiosWrapper';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
import GetBasicDetails from '../../core/utils/GetBasicDetails';

type getEtaDataPayload = {
  locale: string
}

const getEtaData = createAsyncThunk(
  '/reference-data/estimated-transfer-amounts', async (data: getEtaDataPayload, thunkAPI) => {

    const { orgCode, localeId } = GetBasicDetails(data.locale);

    const dataToInsert: ObjectMapping = {
      orgCode,
      localeId: localeId + '',
    };

    try {
      const response = await axiosWrapper('getEtaData', 'GET', thunkAPI, null, dataToInsert);

      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  });

export default getEtaData;
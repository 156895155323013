import { useState, FC } from 'react';

import { RPDocsRequiredDialog } from '../../molecules/RPDialog';
import RPButton from '../../atoms/RPButton';

const RPDialogVariants: FC = () => {
  const [isDocRequiredDialogOpen, setIsDocRequiredDialogOpen] = useState(false);
  const [isSmallDocRequiredDialogOpen, setIsSmallDocRequiredDialogOpen] =
    useState(false);

  return (
    <>
      {/*Docs required dialog */}
      <RPButton onClick={() => setIsDocRequiredDialogOpen(true)}>
        Docs required Dialog
      </RPButton>
      <RPDocsRequiredDialog
        isOpen={isDocRequiredDialogOpen}
        handleClose={() => setIsDocRequiredDialogOpen(false)}
      />

      {/*Docs required dialog small*/}
      <RPButton
        onClick={() => setIsSmallDocRequiredDialogOpen(true)}
        size="medium"
      >
        Docs required Dialog small
      </RPButton>
      <RPDocsRequiredDialog
        isOpen={isSmallDocRequiredDialogOpen}
        handleClose={() => setIsSmallDocRequiredDialogOpen(false)}
        size="small"
      />
    </>
  );
};

export default RPDialogVariants;

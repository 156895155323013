import { FC, memo, useContext, MouseEvent, KeyboardEvent } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPSelectNew } from '../../atoms/RPSelect';
import { RPText } from '../../atoms/RPText';
import RPLink from '../../atoms/RPLink';
// Types
import { OptionType } from '../../../core/types/SelectTypes';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { AppDispatch } from '../../../redux/store';
import { OrganizationCodeTypes } from '../../../core/types/OrganizationTypes';
// RTK Slice
import { openLoginDialogModal } from '../../../redux/modules/loginDetailsSlice';
import { setSelectedLanguage } from '../../../redux/modules/referenceDataSlice';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getLoginPageURL } from '../../../core/utils/GetOrganizationEnvSpecificURLs';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import { CreateCookie as createCookie } from '../../../core/utils/CreateCookie';
// Constants
import {
  ENTER_KEY,
  LANDING_PAGE_SPECIFIC_CHANGES_ORGANIZATIONS_LIST,
  LANGUAGE_COOKIE_NAME,
  LANGUAGE_COOKIE_OPTION,
  LOCALE_LANG_CODE_MAPPING,
  ORGANIZATION_CODE_MAPPING,
  SHOW_LANGUAGE_DROPDOWN_ORGANIZATIONS_LIST
} from '../../../core/utils/Constants/Constants';
// GA4
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

interface RPHeaderRightProps {
  selectedLanguage?: string;
  languageOptions?: OptionType[];
  isLandingPage?: boolean;
  isRegisterPage?: boolean;
}

const HeaderRightContainer = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center',
    justifyContent: 'flex-end'
  };
});

const LanguageDropdownContainer = styled('div')(() => {
  return {
    width: '100px',
    flex: '1 0 100px'
  };
});

const TextWrapper = styled('div')(() => {
  return {
    display: 'flex',
    flexWrap: 'wrap'
  };
});

interface LabelTextProps {
  shouldChangeLabelColour: boolean;
}

const LabelText = styled(RPText, {
  shouldForwardProp: (prop) => prop !== 'shouldChangeLabelColour'
})<LabelTextProps>(({ theme, shouldChangeLabelColour }) => {
  const {
    colours: {
      text: { textWeak, textLandingPageWeakest }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const COLOUR_TEXT_TEXT_LANDING_PAGE_WEAKEST: HexColour = textLandingPageWeakest;

  return {
    color: shouldChangeLabelColour ? COLOUR_TEXT_TEXT_LANDING_PAGE_WEAKEST : COLOUR_TEXT_TEXT_WEAK
  };
});

interface StyledRPLinkProps {
  shouldChangeLabelColour: boolean;
}

const StyledRPLink = styled(RPLink, {
  shouldForwardProp: (prop) => prop !== 'shouldChangeLabelColour'
})<StyledRPLinkProps>(({ theme, shouldChangeLabelColour }) => {
  const {
    colours: {
      text: { textLandingPage }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_LANDING_PAGE: HexColour = textLandingPage;

  return {
    ...(shouldChangeLabelColour && {
      color: COLOUR_TEXT_TEXT_LANDING_PAGE,
      ':hover': {
        color: COLOUR_TEXT_TEXT_LANDING_PAGE
      },
      '&.Mui-focusVisible': {
        color: COLOUR_TEXT_TEXT_LANDING_PAGE
      }
    })
  };
});

const RPHeaderRight: FC<RPHeaderRightProps> = (props: RPHeaderRightProps) => {
  const dispatch: AppDispatch = useDispatch();

  const { layout } = useContext(LayoutContext);

  const { language, translations, setLanguage } = useContext(LanguageContext);
  const { tenant } = useContext(TenantContext);

  const translate = TranslateWrapper(translations);

  const { languageOptions, isLandingPage = false, isRegisterPage = false } = props;

  const alreadyHaveAccountText: string = translate('registration.header.alreadyHaveAccountText');
  const loginInText: string = translate('registration.header.loginInText');
  const placeholder: string = translate('registration.header.placeholder');

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const loginPageURL: string = getLoginPageURL(tenant);

  const hideLangDropDownForLandingPage: boolean = isLandingPage && isMobileLayout;

  const showLanguageDropdown: boolean =
    tenant && !hideLangDropDownForLandingPage ? SHOW_LANGUAGE_DROPDOWN_ORGANIZATIONS_LIST.includes(tenant) : false;

  const isFromLandingOrRegister = isLandingPage || isRegisterPage;

  const shouldChangeLabelColour: boolean =
    isLandingPage &&
    LANDING_PAGE_SPECIFIC_CHANGES_ORGANIZATIONS_LIST.includes(tenant || ORGANIZATION_CODE_MAPPING.currenciesdirect as OrganizationCodeTypes);

  const {
    eventAction: { select },
    landingPage: {
      select: { landingPageLanguageSelection }
    }
  } = GoogleAnalyticsTagsMapping;

  const showLoginDialog = (event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    isFromLandingOrRegister ? window.open(loginPageURL, '_self') : dispatch(openLoginDialogModal(true));
  };

  const handleOnLanguageChange = (value: string) => {
    setLanguage(value);

    const selectedLanguageCode: string = LOCALE_LANG_CODE_MAPPING[value];

    isFromLandingOrRegister && TriggerGoogleAnalyticsTag(select, landingPageLanguageSelection, {
      language: selectedLanguageCode
    });

    dispatch(setSelectedLanguage(value));

    createCookie(LANGUAGE_COOKIE_NAME, selectedLanguageCode, LANGUAGE_COOKIE_OPTION);
  };

  return (
    <HeaderRightContainer data-testid="rp-header-right">
      <TextWrapper>
        <LabelText
          shouldChangeLabelColour={shouldChangeLabelColour}
          variant="body2"
          type="bold"
          text={alreadyHaveAccountText}
        />

        <StyledRPLink
          underline="hover"
          text={loginInText}
          onClick={(e: MouseEvent<HTMLAnchorElement>) => showLoginDialog(e)}
          onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
            e.key === ENTER_KEY && showLoginDialog(e);
          }}
          href=""
          data-testid="rp-link-login"
          shouldChangeLabelColour={shouldChangeLabelColour}
        />
      </TextWrapper>
      {languageOptions && languageOptions.length > 0 && showLanguageDropdown && (
        <LanguageDropdownContainer>
          <RPSelectNew
            name="language-drop-down"
            placeholderLabel={placeholder}
            value={language}
            toShrinkLabel={false}
            variant="x-small"
            options={languageOptions}
            isSearchable={false}
            isFixedHeight={true}
            onChange={handleOnLanguageChange}
            data-testid="rp-select-input"
          />
        </LanguageDropdownContainer>
      )}
    </HeaderRightContainer>
  );
};

export default memo(RPHeaderRight);

import {
  FC,
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
  RefObject,
  useEffect,
  useState
} from 'react';

// Contexts
import { ContentRefContext, LayoutContext } from '../../../core/TenantProvider/contexts';
// Hooks
import { useLayoutSize } from '../../../core/hooks';
// Types
import { LayoutSize } from '../../../core/types/LayoutTypes';

export type ComponentChildrenType =
  | string
  | number
  | boolean
  | ReactElement<any, string | JSXElementConstructor<any>>
  | ReactFragment
  | ReactPortal
  | null
  | undefined;

interface RPLayoutProps {
  children: ComponentChildrenType;
}

const RPLayout: FC<RPLayoutProps> = (props: RPLayoutProps) => {
  const layoutSize: LayoutSize = useLayoutSize();

  const [currentLayout, setCurrentLayout] = useState<LayoutSize | ''>('');
  const [contentRefObj, setContentRefObj] = useState<RefObject<HTMLDivElement> | null>(null);

  useEffect(() => {
    if (layoutSize !== currentLayout) {
      setCurrentLayout(layoutSize);
    }
  }, [layoutSize]);

  const updateLayout = (updatedLayout: LayoutSize) => {
    if (currentLayout !== updatedLayout) {
      setCurrentLayout(updatedLayout);
    }
  };

  const updateRef = (ref: RefObject<HTMLDivElement>) => setContentRefObj(ref);

  return (
    <>
      {currentLayout ? (
        <LayoutContext.Provider
          value={{
            layout: currentLayout,
            updateLayout
          }}
        >
          <ContentRefContext.Provider
            value={{
              ref: contentRefObj,
              updateRef
            }}
          >
            {props.children}
          </ContentRefContext.Provider>
        </LayoutContext.Provider>
      ) : (
        <></>
      )}
    </>
  );
};

export default RPLayout;

import { useState } from 'react';
import { styled } from '@mui/material/styles';

import RPPinField from '../../molecules/RPPinField';

const DesktopContainer = styled('div')(() => {
  return {
    width: '420px',
    margin: '0 auto',
  };
});

const TabletContainer = styled('div')(() => {
  return {
    width: '350px',
    margin: '0 auto',
  };
});

const MobileContainer = styled('div')(() => {
  return {
    width: '325px',
    margin: '0 auto',
  };
});

const RPPinFieldVariants = () => {
  const [value, setValue] = useState<string[]>(Array<string>(5).fill(''));

  return (
    <>
      {/* Desktop */}
      <DesktopContainer>
        <RPPinField length={5} onChange={setValue} value={value} />
      </DesktopContainer>

      {/* Tablet */}
      <TabletContainer>
        <RPPinField
          length={5}
          onChange={setValue}
          value={value}
          size="medium"
        />
      </TabletContainer>

      {/* Mobile */}
      <MobileContainer>
        <RPPinField length={5} onChange={setValue} size="small" value={value} />
      </MobileContainer>
    </>
  );
};

export default RPPinFieldVariants;

import { FC, useState, useCallback, useMemo, useEffect } from 'react';
import { styled } from '@mui/material/styles';

import RPFactCard from './RPFactCard';

const FactCardsWrapper = styled('div')(() => {
  return {
    marginBottom: '16px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '426px',
  };
});

const RPFactCards: FC = () => {
  const facts = useMemo(() => {
    return [
      'factCards.fact1',
      'factCards.fact2',
      'factCards.fact3',
      'factCards.fact4',
    ];
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);

  const handleCardTransition = useCallback(() => {
    // If we've reached the end, start again from the first card.
    if (currentIndex >= facts.length - 1) {
      setCurrentIndex(0);
      setNextIndex(1);
    } else {
      setCurrentIndex(currentIndex + 1);
      setNextIndex(currentIndex + 2 === facts.length ? 0 : currentIndex + 2);
    }
  }, [facts, currentIndex]);

  useEffect(() => {
    const transitionInterval = setInterval(() => {
      handleCardTransition();
    }, 6000);

    return () => clearInterval(transitionInterval);
  }, [handleCardTransition, currentIndex, nextIndex]);

  const getCardState = useCallback(
    (cardIndex: number) => {
      switch (cardIndex) {
        case currentIndex:
          return 'active';
        case nextIndex:
          return 'next';
        default:
          return 'inactive';
      }
    },
    [currentIndex, nextIndex]
  );

  return (
    <FactCardsWrapper>
      {facts.map((translationKey: string, index: number) => (
        <RPFactCard
          key={`fact-${index}`}
          handleCardTransition={handleCardTransition}
          getCardState={getCardState}
          index={index}
          translationKey={translationKey}
        />
      ))}
    </FactCardsWrapper>
  );
};

export default RPFactCards;

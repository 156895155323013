import { FC, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import { HexColour } from '../../../core/types/ThemeConfigTypes';

export type SizeProps = 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'xx-huge';

export interface TypeProps {
  type: 'primary' | 'secondary' | 'inverse';
}

interface RPLoaderProps {
  size?: SizeProps;
  type?: TypeProps['type'];
}

const LoaderContainer = styled('div')(() => {
  return {
    position: 'relative'
  };
});

const StyledCircularProgress = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'type'
})<TypeProps>(({ theme, type }) => {
  const {
    colours: { backgrounds }
  } = theme;

  const COLOUR_BG_BACKGROUND_PRIMARY: HexColour = backgrounds.backgroundPrimary;
  const COLOUR_BG_BACKGROUND_SECONDARY: HexColour = backgrounds.backgroundSecondary;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;

  const color: HexColour =
    type === 'primary'
      ? COLOUR_BG_BACKGROUND_PRIMARY
      : type === 'secondary'
      ? COLOUR_BG_BACKGROUND_SECONDARY
      : COLOUR_BG_BACKGROUND_ALT;

  return {
    color: color,

    '> svg > circle.MuiCircularProgress-circle': {
      strokeLinecap: 'round'
    }
  };
});

const RPLoader: FC<RPLoaderProps> = (props: RPLoaderProps) => {
  const { size = 'x-small', type = 'primary' } = props;

  const getLoaderSize = useMemo(() => {
    switch (size) {
      case 'x-small':
        return 18;

      case 'small':
        return 24;

      case 'medium':
        return 36;

      case 'large':
        return 56;

      case 'x-large':
        return 72;

      case 'xx-huge':
        return 80;
    }
  }, [size]);

  return (
    <LoaderContainer>
      <StyledCircularProgress size={getLoaderSize} type={type} thickness={4} />
    </LoaderContainer>
  );
};

export default RPLoader;
// Supported browser version
// Chrome - 1, Edge 12, Firefox - 1, Opera - 12.1, Safari - 1,
// Chrome Android - 18, Firefox Android - 4, Opera Android - 12.1
// Safari on iOS - 1, Samsung Internet - 1.0, Webview Android - 4, Deno - 1.22

export const isMobileOrTabletDevice = {
  android: () => navigator.userAgent.match(/Android/i),
  blackBerry: () => navigator.userAgent.match(/BlackBerry|playbook/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  iPad: () => (navigator.platform.match(/MacIntel/i) || navigator.maxTouchPoints > 4),
  opera: () => navigator.userAgent.match(/Opera Mini/i),
  windows: () => navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i),
  nokia: () => navigator.userAgent.match(/Nokia/i),
  any: () =>
    isMobileOrTabletDevice.android() ||
    isMobileOrTabletDevice.blackBerry() ||
    isMobileOrTabletDevice.iOS() ||
    isMobileOrTabletDevice.iPad() ||
    isMobileOrTabletDevice.opera() ||
    isMobileOrTabletDevice.windows() ||
    isMobileOrTabletDevice.nokia()
};

// getRoundedFileSize => takes in the filesize
// and returns the file size in mb if file size is above 1mb
// otherwise it returns the file size in kb

export const getRoundedFileSize = (fileSize: number): string => {
  const oneMB: number = 1000000;
  const oneKB: number = 1000;

  let currentFileSize: number;
  let currentFileSizeText: string;

  if (fileSize >= oneMB) {
    currentFileSize = fileSize / oneMB;
    currentFileSizeText = 'mb';
  } else {
    currentFileSize = fileSize / oneKB;
    currentFileSizeText = 'kb';
  }

  return Math.floor(currentFileSize) + currentFileSizeText;
};

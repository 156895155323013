import { Navigate, useLocation } from 'react-router-dom';

const RegistrationLandingPage = () => {
  const location = useLocation();

  return (
    <Navigate to={`registration/account${location.search}`} replace={true} />
  )
};

export default RegistrationLandingPage;
import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components - Atoms, Molecules, Organisms, Pages
import MobileNumberScreen from './MobileNumberScreen';
import SecurityPinScreen from './SecurityPinScreen';
import CompletedScreen from './CompletedScreen';
// Hooks
import { useScrollToContentTop } from '../../../../core/hooks';
// RTK Slice
import { getRegistrationDetails } from '../../../../redux/modules/registrationDetailsSlice';

export type SetupSecurityViewType = 'mobile-number-input' | 'security-pin' | 'mobile-number-confirmation';

const SetupSecurity: FC = () => {
  const { isValidOTP, updateLeadStatus, updateLeadLoading } = useSelector(getRegistrationDetails);

  const [viewType, setViewType] = useState<SetupSecurityViewType>('mobile-number-input');

  let currentView: JSX.Element;

  useEffect(() => {
    if (viewType !== 'mobile-number-confirmation' && isValidOTP && updateLeadLoading === 'idle' && updateLeadStatus) {
      setViewType('mobile-number-confirmation');
    }
  }, [isValidOTP, updateLeadStatus, updateLeadLoading, viewType]);

  // Scrolls to the top of the content area
  useScrollToContentTop();

  switch (viewType) {
    case 'security-pin':
      currentView = <SecurityPinScreen setViewType={setViewType} />;
      break;
    case 'mobile-number-confirmation':
      currentView = <CompletedScreen setViewType={setViewType} />;
      break;
    case 'mobile-number-input':
    default:
      currentView = <MobileNumberScreen setViewType={setViewType} />;
  }

  return <>{currentView}</>;
};

export default SetupSecurity;

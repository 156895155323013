import * as yup from 'yup';

export const ValidationSchema = () => {
  return yup
    .object({
      securityPin: yup
        .array()
        .of(
          yup
            .string()
            .matches(/^[0-9]/)
            .required()
        )
        .length(5),
    })
    .required();
};

import { FC } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import RPModal from './RPModal';
import { PartialService }  from '../PartialService';
// Types
import { ServiceModalProps } from './ServiceModalCommon';

// Interfaces
interface PartialServiceModalProps extends ServiceModalProps {
  isOpen: boolean;
}

const PartialServiceModal: FC<PartialServiceModalProps> = (props: PartialServiceModalProps) => {
  const { isOpen, selectedState, handleClose, size = 'normal' } = props;

  const body = <PartialService selectedState={selectedState} handleClose={handleClose} size={size} />;

  return <RPModal isOpen={isOpen} handleClose={handleClose} body={body} size={size} />;
};

export default PartialServiceModal;

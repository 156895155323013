import { useParams } from 'react-router-dom';

const useStepParams = () => {
  const { step } = useParams();

  let stepNumber: number;

  switch (step) {
    case 'account':
      stepNumber = 1;
      break;
    case 'set-up-security':
      stepNumber = 2;
      break;
    case 'your-details':
      stepNumber = 3;
      break;
    case 'set-preference':
      stepNumber = 4;
      break;
    case 'identity':
    case 'document-upload':
      stepNumber = 5;
      break;
    default:
      stepNumber = -1;
  }

  return { step, stepNumber };
};

export default useStepParams;

import { FC, useCallback, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';

import RPText from '../../atoms/RPText';
import {
  LanguageContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface CriteriaContainerProps {
  isLastChild?: boolean;
}

interface RPPasswordCriteriaProps {
  value: string;
}

type CriteriaTypes = 'minLength' | 'number' | 'lowerCase' | 'upperCase';

const Container = styled('div')(() => {
  return {
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    padding: '20px 25px',
    borderRadius: '4px',
  };
});

const Heading = styled(RPText)(() => {
  return {
    marginBottom: '8px',
  };
});

const CriteriaContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isLastChild',
})<CriteriaContainerProps>(({ isLastChild }) => {
  return {
    display: 'flex',
    ...(!isLastChild && { marginBottom: '5px' }),
  };
});

const IconContainer = styled('div')(({ theme }) => {
  const {
    colours: {
      text: { textSuccess, textInputDisabled },
      backgrounds: { backgroundAlt },
    },
  } = theme;

  const COLOUR_TEXT_TEXT_SUCCESS: HexColour = textSuccess;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: HexColour = textInputDisabled;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  return {
    marginRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '> svg': {
      height: '16px',
      width: '16px',
      '> path': {
        fill: COLOUR_TEXT_TEXT_SUCCESS,
      },

      '> circle': {
        fill: COLOUR_BG_BACKGROUND_ALT,
        stroke: COLOUR_TEXT_TEXT_INPUT_DISABLED,
      },
    },
  };
});

const CriteriaText = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak },
    },
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    color: COLOUR_TEXT_TEXT_WEAK,
  };
});

const RPPasswordCriteria: FC<RPPasswordCriteriaProps> = (
  props: RPPasswordCriteriaProps
) => {
  const { value } = props;

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);

  const CheckedIcon = useMemo(
    () => getIcon(tenant, 'statusCheckmarkIcon'),
    [tenant]
  );
  const UnCheckedIcon = useMemo(
    () => getIcon(tenant, 'statusUnCheckmarkIcon'),
    [tenant]
  );

  const validateCriteria = useCallback(
    (type: CriteriaTypes) => {
      if (value && value.length) {
        switch (type) {
          case 'minLength':
            return value.length > 7;

          case 'number':
            return /[0-9]/.test(value);

          case 'lowerCase':
            return /[a-z]/.test(value);

          case 'upperCase':
            return /[A-Z]/.test(value);
        }
      } else {
        return false;
      }
    },
    [value]
  );

  const showCriteriaText = useCallback(
    (text: string) => (
      <CriteriaText variant="body2" type="normal" text={text} />
    ),
    []
  );

  return (
    <Container data-testid="pwd-criteria-container">
      <Heading
        variant="body2"
        type="medium"
        text={translate('passwordCriteria.heading')}
      />
      <CriteriaContainer>
        <IconContainer>
          {validateCriteria('minLength') ? (
            <CheckedIcon data-testid="valid-min-length" />
          ) : (
            <UnCheckedIcon data-testid="in-valid-min-length" />
          )}
        </IconContainer>
        {showCriteriaText(translate('passwordCriteria.item1'))}
      </CriteriaContainer>
      <CriteriaContainer>
        <IconContainer>
          {validateCriteria('number') ? (
            <CheckedIcon data-testid="valid-number" />
          ) : (
            <UnCheckedIcon data-testid="in-valid-number" />
          )}
        </IconContainer>
        {showCriteriaText(translate('passwordCriteria.item2'))}
      </CriteriaContainer>
      <CriteriaContainer>
        <IconContainer>
          {validateCriteria('lowerCase') ? (
            <CheckedIcon data-testid="valid-lowercase" />
          ) : (
            <UnCheckedIcon data-testid="in-valid-lowerCase" />
          )}
        </IconContainer>
        {showCriteriaText(translate('passwordCriteria.item3'))}
      </CriteriaContainer>
      <CriteriaContainer isLastChild={true}>
        <IconContainer>
          {validateCriteria('upperCase') ? (
            <CheckedIcon data-testid="valid-uppercase" />
          ) : (
            <UnCheckedIcon data-testid="in-valid-uppercase" />
          )}
        </IconContainer>
        {showCriteriaText(translate('passwordCriteria.item4'))}
      </CriteriaContainer>
    </Container>
  );
};

export default RPPasswordCriteria;

import { FC, useContext, useMemo } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

import { RPText } from '../../atoms/RPText';
import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface ContentProps {
  hasText: boolean;
}

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;

  return {
    color: COLOUR_TEXT_TEXT_LINK,
    '& > svg > circle': {
      strokeLinecap: 'round'
    }
  };
});

const Container = styled(Box)(() => {
  return {
    position: 'relative',
    marginBottom: '50px'
  };
});

const Content = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasText'
})<ContentProps>(({ hasText }) => {
  return {
    top: !hasText ? 'calc(50% - 48px/2 + 0.5px)' : '65px',
    left: !hasText ? 'calc(50% - 66px/2 + 3.1px)' : '50px',
    ...(!hasText && { padding: 0, width: '66px', height: '48px' }),
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  };
});

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: { backgrounds }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK: HexColour = backgrounds.backgroundAltInverseWeak;

  return {
    position: 'absolute',
    '> svg > path': {
      fill: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK
    }
  };
});

const LoaderValue = styled(Box)(() => {
  return {
    display: 'flex',
    alignItems: 'baseline'
  };
});

interface RPPercentageLoaderProps {
  value: number;
  text?: string;
}

const RPPercentageLoader: FC<RPPercentageLoaderProps> = (props: RPPercentageLoaderProps) => {
  const { value, text } = props;

  const { tenant } = useContext(TenantContext);

  const BlankEllipsis = useMemo(() => getIcon(tenant, 'ellipseBlank'), [tenant]);

  return (
    <Container>
      <IconContainer>
        <BlankEllipsis />
      </IconContainer>
      <StyledCircularProgress variant="determinate" size={200} thickness={2} {...props} />
      <Content hasText={Boolean(text)}>
        <LoaderValue>
          <RPText variant="h1" type="bold" text={`${value}`} />
          <RPText variant="h3" type="normal" text="%" />
        </LoaderValue>
        {text && <RPText variant="body1" type="medium" text={text} />}
      </Content>
    </Container>
  );
};

export default RPPercentageLoader;

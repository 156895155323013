import { useContext } from 'react';
import * as yup from 'yup';

import { LanguageContext } from '../../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';

export const ValidationSchema = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const firstNameRequiredText: string = translate('registration.createLogin.firstNameRequired');
  const firstNameInvalidText: string = translate('registration.createLogin.firstNameInvalid');
  const firstNameMaxLengthText: string = translate('registration.createLogin.firstNameMaxLength');

  const lastNameRequiredText: string = translate('registration.createLogin.lastNameRequired');
  const lastNameInvalidText: string = translate('registration.createLogin.lastNameInvalid');
  const lastNameMaxLengthText: string = translate('registration.createLogin.lastNameMaxLength');

  const emailRequiredText: string = translate('registration.createLogin.emailRequired');
  const emailInvalidText: string = translate('registration.createLogin.emailInvalid');

  const passwordRequiredText: string = translate('registration.createLogin.passwordRequired');
  const passwordInvalidText: string = translate('registration.createLogin.passwordInvalid');

  const referralCodeRequiredText: string = translate('registration.createLogin.referralCodeRequired');
  const referralCodeRequiredInvalidText: string = translate('registration.createLogin.referralCodeInvalid');
  const referralCodeMaxLengthText: string = translate('registration.createLogin.referralCodeMaxLength');

  return yup
    .object({
      firstName: yup
        .string()
        .trim()
        .max(40, firstNameMaxLengthText)
        .required(firstNameRequiredText)
        .matches(
          /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]*$/i,
          firstNameInvalidText
        ),
      lastName: yup
        .string()
        .trim()
        .max(50, lastNameMaxLengthText)
        .required(lastNameRequiredText)
        .matches(
          /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]*$/i,
          lastNameInvalidText
        ),
      email: yup
        .string()
        .trim()
        .max(50, emailInvalidText)
        .required(emailRequiredText)
        .matches(/^[a-zA-Z0-9]+([._-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/i, emailInvalidText),
      password: yup
        .string()
        .trim()
        .required(passwordRequiredText)
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,50}$/g, passwordInvalidText),
      referred: yup.string().required(),
      referralCodeOrName: yup
        .string()
        .trim()
        .max(40, referralCodeMaxLengthText)
        .matches(/^[0-9a-zA-Z ]*$/i, referralCodeRequiredInvalidText)
        .when('referred', {
          is: 'yes',
          then: yup.string().required(referralCodeRequiredText)
        })
    })
    .required();
};

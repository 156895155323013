import { OrganizationCodeTypes } from '../../types/OrganizationTypes';

export const loadFont = (
  tenant: OrganizationCodeTypes,
  name: string,
  fileName: string,
  fontWeight: string,
  fontStyle: string
) => {
  import(`../../TenantProvider/tenants/${tenant}/fonts/${fileName}`)
    .then(({ default: fontPath }) => {
      const font: FontFace = new FontFace(
        name,
        'url(' + fontPath + ') format("opentype")',
        {
          weight: fontWeight,
          style: fontStyle,
        }
      );
      font
        .load()
        .then(() => {
          document.fonts.add(font);
        })
        .catch((error) => {
          console.log('Font error', error);
        });
    })
    .catch((err) => {
      console.log('Import error', err);
    });
};

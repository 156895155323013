import GetBasicDetails from '../GetBasicDetails';
import { getConfigProperty } from '../../EnvironmentConfig/EnvironmentConfig';
import { OrganizationCodeTypes, OrganizationDetailsEnvSpecific } from '../../types/OrganizationTypes';

const googleAnalyticsConfigUrl: Function = (gtmId: string) => `https://www.googletagmanager.com/ns.html?id=${gtmId}`;

const googleAnalyticsHeaderConfigUrl: Function = (gtmId: string) =>
  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`;

export const LoadGoogleAnalyticsScript = (type: string) => {
  const { referringCompany } = GetBasicDetails();
  let currentGAConfig: string = '';
  let googleAnalyticsScript: string = '';

  if (referringCompany) {
    currentGAConfig = (getConfigProperty('googleAnalyticsConfig') as OrganizationDetailsEnvSpecific)[referringCompany as OrganizationCodeTypes];

    const GAHeaderConfigURL: string = googleAnalyticsHeaderConfigUrl(currentGAConfig);
    const GABodyConfigURL: string = googleAnalyticsConfigUrl(currentGAConfig);

    googleAnalyticsScript = type === 'header' ? GAHeaderConfigURL : type === 'body' ? GABodyConfigURL : '';
  }

  return googleAnalyticsScript;
};

import { FC, useContext, KeyboardEvent } from 'react';
import { styled } from '@mui/material/styles';
import { Control } from 'react-hook-form';

// Contexts
import { LanguageContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPInput from '../../atoms/RPInput';
import RPValidationErrorMessage from '../../atoms/RPValidationErrorMessage/RPValidationErrorMessage';
import ControllerElementWrapper from '../../organisms/ControllerWrapper';
// Types
import { DoBType } from '../../../core/types/RegistrationStepForm';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { isModifierKey, isNumericInput } from '../../../core/utils/KeyboardInputChecks';

interface OrientationProps {
  orientation?: 'horizontal' | 'vertical';
}

interface RHFRPDoBProps extends OrientationProps {
  defaultValue: DoBType;
  fieldNames: [string, string, string];
  control: Control<any, any>;
  size?: 'normal' | 'small';
  dateFormat?: 'little-endian' | 'middle-endian' | 'big-endian'; // DD/MM/YYYY | MM/DD/YYYY | YYYY/MM/DD
  error?: string;
}

interface DobContainerProps extends OrientationProps {}

const DobContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'orientation'
})<DobContainerProps>(({ orientation }) => {
  const isHorizontalOrientation: boolean = orientation === 'horizontal';
  return {
    display: 'flex',
    gap: '15px',

    ...(isHorizontalOrientation
      ? {
          flexDirection: 'row',
          justifyContent: 'space-between'
        }
      : {
          flexDirection: 'column'
        })
  };
});

const RHFRPDoB: FC<RHFRPDoBProps> = (props: RHFRPDoBProps) => {
  const {
    defaultValue,
    fieldNames,
    control,
    size = 'normal',
    dateFormat = 'little-endian',
    orientation = 'horizontal',
    error
  } = props;

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const dayLabelText: string = translate('dob.dayLabel');
  const monthLabelText: string = translate('dob.monthLabel');
  const yearLabelText: string = translate('dob.yearLabel');

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // Input must be of a valid number format or a modifier key and shift key should not be pressed
    if ((!isNumericInput(event.keyCode) && !isModifierKey(event)) || event.shiftKey === true) {
      event.preventDefault();
    }
  };

  const dayInputField = () => (
    <ControllerElementWrapper
      name={fieldNames[0]}
      control={control}
      defaultValue={defaultValue.day}
      placeholderLabel={dayLabelText}
      component={RPInput}
      size={size}
      type="number"
      maxLength={2}
      handleOnKeyDown={handleOnKeyDown}
      isCoralogixMasked={true}
    />
  );

  const monthInputField = () => (
    <ControllerElementWrapper
      name={fieldNames[1]}
      control={control}
      defaultValue={defaultValue.month}
      placeholderLabel={monthLabelText}
      component={RPInput}
      size={size}
      type="number"
      maxLength={2}
      handleOnKeyDown={handleOnKeyDown}
      isCoralogixMasked={true}
    />
  );

  const yearInputField = () => (
    <ControllerElementWrapper
      name={fieldNames[2]}
      control={control}
      defaultValue={defaultValue.year}
      placeholderLabel={yearLabelText}
      component={RPInput}
      size={size}
      type="number"
      maxLength={4}
      handleOnKeyDown={handleOnKeyDown}
      isCoralogixMasked={true}
    />
  );

  const displayDobField = () => {
    if (dateFormat === 'little-endian') {
      return (
        <>
          {dayInputField()}
          {monthInputField()}
          {yearInputField()}
        </>
      );
    } else if (dateFormat === 'middle-endian') {
      return (
        <>
          {monthInputField()}
          {dayInputField()}
          {yearInputField()}
        </>
      );
    } else {
      return (
        <>
          {yearInputField()}
          {monthInputField()}
          {dayInputField()}
        </>
      );
    }
  };

  return (
    <div>
      <DobContainer data-testid="rp-dob-container" orientation={orientation}>
        {displayDobField()}
      </DobContainer>
      {!!error && <RPValidationErrorMessage message={error} />}
    </div>
  );
};

export default RHFRPDoB;

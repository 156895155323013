import React, { FC, useContext, useMemo } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  ListProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { RPText } from '../../atoms/RPText';
import {
  LanguageContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface ListItemType {
  icon: React.ReactElement;
  textKey: string;
}

interface StyledListProps extends ListProps {
  listType: string;
}

const StyledList = styled(List, {
  shouldForwardProp: (prop) => prop !== 'listType',
})<StyledListProps>(({ theme }) => {

  const {
    colours: {
      backgrounds,
      text
    },
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT_INVERSE: HexColour =
    backgrounds.backgroundAltInverse;
  const COLOUR_TEXT_TEXT_INVERSE: HexColour = text.textInverse;

  return {
    background: COLOUR_BG_BACKGROUND_ALT_INVERSE,

    '.MuiTypography-root': {
      color: COLOUR_TEXT_TEXT_INVERSE,
    },
  };
});

const StyledListItemIcon = styled(ListItemIcon)<ListItemIconProps>(
  ({ theme }) => {

    const {
      colours: {
        text
      },
    } = theme;

    const COLOUR_TEXT_TEXT_INVERSE_WEAK: HexColour = text.textInverseWeak;

    return {
      minWidth: 35,
      color: COLOUR_TEXT_TEXT_INVERSE_WEAK,
      path: {
        fill: 'currentColor',
      },
    };
  }
);

const ListWithIcon: FC<StyledListProps> = (props: StyledListProps) => {
  const { tenant } = useContext(TenantContext);

  const UserAdminIcon = useMemo(() => getIcon(tenant, 'userAdmin'), [tenant]);
  const IBMSecurityIcon = useMemo(() => getIcon(tenant, 'ibmSecurity'), [tenant]);
  const ActionToggleLockedIcon = useMemo(() => getIcon(tenant, 'toggleLocked'), [tenant]);

  const CD_FEATURES_BULLETS: Array<ListItemType> = [
    {
      icon: <UserAdminIcon data-testid="rp-icon-user-admin" />,
      textKey: 'sidebar.bullets.expertAdviceText',
    },
    {
      icon: <IBMSecurityIcon data-testid="rp-icon-ibm-security" />,
      textKey: 'sidebar.bullets.safeSecureText',
    },
    {
      icon: (
        <ActionToggleLockedIcon data-testid="rp-icon-action-toggle-locked" />
      ),
      textKey: 'sidebar.bullets.shareDaaText',
    },
  ];

  const LIST_TYPE_MAPPING: { [key: string]: Array<ListItemType> } = {
    'features-bullets': CD_FEATURES_BULLETS,
  };

  const currentList = LIST_TYPE_MAPPING[props.listType];

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  return (
    <StyledList {...props} dense={true} data-testid="rp-list-with-icon">
      {currentList &&
        currentList.map((item: ListItemType) => {
          return (
            <ListItem key={item.textKey} disableGutters>
              <StyledListItemIcon>{item.icon}</StyledListItemIcon>
              <ListItemText>
                <RPText
                  variant="body1"
                  type="medium"
                  text={translate(item.textKey)}
                />
              </ListItemText>
            </ListItem>
          );
        })}
    </StyledList>
  );
};

export default ListWithIcon;

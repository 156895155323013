import { FC, MouseEvent, KeyboardEvent, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// Contexts
import { LanguageContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPLink from '../../atoms/RPLink';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
// Constants
import { ENTER_KEY } from '../../../core/utils/Constants/Constants';

interface RPEnterManualAddressLinkProps extends IsMobileOptimisedProps {
  handleManualAddressClick: (event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => void;
}

interface IsMobileOptimisedProps {
  isMobileOptimised?: boolean;
}

interface EnterAddressManuallyProps extends BoxProps, IsMobileOptimisedProps {}

const EnterAddressManually = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobileOptimised'
})<EnterAddressManuallyProps>(({ theme, isMobileOptimised }) => {
  const {
    colours: {
      backgrounds: { backgroundAltInverseWeak }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK: HexColour = backgroundAltInverseWeak;

  return {
    padding: '15px 20px',
    background: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK,
    borderRadius: '0px 0px 4px 4px',
    display: 'flex',
    alignItems: 'center',
    ...(!isMobileOptimised && {
      marginTop: '16px'
    })
  };
});

const StyledLink = styled(RPLink)(({ theme }) => {
  const {
    colours: {
      borders: { borderInputActive }
    }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borderInputActive;

  return {
    color: COLOUR_BORDER_BORDER_INPUT_ACTIVE,

    '&:hover': {
      color: COLOUR_BORDER_BORDER_INPUT_ACTIVE,
      cursor: 'pointer'
    }
  };
});

const RPEnterManualAddressLink: FC<RPEnterManualAddressLinkProps> = (props: RPEnterManualAddressLinkProps) => {
  const { handleManualAddressClick, isMobileOptimised = false } = props;

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const enterAddressManually: string = translate('registration.addressLookup.enterAddressManually');

  return (
    <EnterAddressManually isMobileOptimised={isMobileOptimised} data-testid="rp-link-container">
      <StyledLink
        underline="hover"
        onClick={(e: MouseEvent<HTMLAnchorElement>) => handleManualAddressClick(e)}
        type="medium"
        text={enterAddressManually}
        onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
          e.key === ENTER_KEY && handleManualAddressClick(e);
        }}
        href=""
        data-testid="rp-link-enter-address-manually"
      />
    </EnterAddressManually>
  );
};

export default RPEnterManualAddressLink;

import {
  HELP_HEADER,
  WIDGET_HEIGHT,
  WIDGET_WIDTH,
  DEFAULT_LIVE_CHAT_GUEST_NAME
} from '../../../core/utils/Constants/Constants';
import { liveChatConfigType } from '../../../core/types/LiveChatTypes';

declare const window: any;

export const initESW = (
  chatOnlineBtnText: string,
  chatOfflineBtnText: string,
  firstName: string,
  setShowHelpSection: (value: boolean) => void,
  liveChatConfig: liveChatConfigType,
  setShowChatWidget: (value: boolean) => void
) => {
  if (window?.embedded_svc !== undefined) {
    window.embedded_svc.settings.widgetWidth = WIDGET_WIDTH;
    window.embedded_svc.settings.widgetHeight = WIDGET_HEIGHT;

    window.embedded_svc.settings.displayHelpButton = true; //Or false
    window.embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

    window.embedded_svc.settings.defaultMinimizedText = chatOnlineBtnText; //(Defaults to Chat with an Expert)
    window.embedded_svc.settings.disabledMinimizedText = chatOfflineBtnText; //(Defaults to Agent Offline)

    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';

    window.embedded_svc.settings.targetElement = document?.getElementById(HELP_HEADER) as HTMLElement;

    window.embedded_svc.msgCount = 1;

    window.embedded_svc.addEventHandler('afterMinimize', function () {
      //Added this function to toggle the chat window on click of minimize button
      setShowHelpSection(false);
    });

    window.embedded_svc.addEventHandler('afterMaximize', function () {
      // Show the chat widget when the agent and chasitor sends a message
      setShowChatWidget(true);
    }); 

    window.embedded_svc.addEventHandler('afterDestroy', function () {
      // Hide the chat widget when the agent and chasitor end chat
      setShowChatWidget(false);
    });

    window.embedded_svc.settings.extraPrechatFormDetails = [
      {
        label: 'First Name',
        name: 'FirstName',
        value: firstName || DEFAULT_LIVE_CHAT_GUEST_NAME, // Use the retrieved first name or default to 'Guest'
        displayToAgent: true
      }
    ];

    window.embedded_svc.init(
      liveChatConfig?.embdServiceDeplySbxURL,
      liveChatConfig?.chatWithAgentURL,
      liveChatConfig?.serviceURL,
      liveChatConfig?.embdServiceDeplyId,
      liveChatConfig?.embdServiceDeplyName,
      {
        baseLiveAgentContentURL: liveChatConfig?.baseLiveAgentContentURL,
        deploymentId: liveChatConfig?.deploymentId,
        buttonId: liveChatConfig?.buttonId,
        baseLiveAgentURL: liveChatConfig?.baseLiveAgentURL,
        eswLiveAgentDevName: liveChatConfig?.eswLiveAgentDevName,
        isOfflineSupportEnabled: false
      }
    );
  }
};
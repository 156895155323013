import React from 'react';
import './App.css';
import TenantProvider from './core/TenantProvider';
import Router from './router';

function App() {
  return (
    <TenantProvider>
      <Router />
    </TenantProvider>
  );
}

export default App;

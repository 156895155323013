import RPText from '../../atoms/RPText';

const RPTypography = () => {
  return (
    <>
      <hr/>
      <RPText variant='h1' type='display-huge-bold' text='Display Huge Bold'/>
      <br/>
      <hr/>
      <RPText variant='h1' type='display-large-bold' text='Display Large Bold'/>
      <br/>
      <hr/>
      <RPText variant='h1' type='display-medium-norm' text='Display Medium Norm'/>
      <br/>
      <hr/>
      <RPText variant='h1' type='bold' text='H1 Bold'/>
      <br/>
      <RPText variant='h1' type='normal' text='H1 Normal'/>
      <br/>
      <RPText variant='h1' type='medium' text='H1 Medium'/>
      <br/>
      <hr/>
      <RPText variant='h2' type='bold' text='H2 Bold'/>
      <br/>
      <RPText variant='h2' type='normal' text='H2 Normal'/>
      <br/>
      <RPText variant='h2' type='medium' text='H2 Medium'/>
      <br/>
      <hr/>
      <RPText variant='h3' type='bold' text='H3 Bold'/>
      <br/>
      <RPText variant='h3' type='normal' text='H3 Normal'/>
      <br/>
      <RPText variant='h3' type='medium' text='H3 Medium'/>
      <br/>
      <hr/>
      <RPText variant='body1' type='bold' text='Body 1 Bold'/>
      <br/>
      <RPText variant='body1' type='normal' text='Body 1 Normal'/>
      <br/>
      <RPText variant='body1' type='medium' text='Body 1 Medium'/>
      <br/>
      <RPText variant='body1' type='link' text='Body 1 Link'/>
      <br/>
      <RPText variant='body1' type='alt-link' text='Body 1 Alt Link'/>
      <br/>
      <hr/>
      <RPText variant='body2' type='bold' text='Body 2 Bold'/>
      <br/>
      <RPText variant='body2' type='normal' text='Body 2 Normal'/>
      <br/>
      <RPText variant='body2' type='medium' text='Body 2 Medium'/>
      <br/>
      <RPText variant='body2' type='link' text='Body 2 Link'/>
      <br/>
      <RPText variant='body2' type='alt-link' text='Body 2 Alt Link'/>
      <br/>
      <hr/>
      <RPText variant='subtitle1' type='bold' text='Body small Bold'/>
      <br/>
      <RPText variant='subtitle1' type='normal' text='Body small Normal'/>
      <br/>
      <RPText variant='subtitle1' type='link' text='Body small Link'/>
      <br/>
      <RPText variant='subtitle1' type='alt-link' text='Body small Alt Link'/>
      <br/>
      <hr/>
      <RPText variant='subtitle2' type='bold' text='Body extra small Bold'/>
      <br/>
      <RPText variant='subtitle2' type='normal' text='Body extra small Normal'/>
      <br/>
      <RPText variant='subtitle2' type='link' text='Body extra small Link'/>
      <br/>
      <RPText variant='subtitle2' type='alt-link' text='Body extra small Alt Link'/>
      <br/>
      <hr/>
      <RPText variant='body1' type='button-large' text='Button Large'/>
      <br/>
      <RPText variant='body2' type='button-medium' text='Button Medium'/>
      <br/>
      <RPText variant='body2' type='button-small' text='Button Small'/>
      <br/>
    </>
  )
};

export default RPTypography;



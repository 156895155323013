import { FC, SyntheticEvent, memo, useContext, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPText } from '../RPText';
// Types
import { TypeProps } from './RPSelectNew';
import { SizeVariants } from './RPSelect';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { OptionType, SelectedOptionType } from '../../../core/types/SelectTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import { extractDialingCodeValue } from '../../../core/utils/DialingData/GetDialingData';

interface StyledListItemProps {
  variant: SizeVariants['variant'];
}

const StyledListItem = styled('li', {
  shouldForwardProp: (prop) => prop !== 'currentSelectedOption' && prop !== 'variant'
})<StyledListItemProps>(({ variant }) => {
  const isXSmallSize: boolean = variant === 'x-small';

  // Adding styles over here are getting overridden by the default styles of the ListBoxComponent
  return {
    height: 40,
    ...(isXSmallSize && {
      listStyleType: 'none',
      display: 'flex',
      cursor: 'pointer'
    })
  };
});

interface StyledOptionContainerProps {
  variant: SizeVariants['variant'];
}

const StyledOptionContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant'
})<StyledOptionContainerProps>(({ variant }) => {
  const isXSmallSize: boolean = variant === 'x-small';

  return {
    display: 'inline-flex',
    alignItems: 'center',
    img: {
      width: 18,
      height: 18,
      marginRight: isXSmallSize ? 10 : 5
    },
    div: {
      marginRight: isXSmallSize ? 10 : 15,
      minWidth: '45px'
    },
    ...(isXSmallSize && {
      '>p': {
        height: 'fit-content',
        width: '210px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    })
  };
});

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: {
      text: { textInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;

  return {
    display: 'flex',
    alignItems: 'center',
    '> svg > path': {
      fill: COLOUR_TEXT_TEXT_INVERSE
    }
  };
});

interface RPSelectItemProps {
  option: OptionType;
  currentSelectedOption: SelectedOptionType | null;
  variant: SizeVariants['variant'];
  type?: TypeProps;
  listClickHandler?: (e: SyntheticEvent, newValueObj: any) => void;
}

const RPSelectItem: FC<RPSelectItemProps> = ({
  option,
  currentSelectedOption,
  variant,
  type,
  listClickHandler,
  ...otherProps
}: RPSelectItemProps) => {
  const { tenant } = useContext(TenantContext);

  const TickIcon = useMemo(() => getIcon(tenant, 'toolsTick'), [tenant]);

  const [icon, setIcon] = useState('');

  const { value, label, iconPath } = option;

  const displayValue: string = extractDialingCodeValue(value);

  useEffect(() => {
    if (iconPath) {
      import(`../../../core/CountryMetadata/${iconPath}.svg`)
        .then(({ default: currentIcon }) => {
          setIcon(currentIcon);
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    }
  }, [iconPath]);

  const isSelected: boolean =
    displayValue?.trim().toLowerCase() === currentSelectedOption?.value?.trim().toLowerCase() &&
    label?.trim().toLowerCase() === currentSelectedOption?.label?.trim().toLowerCase();

  return (
    <StyledListItem
      // @ts-ignore
      onClick={(e) => listClickHandler(e, option)}
      variant={variant}
      {...otherProps}
      aria-selected={isSelected}
      /*key={`${value}-${label}-${iconPath}`}*/
    >
      <StyledOptionContainer variant={variant}>
        {iconPath && <img loading="lazy" alt={value} src={icon} />}
        {type === 'dialingCode' && (
          <div>
            <RPText text={`${displayValue}`} variant="body2" type="normal" data-option-value={value} />
          </div>
        )}
        <RPText text={`${label}`} variant="body2" type="normal" data-option-value={value} />
      </StyledOptionContainer>
      {isSelected && (
        <IconContainer>
          <TickIcon />
        </IconContainer>
      )}
    </StyledListItem>
  );
};

export default memo(RPSelectItem);

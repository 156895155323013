import generateToken from '../../../redux/api/generateToken';
import registerDevice from '../../../redux/api/registerDevice';

export function GenerateNewTokenAfterApiFail(thunkAPI?: any) {
  const { getState } = thunkAPI;
  const { authorisationDetails } = getState();

  console.log("getState", getState());
  console.log("authorisationDetails", authorisationDetails);

  const { generateTokenLoading, clientId, clientSecret } = authorisationDetails;

  console.log('thunkAPI', thunkAPI);

  const callGenerateToken = (currentClientId: string, currentClientSecret: string, resolve: { (value: unknown): void; (arg0: { success: boolean; }): void; }, reject: ((reason?: any) => void)) => {
    if (generateTokenLoading !== 'loading') {
      console.log('useEffect generateToken 2');

      thunkAPI.dispatch(generateToken(
        {
          clientId: currentClientId,
          clientSecret: currentClientSecret,
          attempt: 1
        })).then((response: any) => {
        console.log('response', response);

        resolve({ success: true });
      }).catch((error: { response: any; }) => {
        console.log('error', error.response);

        reject({ success: false });
        window.open('/generic-error', '_self');
      });
    }
  }
  return new Promise((resolve, reject) => {
    if (clientId === '' || clientSecret === '') {
      thunkAPI.dispatch(registerDevice(1)).then((response: any) => {
        console.log('register response', response);

        if (response.payload) {
          const { client_id, client_secret } = response.payload;
          callGenerateToken(client_id, client_secret, resolve, reject);
        } else {
          reject({ success: false });
          window.open('/generic-error', '_self');
        }
      }).catch((error: { response: any; }) => {
        console.log('error', error.response);

        reject({ success: false });
        window.open('/generic-error', '_self');
      });
    } else {
      callGenerateToken(clientId, clientSecret, resolve, reject);
    }
  });
}

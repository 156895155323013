/* Note: The datepicker library was in beta version while developing this component. Please try and update to a stable version of @mui/x-date-pickers. Link: https://www.npmjs.com/package/@mui/x-date-pickers */

import { FC, useState, useContext, useMemo, FocusEvent } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled, Theme } from '@mui/material/styles';

import {
  getBody2NormalStyles,
  getBodySmallBoldStyles,
  getBodyExtraSmallNormalStyles,
  getBodyExtraSmallBoldStyles
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import RPValidationErrorMessage from '../RPValidationErrorMessage/RPValidationErrorMessage';

interface RPDatePickerProps {
  name: string;
  value: Date | null;
  placeholderLabel: string;
  handleOnChange: (selectedValue: Date | null) => void;
  size?: 'normal' | 'small';
  error?: string;
  isDisabled?: boolean;
  inputDateFormat?: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

interface IconProps {
  disabled: boolean;
  error: boolean;
}

const DatePickerContainer = styled('div')(() => {
  return {
    marginBottom: '16px'
  };
});

const StyledTextField = styled(TextField)(({ theme, disabled, error, size }) => {
  const {
    colours: { backgrounds, borders, text }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;
  const COLOUR_BORDER_BORDER_INPUT: HexColour = borders.borderInput;
  const COLOUR_BORDER_BORDER_SELECTION_HOVER: HexColour = borders.borderSelectionHover;
  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borders.borderInputActive;
  const COLOUR_BORDER_BORDER_ERROR: HexColour = borders.borderError;
  const COLOUR_TEXT_TEXT_ERROR: HexColour = text.textError;
  const COLOUR_TEXT_TEXT_INPUT_LABEL: HexColour = text.textInputLabel;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: HexColour = text.textInputDisabled;
  const COLOUR_BG_BACKGROUND_INPUT_DISABLED: HexColour = backgrounds.backgroundInputDisabled;
  const isSmallSize: boolean = size === 'small';

  let borderColor: string = 'transparent';
  let borderHoverColor: HexColour = COLOUR_BORDER_BORDER_SELECTION_HOVER;

  if (error) {
    borderColor = COLOUR_BORDER_BORDER_ERROR;
    borderHoverColor = COLOUR_BORDER_BORDER_ERROR;
  } else if (disabled) {
    borderColor = COLOUR_BORDER_BORDER_INPUT;
    borderHoverColor = COLOUR_BORDER_BORDER_INPUT;
  }

  return {
    // Form control
    border: '2px solid transparent',
    ...(!disabled &&
      !error && {
        boxShadow: `${COLOUR_BORDER_BORDER_INPUT} 0px 0px 0px 1px`
      }),
    borderColor: borderColor,
    borderRadius: '4px',
    width: '100%',
    transition: 'all 0.3s ease-in-out 0s',

    '&:hover': {
      borderColor: borderHoverColor
    },

    '&:focus-within': {
      border: `2px solid ${error ? COLOUR_BORDER_BORDER_ERROR : COLOUR_BORDER_BORDER_INPUT_ACTIVE}`
    },

    // label
    '> label': {
      ...getBody2NormalStyles(theme),
      color: COLOUR_TEXT_TEXT_INPUT_LABEL,
      paddingLeft: isSmallSize ? '6px' : '10px',
      transform: isSmallSize ? 'translate(12px, 14px) scale(1)' : 'translate(12px, 18px) scale(1)',

      '&.MuiFormLabel-filled': {
        transform: `translate(12px, ${isSmallSize ? '5px' : '9px'}) scale(0.75)`
      },

      '&.Mui-focused': {
        color: error ? COLOUR_TEXT_TEXT_ERROR : COLOUR_TEXT_TEXT_INPUT_LABEL,
        paddingLeft: isSmallSize ? '6px' : '10px',
        transform: `translate(12px, ${isSmallSize ? '5px' : '9px'}) scale(0.75)`
      },

      '&.Mui-error': {
        color: COLOUR_TEXT_TEXT_ERROR
      },

      '&.Mui-disabled': {
        color: COLOUR_TEXT_TEXT_INPUT_DISABLED
      }
    },

    // Input
    '& .MuiFilledInput-root': {
      backgroundColor: COLOUR_BG_BACKGROUND_ALT,
      padding: isSmallSize ? '0 16px' : '0 20px',

      ':before, :after': {
        borderBottom: 'none',
        transition: 'none'
      },

      '&.Mui-focused': {
        backgroundColor: COLOUR_BG_BACKGROUND_ALT
      },

      '&:hover': {
        backgroundColor: COLOUR_BG_BACKGROUND_ALT,
        '&:not(.Mui-disabled)': {
          '&:before': {
            borderBottom: 'none'
          }
        }
      },

      '&.Mui-disabled': {
        backgroundColor: COLOUR_BG_BACKGROUND_INPUT_DISABLED,
        color: 'transparent'
      },

      '& .MuiButtonBase-root.MuiIconButton-root:hover': {
        backgroundColor: 'transparent'
      },

      '> input.MuiFilledInput-input.MuiInputBase-input': {
        ...getBody2NormalStyles(theme),
        padding: ` ${isSmallSize ? '23px' : '27px'} 0 ${isSmallSize ? '5px' : '9px'} 0`,

        ':disabled': {
          color: COLOUR_TEXT_TEXT_INPUT_DISABLED
        }
      },

      '& .MuiInputAdornment-root .MuiTouchRipple-root': {
        display: 'none'
      }
    }
  };
});

const IconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'error'
})<IconProps>(({ theme, disabled, error }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;
  const COLOUR_TEXT_TEXT_ERROR: HexColour = text.textError;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: HexColour = text.textInputDisabled;

  let fillColor: HexColour = COLOUR_TEXT_TEXT_LINK;

  if (error) {
    fillColor = COLOUR_TEXT_TEXT_ERROR;
  } else if (disabled) {
    fillColor = COLOUR_TEXT_TEXT_INPUT_DISABLED;
  }

  return {
    display: 'flex',
    alignItems: 'center',
    cursor: disabled ? 'unset' : 'pointer',

    '> svg > path': {
      fill: fillColor
    }
  };
});

const styles = (theme: Theme) => {
  const {
    colours: { backgrounds, borders, text }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;
  const COLOUR_BORDER_BORDER_INPUT: HexColour = borders.borderInput;
  const COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK: HexColour = backgrounds.backgroundAltInverseWeak;
  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;
  const COLOUR_TEXT_TEXT_INVERSE: HexColour = text.textInverse;
  const COLOUR_TEXT_TEXT_WEAKEST: HexColour = text.textWeakest;

  return {
    // MuiPaper-root
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',

    // to remove ripple effect
    '.MuiTouchRipple-root': {
      display: 'none'
    },

    // for the mobile version of the datepicker
    '.MuiDialog-paper': {
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)'
    },

    // Container for prev next icons and year dropdown
    '.MuiCalendarPicker-root > div:nth-of-type(1) ': {
      margin: 0,
      padding: '16px 12px 12px'
    },

    // Prev next arrow icons
    '.MuiPickersArrowSwitcher-root': {
      position: 'absolute',
      width: '92.5%',
      justifyContent: 'space-between',

      '> button': {
        borderRadius: '8px',
        backgroundColor: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK,
        color: COLOUR_TEXT_TEXT_LINK,
        padding: '4px',

        ':hover': {
          backgroundColor: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK
        }
      }
    },

    '.MuiPickersArrowSwitcher-spacer': {
      display: 'none'
    },

    // Year dropdown
    'div[role="presentation"]': {
      backgroundColor: COLOUR_BG_BACKGROUND_ALT,
      border: `1px solid ${COLOUR_BORDER_BORDER_INPUT}`,
      borderRadius: '4px',
      padding: '4px 16px',
      ...getBody2NormalStyles(theme),
      margin: '0 auto',
      zIndex: 2,

      '> button': {
        padding: 0,

        ':hover': {
          backgroundColor: 'transparent'
        }
      }
    },

    // Year and month picker
    '.PrivatePickersYear-root .PrivatePickersYear-yearButton, .PrivatePickersMonth-root': {
      ...getBody2NormalStyles(theme),
      borderRadius: '8px',

      ':hover': {
        backgroundColor: 'transparent',
        color: COLOUR_TEXT_TEXT_LINK
      }
    },

    // Selected option for month and year
    '.PrivatePickersYear-root .Mui-selected, .PrivatePickersMonth-root.Mui-selected': {
      backgroundColor: COLOUR_TEXT_TEXT_LINK,
      color: COLOUR_TEXT_TEXT_INVERSE,
      ':hover, :focus': {
        backgroundColor: COLOUR_TEXT_TEXT_LINK,
        color: COLOUR_TEXT_TEXT_INVERSE
      }
    },

    // Disabled months and years
    '.PrivatePickersYear-root .Mui-disabled, .PrivatePickersYear-root .Mui-disabled:hover, .PrivatePickersMonth-root.Mui-disabled, .PrivatePickersMonth-root.Mui-disabled:hover':
      {
        color: COLOUR_TEXT_TEXT_WEAKEST
      },

    // Day heading
    '.MuiTypography-caption': {
      width: '40px',
      ...getBodyExtraSmallBoldStyles(theme)
    },

    // Date picker
    '.PrivatePickersSlideTransition-root': {
      minHeight: '255px'
    },

    // Days from 1-31
    '.PrivatePickersSlideTransition-root .MuiPickersDay-root': {
      ...getBodyExtraSmallNormalStyles(theme),
      width: '40px',
      height: '40px',
      borderRadius: '8px',
      backgroundColor: 'transparent',
      margin: '0 2px',

      ':hover': {
        backgroundColor: 'transparent',
        color: COLOUR_TEXT_TEXT_LINK
      }
    },

    // Today
    '.PrivatePickersSlideTransition-root .MuiPickersDay-today': {
      border: 'none',
      color: COLOUR_TEXT_TEXT_LINK
    },

    // Selected date
    '.PrivatePickersSlideTransition-root .Mui-selected': {
      color: COLOUR_TEXT_TEXT_INVERSE,
      backgroundColor: COLOUR_TEXT_TEXT_LINK,

      ':hover, :focus': {
        color: COLOUR_TEXT_TEXT_INVERSE,
        backgroundColor: COLOUR_TEXT_TEXT_LINK
      }
    },

    // Disabled dates
    '.PrivatePickersSlideTransition-root .Mui-disabled': {
      color: COLOUR_TEXT_TEXT_WEAKEST,

      ':hover': {
        color: COLOUR_TEXT_TEXT_WEAKEST
      }
    },

    // Clear button
    '.MuiDialogActions-root ': {
      padding: '10px 10px 16px',
      '.MuiButton-root': {
        width: '100%',
        backgroundColor: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK,
        ...getBodySmallBoldStyles(theme),
        padding: '4px'
      }
    }
  };
};

const ArrowIconContainer = styled('span')(({ theme }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;

  return {
    '> svg > path': {
      fill: COLOUR_TEXT_TEXT_LINK
    }
  };
});

const RPDatePicker: FC<RPDatePickerProps> = (props: RPDatePickerProps) => {
  const {
    name,
    value,
    placeholderLabel,
    handleOnChange,
    size,
    error,
    isDisabled = false,
    inputDateFormat = 'DD/MM/YYYY',
    onBlur
  } = props;

  const { tenant } = useContext(TenantContext);

  const DatePickerIcon = useMemo(() => getIcon(tenant, 'calendar'), [tenant]);
  const RightArrowIcon = useMemo(() => getIcon(tenant, 'navigationChevronRightIcon'), [tenant]);
  const LeftArrowIcon = useMemo(() => getIcon(tenant, 'navigationChevronLeftIcon'), [tenant]);
  const SwitchArrowIcon = useMemo(() => getIcon(tenant, 'navigationChevronDownIcon'), [tenant]);

  const [open, setOpen] = useState(false);

  return (
    <DatePickerContainer data-testid="datepicker-container">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          open={open}
          onClose={() => setOpen(false)}
          label={placeholderLabel}
          value={value}
          onChange={(newValue) => {
            handleOnChange(newValue);
          }}
          closeOnSelect={true}
          views={['year', 'month', 'day']}
          inputFormat={inputDateFormat}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              variant="filled"
              size={size === 'normal' ? 'medium' : size}
              disabled={isDisabled}
              name={name}
              error={!!error}
              onBlur={onBlur}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
                placeholder: ''
              }}
              InputProps={{
                endAdornment: (
                  <IconContainer disabled={isDisabled} error={!!error}>
                    <DatePickerIcon />
                  </IconContainer>
                )
              }}
              onClick={() => !isDisabled && setOpen(true)}
            />
          )}
          showToolbar={false}
          disabled={isDisabled}
          toolbarPlaceholder=""
          components={{
            RightArrowIcon: () => (
              <ArrowIconContainer>
                <RightArrowIcon />
              </ArrowIconContainer>
            ),
            LeftArrowIcon: () => (
              <ArrowIconContainer>
                <LeftArrowIcon />
              </ArrowIconContainer>
            ),
            SwitchViewIcon: () => (
              <ArrowIconContainer>
                <SwitchArrowIcon />
              </ArrowIconContainer>
            )
          }}
          componentsProps={{
            actionBar: {
              actions: ['clear']
            }
          }}
          PaperProps={{
            // Applying styles for the desktop version
            sx: (theme: Theme) => styles(theme)
          }}
          DialogProps={{
            // Applying styles for the mobile version
            sx: (theme: Theme) => styles(theme)
          }}
        />
      </LocalizationProvider>
      {!!error && <RPValidationErrorMessage message={error} />}
    </DatePickerContainer>
  );
};

export default RPDatePicker;

import { FC, useState, ChangeEvent, useCallback, useRef, memo, useEffect, useContext, useReducer } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { Control, UseFormSetValue } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// Contexts
import { LanguageContext, LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPInput from '../../atoms/RPInput';
import { RPSelectNew } from '../../atoms/RPSelect';
import ControllerElementWrapper from '../../organisms/ControllerWrapper';
import RPAddressLookupPopper from './RPAddressLookupPopper';
import RPSelectionSummary from './RPSelectionSummary';
import RPMobileOptimisedAddressLookup from './RPMobileOptimisedAddressLookup';
// Types
import {
  SelectedAddressType,
  AddressItemsType,
  AddressItem,
  Item,
  AddressType,
  AddressFieldKeys,
  AddressFieldTypes,
  FieldDetail,
  FieldType
} from '../../../core/types/addressLookupTypes';
import { OptionType } from '../../../core/types/SelectTypes';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import {
  addressSearchUrl,
  addressRetrieveUrl,
  additionalAddressSearchUrl,
  initialState,
  stateReducer
} from '../../../core/utils/AddressLookup';
import { CountryFieldMapping, MANDATORY_FIELDS } from '../../../core/utils/AddressLookup/CountryFieldsMatrix';
import { isMobileOrTabletDevice } from '../../../core/utils/CheckMobileOrTabletDevice';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
// Constants
import { TEXT_OR_CONTAINER_REQUIRED } from '../../../core/utils/Constants/Constants';

interface RHFRPAddressLookupProps {
  selectedCountry: string;
  control: Control<any, any>;
  defaultValue: AddressType;
  addressValue: AddressType;
  setValue: UseFormSetValue<any>;
  handleDropdownChange?: (value: string) => void;
  handleSetAddressValue?: (address: AddressType) => void;
  dropdownOptions?: OptionType[];
  isMobileOptimised?: boolean;
}

interface AddressLabelsType extends Record<AddressFieldKeys, string> {}

interface MandatoryFieldType {
  type: FieldType;
  key: AddressFieldKeys;
}

const FormWrapper = styled(Box)(() => {
  return {
    '> div': {
      marginBottom: '15px'
    }
  };
});

const MobileOptimisedInputOverlaySection = styled('section')(() => {
  return {
    position: 'absolute',
    height: 60,
    width: '100%',
    background: 'transparent',
    zIndex: 2
  };
});

const RHFRPAddressLookup: FC<RHFRPAddressLookupProps> = (props: RHFRPAddressLookupProps) => {
  const {
    selectedCountry,
    control,
    defaultValue,
    addressValue,
    setValue,
    handleDropdownChange,
    handleSetAddressValue,
    dropdownOptions,
    isMobileOptimised = true
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const anchorEl = inputRef?.current?.parentElement;

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const [state, dispatch] = useReducer(stateReducer, initialState);

  const [isManualEntry, setIsManualEntry] = useState<boolean>(false);
  const [isCountryChange, setCountryChange] = useState<boolean>(false);
  const [showMobileOptimisedView, setShowMobileOptimisedView] = useState<boolean>(false);

  const { searchText, isLoading, hasSelectedValues, open, address, addressItems, hasAddressResults } = state;

  const placeholder: string = translate('registration.addressLookup.placeholder');

  const addressLabels: AddressLabelsType = {
    addressLine1: translate('registration.addressLookup.addressLine1'),
    addressLine2: translate('registration.addressLookup.addressLine2'),
    addressLine3: translate('registration.addressLookup.addressLine3'),
    suburbCity: translate('registration.addressLookup.suburbCity'),
    province: translate('registration.addressLookup.province'),
    townCity: translate('registration.addressLookup.townCity'),
    county: translate('registration.addressLookup.county'),
    state: translate('registration.addressLookup.state'),
    postcode: translate('registration.addressLookup.postcode')
  };

  const isMobileOrTabletDevices: boolean = !!isMobileOrTabletDevice.any();

  const isSmallMobileLayout: boolean = layout === 'mobileSM';

  useEffect(() => {
    if (!isMobileOrTabletDevices) {
      window.addEventListener('resize', handleWindowResize);

      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    }
  }, []);

  useEffect(() => {
    setIsManualEntry(false);

    if (selectedCountry) {
      setCountryChange(true);
      dispatch({ type: 'reset' });
    }

    const showPrefilledSummaryView: boolean = defaultValue.addressLine1 !== '' || defaultValue.postcode !== '';

    if (showPrefilledSummaryView) {
      setCountryChange(false);
      setValue('address', defaultValue);
      dispatch({ type: 'set-has-selectedValues', payload: true });
    }
  }, [selectedCountry, setValue, defaultValue]);

  const addressFields: AddressFieldTypes = CountryFieldMapping[selectedCountry]
    ? CountryFieldMapping[selectedCountry]
    : CountryFieldMapping['default'];

  const fieldsToDisplay: JSX.Element[] = [];
  const mandatoryFields: MandatoryFieldType[] = [];

  if (addressFields) {
    Object.entries(addressFields).forEach(([key, value]) => {
      const objectKey = key as AddressFieldKeys;
      const fieldValue: FieldDetail = value as FieldDetail;
      if (fieldValue.visible) {
        // @ts-ignore
        if (MANDATORY_FIELDS.includes(objectKey)) {
          mandatoryFields.push({
            type: fieldValue.type,
            key: objectKey
          });
        }

        fieldsToDisplay.push(
          <ControllerElementWrapper
            name={`address.${objectKey}`}
            control={control}
            {...(fieldValue.type === 'input' && {
              defaultValue: defaultValue[objectKey] ? defaultValue[objectKey] : ''
            })}
            {...(fieldValue.type === 'dropdown' &&
              handleDropdownChange &&
              dropdownOptions && {
                value: addressValue && addressValue[objectKey] ? addressValue[objectKey] : '',
                handleChange: (selectedValue: string) => handleDropdownChange(selectedValue),
                options: dropdownOptions
              })}
            placeholderLabel={addressLabels[objectKey]}
            component={fieldValue.type === 'input' ? RPInput : RPSelectNew}
            size="normal"
            key={objectKey}
            showMaxOptions={!isMobileLayoutApplicable(layout)}
            isCoralogixMasked={true}
          />
        );
      }
    });
  }

  const handleWindowResize = () => {
    dispatch({ type: 'set-search-text', payload: '' });
    dispatch({ type: 'set-open', payload: false });
  };

  const onAddressSearch = (evt: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = evt;

    if (value.length === 0) {
      resetAddressItems();
      handleHasAddressResults(true);
    }
    dispatch({ type: 'set-search-text', payload: value });
    dispatch({ type: 'set-open', payload: value.length !== 0 });
    dispatch({ type: 'set-is-loading', payload: !!value.length });
    searchAddress(value);
  };

  const onSelectAddress = (entry: AddressItem) => {
    if (entry && entry?.Id && entry?.Type) {
      const { Text, Id } = entry;
      if (entry.Type === 'Address') {
        axios
          .get<SelectedAddressType>(addressRetrieveUrl(Id))
          .then((resp) => {
            setCountryChange(false);
            dispatch({ type: 'set-has-selectedValues', payload: true });
            setShowMobileOptimisedView(false);
            const selection: Item = resp?.data && resp?.data?.Items[0];
            if (selection) {
              const addressFieldsObject: AddressType = {
                addressLine1: selection?.Line1 || '',
                addressLine2: selection?.Line2 || '',
                addressLine3: selection?.Line3 || '',
                suburbCity: selection?.City || '',
                province: selection?.Province || '',
                townCity: selection?.City || '',
                county: selection?.Province || '',
                state: selection?.ProvinceName || '',
                postcode: selection?.PostalCode || ''
              };

              handleSetAddressValue && handleSetAddressValue(addressFieldsObject);

              setValue('address', addressFieldsObject, {
                shouldTouch: true
              });

              // If all values are not provided by the API for the mandatory
              // fields, then show manual entry view for user to fill in
              mandatoryFields?.forEach((obj: MandatoryFieldType) => {
                if (addressFieldsObject[obj.key as keyof AddressType] === '') {
                  setIsManualEntry(true);
                  // setting value again to trigger validation
                  setValue(`address.${obj.key}`, '', { shouldTouch: true });
                } else if (obj.type === 'dropdown' && dropdownOptions && dropdownOptions.length > 0) {
                  const exists: OptionType | undefined = dropdownOptions.find(
                    (option: OptionType) => option.value === addressFieldsObject[obj.key]
                  );
                  if (!exists) {
                    setIsManualEntry(true);
                    // setting value again to trigger validation
                    setValue(`address.${obj.key}`, '', { shouldTouch: true });
                  }
                }
              });
            }
          })
          .catch((error) => {
            return error.response;
          });
      } else {
        dispatch({ type: 'set-is-loading', payload: true });
        axios
          .get<AddressItemsType>(additionalAddressSearchUrl(Text, selectedCountry, Id))
          .then((resp) => {
            dispatch({ type: 'set-address-items', payload: resp?.data });
            dispatch({ type: 'set-is-loading', payload: false });
          })
          .catch((error) => {
            return error.response;
          });
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchAddress = useCallback(
    debounce((value) => {
      if (value.length > 0) {
        dispatch({ type: 'set-is-loading', payload: true });
        axios
          .get<AddressItemsType>(addressSearchUrl(value, selectedCountry))
          .then((resp) => {
            const addressItemsData: AddressItem[] = resp?.data?.Items;
            if (addressItemsData && addressItemsData.length) {
              dispatch({ type: 'set-address-items', payload: resp?.data });
              if (addressItemsData.length === 1 && addressItemsData[0].Description === TEXT_OR_CONTAINER_REQUIRED) {
                handleHasAddressResults(false);
              } else {
                handleHasAddressResults(true);
              }
            } else {
              handleHasAddressResults(false);
            }
            dispatch({ type: 'set-is-loading', payload: false });
          })
          .catch((error) => {
            return error.response;
          });
      }
    }, 200),
    [selectedCountry]
  );

  const handleInputOverlayClick = () => {
    setShowMobileOptimisedView(true);
    dispatch({ type: 'reset' });
  };

  const handleCloseDialog = () => {
    setShowMobileOptimisedView(false);
    dispatch({ type: 'set-search-text', payload: '' });
    dispatch({ type: 'reset' });
  };

  const resetAddressItems = () => {
    dispatch({ type: 'reset-address-items' });
  };

  const handleHasAddressResults = (value: boolean) => {
    dispatch({ type: 'set-has-address-results', payload: value });
  };

  const handleMobileOptimisedClearText = () => {
    if (searchText.length === 0) {
      handleCloseDialog();
    } else {
      dispatch({ type: 'set-search-text', payload: '' });
      resetAddressItems();
      handleHasAddressResults(true);
    }
  };

  const addressSearchTemplate = (
    <>
      {/* The focus handler on the input isn't working as expected and hence a workaround implemented for mobile optimised view. */}
      {isSmallMobileLayout && isMobileOptimised && (
        <MobileOptimisedInputOverlaySection
          data-testid="rp-address-lookup-overlay-section"
          onClick={handleInputOverlayClick}
        ></MobileOptimisedInputOverlaySection>
      )}

      <RPInput
        name="search-text"
        value={searchText}
        placeholderLabel={placeholder}
        size="normal"
        onChange={onAddressSearch}
        inputRef={inputRef}
        showLoader={isLoading}
        isPopperOpen={open}
        maxLength={50}
        isCoralogixMasked={true}
      />

      {isSmallMobileLayout && isMobileOptimised ? (
        <RPMobileOptimisedAddressLookup
          open={showMobileOptimisedView}
          isLoading={isLoading}
          addressItems={addressItems}
          hasAddressResults={hasAddressResults}
          placeholder={placeholder}
          onAddressSearch={onAddressSearch}
          setIsManualEntry={setIsManualEntry}
          setCountryChange={setCountryChange}
          handleCloseDialog={handleCloseDialog}
          setShowMobileOptimisedView={setShowMobileOptimisedView}
          onSelectAddress={onSelectAddress}
          handleMobileOptimisedClearText={handleMobileOptimisedClearText}
          searchText={searchText}
        />
      ) : (
        <>
          {addressItems && (
            <RPAddressLookupPopper
              addressItems={addressItems}
              onSelectAddress={onSelectAddress}
              setIsManualEntry={setIsManualEntry}
              setCountryChange={setCountryChange}
              open={open}
              anchorEl={anchorEl}
              hasAddressResults={hasAddressResults}
            />
          )}
        </>
      )}
    </>
  );

  const manualAddressTemplate = <FormWrapper>{fieldsToDisplay}</FormWrapper>;

  const currentView = isCountryChange ? (
    addressSearchTemplate
  ) : isManualEntry ? (
    manualAddressTemplate
  ) : hasSelectedValues && address ? (
    <RPSelectionSummary
      address={addressValue}
      setIsManualEntry={setIsManualEntry}
      setCountryChange={setCountryChange}
      countryCode={selectedCountry}
    />
  ) : (
    addressSearchTemplate
  );

  return <>{currentView}</>;
};

export default memo(RHFRPAddressLookup);

import { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../atoms/RPText';
import RPButton, { RPButtonContainer } from '../../../atoms/RPButton';
import MobileAppLinksWithIcons from '../../../organisms/MobileAppLinksWithIcons';
// Hooks
import { useScrollToContentTop } from '../../../../core/hooks';
// Types
import { HexColour } from '../../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../../core/types/LayoutProps';
// RTK Slice
import { getRegistrationDetails } from '../../../../redux/modules/registrationDetailsSlice';
import { updateStepStatus } from '../../../../redux/modules/registrationStepsSlice';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../../core/utils/IconOrgData';
import { getImage } from '../../../../core/utils/ImageOrgData';
import { extractDialingCodeValue } from '../../../../core/utils/DialingData/GetDialingData';
import { getFormattedMobileNumber } from '../../../../core/utils/GetFormattedMobileNumber';
import { getLandingPageURL } from '../../../../core/utils/GetOrganizationEnvSpecificURLs';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';
// Constants
import { REGISTRATION_DEFINITIONS } from '../RegistrationDefinitions';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
import { LinkType } from '../../../../core/types/GoogleAnalyticsTypes';

interface SmsSentSuccessProps {
  clickBackHandler: (value: boolean) => void;
}

interface MobileNumberProps extends LayoutProps {}

interface IconContainerProps extends LayoutProps {}

interface CloseWindowTextProps extends LayoutProps {}

interface AppInfoContainerProps extends LayoutProps {}

interface DownloadAppContainerProps extends LayoutProps {}

interface DownloadAppTextProps extends LayoutProps {}

const SentSmsText = styled(RPText)(() => {
  return {
    textAlign: 'center'
  };
});

const MobileNumber = styled(RPText)<MobileNumberProps>(({ layout }) => {
  const isDesktopLayout = layout === 'desktop';
  return {
    textAlign: 'center',
    marginBottom: isDesktopLayout ? '40px' : '20px'
  };
});

const CloseWindowText = styled(RPText)<CloseWindowTextProps>(({ layout, theme }) => {
  const isDesktopLayout = layout === 'desktop';
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    textAlign: 'center',
    marginBottom: isDesktopLayout ? '40px' : '20px',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const IconContainer = styled('div')<IconContainerProps>(({ layout }) => {
  const isDesktopLayout = layout === 'desktop';
  return {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: isDesktopLayout ? '40px' : '20px'
  };
});

const AppInfoContainer = styled('div')<AppInfoContainerProps>(({ theme, layout }) => {
  const {
    colours: {
      backgrounds: { backgroundAltInverseWeak }
    }
  } = theme;

  const COLOUR_BH_BACKGROUND_ALT_INVERSE_WEAK: HexColour = backgroundAltInverseWeak;
  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    backgroundColor: COLOUR_BH_BACKGROUND_ALT_INVERSE_WEAK,
    padding: isDesktopLayout ? '20px 20px 0 20px' : '15px',
    height: isDesktopLayout ? '116px' : '104px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex'
  };
});

const StyledMobileAppImg = styled('img', {
  shouldForwardProp: (prop) => prop !== 'size'
})(() => {
  return {
    maxWidth: '78px',
    height: 'fit-content'
  };
});

const DownloadAppContainer = styled('div')<DownloadAppContainerProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    ...(isDesktopLayout
      ? {
          marginLeft: '20px'
        }
      : {
          margin: '0 auto'
        })
  };
});

const DownloadAppText = styled(RPText)<DownloadAppTextProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const isNotDesktopLayout: boolean = layout !== 'desktop';

  return {
    color: COLOUR_TEXT_TEXT_WEAK,
    marginBottom: '7px',
    ...(isNotDesktopLayout && {
      textAlign: 'center'
    })
  };
});

const StoreContainer = styled('div')(() => {
  return {
    display: 'flex',
    columnGap: '10px',
    '> a': {
      height: '36px'
    },
    '> a > svg': {
      width: '120px',
      height: 'auto'
    }
  };
});

const SmsSentSuccess: FC<SmsSentSuccessProps> = (props: SmsSentSuccessProps) => {
  const { clickBackHandler } = props;

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);
  const { layout } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);

  const dispatch: any = useDispatch();

  const { countryCode, mobileNumber } = useSelector(getRegistrationDetails);

  const landingPageURL: string = getLandingPageURL(tenant);

  const smsSentMessage: string = translate('registration.confirmingItsYou.uploadDocs.uploadMobile.smsSentMessage');
  const closeWindowText: string = translate('registration.confirmingItsYou.uploadDocs.uploadMobile.closeWindow');
  const downloadAppText: string = translate('registration.confirmingItsYou.uploadDocs.uploadMobile.downloadApp');
  const closeButtonText: string = translate('registration.confirmingItsYou.uploadDocs.uploadMobile.closeButtonText');
  const backButtonText: string = translate('registration.confirmingItsYou.uploadDocs.uploadMobile.backButtonText');

  const WelcomeCardInHandIcon = useMemo(() => getIcon(tenant, 'welcomeCardInHand'), [tenant]);
  const MobileAppImg = useMemo(() => getImage(tenant, 'mobileApp'), [tenant]);

  const isDesktopLayout: boolean = layout === 'desktop';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const buttonSize: 'medium' | 'large' = isMobileLayout ? 'medium' : 'large';
  const textVariant: 'h2' | 'h3' = isDesktopLayout ? 'h2' : 'h3';

  const formattedMobileNumber: string = getFormattedMobileNumber(countryCode, mobileNumber);
  let dataFetchedRef = useRef(false);
  const {
    eventAction: { load, click },
    kycFailedDocument: {
      click: { backTextLink, closeWindow, googlePlayIcon, appStoreIcon },
      load: { smsSendConfirmation }
    }
  } = GoogleAnalyticsTagsMapping;

  const {
    confirmingYou: {
      mobileUpload: {
        SMSSentSuccess: {
          closeWindowButton: { name: closeWindowButtonName, dataTestId: closeWindowButtonDataTestId },
          backButton: { name: backButtonName, dataTestId: backButtonDataTestId }
        }
      }
    }
  } = REGISTRATION_DEFINITIONS;

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    TriggerGoogleAnalyticsTag(load, smsSendConfirmation);
  }, [dataFetchedRef]);

  // Scrolls to the top of the content area
  useScrollToContentTop();

  const navigateToKycFailPage = () => {
    TriggerGoogleAnalyticsTag(click, backTextLink);

    dispatch(
      updateStepStatus({
        stepType: 'confirming-you',
        stepStatus: 'active',
        isLoading: false
      })
    );
    clickBackHandler(false);
  };

  const linkClick = (type: LinkType) => {
    TriggerGoogleAnalyticsTag(click, type === LinkType.AndroidStore ? googlePlayIcon : appStoreIcon);
  };

  return (
    <>
      <SentSmsText variant={textVariant} type="bold" text={smsSentMessage} />
      <MobileNumber
        variant={textVariant}
        type="bold"
        text={`${extractDialingCodeValue(countryCode)} ${formattedMobileNumber}`}
        layout={layout}
      />
      <IconContainer layout={layout}>
        <WelcomeCardInHandIcon />
      </IconContainer>
      <CloseWindowText variant="body2" type="normal" text={closeWindowText} layout={layout} />
      <AppInfoContainer layout={layout}>
        {isDesktopLayout && <StyledMobileAppImg src={MobileAppImg} alt="Mobile App" />}
        <DownloadAppContainer layout={layout}>
          <DownloadAppText variant="body1" type="medium" text={downloadAppText} layout={layout} />
          <StoreContainer>
            <MobileAppLinksWithIcons linkClickHandler={linkClick} />
          </StoreContainer>
        </DownloadAppContainer>
      </AppInfoContainer>
      <RPButtonContainer layout={layout}>
        <RPButton
          size={buttonSize}
          onClick={() => {
            window.open(landingPageURL, '_self');
            TriggerGoogleAnalyticsTag(click, closeWindow);
          }}
          name={closeWindowButtonName}
          data-testid={closeWindowButtonDataTestId}
        >
          {closeButtonText}
        </RPButton>
        <RPButton
          size={buttonSize}
          btnType="text"
          onClick={navigateToKycFailPage}
          name={backButtonName}
          data-testid={backButtonDataTestId}
        >
          {backButtonText}
        </RPButton>
      </RPButtonContainer>
    </>
  );
};

export default SmsSentSuccess;

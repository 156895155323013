import { FC, useState, useEffect } from 'react';

import { RPPercentageLoader } from '../../molecules/RPPercentageLoader';

const RPPercentageLoaderVariant: FC = () => {

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setPercentage((prevPercentage) =>
        (prevPercentage >= 100 ? 0 : prevPercentage + 1)
      );
    }, 1000);

    return () => {
      clearInterval(timer);
    }
  });

  return (
    <RPPercentageLoader value={percentage} text='uploading...' />
  )
};

export default RPPercentageLoaderVariant;
import { RPHeaderRight } from '../../molecules/RPHeaderRight';
import { getLanguageOptions } from '../../../core/utils/LanguageData';
import { OptionType } from '../../../core/types/SelectTypes';

const RPHeaderRightVariants = () => {
  const LANGUAGE_OPTIONS: OptionType[] | [] = getLanguageOptions('cd');

  return (
    <>
      <br />
      <RPHeaderRight
        selectedLanguage={'en-gb'}
        languageOptions={LANGUAGE_OPTIONS}
      />
      <br />
      <RPHeaderRight />
      <br />
    </>
  );
};

export default RPHeaderRightVariants;

import { FC, ChangeEvent, useMemo, useContext } from 'react';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';

import { TenantContext } from '../../../core/TenantProvider/contexts';
import { TypographyStyleObject } from '../../../core/types/TypographyTypes';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { getBody2NormalStyles, getBodySmallNormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { getIcon } from '../../../core/utils/IconOrgData';

interface RadioButtonProps extends SizeProps {
  name: string;
  defaultValue?: string;
  value?: string;
  radioOptions: RadioOption[];
  label?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export interface RadioOption {
  label: string;
  value: string;
}

type SizeProps = {
  size?: 'small' | 'normal';
};

interface StyledFormControlLabelProps extends SizeProps {
  hasLabel: boolean;
  isChecked: boolean;
}

const StyledFormControl = styled(FormControl)(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '10px'
  };
});

const StyledFormLabel = styled(FormLabel, {
  shouldForwardProp: (prop) => prop !== 'size'
})<SizeProps>(({ theme, size }) => {
  const {
    colours: {
      text: { textInput }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INPUT: HexColour = textInput;

  const isSmallSize: boolean = size === 'small';

  return {
    ...getBody2NormalStyles(theme),
    marginRight: '16px',
    color: COLOUR_TEXT_TEXT_INPUT,
    display: 'flex',
    alignItems: 'center',

    ...(isSmallSize && {
      fontWeight: '400',
      letterSpacing: '-0.5px',
      fontFamily: 'CircularXX TT,Helvetica,Roboto,Arial,sans-serif'
    }),

    '&.Mui-focused': {
      color: COLOUR_TEXT_TEXT_INPUT
    }
  };
});

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'hasLabel' && prop !== 'isChecked'
})<StyledFormControlLabelProps>(({ theme, size, hasLabel, isChecked }) => {
  const {
    colours: {
      borders: { borderInputActive, borderSelectionHover }
    }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borderInputActive;
  const COLOUR_BORDER_BORDER_SELECTION_HOVER: HexColour = borderSelectionHover;

  const typography: TypographyStyleObject = size === 'small' ? getBodySmallNormalStyles(theme) : getBody2NormalStyles(theme);

  return {
    '> span:nth-of-type(2)': {
      ...typography
    },

    ...(!isChecked && {
      ':hover': {
        '> span > svg > rect': {
          stroke: hasLabel ? COLOUR_BORDER_BORDER_INPUT_ACTIVE : COLOUR_BORDER_BORDER_SELECTION_HOVER
        }
      }
    })
  };
});

const StyledRadio = styled(Radio)(({ theme, size }) => {
  const {
    colours: {
      borders: { borderInputActive, borderInput, borderInputFocus },
      text: { textInputFocus }
    }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borderInputActive;
  const COLOUR_BORDER_BORDER_INPUT: HexColour = borderInput;
  const COLOUR_BORDER_BORDER_INPUT_FOCUS: HexColour = borderInputFocus;
  const COLOUR_TEXT_TEXT_INPUT_FOCUS: HexColour = textInputFocus;

  const isSmallSize: boolean = size === 'small';

  return {
    padding: '3px 9px',

    ...(isSmallSize
      ? {
          '> svg > path:nth-of-type(1)': {
            display: 'none'
          }
        }
      : {
          '> svg > path:nth-of-type(2)': {
            display: 'none'
          }
        }),

    // unchecked
    '> svg': {
      height: isSmallSize ? '16px' : '18px',
      width: isSmallSize ? '16px' : '18px',

      '> path': {
        fill: 'none'
      },

      '> rect': {
        stroke: COLOUR_BORDER_BORDER_INPUT,
        height: isSmallSize ? 15 : 17,
        width: isSmallSize ? 15 : 17
      }
    },

    ':hover': {
      backgroundColor: 'transparent'
    },

    '&.Mui-focusVisible': {
      '> svg > rect': {
        stroke: COLOUR_BORDER_BORDER_INPUT_FOCUS,
        strokeWidth: 2,
        x: 1,
        y: 1,
        rx: isSmallSize ? 7 : 8,
        height: isSmallSize ? 14 : 16,
        width: isSmallSize ? 14 : 16
      }
    },

    '&.Mui-focusVisible + span': {
      color: `${COLOUR_TEXT_TEXT_INPUT_FOCUS} !important`
    },

    '&.Mui-checked': {
      '> svg ': {
        '> path': {
          fill: COLOUR_BORDER_BORDER_INPUT_ACTIVE
        },

        '> rect': {
          stroke: COLOUR_BORDER_BORDER_INPUT_ACTIVE
        }
      }
    }
  };
});

const RPRadioButton: FC<RadioButtonProps> = (props: RadioButtonProps) => {
  const { name, defaultValue, value, radioOptions, label, size = 'normal', onChange } = props;

  const { tenant } = useContext(TenantContext);

  const RadioButtonCheckedIcon = useMemo(() => getIcon(tenant, 'radioButtonChecked'), [tenant]);

  return (
    <StyledFormControl>
      {label && <StyledFormLabel size={size}>{label}</StyledFormLabel>}

      <RadioGroup row name={name} defaultValue={defaultValue} value={value} onChange={onChange}>
        {radioOptions &&
          radioOptions.length > 0 &&
          radioOptions.map((option: RadioOption) => (
            <StyledFormControlLabel
              key={option.value}
              value={option.value}
              hasLabel={!!option.label}
              isChecked={defaultValue === option.value}
              control={
                <StyledRadio
                  disableRipple
                  size={size === 'normal' ? 'medium' : size}
                  icon={<RadioButtonCheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                />
              }
              label={option.label}
              labelPlacement="end"
              data-testid={`radio-label-${option.value}`}
              size={size}
            />
          ))}
      </RadioGroup>
    </StyledFormControl>
  );
};

export default RPRadioButton;

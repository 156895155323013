import { useState, FC, useContext, useEffect } from 'react';

// Contexts
import { LanguageContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPButton from '../../atoms/RPButton';
import { RPConfirmationModal, PartialServiceModal, NoServiceModal, RPSessionModal } from '../../molecules/RPModals';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { WebWorker } from '../../../core/utils/WebWorker';
// Constants
import { SESSION_EXPIRY_MODAL_TIMER_IN_MS } from '../../../core/utils/Constants/Constants';

const timerWorker = new Worker(WebWorker);

const RPModalVariants: FC = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const ZARMessage: string = translate('modal.ZAR.heading');
  const ZARAdditionalMessage: string = translate('modal.ZAR.description');
  const ZARPrimaryBtnLabel: string = translate('modal.ZAR.primaryBtnLabel');
  const ZARSecondaryBtnLabel: string = translate('modal.ZAR.secondaryBtnLabel');

  const closeConfirmMessage: string = translate('modal.closeConfirm.heading');
  const closeConfirmAdditionalMessage: string = translate('modal.closeConfirm.description');
  const closeConfirmPrimaryBtnLabel: string = translate('modal.closeConfirm.primaryBtnLabel');
  const closeConfirmSecondaryBtnLabel: string = translate('modal.closeConfirm.secondaryBtnLabel');

  const skipDocsMessage: string = translate('modal.skipDocs.heading');
  const skipDocsAdditionalMessage: string = translate('modal.skipDocs.description');
  const skipDocsPrimaryBtnLabel: string = translate('modal.skipDocs.primaryBtnLabel');
  const skipDocsSecondaryBtnLabel: string = translate('modal.skipDocs.secondaryBtnLabel');

  const sessionTimerModalHeading: string = translate('modal.sessionTimerModal.heading');
  const sessionTimerModalSubHeadingLine1: string = translate('modal.sessionTimerModal.subHeadingLine1');
  const sessionTimerModalSubHeadingLine2: string = translate('modal.sessionTimerModal.subHeadingLine2');
  const sessionTimerModalBtnLabel: string = translate('modal.sessionTimerModal.primaryBtnLabel');
  const sessionTimerModalCountDownText: string = translate('modal.sessionTimerModal.countDownText');

  const sessionExpiredModalHeading: string = translate('modal.sessionExpiredModal.heading');
  const sessionExpiredModalSubHeadingLine1: string = translate('modal.sessionExpiredModal.subHeadingLine1');
  const sessionExpiredModalBtnLabel: string = translate('modal.sessionExpiredModal.primaryBtnLabel');

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSmallConfirmationModalOpen, setIsSmallConfirmationModalOpen] = useState(false);
  const [isSkipDocsModalOpen, setIsSkipDocsModalOpen] = useState(false);
  const [isSmallSkipDocsModalOpen, setIsSmallSkipDocsModalOpen] = useState(false);
  const [isZARModalOpen, setIsZARModalOpen] = useState(false);
  const [isSmallZARModalOpen, setIsSmallZARModalOpen] = useState(false);
  const [isPartialLocationWarningOpen, setIsPartialLocationWarningOpen] = useState(false);
  const [isSmallPartialLocationWarningOpen, setIsSmallPartialLocationWarningOpen] = useState(false);
  const [isNoLocationWarningOpen, setIsNoLocationWarningOpen] = useState(false);
  const [isSmallNoLocationWarningOpen, setIsSmallNoLocationWarningOpen] = useState(false);
  const [isSessionAboutToExpireOpen, setIsSessionAboutToExpireOpen] = useState(false);
  const [isSessionExpiredOpen, setIsSessionExpiredOpen] = useState(false);
  const [webWorkerTime, setWebWorkerTime] = useState<number>(0);
  const [percentage, setPercentage] = useState<number>(100);

  useEffect(() => {
    timerWorker.onmessage = ({ data: { time } }) => {
      setWebWorkerTime(time * 1000);
    };
  }, []);

  useEffect(() => {
    //Stop interval after completion of 9 minute (540 seconds)
    if (webWorkerTime >= SESSION_EXPIRY_MODAL_TIMER_IN_MS) {
      if (isSessionAboutToExpireOpen) {
        resetWebWorkerTimer();
      }
    }
    //Adding 100 in the percentage to reverse the loader
    if (isSessionAboutToExpireOpen) {
      const loaderPercentageValue = 100 + Math.round((100 / SESSION_EXPIRY_MODAL_TIMER_IN_MS) * webWorkerTime);
      setPercentage((prevPercentage) =>
        prevPercentage >= 200 && webWorkerTime > SESSION_EXPIRY_MODAL_TIMER_IN_MS ? 0 : loaderPercentageValue
      );
    }
  }, [webWorkerTime]);

  const startWebWorkerTimer = () => {
    timerWorker.postMessage({ turn: 'on' });
  };

  const resetWebWorkerTimer = () => {
    timerWorker.postMessage({ turn: 'off' });
    setWebWorkerTime(0);
  };

  //Subtracting timer count down from SESSION_EXPIRY_MODAL_TIMER_IN_MS to reverse the count down
  const timerRevCountDownText: string = isSessionAboutToExpireOpen
    ? ((SESSION_EXPIRY_MODAL_TIMER_IN_MS - webWorkerTime) / 1000).toString()
    : (SESSION_EXPIRY_MODAL_TIMER_IN_MS / 1000).toString();

  return (
    <>
      {/* Normal */}
      {/* Close confirmation modal */}
      <RPButton onClick={() => setIsConfirmationModalOpen(true)}>Confirmation Modal</RPButton>
      <RPConfirmationModal
        isOpen={isConfirmationModalOpen}
        handleClose={() => setIsConfirmationModalOpen(false)}
        message={closeConfirmMessage}
        additionMessage={closeConfirmAdditionalMessage}
        primaryBtnLabel={closeConfirmPrimaryBtnLabel}
        handlePrimaryBtnClick={() => setIsConfirmationModalOpen(false)}
        secondaryBtnLabel={closeConfirmSecondaryBtnLabel}
        handleSecondaryBtnClick={() => setIsConfirmationModalOpen(false)}
      />

      {/*Skip docs modal */}
      <RPButton onClick={() => setIsSkipDocsModalOpen(true)}>Skip docs Modal</RPButton>
      <RPConfirmationModal
        isOpen={isSkipDocsModalOpen}
        handleClose={() => setIsSkipDocsModalOpen(false)}
        message={skipDocsMessage}
        additionMessage={skipDocsAdditionalMessage}
        primaryBtnLabel={skipDocsPrimaryBtnLabel}
        handlePrimaryBtnClick={() => setIsSkipDocsModalOpen(false)}
        secondaryBtnLabel={skipDocsSecondaryBtnLabel}
        handleSecondaryBtnClick={() => setIsSkipDocsModalOpen(false)}
      />

      {/*ZAR confirmation modal */}
      <RPButton onClick={() => setIsZARModalOpen(true)}>ZAR confirmation Modal</RPButton>
      <RPConfirmationModal
        isOpen={isZARModalOpen}
        handleClose={() => setIsZARModalOpen(false)}
        message={ZARMessage}
        additionMessage={ZARAdditionalMessage}
        primaryBtnLabel={ZARPrimaryBtnLabel}
        handlePrimaryBtnClick={() => setIsZARModalOpen(false)}
        secondaryBtnLabel={ZARSecondaryBtnLabel}
        handleSecondaryBtnClick={() => setIsZARModalOpen(false)}
        modalVariant="ZAR"
      />

      {/* partial location warning modal */}
      <RPButton onClick={() => setIsPartialLocationWarningOpen(true)}>Location warning</RPButton>
      <PartialServiceModal
        isOpen={isPartialLocationWarningOpen}
        handleClose={() => setIsPartialLocationWarningOpen(false)}
        selectedState="Alabama"
      />

      {/* No location warning modal */}
      <RPButton onClick={() => setIsNoLocationWarningOpen(true)}>No location warning</RPButton>
      <NoServiceModal
        isOpen={isNoLocationWarningOpen}
        handleClose={() => setIsNoLocationWarningOpen(false)}
        selectedState="Alaska"
        selectedCountry="USA"
      />

      <br />
      <br />

      {/* Small */}
      {/* Close confirmation modal */}
      <RPButton onClick={() => setIsSmallConfirmationModalOpen(true)} size="medium">
        Confirmation Modal
      </RPButton>
      <RPConfirmationModal
        isOpen={isSmallConfirmationModalOpen}
        handleClose={() => setIsSmallConfirmationModalOpen(false)}
        message={closeConfirmMessage}
        additionMessage={closeConfirmAdditionalMessage}
        primaryBtnLabel={closeConfirmPrimaryBtnLabel}
        handlePrimaryBtnClick={() => setIsSmallConfirmationModalOpen(false)}
        secondaryBtnLabel={closeConfirmSecondaryBtnLabel}
        handleSecondaryBtnClick={() => setIsSmallConfirmationModalOpen(false)}
        size="small"
      />

      {/*Skip docs modal */}
      <RPButton onClick={() => setIsSmallSkipDocsModalOpen(true)} size="medium">
        Skip docs Modal
      </RPButton>
      <RPConfirmationModal
        isOpen={isSmallSkipDocsModalOpen}
        handleClose={() => setIsSmallSkipDocsModalOpen(false)}
        message={skipDocsMessage}
        additionMessage={skipDocsAdditionalMessage}
        primaryBtnLabel={skipDocsPrimaryBtnLabel}
        handlePrimaryBtnClick={() => setIsSmallSkipDocsModalOpen(false)}
        secondaryBtnLabel={skipDocsSecondaryBtnLabel}
        handleSecondaryBtnClick={() => setIsSmallSkipDocsModalOpen(false)}
        size="small"
      />

      {/*ZAR confirmation modal */}
      <RPButton onClick={() => setIsSmallZARModalOpen(true)} size="medium">
        ZAR confirmation Modal
      </RPButton>
      <RPConfirmationModal
        isOpen={isSmallZARModalOpen}
        handleClose={() => setIsSmallZARModalOpen(false)}
        message={ZARMessage}
        additionMessage={ZARAdditionalMessage}
        primaryBtnLabel={ZARPrimaryBtnLabel}
        handlePrimaryBtnClick={() => setIsSmallZARModalOpen(false)}
        secondaryBtnLabel={ZARSecondaryBtnLabel}
        handleSecondaryBtnClick={() => setIsSmallZARModalOpen(false)}
        size="small"
        modalVariant="ZAR"
      />

      {/* Partial location warning modal */}
      <RPButton onClick={() => setIsSmallPartialLocationWarningOpen(true)} size="medium">
        Partial location warning
      </RPButton>
      <PartialServiceModal
        isOpen={isSmallPartialLocationWarningOpen}
        handleClose={() => setIsSmallPartialLocationWarningOpen(false)}
        selectedState="Alabama"
        size="small"
      />

      {/* No location warning modal */}
      <RPButton onClick={() => setIsSmallNoLocationWarningOpen(true)} size="medium">
        No location warning
      </RPButton>
      <NoServiceModal
        isOpen={isSmallNoLocationWarningOpen}
        handleClose={() => setIsSmallNoLocationWarningOpen(false)}
        selectedState="Alaska"
        size="small"
        selectedCountry="USA"
      />

      {/* Session Timer modal */}
      <RPButton
        onClick={() => {
          setIsSessionAboutToExpireOpen(true);
          startWebWorkerTimer();
        }}
        size="medium"
      >
        Session timer
      </RPButton>
      <RPSessionModal
        isOpen={isSessionAboutToExpireOpen}
        heading={sessionTimerModalHeading}
        subHeadingLine1={sessionTimerModalSubHeadingLine1}
        subHeadingLine2={sessionTimerModalSubHeadingLine2}
        primaryBtnLabel={sessionTimerModalBtnLabel}
        countDownText={sessionTimerModalCountDownText}
        handleClose={() => undefined} // disabling backdrop click on popup
        handlePrimaryBtnClick={() => {
          setWebWorkerTime(0);
          resetWebWorkerTimer();
          setIsSessionAboutToExpireOpen(false);
        }}
        modalVariant="sessionTimer"
        size={'small'}
        percentage={percentage}
        timerValue={timerRevCountDownText}
      />

      {/* Session expired modal */}
      <RPButton onClick={() => setIsSessionExpiredOpen(true)} size="medium">
        Session expired
      </RPButton>
      <RPSessionModal
        isOpen={isSessionExpiredOpen}
        heading={sessionExpiredModalHeading}
        subHeadingLine1={sessionExpiredModalSubHeadingLine1}
        primaryBtnLabel={sessionExpiredModalBtnLabel}
        handleClose={() => undefined} // disabling backdrop click on popup
        handlePrimaryBtnClick={() => setIsSessionExpiredOpen(false)}
        modalVariant="sessionExpired"
        size={'small'}
      />
    </>
  );
};

export default RPModalVariants;

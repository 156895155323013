import { FC, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import RPText from '../../atoms/RPText';
import { LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../core/types/LayoutProps';
import { getIcon } from '../../../core/utils/IconOrgData';
// Constants
import { MASK_CLASS } from '../../../core/utils/Constants/Constants';

const UploadedFileDetails = styled(Box)(({ theme }) => {
  const {
    colours: { backgrounds }
  } = theme;

  const COLOUR_BG_BACKGROUND: string = backgrounds.background;

  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: COLOUR_BG_BACKGROUND
  };
});

const FileDetailsContainer = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px'
  };
});

interface FileNameDetailsProps extends LayoutProps {}

const FileNameDetails = styled(RPText)<FileNameDetailsProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';

  return {
    maxWidth: isDesktopLayout ? '290px' : isTabletLayout ? '228px' : '196px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };
});

const FileSizeDetails = styled(RPText)(({ theme }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAKEST: HexColour = text.textWeakest;

  return {
    color: COLOUR_TEXT_TEXT_WEAKEST
  };
});

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT: HexColour = text.text;

  return {
    display: 'inline-flex',
    cursor: 'pointer',

    '> svg > path': {
      fill: COLOUR_TEXT_TEXT
    }
  };
});

interface RPUploadedFileDetailsProps {
  fileName: string;
  fileSize: string;
  removeUploadedFileHandler: () => void;
}

const RPUploadedFileDetails: FC<RPUploadedFileDetailsProps> = (props: RPUploadedFileDetailsProps) => {
  const { layout } = useContext(LayoutContext);
  const { tenant } = useContext(TenantContext);

  const RemoveFileIcon = useMemo(() => getIcon(tenant, 'navigationClose'), [tenant]);

  const { fileName: uploadedFileName, fileSize: uploadedFileSize, removeUploadedFileHandler } = props;

  return (
    <UploadedFileDetails>
      <FileDetailsContainer>
        <FileNameDetails
          layout={layout}
          variant="body2"
          type="normal"
          title={uploadedFileName}
          text={uploadedFileName}
          className={MASK_CLASS}
        />
        <FileSizeDetails variant="subtitle1" type="normal" text={uploadedFileSize} />
      </FileDetailsContainer>

      <IconContainer data-testid="rp-file-details-close-icon" onClick={removeUploadedFileHandler}>
        <RemoveFileIcon />
      </IconContainer>
    </UploadedFileDetails>
  );
};

export default RPUploadedFileDetails;

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import RPLoader from '../../atoms/RPLoader';
import RPText from '../../atoms/RPText';

const StyledBox = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };
});

const RPLoaderVariants = () => {
  return (
    <>
      <RPText variant="h3" type="normal" text="Primary" />
      <StyledBox>
        <RPLoader type="primary" size="x-small" />
        <RPLoader type="primary" size="small" />
        <RPLoader type="primary" size="medium" />
        <RPLoader type="primary" size="large" />
        <RPLoader type="primary" size="x-large" />
      </StyledBox>

      <RPText variant="h3" type="normal" text="Secondary" />
      <StyledBox>
        <RPLoader type="secondary" size="x-small" />
        <RPLoader type="secondary" size="small" />
        <RPLoader type="secondary" size="medium" />
        <RPLoader type="secondary" size="large" />
        <RPLoader type="secondary" size="x-large" />
      </StyledBox>

      <RPText variant="h3" type="normal" text="Inverse" />
      <StyledBox sx={{ backgroundColor: '#0530AD', padding: '10px' }}>
        <RPLoader type="inverse" size="x-small" />
        <RPLoader type="inverse" size="small" />
        <RPLoader type="inverse" size="medium" />
        <RPLoader type="inverse" size="large" />
        <RPLoader type="inverse" size="x-large" />
      </StyledBox>
    </>
  );
};

export default RPLoaderVariants;

import { FC, useContext, useMemo } from 'react';
// import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useEffectOnce } from 'usehooks-ts';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../atoms/RPText';
import { MobileAppLinksWithIcons } from '../../../organisms/MobileAppLinksWithIcons';
// import RPButton from '../../../atoms/RPButton';
// import MobilePlaceholderWithIcon from '../../../molecules/MobilePlaceholderWithIcon';
// Types
import { HexColour } from '../../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../../core/types/LayoutProps';
import { IconKeys } from '../../../../core/types/IconOrgTypes';
import { LinkType}  from '../../../../core/types/GoogleAnalyticsTypes';
// RTK Slice
// import { getRegistrationDetails } from '../../../../redux/modules/registrationDetailsSlice';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../../core/utils/IconOrgData';
import { getImage } from '../../../../core/utils/ImageOrgData';
// import { extractDialingCodeValue } from '../../../../core/utils/DialingData/GetDialingData';
// import { getFormattedMobileNumber } from '../../../../core/utils/GetFormattedMobileNumber';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';
// Constants
// import { REGISTRATION_DEFINITIONS } from '../RegistrationDefinitions';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

/*interface MobileUploadProps {
  sendSmsSubmitHandler: (countryCode: string, mobileNumber: string) => void;
}*/

interface ButtonContainerProps extends LayoutProps {}

interface AppInfoContainerProps extends LayoutProps {}

interface DownloadAppContainerProps extends LayoutProps {}

interface DownloadAppTextProps extends LayoutProps {}

const StyledBoldText = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  return {
    color: COLOUR_TEXT_TEXT_WEAK,
    marginBottom: '10px'
  };
});

const SendFromMobileDescription = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  return {
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const MobileDownloadQRContainer = styled('div')<ButtonContainerProps>(({ layout }) => {
  return {
    marginTop: layout === 'desktop' ? 40 : 30,
    marginBottom: layout === 'desktop' ? 40 : 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& button': {
      width: '100%'
    }
  };
});

const AppInfoContainer = styled('div')<AppInfoContainerProps>(({ theme, layout }) => {
  const {
    colours: {
      backgrounds: { backgroundAltInverseWeak }
    }
  } = theme;

  const COLOUR_BH_BACKGROUND_ALT_INVERSE_WEAK: HexColour = backgroundAltInverseWeak;
  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    backgroundColor: COLOUR_BH_BACKGROUND_ALT_INVERSE_WEAK,
    padding: isDesktopLayout ? '20px 20px 0 20px' : '15px',
    height: isDesktopLayout ? '116px' : '104px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex'
  };
});

const StyledMobileAppImg = styled('img', {
  shouldForwardProp: (prop) => prop !== 'size'
})(() => {
  return {
    maxWidth: '78px',
    height: 'fit-content'
  };
});

const DownloadAppContainer = styled('div')<DownloadAppContainerProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    ...(isDesktopLayout
      ? {
        marginLeft: '20px'
      }
      : {
        margin: '0 auto'
      })
  };
});

const DownloadAppText = styled(RPText)<DownloadAppTextProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const isNotDesktopLayout: boolean = layout !== 'desktop';

  return {
    color: COLOUR_TEXT_TEXT_WEAK,
    marginBottom: '7px',
    ...(isNotDesktopLayout && {
      textAlign: 'center'
    })
  };
});

const StoreContainer = styled('div')(() => {
  return {
    display: 'flex',
    columnGap: '10px',
    '> a': {
      height: '36px'
    },
    '> a > svg': {
      width: '120px',
      height: 'auto'
    }
  };
});

const MobileUpload: FC = () => {
  // const { sendSmsSubmitHandler } = props;

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const mobileDownloadQRIconKey: string = tenant === 'torfxoz' ? 'mobileDownloadQRTorAU' : 'mobileDownloadQR';
  const MobileDownloadQRIcon = useMemo(() => getIcon(tenant, mobileDownloadQRIconKey as IconKeys), [tenant]);
  const MobileAppImg = useMemo(() => getImage(tenant, 'mobileApp'), [tenant]);

  const isDesktopLayout: boolean = layout === 'desktop';
  // const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  // const buttonSize: 'medium' | 'large' = isMobileLayout ? 'medium' : 'large';
  const sendFromMobileTextVariant: 'body2' | 'body1' = isDesktopLayout ? 'body1' : 'body2';
  const sendFromMobileDescType: 'normal' | 'medium' = isDesktopLayout ? 'medium' : 'normal';
  // const mobileNumberHeadingVariant: 'body2' | 'body1' = isDesktopLayout ? 'body1' : 'body2';

  // const { countryCode, mobileNumber, loading: mobileDownloadSMSLoading } = useSelector(getRegistrationDetails);

  // const formattedMobileNumber: string = getFormattedMobileNumber(countryCode, mobileNumber);

  // const isAPIInProgress: boolean = mobileDownloadSMSLoading === 'loading';

  const {
    eventAction: { load, click },
    kycFailedDocument: {
      load: { mobileDocumentUploadField },
      click: { googlePlayIcon, appStoreIcon }
    }
  } = GoogleAnalyticsTagsMapping;

  const sendFromMobileText: string = translate('registration.confirmingItsYou.uploadDocs.uploadMobile.sendFromMobile');
  const sendFromMobileDescription: string = translate(
    'registration.confirmingItsYou.uploadDocs.uploadMobile.sendFromMobileDescription'
  );
  const downloadAppText: string = translate('registration.confirmingItsYou.uploadDocs.uploadMobile.downloadApp');
  /*const mobileNumberHeading: string = translate(
    'registration.confirmingItsYou.uploadDocs.uploadMobile.mobileNumberHeading'
  );
  const continueButtonText: string = translate(
    'registration.confirmingItsYou.uploadDocs.uploadMobile.continueButtonText'
  );*/

  /*const {
    confirmingYou: {
      mobileUpload: {
        sendSMS: {
          name: sendSMSFieldName,
          dataTestId: sendSMSFieldDataTestId
        }
      }
    }
  } = REGISTRATION_DEFINITIONS;*/

  useEffectOnce(() => {
    TriggerGoogleAnalyticsTag(load, mobileDocumentUploadField);
  });

  const linkClick = (type: LinkType) => {
    TriggerGoogleAnalyticsTag(click, type === LinkType.AndroidStore ? googlePlayIcon : appStoreIcon);
  };

  return (
    <>
      <StyledBoldText variant={sendFromMobileTextVariant} type="bold" text={sendFromMobileText} />
      <SendFromMobileDescription variant="body2" type={sendFromMobileDescType} text={sendFromMobileDescription} />
      {/*<StyledBoldText variant={mobileNumberHeadingVariant} type="bold" text={mobileNumberHeading} />
      <MobilePlaceholderWithIcon
        size={isDesktopLayout ? 'large' : isTabletLayout ? 'medium' : 'small'}
        mobileNoData={extractDialingCodeValue(countryCode) + ' ' + formattedMobileNumber}
      />*/}

      <MobileDownloadQRContainer layout={layout}>
        <MobileDownloadQRIcon />
        {/*<RPButton
          size={buttonSize}
          disabled={isAPIInProgress}
          endIconType={isAPIInProgress ? 'loader' : ''}
          onClick={() => sendSmsSubmitHandler(countryCode, mobileNumber)}
          name={sendSMSFieldName}
          data-testid={sendSMSFieldDataTestId}
        >
          {continueButtonText}
        </RPButton>*/}
      </MobileDownloadQRContainer>
      <AppInfoContainer layout={layout}>
        {isDesktopLayout && <StyledMobileAppImg src={MobileAppImg} alt="Mobile App" />}
        <DownloadAppContainer layout={layout}>
          <DownloadAppText variant="body1" type="medium" text={downloadAppText} layout={layout} />
          <StoreContainer>
            <MobileAppLinksWithIcons linkClickHandler={linkClick} />
          </StoreContainer>
        </DownloadAppContainer>
      </AppInfoContainer>
    </>
  );
};

export default MobileUpload;

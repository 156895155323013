import { useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
import FullLayout from '../../organisms/FullLayout';
// Hooks
import { useScrollToContentTop } from '../../../core/hooks';
// Types
import { LayoutProps } from '../../../core/types/LayoutProps';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

interface MaintenancePageContainerWrapperProps extends LayoutProps {}
interface HeadingTextWrapperProps extends LayoutProps {}

const MaintenancePageContainerWrapper = styled(Box)<MaintenancePageContainerWrapperProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';

  const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    maxWidth: isMobileLayout ? '325px' : '550px',
    height: '100%',
    maxHeight: 600,
    margin: isDesktopLayout ? '165px 0px 0px 0px' : isTabletLayout ? '50px 0px 0px 0px' : '130px 0px 30px 0px'
  };
});

const MaintenancePageContainer = styled(Box)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '30px',
    boxSizing: 'border-box',
    marginTop: '9px',

    '& .MuiTypography-root': {
      textAlign: 'center',
      color: COLOUR_TEXT_TEXT_WEAK
    }
  };
});

const HeadingTextWrapper = styled(Box)<HeadingTextWrapperProps>(({ layout }) => {
  const isMobileLayout = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: isMobileLayout ? '10px' : '24px'
  };
});

const MaintenancePage = () => {
  const { layout } = useContext(LayoutContext);
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);

  const MaintenancePageIcon = useMemo(() => getIcon(tenant, 'MaintenancePage'), [tenant]);

  const headingText: string = translate('maintenancePage.heading');
  const subHeadingText: string = translate('maintenancePage.description');

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const headingVariant: 'h2' | 'h3' = isMobileLayout ? 'h3' : 'h2';
  const subHeadingVariant: 'body1' | 'body2' = isMobileLayout ? 'body2' : 'body1';

  // Scrolls to the top of the content area
  useScrollToContentTop();

  return (
    <FullLayout hideAdditionalInteractions>
      <MaintenancePageContainerWrapper layout={layout} data-testid="rp-maintenance-page-wrapper">
        <MaintenancePageContainer>
          <MaintenancePageIcon />
          <HeadingTextWrapper layout={layout}>
            <RPText variant={headingVariant} type="bold" text={headingText} />
            <RPText variant={subHeadingVariant} type="normal" text={subHeadingText} />
          </HeadingTextWrapper>
        </MaintenancePageContainer>
      </MaintenancePageContainerWrapper>
    </FullLayout>
  );
};

export default MaintenancePage;

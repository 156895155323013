import { useContext } from 'react';
import * as yup from 'yup';

// Contexts
import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Types
import { AccountTypes } from './Register';
import { OrganizationCodeTypes } from '../../../core/types/OrganizationTypes';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { checkIfCFSBIsEnabled } from '../../../core/utils/CheckIfCFSBIsEnabled';
// Constants
import {
  ORGANIZATION_CODE_MAPPING,
  ORGANIZATION_STATE_DROPDOWN_MAPPING,
  ORGANIZATION_STATE_DROPDOWN_MAPPING_CFSB_FOR_LANDING
} from '../../../core/utils/Constants/Constants';

export const ValidationSchema = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);
  const { tenant } = useContext(TenantContext);

  const currentTenant: string = tenant ? tenant : ORGANIZATION_CODE_MAPPING.currenciesdirect;

  const stateRequiredErrorText: string = translate('registerPage.stateRequiredErrorText');

  const isCFSBEnabled: boolean = checkIfCFSBIsEnabled();

  const OrganisationStateDropdownMappingObject: { [K in OrganizationCodeTypes]: Array<string> } = isCFSBEnabled ?
    ORGANIZATION_STATE_DROPDOWN_MAPPING_CFSB_FOR_LANDING : ORGANIZATION_STATE_DROPDOWN_MAPPING;

  // const countryOfResidenceRequiredText: string = translate('registerPage.countryOfResidence.error.required');

  return yup
    .object({
      selectedState: yup.string().when('accountType', {
        is: AccountTypes.individual,
        then: yup.string().when('countryOfResidence', {
          is: (countryOfResidence: string) => {
            return OrganisationStateDropdownMappingObject[currentTenant as OrganizationCodeTypes].includes(
              countryOfResidence?.trim().toLowerCase()
            );
          },
          then: yup.string().trim().required(stateRequiredErrorText)
        })
      })
    })
    .required();
};
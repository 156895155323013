// Types
import { OrganizationCodeTypes, OrganizationDetailsEnvSpecific } from '../../types/OrganizationTypes';
import { GetGAMeasurementAndClientIDType } from '../../types/GoogleAnalyticsTypes';
// Utils
import GetBasicDetails from '../GetBasicDetails';
import GetCurrentTenant from '../GetCurrentTenant';
import { getConfigProperty } from '../../EnvironmentConfig/EnvironmentConfig';

const GetGAMeasurementAndClientID = (): GetGAMeasurementAndClientIDType => {
  const { referringCompany } = GetBasicDetails();
  const { currentTenant } = GetCurrentTenant(referringCompany as OrganizationCodeTypes);

  const measurementId = (getConfigProperty('googleAnalyticsMeasurementId') as OrganizationDetailsEnvSpecific)[
    currentTenant as OrganizationCodeTypes
  ];

  const clientId: string = (window as any)?.ga?.getAll()?.[0]?.get('clientId') || '';

  return {
    measurementId,
    clientId
  };
};

export default GetGAMeasurementAndClientID;

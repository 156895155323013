import { FC, memo } from 'react';
import Link, { LinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';

// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { TypographyTypes } from '../../../core/types/TypographyTypes';
// Utils
import getTypographyStyles from '../../../core/utils/GetTypographyStyles';

type LinkTypes = 'bold' | 'medium' | 'normal';

interface RPLinkWithChildren extends LinkProps {
  children: React.ReactNode;
}

interface RPLinkWithText extends LinkProps {
  text: string;
  type?: LinkTypes;
}

type RPLinkProps = RPLinkWithChildren | RPLinkWithText;

const StyledRPLink = styled(Link)(({ theme, variant, type }) => {
  const {
    colours: {
      text: { textLink, textInputFocus }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = textLink;
  const COLOUR_TEXT_TEXT_INPUT_FOCUS: HexColour = textInputFocus;

  return {
    ...getTypographyStyles(variant, type as TypographyTypes, theme),
    color: COLOUR_TEXT_TEXT_LINK,
    textDecorationColor: COLOUR_TEXT_TEXT_LINK,

    '&:hover': {
      color: COLOUR_TEXT_TEXT_LINK,
      cursor: 'pointer'
    },

    '&.Mui-focusVisible': {
      outline: `${COLOUR_TEXT_TEXT_INPUT_FOCUS} solid 1px`,
      outlineOffset: 1,
      color: COLOUR_TEXT_TEXT_INPUT_FOCUS,
      borderRadius: 4
    }
  };
});

const RPLink: FC<RPLinkProps> = (props: RPLinkProps) => {
  const { variant = 'body2', underline = 'hover', onClick, children, ...otherProps } = props;

  const linkText: string = 'text' in props ? props.text : '';
  const linkType: LinkTypes = 'type' in props && props.type ? (props.type as LinkTypes) : 'bold';

  return (
    <StyledRPLink
      data-testid={'rp-link'}
      underline={underline}
      variant={variant}
      type={linkType}
      onClick={(e) => {
        onClick && onClick(e);
      }}
      {...otherProps}
    >
      {children}
      {linkText}
    </StyledRPLink>
  );
};

export default memo(RPLink);

import cookie from 'js-cookie';

const CreateCookie = (name: string, value: string, options: cookie.CookieAttributes = {}): void => {
  cookie.set(name, value, options);
};

const GetCookie = (name: string): string | undefined => {
  return cookie.get(name);
}

export { CreateCookie, GetCookie };
import { useState, ChangeEvent } from 'react';
import Collapse from '@mui/material/Collapse';

import RPInput from '../../atoms/RPInput';
import RPPasswordCriteria from '../../molecules/RPPasswordCriteria';

const RPPasswordCriteriaVariants = () => {
  const [value, setValue] = useState('');
  const [isCriteriaVisible, setIsCriteriaVisible] = useState(false);

  const handleOnChange = (password: string) => {
    setValue(password);
  };

  return (
    <>
      <RPInput
        name="inputPassword"
        value={value}
        placeholderLabel="Password"
        type="password"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleOnChange(e.target.value)
        }
        onFocus={() => setIsCriteriaVisible(true)}
        maxLength={50}
      />
      <Collapse in={isCriteriaVisible}>
        {isCriteriaVisible && <RPPasswordCriteria value={value} />}
      </Collapse>
    </>
  );
};

export default RPPasswordCriteriaVariants;

const getErrorTextKey = (errorCode: string) => {
  let errorTextKey: string;

  switch (errorCode) {
    case '400':
      errorTextKey = 'error.errorCode400Text';
      break;
    case '404':
      errorTextKey = 'error.errorCode404Text';
      break;
    case '406':
      errorTextKey = 'error.errorCode406Text';
      break;
    case '500':
      errorTextKey = 'error.errorCode500Text';
      break;
    case 'C003':
      errorTextKey = 'error.checkOTP.C003';
      break;
    case 'C006':
      errorTextKey = 'error.checkOTP.C006';
      break;
    case 'V005':
      errorTextKey = 'error.generateOTP.V005';
      break;
    case 'V012':
      errorTextKey = 'error.generateOTP.V012';
      break;
    case 'V013':
      errorTextKey = 'error.generateOTP.V013';
      break;
    case 'V016':
      errorTextKey = 'error.generateOTP.V016';
      break;
    default:
      errorTextKey = 'error.commonErrorCodeText';
      break;
  }

  return errorTextKey;
};

export default getErrorTextKey;

import { FC, useContext, useMemo, useRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { CSSTransition } from 'react-transition-group';

// Contexts
import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPText } from '../../atoms/RPText';
import MobileAppLinksWithIcons from '../MobileAppLinksWithIcons';
// Hooks
import { useAnimation } from '../../../core/hooks';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getImage } from '../../../core/utils/ImageOrgData';
// Constants
import { LinkType } from '../../../core/types/GoogleAnalyticsTypes';

interface MobileAppFooterSize {
  size: 'large' | 'small';
}

interface MobileAppFooterProps extends BoxProps, MobileAppFooterSize {
  linkClickHandler?: (type: LinkType) => void;
}

const MobileAppFooter = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size'
})<MobileAppFooterProps>(({ theme, size }) => {
  const {
    colours: { backgrounds }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;

  return {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    zIndex: 999,
    left: 0,
    bottom: 0,
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    transform: 'none',
    opacity: 1
  };
});

interface MobileAppFooterContainerProps extends BoxProps, MobileAppFooterSize {}

const MobileAppFooterContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size'
})<MobileAppFooterContainerProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    position: 'relative',
    padding: isSmallSize ? '17px 0px 12px 0px' : '17px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  };
});

const StyledMobileAppImg = styled('img', {
  shouldForwardProp: (prop) => prop !== 'size'
})<MobileAppFooterSize>(({ size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    position: 'absolute',
    maxWidth: isSmallSize ? '90px' : '105px',
    left: 0,
    ...(isSmallSize && {
      top: '8px'
    })
  };
});

const StyledCopyAndLinksWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size'
})<MobileAppFooterSize>(({ size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    display: 'flex',
    gap: isSmallSize ? '10px' : '79px',
    flexDirection: isSmallSize ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...(isSmallSize
      ? {
          padding: '0px 0px 0px 100px'
        }
      : {
          padding: '2px 0px 2px 141px'
        })
  };
});

const MobileAppLinks = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size'
})<MobileAppFooterProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    display: 'flex',
    gap: '20px',
    ...(isSmallSize && {
      '> a': {
        height: '33px'
      },
      '> a > svg': {
        ':nth-of-type(1)': {
          width: '110px',
          height: 'auto'
        },
        ':nth-of-type(2)': {
          width: '110px',
          height: 'auto'
        }
      }
    })
  };
});

const MobileAppCopy = styled(Box)(() => {
  return {
    maxWidth: '243px'
  };
});

const RPMobileAppFooter: FC<MobileAppFooterProps> = (props: MobileAppFooterProps) => {
  const { tenant } = useContext(TenantContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const [animateContent, setAnimateContent] = useAnimation(false, 700);
  const cssTransitionRef = useRef(null);

  const MobileAppImg = useMemo(() => getImage(tenant, 'mobileApp'), [tenant]);

  const { size, linkClickHandler } = props;

  const isSmallSize: boolean = size === 'small';
  const headingText: string = translate('common.mobileAppFooter.heading');
  const subHeadingText: string = translate('common.mobileAppFooter.subHeading');

  return (
    <CSSTransition
      nodeRef={cssTransitionRef}
      in={animateContent}
      timeout={500}
      classNames="slideUp"
      unmountOnExit
      onEnter={() => setAnimateContent}
      onExited={() => setAnimateContent}
    >
      <MobileAppFooter size={size} ref={cssTransitionRef}>
        <MobileAppFooterContainer size={size}>
          <StyledMobileAppImg size={size} src={MobileAppImg} alt="Mobile App" />
          <StyledCopyAndLinksWrapper size={size}>
            <MobileAppCopy>
              <RPText variant={isSmallSize ? 'body2' : 'body1'} type="bold" text={headingText} />
              <RPText variant="body2" type="normal" text={subHeadingText} />
            </MobileAppCopy>
            <MobileAppLinks size={size}>
              <MobileAppLinksWithIcons linkClickHandler={() => linkClickHandler} />
            </MobileAppLinks>
          </StyledCopyAndLinksWrapper>
        </MobileAppFooterContainer>
      </MobileAppFooter>
    </CSSTransition>
  );
};

export default RPMobileAppFooter;

import { FC, useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPText } from '../../atoms/RPText';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';

interface ContentProps {
  hasText: boolean;
}

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;

  return {
    color: COLOUR_TEXT_TEXT_LINK,
    '& > svg > circle': {
      strokeLinecap: 'round'
    }
  };
});

const Container = styled(Box)(() => {
  return {
    position: 'relative',
    display: 'inline-flex'
  };
});

const Content = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasText'
})<ContentProps>(({ hasText }) => {
  return {
    ...(!hasText && { padding: 0, width: '66px', height: '48px' }),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  };
});

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: { backgrounds }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK: HexColour = backgrounds.backgroundAltInverseWeak;

  return {
    position: 'absolute',
    '> svg': {
      width: '150px',
      height: '150px'
    },
    '> svg > path': {
      fill: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK
    }
  };
});

const LoaderValue = styled(Box)(() => {
  return {
    display: 'flex',
    alignItems: 'baseline'
  };
});

interface RPTimerLoaderProps {
  value: number;
  countDownText?: string;
  timerValue?: string;
}

const RPTimerLoader: FC<RPTimerLoaderProps> = (props: RPTimerLoaderProps) => {
  const { timerValue, countDownText } = props;

  const { tenant } = useContext(TenantContext);

  const BlankEllipsis = useMemo(() => getIcon(tenant, 'ellipseBlank'), [tenant]);

  return (
    <Container>
      <IconContainer>
        <BlankEllipsis />
      </IconContainer>
      <StyledCircularProgress variant="determinate" size={150} thickness={2} {...props} />
      <Content hasText={Boolean(timerValue)}>
        <LoaderValue>{timerValue && <RPText variant="h1" type="bold" text={timerValue} />}</LoaderValue>
        {countDownText && <RPText variant="body1" type="medium" text={countDownText} />}
      </Content>
    </Container>
  );
};

export default RPTimerLoader;

import { useContext, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Container, Stack, Box } from '@mui/material';

import RPButton1 from '../../atoms/RPButton1';
import RPLogoWrapper from '../../organisms/RPLogoWrapper';
import RPTypography from '../../organisms/RPTypography';
import { RPButtonVariants } from '../../organisms/RPButtonVariants';
import { LanguageContext } from '../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import RPInputVariants from '../../organisms/RPInputVariants';
import RPRadioVariants from '../../organisms/RPRadioVariants';
import RPPinFieldVariants from '../../organisms/RPPinFieldVariants';
import ListWithIcon from '../../molecules/ListWithIcon';
import { RPText } from '../../atoms/RPText';
import RPDatePickerVariants from '../../organisms/RPDatePickerVariants';
import RPCheckbox from '../../atoms/RPCheckbox';
import MobilePlaceholderWithIcon from '../../molecules/MobilePlaceholderWithIcon';
import RPModalVariants from '../../organisms/RPModalVariants';
import RPDialogVariants from '../../organisms/RPDialogVariants';
import RPDoBVariants from '../../organisms/RPDoBVariants';
import RPNeedHelpSectionVariants from '../../organisms/RPNeedHelpSectionVariants';
import RPFooter from '../../organisms/RPFooter';
import RPSelectVariants from '../../organisms/RPSelectVariants';
import RPNotificationVariants from '../../organisms/RPNotificationVariants';
import SocialProof from '../../molecules/SocialProof';
import RPPasswordCriteriaVariants from '../../organisms/RPPasswordCriteriaVariants';
import RPFactCards from '../../molecules/RPFactCards';
import RPStepProgressTrackerVariants from '../../organisms/RPStepProgressTrackerVariants';
import RPLoaderVariants from '../../organisms/RPLoaderVariants';
import RPHeaderRightVariants from '../../organisms/RPHeaderRightVariants';
import RPLinearProgressBarVariants from '../../organisms/RPLinearProgressBarVariants';
import RPAddressLookupVariants from '../../organisms/RPAddressLookupVariants';
import RPDocumentUpload from '../../molecules/RPDocumentUpload';
import RPMobileMenuVariants from '../../organisms/RPMobileMenuVariants';
import RPPercentageLoader from '../../molecules/RPPercentageLoader';
import RPPercentageLoaderVariant from '../../organisms/RPPercentageLoaderVariant/RPPercentageLoaderVariant';
import setSelectedCountry from '../../../core/utils/SetSelectedCountry';
import RPSnackbarVariants from '../../organisms/RPSnackbarVariants';
import RPInformationalBlurb from '../../molecules/RPInformationalBlurb';

const LandingPage = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const country: string | null = searchParams.get('country');
    setSelectedCountry(country);
  }, [searchParams]);

  /*const { name, email } = useSelector(getRegistrationDetails);

  const displayRegistrationDetails = (
    <>
      <h3>{`${translate('registration.content.name')} ${name}`}</h3>
      <h3>{`${translate('registration.content.email')} ${email}`}</h3>
    </>
  );*/

  console.log('APP VERSION: ', process.env?.REACT_APP_VERSION);

  return (
    <Container maxWidth="md" data-app-version={`v${process.env.REACT_APP_VERSION}`}>
      <Stack spacing={2} direction="column">
        <RPLogoWrapper
          logoVariant="logo"
          tenant="currenciesdirect"
          link="https://uatcd.currenciesdirectholdings.com/CustomerPortal/landing.html"
        />
        <RPButton1 />
        <Link to="/registration/1">{translate('landing.startRegistrationLink')}</Link>
        <br />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Link to="/split-layout">Split Layout</Link>
          <br />
          <Link to="/split-layout-unequal">Split Layout Unequal</Link>
          <br />
          <Link to="/full-layout">Full Page Layout</Link>
          <br />
          <Link to="/skip-docs">Skip Docs Page</Link>
          <br />
          <Link to="/kyc-pass">KYC Pass Page</Link>
          <br />
          <Link to="/registration-pending">KYC Fail Page</Link>
          <br />
          <Link to="/generic-error">Generic Error Page</Link>
          <br />
          <Link to="/error-boundary">Error boundary Page</Link>
          <br />
          <Link to="/doc-upload-complete">Doc Upload Complete Page</Link>
          <br />
          <Link to="/document-uploaded">Document Uploaded Page</Link>
          <br />
          <Link to="/document-uploading">Document Uploading Page</Link>
          <br />
          <Link to="/page-not-found">404 Page</Link>
          <br />
          <Link to="/maintenance-page">Maintenance Page</Link>
        </Box>
        <br />
        {/*{name.length > 0 && displayRegistrationDetails}*/}

        {/*<Box>
          <RPInformationalBlurb />
        </Box>*/}

        <RPSelectVariants />

        <Box
          sx={{
            width: '420px',
            margin: 'auto !important'
          }}
        >
          <RPPercentageLoader value={62} text="uploading..." />
          <br />

          <RPPercentageLoaderVariant />
          <br />

          <RPDocumentUpload />
        </Box>

        <br />

        <RPSnackbarVariants />

        <br />

        <Box>
          <RPFactCards />
        </Box>

        <Box>
          <RPLoaderVariants />
        </Box>

        <Box>
          <RPLinearProgressBarVariants />
        </Box>

        <Box>
          <RPStepProgressTrackerVariants />
        </Box>

        <Box>
          <RPMobileMenuVariants />
        </Box>

        <Box
          sx={{
            backgroundColor: '#0530AD',
            padding: '40px 60px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'center'
          }}
        >
          <SocialProof />
          <br />
          <SocialProof size="medium" />
        </Box>

        <Box>
          <RPPasswordCriteriaVariants />
        </Box>
        <Box>
          <RPAddressLookupVariants />
        </Box>

        <RPHeaderRightVariants />

        <RPNotificationVariants />

        <Box>
          {/* Modals */}
          <RPModalVariants />
        </Box>
        <Box>
          {/* Dialogs */}
          <RPDialogVariants />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <RPFooter />
        </Box>

        <RPNeedHelpSectionVariants />
        <Box component="form" autoComplete="off">
          {/* Pin field */}
          <RPPinFieldVariants />

          {/* ------- Date picker ------- */}
          <RPDatePickerVariants />

          {/* ------- Date of birth ------- */}
          <RPDoBVariants />

          {/* ------- Radio button ------- */}
          <RPRadioVariants />

          {/* ------- Input field ------- */}
          <RPInputVariants />

          {/* ------- Checkbox variant ------- */}
          <RPCheckbox type="large" label="Checkbox label" />
          <br />
          <RPCheckbox disabled label="Checkbox - disabled" />
        </Box>

        <Box>
          <MobilePlaceholderWithIcon size="large" mobileNoData="+44 7944 123 456" />
          <MobilePlaceholderWithIcon size="medium" mobileNoData="+44 7944 123 456" />
          <MobilePlaceholderWithIcon size="small" mobileNoData="+44 7944 123 456" />
          <br />
        </Box>

        <Box>
          <RPCheckbox type="large" label="Checkbox label" />
          <br />
          <RPCheckbox disabled label="Checkbox - disabled" />
        </Box>
        <Box>
          <MobilePlaceholderWithIcon size="large" mobileNoData="+44 7944 123 456" />
          <MobilePlaceholderWithIcon size="medium" mobileNoData="+44 7944 123 456" />
          <MobilePlaceholderWithIcon size="small" mobileNoData="+44 7944 123 456" />
          <br />
        </Box>
        <Box>
          <RPText variant="h2" type="normal" text="List with Icons" />
          <ListWithIcon listType="features-bullets" />
        </Box>
        <Box>
          <RPTypography />
          <RPButtonVariants />
        </Box>
      </Stack>
    </Container>
  );
};

export default LandingPage;

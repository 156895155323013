import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper, { ObjectMapping } from './axiosWrapper';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';

type getProofOfIdentityRequestData = {
  legalEntity: string;
  locale: string;
};

const getProofOfIdentity = createAsyncThunk(
  '/reference-data/proof-of-identity', async (data: getProofOfIdentityRequestData, thunkAPI) => {

    const { locale, legalEntity } = data;
    const dataToReplace: ObjectMapping = {
      legalEntity,
      locale
    };

    try {
      const response = await axiosWrapper('getProofOfIdentity', 'GET', thunkAPI, null, dataToReplace);

      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  });

export default getProofOfIdentity;
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import GenerateQueryParamsFromObject from '../../core/utils/GenerateQueryParamsFromObject';
import { AUTH_SERVICE_MAX_ATTEMPTS } from '../../core/utils/Constants/Constants';

export interface GenerateTokenData {
  clientId: string;
  clientSecret: string;
  attempt: number;
  test?: boolean;
}

const generateToken = createAsyncThunk(
  'generateToken',
  async (generateTokenData: GenerateTokenData, thunkAPI) => {

    const deviceId: string = localStorage.getItem('deviceId') || '';
    const { clientId, clientSecret, attempt, test = false } = generateTokenData;

    const data = {
      'grant_type': 'registration',
      'client_id': clientId,
      'client_secret': clientSecret,
      'scope': 'app',
      'device_id': deviceId,
      'version': '10',
    };

    const queryParams: string = GenerateQueryParamsFromObject(data);

    try {
      // if(test) throw new Error;

      const response = await axios({
        url: '/auth/oauth/v2/token',
        method: 'POST',
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "accept": "*!/!*"
        },
        data: queryParams,
      });

      console.log('generateToken response: ', response);

      const accessToken = response.data.access_token;
      localStorage.setItem('accessToken', accessToken);

      return response && response.data;
    } catch (error: any) {
      console.log('generateToken error: ', error);
      if (attempt < AUTH_SERVICE_MAX_ATTEMPTS) {
        const updatedGenerateTokenData: GenerateTokenData = {
          ...generateTokenData,
          attempt: attempt + 1,
        };
        console.log('generateToken re-attempt: ', error);
        thunkAPI.dispatch(generateToken(updatedGenerateTokenData));
      } else {
        // thunkAPI.dispatch(getServicesErrorObj(error));
        console.log('Error in generateToken');
        return thunkAPI.rejectWithValue({ error: error.response.data.message });
      }
    }
  }
);

export default generateToken;
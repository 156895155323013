import { getDefaultTheme } from '../currenciesdirect/getThemeConfig';

const theme = {
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none'
        }
      }
    }
  },
  ...getDefaultTheme()
};

export default theme;

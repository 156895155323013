import { FC, ReactNode, useContext, useMemo } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { keyframes } from '@mui/material';
import { Keyframes } from '@emotion/react';

import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface RPModalProps {
  isOpen: boolean;
  handleClose: () => void;
  body: ReactNode;
  size?: SizeProps['size'];
  isSessionModal?: boolean;
}

export type SizeProps = {
  size: 'normal' | 'small';
};

interface StyledIconButtonProps {
  iconSize: SizeProps['size'];
}

interface ModalContainerProps extends SizeProps {
  isOpen: boolean;
  isSessionModal?: boolean;
}

interface StyledBodyProps extends SizeProps {
  isSessionModal?: boolean;
}

const StyledModal = styled(Modal)(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
});

const StyledBackdrop = styled(Backdrop)(({ theme }) => {
  const {
    colours: {
      backgrounds: { backgroundBackdrop }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_BACKDROP: string = backgroundBackdrop;

  return {
    backgroundColor: COLOUR_BG_BACKGROUND_BACKDROP,
    opacity: '0.5 !important'
  };
});

const slideUpAnimation: Keyframes = keyframes`
  0% {
    transform: translate(-50%, 0%);
    opacity: 0;
  }
  40% {
    transform: translate(-50%, -30%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
`;

const slideDownAnimation: Keyframes = keyframes`
  0% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  60% {
    transform: translate(-50%, -30%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0%);
    opacity: 0;
  }
`;

const ModalContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'isOpen' && prop !== 'isSessionModal'
})<ModalContainerProps>(({ theme, size, isOpen, isSessionModal }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  const isSmallSize: boolean = size === 'small';

  let currentSlideAnimation: string = '';
  let currentFadeAnimation: string = '';

  if (isOpen) {
    currentSlideAnimation = slideUpAnimation;
    currentFadeAnimation = 'fadeInAnimation';
  } else {
    currentSlideAnimation = slideDownAnimation;
    currentFadeAnimation = 'fadeOutAnimation';
  }

  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '12px',
    animation: `${currentSlideAnimation} 0.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards,
                ${currentFadeAnimation} 0.5s forwards`,
    opacity: 0,

    ...(isSessionModal
      ? { maxWidth: isSmallSize ? '325px' : '550px' }
      : { maxWidth: isSmallSize ? '285px' : '580px', padding: '20px' }),
    ':focus-visible': {
      outline: 0
    }
  };
});

const Header = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-end'
  };
});

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'iconSize'
})<StyledIconButtonProps>(({ theme, iconSize }) => {
  const {
    colours: {
      text: { textWeak, textInputFocus, textInputIcon }
    }
  } = theme;

  const isSmallSize: boolean = iconSize === 'small';

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const COLOUR_TEXT_TEXT_INPUT_FOCUS: HexColour = textInputFocus;
  const COLOUR_TEXT_TEXT_INPUT_ICON: HexColour = textInputIcon;

  return {
    padding: 0,

    ':hover': {
      backgroundColor: 'transparent'
    },

    '> svg': {
      width: isSmallSize ? '26px' : '36px',
      height: isSmallSize ? '26px' : '40px',

      '> path': {
        fill: COLOUR_TEXT_TEXT_WEAK
      }
    },

    '&.Mui-focusVisible': {
      outline: `${COLOUR_TEXT_TEXT_INPUT_FOCUS} solid 1px`,
      borderRadius: '4%',
      outlineOffset: 4,

      '> svg > path': {
        fill: COLOUR_TEXT_TEXT_INPUT_ICON
      }
    }
  };
});

const StyledBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'isSessionModal'
})<StyledBodyProps>(({ size, isSessionModal }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    display: 'flex',
    justifyContent: 'center',
    ...(isSessionModal
      ? { padding: isSmallSize ? '30px' : '50px 30px 40px 30px' }
      : { marginTop: '-10px', padding: '0 10px 20px' })
  };
});

const RPModal: FC<RPModalProps> = (props: RPModalProps) => {
  const { isOpen, handleClose, body, size = 'normal', isSessionModal } = props;

  const { tenant } = useContext(TenantContext);

  const CloseIcon = useMemo(() => getIcon(tenant, 'navigationClose'), [tenant]);

  return (
    <StyledModal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit timeout={500}>
        <ModalContainer data-testid="container" size={size} isOpen={isOpen} isSessionModal={isSessionModal}>
          {!isSessionModal && (
            <Header>
              <StyledIconButton
                onClick={handleClose}
                disableRipple
                iconSize={size}
                name="closeModalButton"
                data-testid="rp-button-modal-close"
              >
                <CloseIcon />
              </StyledIconButton>
            </Header>
          )}
          <StyledBody size={size} isSessionModal={isSessionModal}>
            {body}
          </StyledBody>
        </ModalContainer>
      </Slide>
    </StyledModal>
  );
};

export default RPModal;

import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper from './axiosWrapper';
import GetBasicDetails from '../../core/utils/GetBasicDetails';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';

type checkDuplicatePayloadType = {
  firstNameInitial: string;
  lastName: string;
  birthDate:string;
};

const { orgCode, parentOrganizationCode, accountType } = GetBasicDetails();

const checkDuplicateAccountAdvanced = createAsyncThunk(
  "registration-service/checkDuplicateAccountAdvanced",
  async (data: checkDuplicatePayloadType, thunkAPI) => {
    const payload = {
      "firstNameInitial": data.firstNameInitial,
      "lastName": data.lastName,
      "orgCode": orgCode,
      "parentOrgCode": parentOrganizationCode,
      "accountType": accountType,
      "birthDate": data.birthDate
    }
    try {
      const response = await axiosWrapper('checkDuplicateAccountAdvanced', 'POST', thunkAPI, payload);

      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  });

export default checkDuplicateAccountAdvanced;
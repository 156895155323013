// Types
import { OrganizationCodeTypes } from '../../types/OrganizationTypes';
// Constants
import {
  EN_US,
  TENANTS,
  CD_TNC_EN_US_LINK,
  CD_TNC_LINK,
  CD_PRIVACY_POLICY_EN_US_LINK,
  CD_PRIVACY_POLICY_LINK,
  TORFX_TNC_LINK,
  TORFX_PRIVACY_POLICY_LINK,
  TORFXOZ_TNC_LINK,
  TORFXOZ_PRIVACY_POLICY_LINK
} from '../Constants/Constants';

type TncPrivacyPolicyLinkType = {
  termsAndConditions: string;
  privacyPolicy: string;
};

export const getTnCPrivacyPolicyLinks = (tenant: OrganizationCodeTypes | undefined, language: string | undefined) => {
  const isTenantTorFX: boolean = tenant === TENANTS.TOR_FX;
  const isTenantTorFXOz: boolean = tenant === TENANTS.TORFX_OZ;
  const links: TncPrivacyPolicyLinkType = {
    termsAndConditions: '',
    privacyPolicy: ''
  };
  const isEnUS: boolean = language?.trim().toLowerCase() === EN_US;

  if (isTenantTorFX) {
    links.termsAndConditions = TORFX_TNC_LINK;
    links.privacyPolicy = TORFX_PRIVACY_POLICY_LINK;
  } else if (isTenantTorFXOz) {
    links.termsAndConditions = TORFXOZ_TNC_LINK;
    links.privacyPolicy = TORFXOZ_PRIVACY_POLICY_LINK;
  } else {
    links.termsAndConditions = isEnUS ? CD_TNC_EN_US_LINK : CD_TNC_LINK;
    links.privacyPolicy = isEnUS ? CD_PRIVACY_POLICY_EN_US_LINK : CD_PRIVACY_POLICY_LINK;
  }
  return links;
};

import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

// Components - Atoms, Molecules, Organisms, Pages
import RPPercentageLoader from '../../molecules/RPPercentageLoader';
import { SplitLayout } from '../../organisms/SplitLayout';
import FullLayout from '../../organisms/FullLayout';
// Hooks
import { useAnimation, useScrollToContentTop } from '../../../core/hooks';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { RegistrationReturnInactiveProps, RegistrationReturnProps } from '../../../core/types/RegistrationReturnTypes';
// RTK Slice
import { updateCurrentStep } from '../../../redux/modules/registrationStepsSlice';
import { getRegistrationDetails } from '../../../redux/modules/registrationDetailsSlice';
// Constants
import {
  DOCUMENT_UPLOADED_PAGE_URL,
  DOCUMENT_UPLOADED_RETURN_INACTIVE_PAGE_URL,
  DOCUMENT_UPLOADED_RETURN_PAGE_URL,
  REGISTRATION_PENDING_PAGE_URL,
  REGISTRATION_PENDING_RETURN_INACTIVE_PAGE_URL,
  REGISTRATION_PENDING_RETURN_PAGE_URL,
  REGISTRATION_RETURN_INACTIVE_GENERIC_ERROR_PAGE
} from '../../../core/utils/Constants/Constants';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

const DocumentUploadingContainer = styled(Box)(({ theme }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = text.textWeak;

  return {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '30px',
    color: COLOUR_TEXT_TEXT_WEAK,
    padding: '0 40px',
    boxSizing: 'border-box',

    '& .MuiTypography-root': {
      color: COLOUR_TEXT_TEXT_WEAK,
      textAlign: 'center'
    }
  };
});

interface DocumentUploadingProps extends RegistrationReturnProps, RegistrationReturnInactiveProps {}

const DocumentUploading = ({
  isRegistrationReturn = false,
  isRegistrationReturnInactive = false
}: DocumentUploadingProps) => {
  const dispatch: any = useDispatch();

  const navigate = useNavigate();

  let dataFetchedRef = useRef(false);

  const [animateContent, setAnimateContent] = useAnimation(false, 500);
  const cssTransitionRef = useRef(null);

  const { uploadDocumentStatus, saveDocumentLoading, isPoaRequired, isPoiRequired } =
    useSelector(getRegistrationDetails);

  const [countProgress, setCountProgress] = useState<number>(0);

  const {
    eventAction: { load },
    kycFailedDocument: {
      load: {
        poiDocumentUploadLoading,
        poaDocumentUploadLoading,
        poaDocumentUploadLoadingReturnInactive,
        poaPoiDocumentUploadLoading,
        poaPoiDocumentUploadLoadingReturnInactive
      }
    }
  } = GoogleAnalyticsTagsMapping;

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    TriggerGoogleAnalyticsTag(
      load,
      isPoaRequired && isPoiRequired
        ? isRegistrationReturnInactive
          ? poaPoiDocumentUploadLoadingReturnInactive
          : poaPoiDocumentUploadLoading
        : isPoiRequired
        ? poiDocumentUploadLoading
        : isRegistrationReturnInactive
        ? poaDocumentUploadLoadingReturnInactive
        : poaDocumentUploadLoading
    );

    dispatch(
      updateCurrentStep({
        stepType: 'confirming-you'
      })
    );
  });

  useEffect(() => {
    let timer: string | number | NodeJS.Timer | undefined;
    if (countProgress === 0) {
      timer = setInterval(() => {
        setCountProgress((prevProgress: number) => (prevProgress >= 100 ? 100 : prevProgress + countProgress + 1));
      }, 1000);
    }
    return () => {
      if (countProgress === 100 || countProgress > 100) {
        clearInterval(timer);
        const navigateToURL: string = isRegistrationReturn
          ? REGISTRATION_PENDING_RETURN_PAGE_URL
          : isRegistrationReturnInactive
          ? REGISTRATION_PENDING_RETURN_INACTIVE_PAGE_URL
          : REGISTRATION_PENDING_PAGE_URL;
        navigate(navigateToURL);
      }
    };
  }, [countProgress, navigate]);

  useEffect(() => {
    const saveDocumentStatus = saveDocumentLoading === 'succeeded' && uploadDocumentStatus;
    const isKycFail = saveDocumentLoading === 'failed' || saveDocumentStatus;

    let navigateToURL: string = '';

    if (saveDocumentStatus) {
      navigateToURL = isRegistrationReturn
        ? DOCUMENT_UPLOADED_RETURN_PAGE_URL
        : isRegistrationReturnInactive
        ? DOCUMENT_UPLOADED_RETURN_INACTIVE_PAGE_URL
        : DOCUMENT_UPLOADED_PAGE_URL;
    } else if (isKycFail) {
      navigateToURL = isRegistrationReturn
        ? REGISTRATION_PENDING_RETURN_PAGE_URL
        : isRegistrationReturnInactive
        ? REGISTRATION_RETURN_INACTIVE_GENERIC_ERROR_PAGE
        : REGISTRATION_PENDING_PAGE_URL;
    }
    navigate(navigateToURL);
  }, [uploadDocumentStatus, saveDocumentLoading, navigate]);

  // Scrolls to the top of the content area
  useScrollToContentTop();

  const children: JSX.Element = (
    <CSSTransition
      nodeRef={cssTransitionRef}
      in={animateContent}
      timeout={500}
      classNames="slideUp"
      unmountOnExit
      onEnter={() => setAnimateContent}
      onExited={() => setAnimateContent}
    >
      <DocumentUploadingContainer>
        <div ref={cssTransitionRef}>
          <RPPercentageLoader value={countProgress} />
        </div>
      </DocumentUploadingContainer>
    </CSSTransition>
  );

  return isRegistrationReturnInactive ? (
    <FullLayout
      layoutType="old"
      hideAdditionalInteractions
      stickHeaderAtTop={false}
      shouldAddWrapperContainerStyling={true}
    >
      {children}
    </FullLayout>
  ) : (
    <SplitLayout layoutVariant={'partial'} hideAdditionalInteractions>
      {children}
    </SplitLayout>
  );
};

export default DocumentUploading;

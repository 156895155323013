import { FC, ReactNode, forwardRef, useContext, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import { TransitionProps } from '@mui/material/transitions';

import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface RPDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  body: ReactNode;
  size?: SizeProps['size'];
}

export type SizeProps = {
  size: 'normal' | 'small';
};

interface StyledIconButtonProps {
  iconSize: SizeProps['size'];
}

const StyledDialog = styled(Dialog)(() => {
  return {
    '.MuiDialog-paper': {
      maxWidth: '100%',
      borderRadius: '12px',
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
      overflow: 'hidden'
    }
  };
});

const StyledBackdrop = styled(Backdrop)(({ theme }) => {
  const {
    colours: {
      backgrounds: { backgroundBackdrop }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_BACKDROP: string = backgroundBackdrop;

  return {
    backgroundColor: COLOUR_BG_BACKGROUND_BACKDROP
  };
});

const ModalContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<SizeProps>(({ theme, size }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  const isSmallSize: boolean = size === 'small';
  return {
    maxWidth: isSmallSize ? '325px' : '620px',
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    padding: '20px',

    ':focus-visible': {
      outline: 0
    }
  };
});

const Header = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-end'
  };
});

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'iconSize'
})<StyledIconButtonProps>(({ theme, iconSize }) => {
  const {
    colours: {
      text: { textWeak, textInputFocus, textInputIcon }
    }
  } = theme;

  const isSmallSize: boolean = iconSize === 'small';

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const COLOUR_TEXT_TEXT_INPUT_FOCUS: HexColour = textInputFocus;
  const COLOUR_TEXT_TEXT_INPUT_ICON: HexColour = textInputIcon;

  return {
    padding: 0,

    ':hover': {
      backgroundColor: 'transparent'
    },

    '> svg': {
      width: isSmallSize ? '26px' : '36px',
      height: isSmallSize ? '26px' : '40px',

      '> path': {
        fill: COLOUR_TEXT_TEXT_WEAK
      }
    },

    '&.Mui-focusVisible': {
      outline: `${COLOUR_TEXT_TEXT_INPUT_FOCUS} solid 1px`,
      borderRadius: '4%',
      outlineOffset: 4,

      '> svg > path': {
        fill: COLOUR_TEXT_TEXT_INPUT_ICON
      }
    }
  };
});

const Body = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '-10px',
    padding: '0 5px 20px'
  };
});

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RPDialog: FC<RPDialogProps> = (props: RPDialogProps) => {
  const { isOpen, handleClose, body, size = 'normal' } = props;

  const { tenant } = useContext(TenantContext);

  const CloseIcon = useMemo(() => getIcon(tenant, 'navigationClose'), [tenant]);

  return (
    <StyledDialog
      open={isOpen}
      onClose={handleClose}
      scroll="body"
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        timeout: 500
      }}
      TransitionComponent={Transition}
      transitionDuration={500}
    >
      <ModalContainer data-testid="container" size={size}>
        <Header>
          <StyledIconButton
            onClick={handleClose}
            disableRipple
            iconSize={size}
            name="closeDialogButton"
            data-testid="rp-button-dialog-close"
          >
            <CloseIcon />
          </StyledIconButton>
        </Header>
        <Body>{body}</Body>
      </ModalContainer>
    </StyledDialog>
  );
};

export default RPDialog;

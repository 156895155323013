// Types
import { OrganizationCodeTypes, OrganizationDetailsEnvSpecific } from '../../types/OrganizationTypes';
// Utils
import { getConfigProperty } from '../../EnvironmentConfig/EnvironmentConfig';
// Constants
import { ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG } from '../Constants/Constants';

const defaultOrganizationCode: OrganizationCodeTypes = ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG[0].organizationCode;

export const getLandingPageURL = (tenant: OrganizationCodeTypes | undefined) => {
  return (getConfigProperty('landingPageURL') as OrganizationDetailsEnvSpecific)[tenant || defaultOrganizationCode];
};

export const getLoginPageURL = (tenant: OrganizationCodeTypes | undefined) => {
  return (getConfigProperty('loginPageURL') as OrganizationDetailsEnvSpecific)[tenant || defaultOrganizationCode];
};

export const getForgotPasswordURL = (tenant: OrganizationCodeTypes | undefined) => {
  return (getConfigProperty('forgotPasswordURL') as OrganizationDetailsEnvSpecific)[tenant || defaultOrganizationCode];
};

export const getCFXLoginURL = (): string => {
  return getConfigProperty('CFXLoginURL') as string;
};

export const getCFXForgotPasswordURL = (): string => {
  return getConfigProperty('CFXForgotPasswordURL') as string;
};

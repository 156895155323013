import { styled } from '@mui/material/styles';

import { SizeProps } from './RPModal';
import RPText from '../../atoms/RPText';

export interface ServiceModalProps {
  handleClose: () => void;
  handleBack?: () => void;
  selectedState: string;
  size?: SizeProps['size'];
  isFromLandingOrRegister?: boolean;
}

interface StyledBodyProps extends SizeProps {
  isFromLandingOrRegister?: boolean;
}

interface IconContainerProps extends SizeProps {}

interface ActionBtnContainerProps extends SizeProps {}

interface StyledHeadingProps {
  hasMarginBottom: boolean;
  size?: SizeProps['size'];
}

export const StyledBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'isFromLandingOrRegister'
})<StyledBodyProps>(({ size, isFromLandingOrRegister }) => {
  const isSmallSize: boolean = size === 'small';
  return {
    width: isSmallSize ? '265px' : '420px',
    padding: isSmallSize ? 0 : '0 30px 10px 30px',
    ...(isFromLandingOrRegister && {
      margin: '0px auto'
    })
  };
});

export const StyledHeading = styled(RPText, {
  shouldForwardProp: (prop) => prop !== 'hasMarginBottom'
})<StyledHeadingProps>(({ size, hasMarginBottom }) => {
  const isSmallSize: boolean = size === 'small';
  return {
    textAlign: 'center',
    ...(hasMarginBottom && {
      marginBottom: isSmallSize ? '10px' : '20px'
    })
  };
});

export const IconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<IconContainerProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';
  return {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '40px',
    ...(isSmallSize && {
      marginTop: '-10px',
      marginBottom: '20px'
    }),

    '> svg': {
      width: isSmallSize ? '130px' : '160px',
      height: isSmallSize ? '130px' : '160px'
    }
  };
});

export const ActionBtnContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<ActionBtnContainerProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';
  return {
    width: 'inherit',
    marginTop: isSmallSize ? '25px' : '60px',
    '>button': {
      width: '100%',
      marginTop: '0px !important'
    }
  };
});

import RPLinearProgressBar from '../../atoms/RPLinearProgressBar';

const RPLinearProgressBarVariants = () => {
  return (
    <>
      <RPLinearProgressBar value={20} />
      <RPLinearProgressBar value={40} />
    </>
  );
};

export default RPLinearProgressBarVariants;

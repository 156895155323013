// TODO - Replace appropriate type for window

export const InitiateAnalyticsCheck = (window: any) => {
  if (window.isAnalyticsLoaded === undefined) {
    window.isAnalyticsLoaded = Promise.race([
      new Promise((resolve) => {
        const isLoaded = () => {
          if (window.gaGlobal) {
            resolve('GA-LOADED');
          } else {
            setTimeout(isLoaded, 200);
          }
        };
        isLoaded();
      })
    ]);
  }
};

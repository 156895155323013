import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { LoadGoogleAnalyticsScript } from '../../../core/utils/GoogleAnalyticTag';

const IFrameWrapper = styled(Box)(() => {
  return {
    display: 'none',
    visibility: 'hidden'
  };
});

const RPGoogleBodyScript = () => {
  const googleAnalyticsScript = LoadGoogleAnalyticsScript('body');
  return (
    <IFrameWrapper data-testid="rp-ga-iframe">
      <iframe src={'data:text/html,' + encodeURIComponent(googleAnalyticsScript)} height="0" width="0" title="googleBodyScript" />
    </IFrameWrapper>
  );
};

export default RPGoogleBodyScript;

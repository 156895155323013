export type PoaPoiDocType = {
  commonName: string;
  displayName: string;
  key: string;
  numberOfSides: string; // getting this value as string from the API itself, so kept it a string but in other places converted into number
};

export type DocSidesType = {
  key: string;
  numberOfSides: number;
  structure: string[];
};

export enum DocType {
  poi = 'poi',
  poa = 'poa'
}

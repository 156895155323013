import { FC, memo, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

import { RPText } from '../RPText';
import { TypographyTypes } from '../../../core/types/TypographyTypes';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import RPLoader from '../RPLoader';
// Primary Button Colours - CD
// const COLOUR_BUTTON_PRIMARY_BG_START: string = '#F03EA6';
// const COLOUR_BUTTON_PRIMARY_BG_END: string = '#FF6B00';
// const COLOUR_BUTTON_PRIMARY_BORDER: string = `linear-gradient(96.05deg,
//                                                 ${COLOUR_BUTTON_PRIMARY_BG_START} 8.48%,
//                                                   ${COLOUR_BUTTON_PRIMARY_BG_END} 91.01%);`;
// const COLOUR_BUTTON_PRIMARY_ICON: string = '#FFFFFF';
// const COLOUR_BUTTON_PRIMARY_HOVER_TEXT: string = '#FFFFFF';
// const COLOUR_BUTTON_PRIMARY_HOVER_BORDER: string = `linear-gradient(94deg,
//                                                   ${COLOUR_BUTTON_PRIMARY_BG_START} 35.24%,
//                                                     ${COLOUR_BUTTON_PRIMARY_BG_END} 64.86%);`;
// const COLOUR_BUTTON_PRIMARY_HOVER_ICON: string = '#FFFFFF';

// // Secondary Button Colours - CD
// // SECONDARY
// const COLOUR_BUTTON_SECONDARY_TEXT: string = '#FFFFFF';
// const COLOUR_BUTTON_SECONDARY_ICON: string = '#FFFFFF';
// const COLOUR_BUTTON_SECONDARY_HOVER_BG: string = '#0530AD';
// const COLOUR_BUTTON_SECONDARY_HOVER_TEXT: string = '#FFFFFF';
// const COLOUR_BUTTON_SECONDARY_HOVER_ICON: string = '#FFFFFF';

// // Outlined Button Colours - CD
// const COLOUR_BUTTON_OUTLINED_HOVER_TEXT: string = '#161616';
// const COLOUR_BUTTON_OUTLINED_HOVER_ICON: string = '#0B5FFF';

// // Text Button Colours - CD
// const COLOUR_BUTTON_TEXT_HOVER_ICON: string = '#0B5FFF';

export type ButtonTypes = 'primary' | 'secondary' | 'outlined' | 'text';

interface StyledButtonProps extends ButtonProps {
  btnType?: ButtonTypes;
  disableHover?: boolean;
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'btnType' && prop !== 'startIconType' && prop !== 'endIconType' && prop !== 'disableHover'
})<StyledButtonProps>(({ theme, btnType, disableHover, size }) => {
  const isPrimaryBtn: boolean = btnType === 'primary';
  const isSecondaryBtn: boolean = btnType === 'secondary';
  const isOutlinedBtn: boolean = btnType === 'outlined';
  const isTextBtn: boolean = btnType === 'text';

  const isLargeSize: boolean = size === 'large';
  const isMediumSize: boolean = size === 'medium';

  const {
    colours: {
      buttons: { primary: primaryButton, secondary: secondaryButton, outline: outlinedButton, text: textButton }
    },
    dimensions: {
      buttons: {
        borderRadius: buttonBorderRadius
      }
    }
  } = theme;

  // Primary Button Colours
  const COLOUR_BUTTON_PRIMARY_BG: string = primaryButton.bg;
  const COLOUR_BUTTON_PRIMARY_HOVER_BG: string = primaryButton.hoverBg;
  const COLOUR_BUTTON_PRIMARY_DISABLED_BG: string = primaryButton.bg;
  const COLOUR_BUTTON_PRIMARY_TEXT: string = primaryButton.text;
  const COLOUR_BUTTON_PRIMARY_FOCUS_BORDER: string = primaryButton.focusBorder;
  const COLOUR_BUTTON_PRIMARY_FOCUS_BG: string = primaryButton.focusBg;
  const COLOUR_BUTTON_PRIMARY_FOCUS_TEXT: HexColour = primaryButton.focusText;

  // Secondary Button Colours
  const COLOUR_BUTTON_SECONDARY_BG: string = secondaryButton.bg;
  const COLOUR_BUTTON_SECONDARY_BORDER: string = secondaryButton.border;
  const COLOUR_BUTTON_SECONDARY_HOVER_BG: string = secondaryButton.hoverBg;
  const COLOUR_BUTTON_SECONDARY_HOVER_BORDER: string = secondaryButton.hoverBorder;
  const COLOUR_BUTTON_SECONDARY_FOCUS_BORDER: string = secondaryButton.focusBorder;
  const COLOUR_BUTTON_SECONDARY_FOCUS_BG: string = secondaryButton.focusBg;
  const COLOUR_BUTTON_SECONDARY_FOCUS_TEXT: HexColour = secondaryButton.focusText;

  // Outlined Button Colours
  const COLOUR_BUTTON_OUTLINED_BG: string = outlinedButton.bg;
  const COLOUR_BUTTON_OUTLINED_BORDER: string = outlinedButton.border;
  const COLOUR_BUTTON_OUTLINED_HOVER_BG: string = outlinedButton.hoverBg;
  const COLOUR_BUTTON_OUTLINED_HOVER_BORDER: string = outlinedButton.hoverBorder;
  const COLOUR_BUTTON_OUTLINED_TEXT: string = outlinedButton.text;
  const COLOUR_BUTTON_OUTLINED_ICON: string = outlinedButton.icon;
  const COLOUR_BUTTON_OUTLINED_FOCUS_BORDER: string = outlinedButton.focusBorder;
  const COLOUR_BUTTON_OUTLINED_FOCUS_BG: string = outlinedButton.focusBg;
  const COLOUR_BUTTON_OUTLINED_FOCUS_TEXT: HexColour = outlinedButton.focusText;

  // Text Button Colours
  const COLOUR_BUTTON_TEXT_HOVER_TEXT: string = textButton.hoverText;
  const COLOUR_BUTTON_TEXT_TEXT: string = textButton.text;
  const COLOUR_BUTTON_TEXT_FOCUS_BORDER: string = textButton.focusBorder;
  const COLOUR_BUTTON_TEXT_FOCUS_TEXT: HexColour = textButton.focusText;

  return {
    padding: isLargeSize ? '18px 32px' : isMediumSize ? '10.5px 32px' : '8px 24px',
    background: isPrimaryBtn
      ? COLOUR_BUTTON_PRIMARY_BG
      : isSecondaryBtn
      ? COLOUR_BUTTON_SECONDARY_BG
      : COLOUR_BUTTON_OUTLINED_BG,
    borderRadius: buttonBorderRadius,
    ...(isSecondaryBtn && {
      boxShadow: `${COLOUR_BUTTON_SECONDARY_BORDER}  0px 0px 0px 1px`,
      border: 'none'
    }),
    ...(isOutlinedBtn && {
      boxShadow: `${COLOUR_BUTTON_OUTLINED_BORDER}  0px 0px 0px 1px`,
      border: 'none'
    }),

    '&:hover': {
      boxShadow: 'none',
      ...(!disableHover
        ? {
            background: isPrimaryBtn
              ? COLOUR_BUTTON_PRIMARY_HOVER_BG
              : isSecondaryBtn
              ? COLOUR_BUTTON_SECONDARY_HOVER_BG
              : COLOUR_BUTTON_OUTLINED_HOVER_BG
          }
        : {
            background: isPrimaryBtn
              ? COLOUR_BUTTON_PRIMARY_BG
              : isSecondaryBtn
              ? COLOUR_BUTTON_SECONDARY_BG
              : COLOUR_BUTTON_OUTLINED_BG
          }),
      ...(isSecondaryBtn && {
        boxShadow: `${COLOUR_BUTTON_SECONDARY_HOVER_BORDER}  0px 0px 0px 1px`,
        border: 'none'
      }),
      ...(isOutlinedBtn && {
        boxShadow: `${COLOUR_BUTTON_OUTLINED_HOVER_BORDER}  0px 0px 0px 1px`,
        border: 'none'
      }),
      '.MuiTypography-root': {
        ...(isTextBtn && { color: COLOUR_BUTTON_TEXT_HOVER_TEXT })
      }
    },

    '&.Mui-focusVisible': {
      boxShadow: 'none',
      ...((isPrimaryBtn || isSecondaryBtn) && {
        outline: `${
          isPrimaryBtn ? COLOUR_BUTTON_PRIMARY_FOCUS_BORDER : COLOUR_BUTTON_SECONDARY_FOCUS_BORDER
        } solid 2px`,
        outlineOffset: 2
      }),

      ...((isOutlinedBtn || isTextBtn) && {
        outline: `1px solid ${isOutlinedBtn ? COLOUR_BUTTON_OUTLINED_FOCUS_BORDER : COLOUR_BUTTON_TEXT_FOCUS_BORDER}`
      }),

      '.MuiTypography-root': {
        color: isPrimaryBtn
          ? COLOUR_BUTTON_PRIMARY_FOCUS_TEXT
          : isSecondaryBtn
          ? COLOUR_BUTTON_SECONDARY_FOCUS_TEXT
          : isOutlinedBtn
          ? COLOUR_BUTTON_OUTLINED_FOCUS_TEXT
          : COLOUR_BUTTON_TEXT_FOCUS_TEXT
      },

      background: isPrimaryBtn
        ? COLOUR_BUTTON_PRIMARY_FOCUS_BG
        : isSecondaryBtn
        ? COLOUR_BUTTON_SECONDARY_FOCUS_BG
        : isOutlinedBtn
        ? COLOUR_BUTTON_OUTLINED_FOCUS_BG
        : 'inherit'
    },

    '&:disabled': {
      background: isPrimaryBtn
        ? COLOUR_BUTTON_PRIMARY_DISABLED_BG
        : isSecondaryBtn
        ? COLOUR_BUTTON_SECONDARY_BG
        : COLOUR_BUTTON_OUTLINED_BG,
      opacity: 0.5
    },

    '.MuiTypography-root': {
      color: isTextBtn
        ? COLOUR_BUTTON_TEXT_TEXT
        : !isOutlinedBtn
        ? COLOUR_BUTTON_PRIMARY_TEXT
        : COLOUR_BUTTON_OUTLINED_TEXT
    },

    '.MuiButton-endIcon': {
      marginLeft: 12,
      ...(isOutlinedBtn && { color: COLOUR_BUTTON_OUTLINED_ICON }),
      '& > span > div': {
        display: 'flex'
      }
    }
  };
});

interface IconContainerProps {
  btnType?: ButtonTypes;
}

const IconContainer = styled('span', {
  shouldForwardProp: (prop) => prop !== 'btnType'
})<IconContainerProps>(({ theme, btnType }) => {
  const {
    colours: {
      buttons: { primary: primaryButton, secondary: secondaryButton, outline: outlinedButton, text: textButton }
    }
  } = theme;

  const COLOUR_BUTTON_PRIMARY_ICON: string = primaryButton.icon;
  const COLOUR_BUTTON_SECONDARY_ICON: string = secondaryButton.icon;
  const COLOUR_BUTTON_OUTLINED_ICON: string = outlinedButton.icon;
  const COLOUR_BUTTON_TEXT_ICON: string = textButton.icon;

  return {
    ...(btnType === 'primary' && { color: COLOUR_BUTTON_PRIMARY_ICON }),
    ...(btnType === 'secondary' && { color: COLOUR_BUTTON_SECONDARY_ICON }),
    ...(btnType === 'outlined' && { color: COLOUR_BUTTON_OUTLINED_ICON }),
    ...(btnType === 'text' && { color: COLOUR_BUTTON_TEXT_ICON }),
    display: 'flex',

    '> svg > path': {
      fill: 'currentColor'
    }
  };
});

export interface RPButtonProps extends StyledButtonProps {
  children: string;
  startIconType?: string;
  endIconType?: string;
  disableHover?: boolean;
}

const RPButton: FC<RPButtonProps> = (props: RPButtonProps) => {
  const { size = 'large', btnType = 'primary', startIconType, endIconType, disableHover = false, ...rest } = props;

  const buttonTextVariant = size === 'large' ? 'body1' : 'body2';
  const buttonTextType: TypographyTypes = `button-${size}`;
  const { tenant } = useContext(TenantContext);

  const StartIcon = useMemo(() => getIcon(tenant, 'chat'), [tenant]);

  return (
    <StyledButton
      variant={btnType === 'outlined' ? 'outlined' : btnType === 'text' ? 'text' : 'contained'}
      size={size}
      btnType={btnType}
      startIcon={
        startIconType && (
          <IconContainer btnType={btnType}>
            <StartIcon data-testid={`rp-icon-${startIconType}`} />
          </IconContainer>
        )
      }
      endIcon={
        endIconType && (
          <IconContainer btnType={btnType}>
            <RPLoader type="inverse" />
          </IconContainer>
        )
      }
      data-testid="rp-button"
      disableHover={disableHover}
      disableRipple
      {...rest}
    >
      <RPText variant={buttonTextVariant} type={buttonTextType} text={props.children} />
    </StyledButton>
  );
};

export default memo(RPButton);

import { FC, memo } from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import getTypographyStyles from '../../../core/utils/GetTypographyStyles';
import { TypographyTypes } from '../../../core/types/TypographyTypes';

interface StyledTextProps extends TypographyProps {
  type: TypographyTypes;
}

const StyledText = styled(Typography)<StyledTextProps>(
  ({ theme, variant, type }) => {
    return getTypographyStyles(variant, type, theme);
  }
);

interface RPTextProps extends StyledTextProps {
  text: string;
}

const RPText: FC<RPTextProps> = (props: RPTextProps) => {
  return (
    <StyledText data-testid="rp-text" {...props}>
      {props.text}
    </StyledText>
  );
};

export default memo(RPText);

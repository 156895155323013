import { useEffect, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import RPPercentageLoader from '../../molecules/RPPercentageLoader';
// Utils
import { CreateCookie as createCookie } from '../../../core/utils/CreateCookie';
// Constants
import { CFSB_BETA_TESTING_OVERRIDE_COOKIE_NAME } from '../../../core/utils/Constants/Constants';

const StyledLoaderContainer = styled('div')(() => {
  return {
    display: 'flex',
    height: 'calc(100vh)',
    justifyContent: 'center',
    alignItems: 'center'
  };
});

const CFSBTestingPage = () => {
  const navigate = useNavigate();

  const [countProgress, setCountProgress] = useState<number>(0);

  useEffectOnce(() => {
    createCookie(CFSB_BETA_TESTING_OVERRIDE_COOKIE_NAME, 'true', { expires: 1 });
  });

  useEffect(() => {
    let timer: number | NodeJS.Timer = -1;

    if (countProgress === 0) {
      timer = setInterval(() => {
        setCountProgress((prevCount: number) => (prevCount > 100 ? 100 : prevCount + 5));
      }, 1000);
    }

    if (countProgress === 100 || countProgress > 100) {
      clearInterval(timer);
      navigate('/landing-page');
    }
    return () => {
      if (countProgress === 100 || countProgress > 100) {
        clearInterval(timer);
        navigate('/landing-page');
      }
    }
  }, [countProgress, navigate]);

  return (
    <StyledLoaderContainer>
      <RPPercentageLoader value={countProgress} />
    </StyledLoaderContainer>
  );
};

export default CFSBTestingPage;
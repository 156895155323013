import { ChangeEvent, FC, useContext, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { InputBase } from '@mui/material';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

interface FullScreenAutoCompleteInputProps {
  handleClose: () => void;
  handleCloseIconClick: () => void;
  handleInput: (value: string) => void;
  placeholder: string;
}

const StyledInput = styled(InputBase)(({ theme }) => {
  const {
    colours: {
      borders: { borderInput, borderInputFocus },
      backgrounds: { backgroundAlt },
      text: { textInputLabel }
    }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT: HexColour = borderInput;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  const COLOUR_TEXT_TEXT_INPUT_LABEL: HexColour = textInputLabel;
  const COLOUR_BORDER_BORDER_INPUT_FOCUS: HexColour = borderInputFocus;

  return {
    width: '100%',
    borderRadius: 'inherit',
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    borderBottom: `1px solid ${COLOUR_BORDER_BORDER_INPUT}`,
    padding: '0px 8px',
    '&.Mui-focused': {
      borderBottomColor: COLOUR_BORDER_BORDER_INPUT_FOCUS
    },
    '& input': {
      backgroundColor: COLOUR_BG_BACKGROUND_ALT,
      padding: '18px 0px 18px 8px',
      ...getBody2NormalStyles(theme),
      '&:: placeholder': {
        color: COLOUR_TEXT_TEXT_INPUT_LABEL
      }
    }
  };
});

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: {
      text: { textLink }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = textLink;

  return {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    width: '24px',
    '> svg': {
      cursor: 'pointer',
      height: '24px',
      width: '24px',
      ' > path': {
        fill: COLOUR_TEXT_TEXT_LINK
      }
    }
  };
});

const FullScreenAutoCompleteInput: FC<FullScreenAutoCompleteInputProps> = (props: FullScreenAutoCompleteInputProps) => {
  const { handleClose, handleCloseIconClick, handleInput, placeholder } = props;

  const [inputValue, setInputValue] = useState('');

  const { tenant } = useContext(TenantContext);
  const LeftArrowIcon = useMemo(() => getIcon(tenant, 'navigationChevronLeftIcon'), [tenant]);
  const CloseIcon = useMemo(() => getIcon(tenant, 'navigationClose'), [tenant]);

  const handleClick = () => {
    if (handleCloseIconClick) {
      setInputValue('');
      handleCloseIconClick();
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentInputValue: string = event.target.value;
    handleInput(currentInputValue);
    setInputValue(currentInputValue);
  };

  return (
    <StyledInput
      value={inputValue}
      data-testid="rp-full-screen-autocomplete-input"
      placeholder={placeholder}
      startAdornment={
        <IconContainer>
          <LeftArrowIcon onClick={handleClose} data-testid="rp-full-screen-autocomplete-input-back-icon" />
        </IconContainer>
      }
      endAdornment={
        <IconContainer>
          <CloseIcon onClick={handleClick} data-testid="rp-full-screen-autocomplete-input-close-icon" />
        </IconContainer>
      }
      onChange={handleOnChange}
    />
  );
};

export default FullScreenAutoCompleteInput;

import { useContext, useMemo, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { CSSTransition } from 'react-transition-group';
import { useEffectOnce } from 'usehooks-ts';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
import { FullLayout } from '../../organisms/FullLayout';
import { RPButton, RPButtonContainer } from '../../atoms/RPButton';
// Hooks
import { useAnimation, usePreventBackAction, useScrollToContentTop } from '../../../core/hooks';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../core/types/LayoutProps';
// RTK
import { removePersistedStoreData } from '../../../redux/store';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../core/utils/IconOrgData';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import { getLoginPageURL } from '../../../core/utils/GetOrganizationEnvSpecificURLs';
// Constants
import { REGISTRATION_DEFINITIONS } from '../../pages/Registration/RegistrationDefinitions';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

interface StyledKYCPassContainerWrapperProps extends LayoutProps {}
interface KYCPassContainerProps extends LayoutProps {}
interface StyledHeadingProps extends LayoutProps {}
interface StyledSubHeadingProps extends LayoutProps {}
interface StyledIllustrationProps extends LayoutProps {}

const StyledKYCPassContainerWrapper = styled(Box)<StyledKYCPassContainerWrapperProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    maxWidth: isMobileLayout ? '325px' : '500px',
    height: '100%',
    maxHeight: 600,
    margin: isMobileLayout ? '10% 0px 0px 0px' : '2% 0px 0px 0px'
  };
});

const KYCPassContainer = styled(Box)<KYCPassContainerProps>(({ layout, theme }) => {
  const {
    colours: {
      text: { textInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    color: COLOUR_TEXT_TEXT_INVERSE,
    padding: isMobileLayout ? '0 15px' : '0px',
    boxSizing: 'border-box',
    ...(isMobileLayout && {
      '> svg': {
        width: '130px',
        height: '130px'
      }
    }),

    '& .MuiTypography-root': {
      color: COLOUR_TEXT_TEXT_INVERSE,
      textAlign: 'center'
    },

    '& .MuiBox-root': {
      maxWidth: isMobileLayout ? '350px' : '420px',
      width: '100%',
      marginTop: isMobileLayout ? '40px' : '60px'
    },

    '& .MuiButton-root': {
      width: '100%'
    }
  };
});

const StyledHeading = styled(RPText)<StyledHeadingProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginTop: isMobileLayout ? '20px' : '25px'
  };
});

const StyledSubHeading = styled(RPText)<StyledSubHeadingProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginTop: isMobileLayout ? '10px' : '25px'
  };
});

const StyledIllustration = styled('span')<StyledIllustrationProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    '> svg': {
      width: isMobileLayout ? '320px' : '460px',
      height: isMobileLayout ? '85px' : '121px'
    },
    alignItems: 'center',
    display: 'flex'
  };
});

const KYCPass = () => {
  const { layout } = useContext(LayoutContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);

  const KycPassWelcomeIcon = useMemo(() => getIcon(tenant, 'uploadDocWelcome'), [tenant]);

  const headingText: string = translate('kycPass.heading');
  const subHeadingText: string = translate('kycPass.subHeading');
  const buttonText: string = translate('kycPass.loginButtonText');

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const isDesktopLayout: boolean = layout === 'desktop';

  const buttonSize: 'medium' | 'large' = isMobileLayout ? 'medium' : 'large';

  const loginPageURL: string = getLoginPageURL(tenant);

  const {
    eventAction: { load },
    stp: {
      load: { stpComplete }
    }
  } = GoogleAnalyticsTagsMapping;

  const headingVariant: 'h2' | 'h3' = isDesktopLayout ? 'h2' : 'h3';
  const subHeadingVariant: 'body1' | 'body2' = isMobileLayout ? 'body2' : 'body1';
  const subHeadingType: 'bold' | 'normal' = isMobileLayout ? 'normal' : 'bold';

  const [animateContent, setAnimateContent] = useAnimation(false, 500);
  const cssTransitionRef = useRef(null);

  useEffectOnce(() => {
    removePersistedStoreData();
    TriggerGoogleAnalyticsTag(load, stpComplete);
  });

  // Scrolls to the top of the content area
  useScrollToContentTop();

  // Prevent back action of browser
  usePreventBackAction();

  const {
    kycPass: {
      loginButton: { name: kycPassLoginButton, dataTestId: loginButtonDataTestId }
    }
  } = REGISTRATION_DEFINITIONS;

  const handleLoginClick = () => window.open(loginPageURL, '_self');

  return (
    <FullLayout enableMobileFooter>
      <CSSTransition
        nodeRef={cssTransitionRef}
        in={animateContent}
        timeout={500}
        classNames="slideUp"
        unmountOnExit
        onEnter={() => setAnimateContent}
        onExited={() => setAnimateContent}
      >
        <StyledKYCPassContainerWrapper layout={layout} ref={cssTransitionRef}>
          <KYCPassContainer layout={layout}>
            <StyledIllustration layout={layout}>
              <KycPassWelcomeIcon />
            </StyledIllustration>
            <StyledHeading layout={layout} variant={headingVariant} type="bold" text={headingText} />
            <StyledSubHeading layout={layout} variant={subHeadingVariant} type={subHeadingType} text={subHeadingText} />
            <RPButtonContainer layout={layout}>
              <RPButton
                size={buttonSize}
                type="submit"
                onClick={handleLoginClick}
                data-testid={loginButtonDataTestId}
                name={kycPassLoginButton}
              >
                {buttonText}
              </RPButton>
            </RPButtonContainer>
          </KYCPassContainer>
        </StyledKYCPassContainerWrapper>
      </CSSTransition>
    </FullLayout>
  );
};

export default KYCPass;

import { FC, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useEffectOnce } from 'usehooks-ts';

// Contexts
import { LanguageContext, LayoutContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../atoms/RPText';
import RPLinearProgressBar from '../../../atoms/RPLinearProgressBar';
import RPFactCards from '../../../molecules/RPFactCards';
import RPLoader from '../../../atoms/RPLoader';
// Hooks
import { useScrollToContentTop } from '../../../../core/hooks';
// Types
import { HexColour } from '../../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../../core/types/LayoutProps';
// RTK Slice
import {
  getRegistrationDetails,
  resetRegistrationAPILoadingState
} from '../../../../redux/modules/registrationDetailsSlice';
import { updateCurrentStep, updateStepStatus } from '../../../../redux/modules/registrationStepsSlice';
// API Wrappers
import addRegistrationLead from '../../../../redux/api/addRegistrationLead';
import getComplianceAndAccountStatus from '../../../../redux/api/getComplianceAndAccountStatus';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { CheckHTTPStatusCodes } from '../../../../core/utils/CheckHTTPStatusCodes';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';
// Constants
import {
  COMPLIANCE_VALID_CODE,
  STEPS_ROUTE_MAPPING,
  KYC_PASS_PAGE_URL,
  REGISTRATION_PENDING_PAGE_URL,
  ADD_REGISTRATION_API_FAIL_PAGE_URL,
  IDENTITY_PAGE_NAVIGATION_INTERVAL,
  LOCALE_LANG_CODE_MAPPING,
  LANG_CODE_MAPPING
} from '../../../../core/utils/Constants/Constants';
// GA4
import { TriggerGoogleAnalyticsTag } from '../../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

interface StyledHeadingProps extends LayoutProps {}

interface StyledDescriptionProps extends LayoutProps {}

const StyledHeading = styled(RPText)<StyledHeadingProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { text }
    }
  } = theme;

  const COLOUR_TEXT_TEXT: HexColour = text;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isMobileLayout ? '15px' : '20px',
    textAlign: isMobileLayout ? 'left' : 'center',
    color: COLOUR_TEXT_TEXT
  };
});

const StyledDescription = styled(RPText)<StyledDescriptionProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';

  let marginBottom: string = '30px';
  if (isDesktopLayout) {
    marginBottom = '70px';
  } else if (isTabletLayout) {
    marginBottom = '40px';
  }

  return {
    marginBottom: marginBottom,
    textAlign: 'center',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const StyledLoaderContainer = styled('div')(() => {
  return {
    paddingTop: '50px',
    display: 'flex',
    justifyContent: 'center'
  };
});

const Identity: FC = () => {
  const dispatch: any = useDispatch();

  const { layout } = useContext(LayoutContext);
  const { language, translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const navigate = useNavigate();

  let dataFetchedRef = useRef(false);
  let complianceStatusFetchedRef = useRef(false);

  const registrationDetails = useSelector(getRegistrationDetails);

  const {
    addRegistrationLeadInProgress,
    crmAccountID,
    tradeAccNo,
    organizationLegalEntity,
    email,
    complianceStatus,
    complianceCode,
    complianceStatusLoading,
    isComplianceStatusComplete,
    registrationLoading,
    isETVExist,
    statusCode
  } = registrationDetails;

  const nextStepType: string = 'confirming-you';
  const previousStepType: 'enter-details' | 'set-preferences' = isETVExist ? 'enter-details' : 'set-preferences';

  const navigateToKycPassPage = () => {
    navigate(KYC_PASS_PAGE_URL);
  };

  const navigateToKycDocumentUploadPage = () => {
    navigate(STEPS_ROUTE_MAPPING[nextStepType]);
  };

  const navigateToRegistrationFailPage = () => {
    navigate(REGISTRATION_PENDING_PAGE_URL);
  };

  const {
    eventAction: { load },
    verificationTimer: {
      load: { verificationTimerPageLoad, captureTitanIdAndLegalEntity }
    }
  } = GoogleAnalyticsTagsMapping;

  useEffectOnce(() => {
    // Navigate user to create login
    // addRegistrationLeadInProgress being false confirms user has
    // directly navigated to this page through the URL
    if (!addRegistrationLeadInProgress) {
      setTimeout(() => {
        navigate(STEPS_ROUTE_MAPPING['create-login']);
      }, IDENTITY_PAGE_NAVIGATION_INTERVAL);
    }
  });

  useEffectOnce(() => {
    // If addRegistrationLead API is in progress and user tries
    // to reload the page, navigate user to registration fail page
    if (registrationLoading === 'loading' && crmAccountID === '') {
      setTimeout(() => {
        navigateToRegistrationFailPage();
      }, IDENTITY_PAGE_NAVIGATION_INTERVAL);
    }
  });

  useEffect(() => {
    // If user reloads the page while compliance status API is in progress,
    // dispatch the action again to make API call for getting compliance status
    if (complianceStatusFetchedRef.current) return;
    if (complianceStatusLoading === 'loading' && crmAccountID !== '') {
      complianceStatusFetchedRef.current = true;
      const compliancePayload = {
        crmAccountID: crmAccountID,
        email: email
      };
      dispatch(getComplianceAndAccountStatus(compliancePayload));
    }
  }, []);

  useEffect(() => {
    // Dispatch and action to make API call for add registration lead.
    if (dataFetchedRef.current) return;
    if (addRegistrationLeadInProgress && complianceStatusLoading === 'idle') {
      dataFetchedRef.current = true;
      dispatch(
        addRegistrationLead({
          ...registrationDetails,
          locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
        })
      );
    }
  }, [addRegistrationLeadInProgress, complianceStatusLoading]);

  useEffect(() => {
    // Dispatch action to make API call for getting compliance status
    if (complianceStatusLoading === 'idle' && crmAccountID !== '' && tradeAccNo !== '') {
      const compliancePayload = {
        crmAccountID: crmAccountID,
        email: email
      };

      const dataLayerPayload: { [key: string]: string } = {
        Legal_entity_type: organizationLegalEntity,
        user_id: tradeAccNo
      };

      dispatch(getComplianceAndAccountStatus(compliancePayload));
      TriggerGoogleAnalyticsTag(load, captureTitanIdAndLegalEntity, {}, dataLayerPayload);
    }

    if (addRegistrationLeadInProgress && registrationLoading === 'failed') {
      if (statusCode && CheckHTTPStatusCodes.isServerErrorCode(statusCode)) {
        navigate(ADD_REGISTRATION_API_FAIL_PAGE_URL);
      } else {
        dispatch(resetRegistrationAPILoadingState());
        dispatch(
          updateCurrentStep({
            stepType: previousStepType
          })
        );
        navigate(STEPS_ROUTE_MAPPING[previousStepType]);
      }
    }
  }, [
    addRegistrationLeadInProgress,
    crmAccountID,
    tradeAccNo,
    registrationLoading,
    complianceStatusLoading,
    statusCode
  ]);

  useEffect(() => {
    // After compliance status check if completed
    const isValidStatusCode: boolean = COMPLIANCE_VALID_CODE.includes(complianceCode);
    const showDocumentUploadPage: boolean = complianceStatus === 'INACTIVE' && isValidStatusCode;
    const showRegistrationFailPage: boolean =
      (complianceStatusLoading === 'succeeded' && !isValidStatusCode) || complianceStatusLoading === 'failed';

    if (isComplianceStatusComplete) {
      dispatch(
        updateStepStatus({
          stepType: nextStepType,
          isLoading: false
        })
      );
      navigateToKycPassPage();
    } else if (showDocumentUploadPage) {
      dispatch(
        updateStepStatus({
          stepType: nextStepType,
          isLoading: false
        })
      );
      navigateToKycDocumentUploadPage();
    } else if (showRegistrationFailPage) {
      dispatch(
        updateStepStatus({
          stepType: nextStepType,
          isLoading: false
        })
      );
      navigateToRegistrationFailPage();
    }
  }, [isComplianceStatusComplete, complianceStatus, complianceCode, complianceStatusLoading]);

  useEffect(() => {
    // GA Tags
    if (registrationLoading === 'succeeded') {
      TriggerGoogleAnalyticsTag(load, verificationTimerPageLoad);
    }
  }, [registrationLoading]);

  // Scrolls to the top of the content area
  useScrollToContentTop();

  const heading: string = translate('registration.identity.heading');
  const description: string = translate('registration.identity.description');

  const isDesktopLayout: boolean = layout === 'desktop';
  const isMobileSmallLayout: boolean = layout === 'mobileSM';

  return (
    <div>
      <StyledHeading variant={isDesktopLayout ? 'h2' : 'h3'} type="bold" text={heading} layout={layout} />
      {isMobileLayoutApplicable(layout) && <RPLinearProgressBar value={80} />}
      <StyledDescription
        variant={isDesktopLayout ? 'body1' : 'body2'}
        type="normal"
        text={description}
        layout={layout}
      />
      {isMobileSmallLayout ? (
        <StyledLoaderContainer>
          <RPLoader type="secondary" size="xx-huge" />
        </StyledLoaderContainer>
      ) : (
        <RPFactCards />
      )}
    </div>
  );
};
export default Identity;

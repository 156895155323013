import cookie from 'cookie';
import _, { isEmpty } from 'lodash';

// Types
import { CookieDataObjectType, commonCookieDataType } from '../../types/CookieDataObjectTypes';
// Utils
import { CheckIfAllKeysExistsInObject } from '../CheckIfAllKeysExistsInObject';
// Constants
import { COOKIE_NAME_TO_DATA_KEY_MAPPING } from '../Constants/Constants';

type SourceAPIType = 'addEnquiryLead' | 'addRegistrationLead';

const GetCookieDataObject = (sourceAPI: SourceAPIType = 'addRegistrationLead') => {
  const { cookiesData, ppcData, referralCookieData } = COOKIE_NAME_TO_DATA_KEY_MAPPING.reduce(
    (commonCookieData: commonCookieDataType, cookieDataObj: CookieDataObjectType) => {
      const { cookieName, cookieDataKey, applicableAPIs, type } = cookieDataObj;
      const isCookieApplicableForAPI: boolean = applicableAPIs.includes(sourceAPI);

      let cookieValue: string = cookie.parse(document.cookie)[cookieName];

      if (cookieValue && isCookieApplicableForAPI) {
        // fetching the payload key name for cookiesData from COOKIE_NAME_TO_DATA_KEY_MAPPING[cookieName]
        commonCookieData[type as keyof commonCookieDataType][cookieDataKey] = cookieValue;
      }
      return commonCookieData;
    },
    {
      cookiesData: {},
      ppcData: {},
      referralCookieData: {}
    }
  );

  const isCookiesDataEmpty = isEmpty(cookiesData);
  const isPPCDataEmpty = isEmpty(ppcData);
  const isReferralCookieDataEmpty = isEmpty(referralCookieData);
  const isReferralCookieDataApplicable = !isReferralCookieDataEmpty && CheckRequiredReferralData();

  return {
    cookiesData,
    ppcData,
    referralCookieData,
    isCookiesDataEmpty,
    isPPCDataEmpty,
    isReferralCookieDataApplicable
  };
};

export const CheckRequiredReferralData = () => {
  const referralCookieData = GetReferralCookieDataObject();

  const referralCookieNamesArray: string[] = GetCookieDataKeysForType('referralCookieData');

  return CheckIfAllKeysExistsInObject(referralCookieData, referralCookieNamesArray);
};

export const GetReferralCookieDataObject = () => {
  return COOKIE_NAME_TO_DATA_KEY_MAPPING.reduce(
    (referralCookieData: { [keyName: string]: string }, cookieDataObj: CookieDataObjectType) => {
      const { cookieName, cookieDataKey, type } = cookieDataObj;
      const isReferralCookieDataType: boolean = type.includes('referralCookieData');

      const cookieValue: string = cookie.parse(document.cookie)[cookieName];

      if (cookieValue && isReferralCookieDataType) {
        referralCookieData[cookieDataKey] = cookieValue;
      }
      return referralCookieData;
    },
    {}
  );
};

export const GetCookieDataKeysForType = (cookieType: string) => {
  return COOKIE_NAME_TO_DATA_KEY_MAPPING.reduce(
    (data: string[], { type, cookieDataKey }: CookieDataObjectType) =>
      type === cookieType ? [...data, cookieDataKey] : data,
    []
  );
};

export default GetCookieDataObject;

import { createAsyncThunk } from '@reduxjs/toolkit';

// Types
import { ReduxState } from '../../core/types/ReduxStoreTypes';
import { BaseRegistrationStep } from '../../core/types/StepProgressTrackerTypes';
// RTK Slice
import axiosWrapper from './axiosWrapper';
import { addPreferencesStep, removePreferencesStep } from '../modules/registrationStepsSlice';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
// Utils
import GetBasicDetails from '../../core/utils/GetBasicDetails';
import { ORGANIZATION_CODE_MAPPING } from '../../core/utils/Constants/Constants';

const { orgCode } = GetBasicDetails();

export type etvPayloadData = {
  email: string;
  countryCode: string;
  mobileNumber: string;
  corIsUSA: boolean;
  brand: string;
};

const verifyEtvExist = createAsyncThunk(
  'registration-service/verifyEtvExist',
  async (data: etvPayloadData, thunkAPI) => {
    const { parentOrganizationCode } = GetBasicDetails();

    const payload = {
      orgCode: orgCode,
      parentOrgCode: parentOrganizationCode,
      email: data.email,
      mobilePhone: `${data.countryCode}-${data.mobileNumber}`
    };
    try {
      const response = await axiosWrapper('verifyEtvExist', 'POST', thunkAPI, payload);

      const responseData = {
        ...response.data,
        data: {
          ...response?.data?.data,
          corIsUSA: data.corIsUSA,
          brand: data.brand
        }
      };
      const isETVExist: boolean = response?.data?.data?.isETVExist;
      const reduxState: ReduxState = thunkAPI.getState() as ReduxState;
      const steps: BaseRegistrationStep[] = reduxState.registrationSteps.steps;

      // Check if ETV exists from the APIs response
      // OR country of residence is USA, then remove set-preferences
      // else add back if it doesn't exist.

      const setPreferencesStepIndex: number = steps.findIndex(
        (registrationStep) => registrationStep.type === 'set-preferences'
      );

      if (isETVExist || data.corIsUSA || data.brand === ORGANIZATION_CODE_MAPPING.propertypay) {
        setPreferencesStepIndex !== -1 && thunkAPI.dispatch(removePreferencesStep());
      } else {
        setPreferencesStepIndex === -1 && thunkAPI.dispatch(addPreferencesStep());
      }

      return responseData;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  }
);

export default verifyEtvExist;

import { FC, ChangeEvent, useContext, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { LanguageContext } from '../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import RPText from '../../atoms/RPText';
import { RPButton } from '../../atoms/RPButton';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { CustomFile } from '../../../core/types/RegistrationStepForm';
import RPValidationErrorMessage from '../../atoms/RPValidationErrorMessage/RPValidationErrorMessage';

const Container = styled(Box)(({ theme }) => {
  const {
    colours: {
      borders: { borderInput, borderInputActive, borderInputFocus },
      backgrounds: { backgroundAlt, backgroundAltInverseWeak }
    }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT: HexColour = borderInput;
  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borderInputActive;
  const COLOUR_BORDER_BORDER_INPUT_FOCUS: HexColour = borderInputFocus;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  const COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK: HexColour = backgroundAltInverseWeak;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    cursor: 'pointer',
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    border: `1px solid ${COLOUR_BORDER_BORDER_INPUT}`,
    borderRadius: '8px',
    padding: '20px 16px 18px 16px',

    '&:hover, &:active': {
      border: `1px solid ${COLOUR_BORDER_BORDER_INPUT_ACTIVE}`,
      backgroundColor: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK
    },

    ':focus-within': {
      border: `1px solid ${COLOUR_BORDER_BORDER_INPUT_FOCUS}`,
      backgroundColor: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK
    }
  };
});

const FileInput = styled('input')(() => {
  return {
    display: 'none'
  };
});

const BrowseFileButton = styled(RPButton)(() => {
  return {
    width: '150px'
  };
});

const UploadFileCopySection = styled(Box)(() => {
  return {
    textAlign: 'center'
  };
});

interface RPUploadFileSectionProps {
  handleFileUpload: Function;
  value: CustomFile | null;
  availableFileTypesText?: string;
  maxFileSizeText?: string;
  error?: string;
}

const RPUploadFileSection: FC<RPUploadFileSectionProps> = (props: RPUploadFileSectionProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const browseFileBtnText: string = translate('registration.documentUpload.browseFileBtnText');
  const fileUploadLine1Text: string = translate('registration.documentUpload.fileUploadLine1');
  const fileUploadLine2Text: string = translate('registration.documentUpload.fileUploadLine2');

  const { handleFileUpload, value, availableFileTypesText, maxFileSizeText, error } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // if (event && event.target && event.target.files) {
    // const fileUploaded = event.target.files[0];
    // const fileName = fileUploaded.name;
    // const roundedFileSize: number =
    //   Math.round((fileUploaded.size / 1024) * 10) / 10;
    // const fileSize: string = roundedFileSize + 'kb';
    // }
    event && event.target && event.target.files && handleFileUpload(event.target.files);
  };

  const uploadFile = () => {
    hiddenFileInput && hiddenFileInput.current && hiddenFileInput?.current.click();
  };

  return (
    <Container onClick={uploadFile} data-testid="rp-file-upload-section">
      <FileInput
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        data-testid="rp-file-upload-input"
        value={value ? value.name : ''}
      />
      <BrowseFileButton
        size="small"
        btnType="secondary"
        disableHover={true}
        name="browseFileButton"
        data-testid="rp-button-browse-file"
      >
        {browseFileBtnText}
      </BrowseFileButton>
      <UploadFileCopySection>
        <RPText
          variant="subtitle1"
          type="normal"
          text={availableFileTypesText ? availableFileTypesText : fileUploadLine1Text}
        />
        <RPText variant="subtitle1" type="normal" text={maxFileSizeText ? maxFileSizeText : fileUploadLine2Text} />
        {!!error && <RPValidationErrorMessage message={error} marginTop={5} />}
      </UploadFileCopySection>
    </Container>
  );
};

export default RPUploadFileSection;

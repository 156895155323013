import { createAction, createSlice } from '@reduxjs/toolkit';

import { ReduxState } from '../../core/types/ReduxStoreTypes';
import { initialState } from '../initialState';
import { generateStepObject } from '../../core/utils/GenerateDefaultRegistrationSteps';
import { RESET_REGISTRATION_STEPS_DETAILS } from '../ActionTypeConstants';

export const resetRegistrationStepsDetails = createAction<string>(RESET_REGISTRATION_STEPS_DETAILS);

const registrationStepsSlice = createSlice({
  name: 'registrationSteps',
  initialState: initialState.registrationSteps,
  reducers: {
    updateCurrentStep(state, action) {
      const { stepType, isLoading } = action.payload;

      let currentStepIndex: number = -1;

      state.steps.forEach((step, index) => {
        if (step.type === stepType) {
          currentStepIndex = index;
          step.status = 'active';
          step.showEditIcon = false;
          step.isLoading = isLoading ? isLoading : false;
        } else {
          step.status = currentStepIndex !== -1 && index > currentStepIndex ? 'incomplete' : 'complete';
          step.showEditIcon = !(currentStepIndex !== -1 && index > currentStepIndex) || stepType === 'confirming-you';
          step.isLoading = stepType === 'confirming-you';
        }
      });
    },
    updateStepStatus(state, action) {
      const { stepType, stepStatus, showEditIcon, isLoading } = action.payload;

      state.steps.forEach((step) => {
        if (step.type === stepType) {
          step.status = stepStatus;
          step.showEditIcon = showEditIcon;
          step.isLoading = isLoading !== undefined ? isLoading : step.isLoading;
        }
      });
    },
    addPreferencesStep(state) {
      state.steps = [
        ...state.steps.slice(0, 3),
        generateStepObject('set-preferences'),
        /*{
          type: 'set-preferences',
          status: 'incomplete',
          showEditIcon: false,
          isLoading: false,
        },*/
        ...state.steps.slice(3)
      ];
    },
    removePreferencesStep(state) {
      state.steps = state.steps.filter((step) => {
        return step.type !== 'set-preferences';
      });
    },
    disableEditIconForSteps(state) {
      state.steps.forEach((step) => {
        step.showEditIcon = false;
      });
    },
    resetSteps(state) {
      state.steps = initialState.registrationSteps.steps;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetRegistrationStepsDetails, () => initialState.registrationSteps);
  }
});

export const getRegistrationSteps = (state: ReduxState) => state.registrationSteps;

export const { updateStepStatus, updateCurrentStep, addPreferencesStep, removePreferencesStep, disableEditIconForSteps, resetSteps } =
  registrationStepsSlice.actions;

export default registrationStepsSlice.reducer;

import {
  FC,
  useState,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
  ClipboardEvent,
} from 'react';
import { styled } from '@mui/material/styles';

import RPPinInput, { SizeTypes } from '../../atoms/RPPinInput/RPPinInput';

interface RPPinFieldProps {
  length: number;
  onChange: (value: string[]) => void;
  value: string[];
  size?: SizeTypes['customSize'];
}

const PinFieldContainer = styled('div')(() => {
  return {
    display: 'flex',
    gap: '15px',
    justifyContent: 'space-between',
  };
});

const RPPinField: FC<RPPinFieldProps> = (props: RPPinFieldProps) => {
  const { length, onChange, value, size } = props;

  const otpValues: string[] = value;

  const [activeInput, setActiveInput] = useState(0);

  // Helper to return OTP from inputs
  const handleOtpChange = useCallback(
    (otp: string[]) => {
      onChange(otp);
    },
    [onChange]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || '';
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.currentTarget.value;
      if (!!val) {
        changeCodeAtFocus(val);
        focusNextInput();
      }
    },
    [changeCodeAtFocus, focusNextInput]
  );

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const pressedKey = e.key;

      switch (pressedKey) {
        case 'Backspace':
        case 'Delete': {
          // Move to previous input field on press of backspace or delete
          e.preventDefault();
          changeCodeAtFocus('');
          focusPrevInput();
          break;
        }
        case 'ArrowLeft': {
          // Move to previous input box
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          // Move to next input box
          e.preventDefault();
          focusNextInput();
          break;
        }
        case 'ArrowDown':
        case 'ArrowUp': {
          // Prevent change of input value of type number on up and down arrow hit
          e.preventDefault();
          break;
        }
        default: {
          // allow only numbers and "v" or "V" character for user to paste copied text
          if (pressedKey.match(/^[^vV0-9]$/)) {
            e.preventDefault();
          }
          break;
        }
      }
    },
    [changeCodeAtFocus, focusNextInput, focusPrevInput]
  );

  // Handle onBlur input
  const handleOnBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onPaste input
  const handleOnPaste = useCallback(
    (e: ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData('text/plain') // get plain text data
        .trim() // trim leading and trailing spaces
        .replace(/\D/g, '') // remove everything other than numbers
        .slice(0, length - activeInput) // slice string to the max length available
        .split('');

      if (pastedData && pastedData.length) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = pastedData.shift() || val;
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setActiveInput(Math.min(nextFocusIndex + 1, length - 1));
        handleOtpChange(updatedOTPValues);
      }
    },
    [activeInput, length, otpValues, handleOtpChange]
  );

  return (
    <PinFieldContainer>
      {Array(length)
        .fill('')
        .map((_, index: number) => (
          <RPPinInput
            key={`pin-input-${index}`}
            focus={activeInput === index}
            name={`pin_${index}`}
            value={otpValues[index]}
            size={size}
            onFocus={handleOnFocus(index)}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onBlur={handleOnBlur}
            onPaste={handleOnPaste}
          />
        ))}
    </PinFieldContainer>
  );
};

export default RPPinField;

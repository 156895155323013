import React, { Component } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

import { ReactComponent as ChevronDown } from './assets/icon-chevron-down.svg';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    color: theme.palette.primary.dark,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    lineHeight: '23px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

type ReferenceData = {
  label: string;
  value: any;
};

interface RPSelectDropdownProps {
  data?: any;
  label?: string;
  onBlur?: any;
  onChange?: any;
  onFocus?: any;
  name?: string;
  value?: any;
  className?: string;
  disabled?: boolean;
}

// TODO - Sample Select component - NOT TO BE USED FOR VIEWS
class RPSelectDropdown extends Component<RPSelectDropdownProps, {}> {
  state = {
    inputValue: undefined,
  };

  static getDerivedStateFromProps(props: RPSelectDropdownProps, state: any) {
    if (state.inputValue !== undefined && state.inputValue !== '') {
      return state;
    }

    return {
      ...state,
      inputValue: props.value,
    };
  }

  constructor(props: RPSelectDropdownProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event: any, selection: any) {
    const { name, onChange } = this.props;

    this.setState(
      {
        inputValue: undefined,
      },
      () => {
        if (onChange) {
          onChange({
            target: {
              name,
              value: selection !== null ? selection.value : '',
            },
          });
        }
      }
    );
  }

  render() {
    const { className, data, label, onChange, name, value: inputValue, disabled } = this.props;

    return (
      <FormControl
        variant="outlined"
        sx={{
          '&.MuiFormControl-root': { mt: 10, minWidth: 200 },
        }}
      >
        <InputLabel htmlFor="outlined-select">{label}</InputLabel>
        <Select
          className={className}
          native
          disabled={disabled}
          IconComponent={ChevronDown}
          value={inputValue}
          onChange={onChange}
          label={label}
          inputProps={{
            name: `${name}`,
            id: 'outlined-select',
            'data-testid': 'rp-select-dropdown',
          }}
          autoWidth
          input={<BootstrapInput />}
        >
          {data.map((option: ReferenceData) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}

export default RPSelectDropdown;

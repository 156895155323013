import { useState, useCallback } from 'react';
import { useTimeout } from 'usehooks-ts';
  
const useAnimation = (initialState: boolean = false, delay: number = 200): [boolean, Function] => {

	const [state, setState] = useState<boolean>(initialState);

	const toggle = useCallback(() => setState(currentState => !currentState), []);

	const showState = () => setState(true);

	useTimeout(showState, delay);

	return [state, toggle];
};

export default useAnimation;
import { FC, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPModal, { SizeProps } from './RPModal';
import RPText from '../../atoms/RPText';
import RPButton from '../../atoms/RPButton';
import RPTimerLoader from '../RPTimerLoader';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';

interface ModalVariantProps {
  modalVariant: 'sessionExpired' | 'sessionTimer';
}

interface RPSessionAlertModalProps {
  isOpen: boolean;
  heading: string;
  handleClose: () => void;
  handlePrimaryBtnClick: () => void;
  subHeadingLine1: string;
  primaryBtnLabel: string;
  subHeadingLine2?: string;
  countDownText?: string;
  timerValue?: string;
  percentage?: number;
  size?: SizeProps['size'];
  modalVariant?: ModalVariantProps['modalVariant'];
}

interface StyledBodyProps extends SizeProps {}
interface IconContainerProps extends SizeProps {}
interface TimerLoaderWrapperProps extends SizeProps {}

const StyledBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<StyledBodyProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';
  const maxWidth: string = isSmallSize ? '265px' : '490px';

  return {
    width: maxWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  };
});

const StyledHeading = styled(RPText)(() => {
  return {
    marginBottom: '10px'
  };
});

const StyledSubHeading = styled(RPText)(() => {
  return {
    textAlign: 'center'
  };
});

const SubHeadingContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<StyledBodyProps>(({ size, theme }) => {
  const isSmallSize: boolean = size === 'small';
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: isSmallSize ? '25px' : '28px',

    '.MuiTypography-root': {
      color: COLOUR_TEXT_TEXT_WEAK
    }
  };
});

const IconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<IconContainerProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';
  return {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: isSmallSize ? '25px' : '78px',
    '> svg': {
      width: '124px',
      height: '133px'
    }
  };
});

const TimerLoaderWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<TimerLoaderWrapperProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    marginBottom: isSmallSize ? '25px' : '63px',
    display: 'inline-flex'
  };
});

const StyledPrimaryBtn = styled(RPButton)(({ size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    width: isSmallSize ? '265px' : '330px'
  };
});

const RPSessionModal: FC<RPSessionAlertModalProps> = (props: RPSessionAlertModalProps) => {
  const {
    isOpen,
    handleClose,
    heading,
    subHeadingLine1,
    subHeadingLine2,
    primaryBtnLabel,
    countDownText,
    handlePrimaryBtnClick,
    size = 'normal',
    modalVariant,
    timerValue,
    percentage = 0
  } = props;

  const { tenant } = useContext(TenantContext);

  const SessionLogoutIcon = useMemo(() => getIcon(tenant, 'sessionLogout'), [tenant]);

  const isSmallSize: boolean = size === 'small';
  const isSessionExpired: boolean = modalVariant === 'sessionExpired';
  const subHeadingType: 'normal' | 'medium' = isSmallSize ? 'normal' : 'medium';

  const subHeading: JSX.Element = (
    <SubHeadingContainer size={size}>
      {subHeadingLine1 && <StyledSubHeading variant="body2" type={subHeadingType} text={subHeadingLine1} />}
      {subHeadingLine2 && <StyledSubHeading variant="body2" type={subHeadingType} text={subHeadingLine2} />}
    </SubHeadingContainer>
  );

  const body: JSX.Element = (
    <StyledBody size={size} data-testid="modal-body">
      {heading && <StyledHeading variant={isSmallSize ? 'body1' : 'h3'} type="bold" text={heading} />}
      {subHeading}
      {isSessionExpired ? (
        <IconContainer size={size}>
          <SessionLogoutIcon />
        </IconContainer>
      ) : (
        <TimerLoaderWrapper size={size}>
          <RPTimerLoader value={percentage} timerValue={timerValue} countDownText={countDownText} />
        </TimerLoaderWrapper>
      )}
      <StyledPrimaryBtn
        onClick={handlePrimaryBtnClick}
        size={isSmallSize ? 'small' : 'large'}
        name="primarySessionModalButton"
        data-testid="rp-button-modal-session-primary"
      >
        {primaryBtnLabel}
      </StyledPrimaryBtn>
    </StyledBody>
  );

  return <RPModal isOpen={isOpen} handleClose={handleClose} body={body} size={size} isSessionModal={true} />;
};

export default RPSessionModal;

export type liveChatConfigType = {
  eswURL: string;
  serviceURL: string;
  embdServiceDeplySbxURL: string;
  chatWithAgentURL: string;
  embdServiceDeplyId: string;
  embdServiceDeplyName: string;
  baseLiveAgentContentURL: string;
  deploymentId: string;
  buttonId: string;
  baseLiveAgentURL: string;
  eswLiveAgentDevName: string;
};

export enum LiveChatConfig {
  withOffset = 'withOffset',
  withoutOffset = 'withoutOffset',
  disableLiveChat = 'disableLiveChat'
}

export type LiveChatRouteOffsetType =
  | LiveChatConfig.withOffset
  | LiveChatConfig.withoutOffset
  | LiveChatConfig.disableLiveChat;

export type RouteLiveChatConfigType = {
  route: string;
  config: LiveChatRouteOffsetType;
};
  

import { FC, memo, useContext } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Contexts
import { LanguageContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPText } from '../../atoms/RPText';
import RPLink from '../../atoms/RPLink';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { HeaderType } from '../../molecules/FullLayoutHeader/FullLayoutHeader';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
// Constants
import { FOOTER_LINKS_CONFIG_ARRAY } from '../../../core/utils/Constants/Constants';

type SizeProps = {
  size: 'small' | 'normal';
};

interface RPFooterProps {
  size?: SizeProps['size'];
  isFromLandingOrRegister?: boolean;
  layoutType?: HeaderType;
}

export interface FooterLinkConfigType {
  textKey: string;
  hrefKey: string;
}

interface ContainerProps extends BoxProps, SizeProps {
  isFromLandingOrRegister: boolean;
  isLayoutTypeOld: boolean;
}

interface StyledRPLinkProps extends SizeProps {
  isFromLandingOrRegister: boolean;
  isLayoutTypeOld: boolean;
}

interface StyledRPTextProps extends SizeProps {}

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'isFromLandingOrRegister' && prop !== 'isLayoutTypeOld'
})<ContainerProps>(({ size, isFromLandingOrRegister, isLayoutTypeOld }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    ...(isSmallSize && {
      width: 'auto',
      flexDirection: 'column',
      gap: isLayoutTypeOld ? 24 : 10
    }),
    ...(!isSmallSize && {
      maxWidth: isFromLandingOrRegister ? '100%' : '500px',
      padding: isFromLandingOrRegister ? 0 : 20,
      gap: 20
    }),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: isFromLandingOrRegister ? 'flex-start' : 'center',
    alignItems: 'center'
  };
});

const StyledRPLink = styled(RPLink, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'isFromLandingOrRegister' && prop !== 'isLayoutTypeOld'
})<StyledRPLinkProps>(({ size, theme, isFromLandingOrRegister, isLayoutTypeOld }) => {
  const {
    colours: {
      text: { textWeakest, textWeak, textLandingPageWeakest }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_LANDING_PAGE_WEAKEST: HexColour = textLandingPageWeakest;
  const COLOUR_TEXT_TEXT_WEAKEST: HexColour = textWeakest;
  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  const isSmallSize: boolean = size === 'small';

  const colorTextTextWeakest: HexColour = isFromLandingOrRegister
    ? COLOUR_TEXT_TEXT_LANDING_PAGE_WEAKEST
    : COLOUR_TEXT_TEXT_WEAKEST;

  const textColor: HexColour = isSmallSize && !isLayoutTypeOld ? COLOUR_TEXT_TEXT_WEAK : colorTextTextWeakest;
  return {
    color: textColor,
    ':hover': {
      color: textColor
    },
    '&.Mui-focusVisible': {
      color: textColor
    }
  };
});

const StyledRPText = styled(RPText, {
  shouldForwardProp: (prop) => prop !== 'size'
})<StyledRPTextProps>(({ theme, size }) => {
  const {
    colours: {
      text: { textWeakest, textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAKEST: HexColour = textWeakest;
  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  const isSmallSize: boolean = size === 'small';
  return {
    color: isSmallSize ? COLOUR_TEXT_TEXT_WEAK : COLOUR_TEXT_TEXT_WEAKEST
  };
});

const RPFooter: FC<RPFooterProps> = (props: RPFooterProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { size = 'normal', isFromLandingOrRegister = false, layoutType = 'normal' } = props;
  const isSmallSize: boolean = size === 'small';

  const isLayoutTypeOld: boolean = layoutType === 'old';

  const footerLinkTextVariant: 'body2' | 'subtitle2' = isLayoutTypeOld || !isSmallSize ? 'subtitle2' : 'body2';
  const footerLinkTextTypeVariant: 'medium' | 'normal' = isLayoutTypeOld || !isSmallSize ? 'normal' : 'medium';

  return (
    <Container
      size={size}
      data-testid="footer-container"
      isFromLandingOrRegister={isFromLandingOrRegister}
      isLayoutTypeOld={isLayoutTypeOld}
    >
      {/* Footer links */}
      {FOOTER_LINKS_CONFIG_ARRAY.map((linkDetails, index) => (
        <StyledRPLink
          size={size}
          key={`${linkDetails.textKey}-${index}`}
          variant={footerLinkTextVariant}
          type={footerLinkTextTypeVariant}
          text={translate(linkDetails.textKey)}
          href={translate(linkDetails.hrefKey)}
          target="_blank"
          data-testid="rp-link-footer"
          isFromLandingOrRegister={isFromLandingOrRegister}
          isLayoutTypeOld={isLayoutTypeOld}
        />
      ))}

      {/* App version */}
      {!isSmallSize && !isFromLandingOrRegister && !isLayoutTypeOld && (
        <StyledRPText
          size={size}
          key={`app-version-${FOOTER_LINKS_CONFIG_ARRAY.length}`}
          variant="subtitle2"
          type="normal"
          text={`v${process.env?.REACT_APP_VERSION}`}
        />
      )}
    </Container>
  );
};

export default memo(RPFooter);

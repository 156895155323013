import { FC } from 'react';
import { styled } from '@mui/material/styles';

import RPModal, { SizeProps } from './RPModal';
import RPText from '../../atoms/RPText';
import RPButton from '../../atoms/RPButton';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { RPButtonProps } from '../../atoms/RPButton/RPButton';

export interface ModalVariantProps {
  modalVariant: 'ZAR' | 'duplicateAccount' | 'default';
}

interface StyledPrimaryBtnProps extends RPButtonProps {
  modalVariant?: 'ZAR' | 'duplicateAccount' | 'default';
}

interface RPConfirmationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  message: string;
  additionMessage: string;
  contactMessage?: string;
  primaryBtnLabel: string;
  handlePrimaryBtnClick: () => void;
  secondaryBtnLabel: string;
  handleSecondaryBtnClick: () => void;
  size?: SizeProps['size'];
  modalVariant?: ModalVariantProps['modalVariant'];
  handleContactNoLinkClick?: () => void;
}

interface StyleProps extends SizeProps {
  modalVariant?: ModalVariantProps['modalVariant'];
}

interface ActionBtnContainerProps extends ModalVariantProps, SizeProps {}

interface StyledMessageProps extends ModalVariantProps, SizeProps {}

const StyledBody = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'modalVariant'
})<StyleProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';
  const maxWidth: string = isSmallSize ? '265px' : '560px';

  return {
    maxWidth: maxWidth
  };
});

const StyledMessage = styled(RPText, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'modalVariant'
})<StyledMessageProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    marginBottom: '10px',
    textAlign: 'center',
    ...(isSmallSize && {
      margin: '-6px auto 10px auto',
      width: '245px',
      whiteSpace: 'pre-line'
    })
  };
});

const StyledAdditionMessage = styled(RPText, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'modalVariant'
})<StyleProps>(({ size, modalVariant }) => {
  const isSmallSize: boolean = size === 'small';
  const isDuplicateAccountModal: boolean = modalVariant === 'duplicateAccount';

  return {
    marginBottom: isDuplicateAccountModal && isSmallSize ? 0 : '25px',
    display: isDuplicateAccountModal ? 'inline' : 'block',
    textAlign: 'center',
    whiteSpace: isSmallSize ? 'normal' : 'pre-line',
    ...(modalVariant === 'ZAR' &&
      !isSmallSize && {
        padding: '0 42px'
      })
  };
});

const CustomRPText = styled(RPText, {
  shouldForwardProp: (prop) => prop !== 'size'
})<StyleProps>(({ theme }) => {
  const {
    colours: { text }
  } = theme;
  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;
  return {
    textAlign: 'center',
    color: COLOUR_TEXT_TEXT_LINK,
    textDecorationLine: 'none',
    display: 'inline'
  };
});

const StyledContactContainer = styled('div')(() => {
  return {
    marginBottom: '25px',
    textAlign: 'center'
  };
});

const ActionBtnContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'modalVariant' && prop !== 'size'
})<ActionBtnContainerProps>(({ size, modalVariant }) => {
  return {
    width: 'inherit',
    display: 'flex',
    justifyContent: 'center',

    '> button': {
      width: modalVariant === 'ZAR' ? '411px' : '330px'
    }
  };
});

const StyledPrimaryBtn = styled(RPButton, {
  shouldForwardProp: (prop) => prop !== 'modalVariant'
})<StyledPrimaryBtnProps>(({ size, modalVariant }) => {
  const isSmallSize: boolean = size === 'small';

  const isZarModal: boolean = modalVariant === 'ZAR';

  return {
    ...(isZarModal &&
      isSmallSize && {
        padding: '8px 20px'
      }),
    marginBottom: isSmallSize ? '15px' : '10px'
  };
});

const RPConfirmationModal: FC<RPConfirmationModalProps> = (props: RPConfirmationModalProps) => {
  const {
    isOpen,
    handleClose,
    message,
    additionMessage,
    contactMessage,
    primaryBtnLabel,
    handlePrimaryBtnClick,
    secondaryBtnLabel,
    handleSecondaryBtnClick,
    size = 'normal',
    modalVariant = 'default',
    handleContactNoLinkClick
  } = props;

  const isSmallSize: boolean = size === 'small';

  const actionButtons = (
    <>
      <ActionBtnContainer modalVariant={modalVariant} size={size}>
        <StyledPrimaryBtn
          modalVariant={modalVariant}
          onClick={handlePrimaryBtnClick}
          size={isSmallSize ? 'small' : 'large'}
          name="primaryConfirmationModalButton"
          data-testid="rp-button-modal-confirmation-primary"
        >
          {primaryBtnLabel}
        </StyledPrimaryBtn>
      </ActionBtnContainer>
      <ActionBtnContainer modalVariant={modalVariant} size={size}>
        <RPButton
          btnType="text"
          onClick={handleSecondaryBtnClick}
          size={isSmallSize ? 'small' : 'large'}
          name="secondaryConfirmationModalButton"
          data-testid="rp-button-modal-confirmation-secondary"
        >
          {secondaryBtnLabel}
        </RPButton>
      </ActionBtnContainer>
    </>
  );

  const textWithContactMessage = (
    <>
      <StyledContactContainer>
        {additionMessage && (
          <StyledAdditionMessage
            variant={'body2'}
            type="normal"
            text={additionMessage}
            size={size}
            modalVariant={modalVariant}
          />
        )}
        {contactMessage && (
          <CustomRPText
            text={contactMessage}
            type={'link'}
            variant={isSmallSize ? 'subtitle1' : 'body2'}
            size={size}
            onClick={handleContactNoLinkClick}
          />
        )}
      </StyledContactContainer>
    </>
  );

  const body = (
    <StyledBody size={size} modalVariant={modalVariant} data-testid="modal-body">
      {message && (
        <StyledMessage
          variant={isSmallSize ? 'body1' : 'h3'}
          type="bold"
          text={message}
          size={size}
          modalVariant={modalVariant}
        />
      )}

      {contactMessage && textWithContactMessage}

      {!contactMessage && additionMessage && (
        <StyledAdditionMessage
          variant="body2"
          type="normal"
          text={additionMessage}
          size={size}
          modalVariant={modalVariant}
        />
      )}
      {actionButtons}
    </StyledBody>
  );

  return <RPModal isOpen={isOpen} handleClose={handleClose} body={body} size={size} />;
};

export default RPConfirmationModal;

import { FC, useContext, useEffect, useState } from 'react';

import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { LanguageContext } from '../../../../core/TenantProvider/contexts';
import RPUploadedFileDetails from '../../../molecules/RPUploadedFileDetails';
import RPUploadFileSection from '../../../molecules/RPUploadFileSection';
import { FieldErrors, UseFormClearErrors, UseFormGetValues, UseFormSetError, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { ConfirmingYouType, CustomFile } from '../../../../core/types/RegistrationStepForm';
import { validateFileExtension, validateFileSize } from './ValidationSchema';
import getRoundedFileSize from '../../../../core/utils/GetRoundedFileSize';

type selectedDocumentTypeObject = {
  key: string;
  numberOfSides: number;
  structure: string[];
};
interface UploadDocumentMappingProps {
  documentSideName: string;
  errors: FieldErrors<ConfirmingYouType>;
  trigger: UseFormTrigger<ConfirmingYouType>;
  setValue: UseFormSetValue<ConfirmingYouType>;
  getValues: UseFormGetValues<ConfirmingYouType>;
  setError: UseFormSetError<ConfirmingYouType>;
  clearErrors: UseFormClearErrors<ConfirmingYouType>;
  currentDocSidesObject: selectedDocumentTypeObject;
  documentType: string;
}

const UploadDocumentMapping: FC<UploadDocumentMappingProps> = (props: UploadDocumentMappingProps) => {
  const { documentSideName, errors, setValue, getValues, trigger, clearErrors, documentType, currentDocSidesObject } = props;

  const [documentSide, setValueDocument] = useState<CustomFile | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const getFileSize = (file: CustomFile) => getRoundedFileSize(file.size);

  const firstFilePoaAvailableFileTypes: string = translate('registration.confirmingItsYou.uploadDocs.uploadDesktop.poa.file.availableFileTypes');
  const firstFilePoaMaxFileSizeText: string = translate('registration.confirmingItsYou.uploadDocs.uploadDesktop.poa.file.maxFileSizeText');
  const allowedFileTypesErrorText: string = translate('registration.confirmingItsYou.uploadDocs.uploadDesktop.allowedFileTypes');
  const maxFileSizeErrorText: string = translate('registration.confirmingItsYou.uploadDocs.uploadDesktop.maxFileSize');

  useEffect(() => {
    setValueDocument(null);
  }, [currentDocSidesObject]);

  const handleFileUpload = (name: any, files: CustomFile[]) => {
    files[0].keyName = documentSideName;

    if (documentType === 'poi') {
      const poiFiles: CustomFile[] | null = getValues('poiFiles');
      if (poiFiles !== null) {
        const poiCurrentDocsideValue: File[] = [...poiFiles, ...[files[0]]];
        poiFiles && setValue('poiFiles', poiCurrentDocsideValue);
      } else {
        setValue('poiFiles', [files[0]]);
      }

      setValue('poiFileNames', [...[files[0].name]]);
    }
    if (documentType === 'poa') {
      const poaFiles: CustomFile[] | null = getValues('poaFiles');
      if (poaFiles !== null) {
        const poaCurrentDocsideValue: CustomFile[] = [...poaFiles, ...[files[0]]];
        poaFiles && setValue('poaFiles', poaCurrentDocsideValue);
      } else {
        setValue('poaFiles', [files[0]]);
      }
      setValue('poaFileNames', [...[files[0].name]]);
    }

    setValueDocument(files[0]);
    setValue(name, files[0]);

    const isFileSizeValid = validateFileSize(files[0]);
    const isFileExtensionValid = validateFileExtension(files[0]);

    if (!isFileSizeValid || !isFileExtensionValid) {
      removeUploadedFile(name, files[0]);
      !isFileSizeValid && setErrorMessage(maxFileSizeErrorText);
      !isFileExtensionValid && setErrorMessage(allowedFileTypesErrorText);
    }
    if (isFileSizeValid && isFileExtensionValid) {
      errors[documentSideName] && clearErrors(documentSideName);
      setErrorMessage(null);
    }
    trigger();
  };

  const removeUploadedFile = (name: any, FileData: CustomFile | null = null) => {
    if (documentType === 'poi') {
      const poiFiles: CustomFile[] | null = getValues('poiFiles');
      if (poiFiles !== null) {
        const poiCurrentDocsideValue: CustomFile[] | null = poiFiles.filter((fileObject) => {
          const fileName: string = fileObject.name;
          const keyName = fileObject.keyName;
          if (FileData) {
            return !(fileName === FileData?.name && keyName === FileData?.keyName);
          }
          return !(fileName === documentSide?.name && keyName === documentSide?.keyName);
        });
        poiFiles && setValue('poiFiles', poiCurrentDocsideValue.length === 0 ? null : poiCurrentDocsideValue);
      }
    }

    if (documentType === 'poa') {
      const poaFiles: CustomFile[] | null = getValues('poaFiles');
      if (poaFiles !== null) {
        const poaCurrentDocsideValue: CustomFile[] | null = poaFiles.filter((fileObject) => {
          const fileName: string = fileObject.name;
          const keyName = fileObject.keyName;
          if (FileData) {
            return !(fileName === FileData?.name && keyName === FileData?.keyName);
          }
          return !(fileName === documentSide?.name && keyName === documentSide?.keyName);
        });
        poaFiles && setValue('poaFiles', poaCurrentDocsideValue.length === 0 ? null : poaCurrentDocsideValue);
      }
    }

    setValueDocument(null);
    setValue(name, null);
    trigger();
  };

  const errorShow: any = errorMessage ? errorMessage : '';

  return (
    <>
      {documentSide && documentSide?.name.length > 0 ? (
        <RPUploadedFileDetails
          fileName={documentSide?.name}
          fileSize={getFileSize(documentSide)}
          removeUploadedFileHandler={() => removeUploadedFile(documentSideName)}
        />
      ) : (
        <RPUploadFileSection
          handleFileUpload={(files: CustomFile[]) => handleFileUpload(documentSideName, files)}
          value={documentSide}
          availableFileTypesText={firstFilePoaAvailableFileTypes}
          maxFileSizeText={firstFilePoaMaxFileSizeText}
          error={errorShow}
        />
      )}
    </>
  );
};

export default UploadDocumentMapping;

import { useSelector } from 'react-redux';

import { getRegistrationSteps } from '../../redux/modules/registrationStepsSlice';

interface AdditionalStepsData {
  previousStepType: string,
  nextStepType: string,
  currentStepProgress: number,
}

const useStepTypeData = (currentStepType: string): AdditionalStepsData => {
  const { steps } = useSelector(getRegistrationSteps);

  let currentStepIndex: number = -1;
  let previousStepIndex: number = -1;
  let nextStepIndex: number = -1;
  let currentStepProgress: number = 0;

  steps.forEach((step, index) => {
    if(currentStepType === step.type) {
      currentStepIndex = index;
      previousStepIndex = index > 0 ? (index - 1) : previousStepIndex;
      nextStepIndex = index < steps.length ? (index + 1) : nextStepIndex;
    }
  });

  if (currentStepIndex !== -1) {
    currentStepProgress = ((currentStepIndex/steps.length) * 100);
  }

  return {
    previousStepType: previousStepIndex !== -1 ? steps[previousStepIndex].type : '',
    nextStepType: nextStepIndex !== -1 ? steps[nextStepIndex].type : '',
    currentStepProgress,
  };
};

export default useStepTypeData;
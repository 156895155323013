// Types
import { OrganizationCodeTypes } from '../../types/OrganizationTypes';

type DefaultCurrencyPairType = {
  [key: string]: CurrencyPairType;
};

type CurrencyPairType = {
  currencyFrom: string;
  currencyTo: string;
};

const DEFAULT_CURRENCY_PAIR: DefaultCurrencyPairType = {
  currenciesdirect: {
    currencyFrom: 'GBP',
    currencyTo: 'EUR'
  },
  torfx: {
    currencyFrom: 'GBP',
    currencyTo: 'EUR'
  },
  torfxoz: {
    currencyFrom: 'AUD',
    currencyTo: 'GBP'
  },
  propertypay: {
    currencyFrom: 'GBP',
    currencyTo: 'EUR'
  }
};

export const getDefaultCurrencyPair = (tenant: OrganizationCodeTypes | undefined) => {
  const currencyPair: CurrencyPairType = {
    currencyFrom: '',
    currencyTo: ''
  };

  if (tenant) {
    currencyPair.currencyFrom = DEFAULT_CURRENCY_PAIR[tenant].currencyFrom;
    currencyPair.currencyTo = DEFAULT_CURRENCY_PAIR[tenant].currencyTo;
  } else {
    currencyPair.currencyFrom = DEFAULT_CURRENCY_PAIR.currenciesdirect.currencyFrom;
    currencyPair.currencyTo = DEFAULT_CURRENCY_PAIR.currenciesdirect.currencyTo;
  }

  return currencyPair;
};

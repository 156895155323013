import { AddressType } from '../addressLookupTypes';
import { StateLicenseStatusType } from '../CountryStateTypes';

export interface CreateLoginType {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  referred: string;
  referralCodeOrName: string;
}

export interface MobileNumberType {
  mobileNumber: string;
  countryCode: string;
}

export interface SecurityPinType {
  securityPin: string[];
}

export interface EnterDetailsType {
  countryOfResidence: string;
  stateLicenseStatus: StateLicenseStatusType;
  countryOfResidenceName: string;
  address: AddressType;
  dob: DoBType;
  occupation: string;
  employmentStatus: string;
  employmentIndustry: string;
  usa: USARegDetailsType | null;
  esp: ESPRegDetailsType | null;
  agreeTC: boolean | null;
  isTnCCheckboxApplicable: boolean;
}

export interface DoBType {
  day: string;
  month: string;
  year: string;
}

export interface USARegDetailsType {
  socialSecurityNumber: string | null;
}

export interface ESPRegDetailsType {
  nieNifTaxIdNumber: string;
}

export interface SetPreferencesType {
  currencyFrom: string;
  currencyTo: string;
  amount: string;
  agreeTC: boolean;
}

export type selectedDocumentTypeObject = {
  key: string;
  numberOfSides: number;
  structure: string[];
};

export interface DocumentUploadSideType {
  [keyname: string]: any;
}

export interface CustomFile extends File {
  keyName?: string;
}

export interface ConfirmingYouType extends DocumentUploadSideType {
  documentUpload: DocumentUploadType;
  isPoiRequired: boolean;
  poi: string;
  showPoiSecondFile: boolean;
  isPoaRequired: boolean;
  poa: string;
  showPoaSecondFile: boolean;
  poiFiles: CustomFile[] | null;
  poaFiles: CustomFile[] | null;
}

export type DocumentUploadType = 'desktopUpload' | 'mobileUpload' | null;

export enum RequestOTPTypes {
  sms = 'sms',
  call = 'call'
}

export type CustomError = {
  type: keyof typeof CustomErrorTypes;
  response: {
    data: {
      message: string;
    };
    status: string;
  };
};

export enum CustomErrorTypes {
  checkOTP = 'checkOTP',
  generateOTP = 'generateOTP'
}

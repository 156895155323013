import { ChangeEvent, FC } from 'react';
import { useController } from 'react-hook-form';

const ControllerElementWrapper: FC<any> = (props: any) => {
  const {
    component: FormComponent,
    control,
    name,
    defaultValue,
    rules,
    handleOnKeyDown,
    handleChange,
    handleBlur,
  } = props;

  const {
    field: { onChange, onBlur, value },
    fieldState: { isTouched, error }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  return (
    <FormComponent
      value={value}
      {...props}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        handleChange && handleChange(e);
        onChange(e);
      }}
      onBlur={(e: ChangeEvent<HTMLInputElement>) => {
        handleBlur && handleBlur(e);
        onBlur();
      }}
      onKeyDown={handleOnKeyDown}
      error={isTouched && error && error.message}
      {...(name === 'referred' && {
        defaultValue: defaultValue,
      })}
    />
  );
};

export default ControllerElementWrapper;

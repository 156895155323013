import { countryMetadata } from '../../CountryMetadata/CountryMetadata';
import { CountryDataInfo } from '../../types/CountryDataTypes';
import { CountryMetadata } from '../../types/CountryMetadataTypes';
import { DiallingCodeOptionType, OptionType } from '../../types/SelectTypes';

export const getDialingOptions = (): OptionType[] | [] => {
  return countryMetadata.reduce(
    (data: OptionType[], currentObj: CountryMetadata) =>
      currentObj.dialingCode !== ''
        ? (data.push({
            value: currentObj.dialingCode,
            label: currentObj.dialingCode,
            iconPath: currentObj.icon,
          }),
          data)
        : data,
    []
  );
};

export const getDialingCodeOptionsData = (countryData: CountryDataInfo[]) => {
  return countryData.reduce(
    (data: DiallingCodeOptionType[], currentObj: CountryDataInfo) =>
      currentObj.isdCode !== ''
        ? (data.push({
            value: `+${currentObj.isdCode}*${currentObj.value}`,
            label: currentObj.label,
            iconPath: currentObj.iconPath,
            diallingCode: currentObj.isdCode
          }),
          data)
        : data,
    []
  );
};

// Considering mobile extension as +44 and country code as GBR as an example
// countryCode is stored as +44*GBR in the store.
// The function is used to extract +44 from the string
export const extractDialingCodeValue = (value: string): string => {
  const splitValue = value?.split('*');
  return splitValue && splitValue[0] ? splitValue[0] : value;
};

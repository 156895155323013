import { countryMetadata } from '../../CountryMetadata/CountryMetadata';
import { CurrenciesFlagMapping } from '../../CountryMetadata/referenceData/CurrenciesFlagMapping';
import { CountryMetadata } from '../../types/CountryMetadataTypes';
import { OptionType } from '../../types/SelectTypes';

export const getCurrencyOptions = (): OptionType[] | [] => {
  return countryMetadata.reduce(
    (data: OptionType[], currentObj: CountryMetadata) =>
      currentObj.showInCurrencyDropDown && !!currentObj.currencyCode
        ? (data.push({
            value: currentObj.currencyCode,
            label: currentObj.currencyName,
            iconPath: currentObj.icon,
          }),
          data)
        : data,
    []
  );
};

export const getCurrencyDropdownOptions = (currenciesData: any) => {
  const flagData: { [name: string]: string } = CurrenciesFlagMapping;
  return currenciesData.reduce((dropDown: OptionType[], data: any) => {
    if (data.active) {
      const flagIcon: string = data?.code || '';
      const dropdownValue = {
        value: data.code,
        label: data.code,
        iconPath: flagData[flagIcon],
      };
      return [...dropDown, dropdownValue];
    } else {
      return dropDown;
    }
  }, []);
};

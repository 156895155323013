import { FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormReset,
  UseFormResetField,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger
} from 'react-hook-form';

// Contexts
import { LanguageContext, LayoutContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../atoms/RPText';
import { RPSelectNew } from '../../../atoms/RPSelect';
import RPButton from '../../../atoms/RPButton';
import ControllerElementWrapper from '../../../organisms/ControllerWrapper';
import UploadDocumentMapping from './UploadDocumentMapping';
// Types
import { HexColour } from '../../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../../core/types/LayoutProps';
import {
  ConfirmingYouType,
  DocumentUploadSideType,
  selectedDocumentTypeObject
} from '../../../../core/types/RegistrationStepForm';
// RTK Slice
import { getReferenceData } from '../../../../redux/modules/referenceDataSlice';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { getUploadDocSides } from '../../../../core/utils/GetPoaAndPoiDocsData';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';
// Constants
import { REGISTRATION_DEFINITIONS } from '../RegistrationDefinitions';

interface UploadDesktopProps {
  setValue: UseFormSetValue<ConfirmingYouType>;
  control: Control<ConfirmingYouType, any>;
  trigger: UseFormTrigger<ConfirmingYouType>;
  getValues: UseFormGetValues<ConfirmingYouType>;
  resetField: UseFormResetField<DocumentUploadSideType>;
  reset: UseFormReset<ConfirmingYouType>;
  errors: FieldErrors<ConfirmingYouType>;
  setError: UseFormSetError<ConfirmingYouType>;
  clearErrors: UseFormClearErrors<ConfirmingYouType>;
  poiValue: string;
  poaValue: string;
  isValid: boolean;
  isPoiRequired: boolean;
  isPoaRequired: boolean;
  isRegistrationReturnInactive?: boolean;
}

interface PoiDropdownContainerProps extends LayoutProps {}

interface PoaDropdownContainerProps extends LayoutProps {}

interface DocUploadContainerProps extends LayoutProps {
  isRegistrationReturnInactive: boolean;
}

interface ButtonContainerProps extends LayoutProps {}

const PoiPoaHeading = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  return {
    color: COLOUR_TEXT_TEXT_WEAK,
    marginBottom: '10px'
  };
});

const PoiDropdownContainer = styled('div')<PoiDropdownContainerProps>(({ layout }) => {
  const isDesktopLayout = layout === 'desktop';
  return {
    marginBottom: isDesktopLayout ? '30px' : '20px'
  };
});

const ImageUploadHeading = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  return {
    color: COLOUR_TEXT_TEXT_WEAK,
    marginBottom: '10px'
  };
});

const DocUploadContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isRegistrationReturnInactive'
})<DocUploadContainerProps>(({ theme, layout, isRegistrationReturnInactive }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  const isDesktopLayout = layout === 'desktop';
  const isMobileLayout = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isDesktopLayout ? '40px' : '20px',
    ...(isRegistrationReturnInactive && {
      '> div': {
        background: COLOUR_BG_BACKGROUND_ALT
      }
    }),
    '> div:not(:last-child)': {
      marginBottom: isMobileLayout ? '15px' : '20px'
    }
  };
});

const PoaDropdownContainer = styled('div')<PoaDropdownContainerProps>(({ layout }) => {
  const isTabletLayout = layout === 'tablet';
  return {
    marginBottom: isTabletLayout ? '10px' : '20px'
  };
});

const ButtonContainer = styled('div')<ButtonContainerProps>(({ layout }) => {
  const isDesktopLayout = layout === 'tablet';
  return {
    marginTop: isDesktopLayout ? 40 : 30,
    marginBottom: isDesktopLayout ? 40 : 30,

    '& button': {
      width: '100%'
    }
  };
});

const UploadDesktop: FC<UploadDesktopProps> = (props: UploadDesktopProps) => {
  const {
    setValue,
    control,
    trigger,
    getValues,
    resetField,
    reset,
    setError,
    clearErrors,
    errors,
    poiValue,
    poaValue,
    isValid,
    isPoiRequired,
    isPoaRequired,
    isRegistrationReturnInactive = false
  } = props;
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);
  const { layout } = useContext(LayoutContext);
  const [poiCurrentDocSides, setCurrentPOIDocSides] = useState<selectedDocumentTypeObject | null>(null);
  const [poaCurrentDocSides, setCurrentPOADocSides] = useState<selectedDocumentTypeObject | null>(null);
  const submitDocsLabel: string = translate('registration.confirmingItsYou.submitDocsLabel');
  const poiHeading: string = translate('registration.confirmingItsYou.uploadDocs.uploadDesktop.poi.heading');
  const poiLabel: string = translate('registration.confirmingItsYou.uploadDocs.uploadDesktop.poi.docType.label');
  const poaHeading: string = translate('registration.confirmingItsYou.uploadDocs.uploadDesktop.poa.heading');
  const poaLabel: string = translate('registration.confirmingItsYou.uploadDocs.uploadDesktop.poa.docType.label');

  const PoiFileHeading: Function = (documentNumber: number = 1) => {
    const translateText =
      documentNumber > 2
        ? `registration.confirmingItsYou.uploadDocs.uploadDesktop.poi.fileHeading.otherSides`
        : `registration.confirmingItsYou.uploadDocs.uploadDesktop.poi.fileHeading.${documentNumber}`;
    return translate(translateText);
  };

  const isMobileLayout = isMobileLayoutApplicable(layout);
  const buttonSize: 'medium' | 'large' = isMobileLayout ? 'medium' : 'large';

  const {
    confirmingYou: {
      desktopUpload: {
        poi: { name: poiFieldName },
        poa: { name: poaFieldName },
        submitDocumentsButton: { name: submitDocumentsButtonName, dataTestId: submitDocumentsButtonDataTestId }
      }
    }
  } = REGISTRATION_DEFINITIONS;

  const { poiDropdownOptions, poaDropdownOptions, poiDocSides, poaDocSides } = useSelector(getReferenceData);

  const handleDropdownChange = (name: 'poi' | 'poa', value: string) => {
    setValue(name, value);
    if (name === 'poi') {
      if (poiCurrentDocSides !== null) {
        poiCurrentDocSides?.structure.forEach((document) => {
          resetField(document);
          setValue(document, null);
        });
        reset((formValues) => ({
          ...formValues,
          poiFiles: null,
          poiFileNames: [],
          selectedPoiDocSides: 0
        }));
      }

      const poiDocsideObject: selectedDocumentTypeObject = getUploadDocSides(value, poiDocSides);
      setValue('selectedPoiDocSides', Number(poiDocsideObject.numberOfSides));
      setCurrentPOIDocSides(poiDocsideObject);
      trigger();
    } else {
      if (poaCurrentDocSides !== null) {
        poaCurrentDocSides?.structure.forEach((document) => {
          resetField(document);
          setValue(document, null);
        });
        reset((formValues) => ({
          ...formValues,
          poaFiles: null,
          poaFileNames: [],
          selectedPoaDocSides: 0
        }));
      }

      const poaDocsideObject: selectedDocumentTypeObject = getUploadDocSides(value, poaDocSides);
      setValue('selectedPoaDocSides', Number(poaDocsideObject.numberOfSides));
      setCurrentPOADocSides(poaDocsideObject);
      trigger();
    }
  };

  return (
    <>
      {isPoiRequired && (
        <>
          <PoiPoaHeading variant="body1" type="bold" text={poiHeading} />
          <PoiDropdownContainer layout={layout}>
            <ControllerElementWrapper
              name={poiFieldName}
              control={control}
              defaultValue=""
              placeholderLabel={poiLabel}
              component={RPSelectNew}
              size="normal"
              options={poiDropdownOptions}
              handleChange={(value: string) => handleDropdownChange('poi', value)}
              showMaxOptions={!isMobileLayoutApplicable(layout)}
            />
          </PoiDropdownContainer>

          {poiValue !== '' && (
            <DocUploadContainer layout={layout} isRegistrationReturnInactive={isRegistrationReturnInactive}>
              {poiCurrentDocSides !== null &&
                poiCurrentDocSides?.structure.map((poiDocument, index) => {
                  return (
                    <>
                      <ImageUploadHeading
                        key={`${poiDocument}-heading`}
                        variant="body2"
                        type="normal"
                        text={`${PoiFileHeading(index + 1)} ${poiValue.toLocaleLowerCase()}`}
                      />
                      <UploadDocumentMapping
                        documentSideName={poiDocument}
                        errors={errors}
                        trigger={trigger}
                        getValues={getValues}
                        setValue={setValue}
                        setError={setError}
                        clearErrors={clearErrors}
                        key={`${poiDocument}-file-upload`}
                        currentDocSidesObject={poiCurrentDocSides}
                        documentType={'poi'}
                      />
                    </>
                  );
                })}
            </DocUploadContainer>
          )}
        </>
      )}
      {isPoaRequired && (
        <>
          <PoiPoaHeading variant="body1" type="bold" text={poaHeading} />
          <PoaDropdownContainer layout={layout}>
            <ControllerElementWrapper
              name={poaFieldName}
              control={control}
              defaultValue=""
              placeholderLabel={poaLabel}
              component={RPSelectNew}
              size="normal"
              options={poaDropdownOptions}
              handleChange={(value: string) => handleDropdownChange('poa', value)}
              showMaxOptions={!isMobileLayoutApplicable(layout)}
            />
          </PoaDropdownContainer>

          {poaValue !== '' && (
            <DocUploadContainer layout={layout} isRegistrationReturnInactive={isRegistrationReturnInactive}>
              {poaCurrentDocSides !== null &&
                poaCurrentDocSides?.structure.map((poaDocument) => {
                  return (
                    <>
                      <UploadDocumentMapping
                        documentSideName={poaDocument}
                        errors={errors}
                        trigger={trigger}
                        getValues={getValues}
                        setValue={setValue}
                        setError={setError}
                        clearErrors={clearErrors}
                        key={`${poaDocument}-file-upload`}
                        currentDocSidesObject={poaCurrentDocSides}
                        documentType={'poa'}
                      />
                    </>
                  );
                })}
            </DocUploadContainer>
          )}
        </>
      )}

      <ButtonContainer layout={layout}>
        <RPButton
          size={buttonSize}
          type="submit"
          disabled={!isValid}
          name={submitDocumentsButtonName}
          data-testid={submitDocumentsButtonDataTestId}
        >
          {submitDocsLabel}
        </RPButton>
      </ButtonContainer>
    </>
  );
};

export default UploadDesktop;

import { useContext } from 'react';
import { useSelector } from 'react-redux';

// Contexts
import { LanguageContext, LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPConfirmationModal } from '../../molecules/RPModals';
// RTK Slice
import { getLoginDetails } from '../../../redux/modules/loginDetailsSlice';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

interface RPOnfidoCloseConfirmationModalProps {
  navigateToDocsSkipped: () => void;
  handlePrimaryBtnClick: () => void;
}

const RPOnfidoCloseConfirmationModal = (props: RPOnfidoCloseConfirmationModalProps) => {
  const { isLoginDialogShow } = useSelector(getLoginDetails);

  const { layout } = useContext(LayoutContext);
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);
  const { handlePrimaryBtnClick, navigateToDocsSkipped } = props;

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const modalHeadingText: string = translate('modal.onfidoCloseConfirm.heading');
  const modalDescriptionText: string = translate('modal.onfidoCloseConfirm.description');
  const modalPrimaryButtonText: string = translate('modal.onfidoCloseConfirm.primaryBtnLabel');
  const modalSecondaryButtonText: string = translate('modal.onfidoCloseConfirm.secondaryBtnLabel');

  return (
    <RPConfirmationModal
      isOpen={isLoginDialogShow}
      additionMessage={modalDescriptionText}
      handleClose={handlePrimaryBtnClick}
      handlePrimaryBtnClick={handlePrimaryBtnClick}
      handleSecondaryBtnClick={navigateToDocsSkipped}
      message={modalHeadingText}
      modalVariant="default"
      primaryBtnLabel={modalPrimaryButtonText}
      secondaryBtnLabel={modalSecondaryButtonText}
      size={isMobileLayout ? 'small' : 'normal'}
    />
  );
};

export default RPOnfidoCloseConfirmationModal;

import { OptionType } from '../../types/SelectTypes';

export const getEtvDropdownOptions = (etvData: any) => {
  return etvData.reduce((dropDown: OptionType[], data: any) => {
    if (data.active) {
      const dropdownValue = {
        value: data.displayName,
        label: data.displayName
      }
      return [...dropDown, dropdownValue];
    } else {
      return dropDown;
    }
  }, []);
};
import { createAsyncThunk } from '@reduxjs/toolkit';

// Types
import { CustomError } from '../../core/types/RegistrationStepForm';
// RTK Slice
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
// API Wrappers
import axiosWrapper from './axiosWrapper';
// Utils
import GetBasicDetails from '../../core/utils/GetBasicDetails';

const { orgCode } = GetBasicDetails();

type OtpData = {
  countryCode: string;
  mobileNumber: string;
  type: string;
  locale: string
};

const generateOTP = createAsyncThunk('communication-service/generateOtp', async (data: OtpData, thunkAPI) => {

  const { parentOrganizationCode } = GetBasicDetails();

  const payload = {
    orgCode: orgCode,
    parentOrgCode: parentOrganizationCode,
    locale: data.locale,
    phoneNumber: `${data.countryCode}-${data.mobileNumber}`,
    type: data.type
  };
  try {
    const response = await axiosWrapper('generateOTP', 'POST', thunkAPI, payload);

    return response && response.data;
  } catch (error: any) {
    const { responseMessage, responseCode } = error?.response?.data?.data;

    /*
     * We are creating a custom error object with type as 'generateOTP'
     * The API will return HTTP error code as 500, but there can be multiple Twilio error codes for this
     * V005 is one such examples for which we need to show separate error message
     * Therefore these error codes are being sent to getServicesErrorObj() instead of the HTTP error code
     * */
    const customErrorObj: CustomError = {
      type: 'generateOTP',
      response: {
        data: {
          message: responseMessage
        },
        status: responseCode
      }
    };

    thunkAPI.dispatch(getServicesErrorObj(responseCode ? customErrorObj : error));
    return thunkAPI.rejectWithValue({ error: responseMessage });
  }
});

export default generateOTP;

import { useContext, useMemo, FC } from 'react';

import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import RPLink from '../../atoms/RPLink';
import { LinkType } from '../../../core/types/GoogleAnalyticsTypes';
import GetMobileAppLinks from '../../../core/utils/GetMobileAppLinks';

export interface MobileAppLinkType {
  iosAppStoreURL: string;
  androidPlayStoreURL: string;
}

interface MobileAppLinksWithIconsProps {
  linkClickHandler: (type: LinkType) => void;
}

const MobileAppLinksWithIcons: FC<MobileAppLinksWithIconsProps> = (props: MobileAppLinksWithIconsProps) => {
  const { tenant } = useContext(TenantContext);

  const AndroidPlayStore = useMemo(() => getIcon(tenant, 'androidPlayStoreBadges'), [tenant]);
  const IOSAppStore = useMemo(() => getIcon(tenant, 'iOSAppStoreBadges'), [tenant]);

  const { linkClickHandler } = props;

  const { iosAppStoreURL, androidPlayStoreURL }: MobileAppLinkType = GetMobileAppLinks(tenant);

  return (
    <>
      <RPLink href={androidPlayStoreURL} target="_blank" data-testid="rp-link-android-play-store-badges">
        <AndroidPlayStore
          data-testid="rp-icon-android-play-store-badges"
          onClick={() => linkClickHandler(LinkType.AndroidStore)}
        />
      </RPLink>
      <RPLink href={iosAppStoreURL} target="_blank" data-testid="rp-link-iOS-app-store-badges">
        <IOSAppStore data-testid="rp-icon-iOS-app-store-badges" onClick={() => linkClickHandler(LinkType.IOSStore)} />
      </RPLink>
    </>
  );
};

export default MobileAppLinksWithIcons;

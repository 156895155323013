import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper, { ObjectMapping } from './axiosWrapper';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
import GetBasicDetails from '../../core/utils/GetBasicDetails';
// import { SAVE_DOCUMENT_URL } from '../../core/utils/GetSaveDocumentURL';

export type documentData = {
  poiFiles: File[] | null;
  poaFiles: File[] | null;
  crmAccountID: string;
  crmContactID: string;
  email: string;
};

const { orgCode, source } = GetBasicDetails();

const saveDocuments = createAsyncThunk(
  'registration-service/saveDocuments',
  async (data: documentData, thunkAPI) => {
    const {
      poiFiles,
      poaFiles,
      crmAccountID,
      crmContactID,
      email,
    } = data;

    const dataToInsert: ObjectMapping = {
      crmAccountID,
      crmContactID,
      source,
      orgCode,
      email,
    };

    const formData = new FormData();

    if (poiFiles !== null && poiFiles.length > 0) {
      poiFiles.forEach((fileObject: string | Blob) => {
        formData.append('files', fileObject);
      })
    }
    if (poaFiles !== null && poaFiles.length > 0) {
      poaFiles.forEach((fileObject: string | Blob) => {
        formData.append('files', fileObject);
      })
    }

    try {
      const response = await axiosWrapper('saveDocuments', 'POST', thunkAPI, formData, dataToInsert);

      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  }
);

export default saveDocuments;

import { FC, memo, useContext, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Contexts
import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { RegistrationReturnProps } from '../../../core/types/RegistrationReturnTypes';
// Hook
import { useResetStore } from '../../../core/hooks';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../core/utils/IconOrgData';
// GA4
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

export interface DocUploadCompleteSizes {
  size: 'large' | 'medium' | 'small';
}

interface DocUploadCompleteContainerWrapperProps extends DocUploadCompleteSizes {}
interface TextWrapperProps extends DocUploadCompleteSizes {}
interface StyledIllustrationProps extends DocUploadCompleteSizes {}
interface DocUploadCompleteProps extends DocUploadCompleteSizes, RegistrationReturnProps {
  isFromOnFidoPage?: boolean;
}

const DocUploadCompleteContainerWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size'
})<DocUploadCompleteContainerWrapperProps>(({ size }) => {
  const isLargeSize: boolean = size === 'large';

  const isMediumSize: boolean = size === 'medium';
  const isSmallSize: boolean = size === 'small';

  return {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    maxWidth: isSmallSize ? '325px' : '500px',
    height: '100%',
    maxHeight: 600,
    margin: isLargeSize ? '170px 0px 0px 0px' : isMediumSize ? '120px 0px 0px 0px' : '130px 0px 30px 0px'
  };
});

const DocUploadCompleteContainer = styled(Box)(({ theme }) => {
  const {
    colours: {
      text: { textInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;

  return {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '25px',
    color: COLOUR_TEXT_TEXT_INVERSE,
    boxSizing: 'border-box',

    '& .MuiTypography-root': {
      color: COLOUR_TEXT_TEXT_INVERSE,
      textAlign: 'center'
    }
  };
});

const TextWrapper = styled(Box)<TextWrapperProps>(({ size }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: size === 'small' ? '10px' : '25px'
  };
});

const StyledIllustration = styled('span')<StyledIllustrationProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    ...(isSmallSize
      ? {
          width: '320px',
          height: '85px'
        }
      : {
          width: '460px',
          height: '121px'
        }),
    alignItems: 'center',
    display: 'flex'
  };
});

const DocUploadComplete: FC<DocUploadCompleteProps> = (props: DocUploadCompleteProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);

  const UploadDocWelcomeIcon = useMemo(() => getIcon(tenant, 'uploadDocWelcome'), [tenant]);

  const headingText: string = translate('docUploadComplete.heading');
  const subHeadingLine1Text: string = translate('docUploadComplete.subHeadingLine1');

  const { size, isFromOnFidoPage = false, isRegistrationReturn = false } = props;

  const {
    eventAction: { load },
    kycFailedDocument: {
      load: { poiOnfidoDocUploadComplete, poiOnFidoDocUploadCompleteReturn }
    }
  } = GoogleAnalyticsTagsMapping;

  const headerVariant: 'h2' | 'h3' = size === 'small' ? 'h3' : 'h2';
  const bodyVariant: 'body1' | 'body2' = size === 'small' ? 'body2' : 'body1';
  const bodyType: 'bold' | 'normal' = size === 'small' ? 'normal' : 'bold';

  useEffect(() => {
    if (isFromOnFidoPage) {
      TriggerGoogleAnalyticsTag(load, isRegistrationReturn ? poiOnFidoDocUploadCompleteReturn : poiOnfidoDocUploadComplete);
    }
  }, [isFromOnFidoPage]);

  // reset redux store state manually
  useResetStore();

  return (
    <DocUploadCompleteContainerWrapper size={size}>
      <DocUploadCompleteContainer>
        <StyledIllustration size={size}>
          <UploadDocWelcomeIcon />
        </StyledIllustration>
        <TextWrapper size={size}>
          <RPText variant={headerVariant} type="bold" text={headingText} />
          <RPText variant={bodyVariant} type={bodyType} text={subHeadingLine1Text} />
        </TextWrapper>
      </DocUploadCompleteContainer>
    </DocUploadCompleteContainerWrapper>
  );
};

export default memo(DocUploadComplete);

import { BaseRegistrationStep, RegistrationStep } from '../../types/StepProgressTrackerTypes';

const GetRegistrationStepsData = (steps: BaseRegistrationStep[], translate: Function): RegistrationStep[] => {

  /*const steps: RegistrationStep[] = [
    {
      type: 'create-login',
      status: 'complete',
      label: translate('sidebar.stepProgressTracker.create-login'),
      showEditIcon: true,
      isLoading: false,
    },
    {
      type: 'setup-security',
      status: 'active',
      label: translate('sidebar.stepProgressTracker.setup-security'),
      showEditIcon: false,
      isLoading: false,
    },
    {
      type: 'enter-details',
      status: 'incomplete',
      label: translate('sidebar.stepProgressTracker.enter-details'),
      showEditIcon: false,
      isLoading: false,
    },
    {
      type: 'set-preferences',
      status: 'incomplete',
      label: translate('sidebar.stepProgressTracker.set-preferences'),
      showEditIcon: false,
      isLoading: false,
    },
    {
      type: 'confirming-you',
      status: 'incomplete',
      label: translate('sidebar.stepProgressTracker.confirming-you'),
      showEditIcon: false,
      isLoading: false,
    },
  ];*/

  return steps.map(step => {
    const stepWithTranslate: RegistrationStep = {
      label: '',
      ...step
    };
    stepWithTranslate.label = translate(`sidebar.stepProgressTracker.${step.type}`);
    return stepWithTranslate
  });
};

export default GetRegistrationStepsData;
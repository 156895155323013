import { countryMetadata } from '../../CountryMetadata/CountryMetadata';
import { CountryMetadata } from '../../types/CountryMetadataTypes';
import { DEFAULT_COUNTRY } from '../Constants/Constants';

const setSelectedCountry = (country: string | null) => {
  if (country && country.length > 0) {
    // Check if the country exists in metadata
    const countryExists: CountryMetadata | undefined = countryMetadata.find(
      (data: CountryMetadata) =>
        data.value.trim().toLowerCase() === country.trim().toLowerCase()
    );
    // Set the country value if the country exists or default to gbr
    const countryValue: string = countryExists
      ? countryExists.value.toLowerCase()
      : DEFAULT_COUNTRY;
    localStorage.setItem('country', countryValue);
  } else {
    // If no value is set from the URL then set the default value
    localStorage.setItem('country', DEFAULT_COUNTRY);
  }
};

export default setSelectedCountry;

const workerCode = () => {
  let timerInterval: any;
  let time = 0;

  // eslint-disable-next-line no-restricted-globals
  self.onmessage = function ({ data: { turn } }) {
    if (turn === 'off' || timerInterval) {
      clearInterval(timerInterval);
      time = 0;
    }
    if (turn === 'on') {
      timerInterval = setInterval(() => {
        time += 1;
        // eslint-disable-next-line no-restricted-globals
        self.postMessage({ time });
      }, 1000);
    }
  };
};

let code = workerCode.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], { type: 'application/javascript' });

export const WebWorker = URL.createObjectURL(blob);

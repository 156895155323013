import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import RPRadioListButton from './RPRadioListButton';

type VariantType = 'twoLineNormal' | 'oneLineSmall';

interface RHFRadioListButtonProps {
  defaultValue: string | null;
  control: Control<any, any>;
  label: string;
  name: string;
  value: string;
  isChecked: boolean;
  handleChange?: (value: string) => void;
  icon?: any;
  description?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  variantType?: VariantType;
}

const RHFRPRadioListButton: FC<RHFRadioListButtonProps> = (
  props: RHFRadioListButtonProps
) => {
  const {
    defaultValue,
    control,
    label,
    name,
    value,
    isChecked,
    handleChange,
    icon,
    description,
    isDisabled = false,
    hasError = false,
    variantType = 'twoLineNormal',
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, name: fieldName } }) => (
        <RPRadioListButton
          label={label}
          name={fieldName}
          value={value}
          isChecked={isChecked}
          handleChange={(currentValue: string) => {
            onChange(currentValue);
            handleChange && handleChange(currentValue);
          }}
          icon={icon}
          description={description}
          hasError={hasError}
          isDisabled={isDisabled}
          variantType={variantType}
        />
      )}
    />
  );
};

export default RHFRPRadioListButton;

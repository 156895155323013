import { ChangeEvent, FC } from 'react';
import { styled } from '@mui/material/styles';

import RPInput from '../../atoms/RPInput';

export type fieldTypes = 'day' | 'month' | 'year';

interface RPDoBProps {
  value: string;
  handleOnChange: (value: string, fieldType: fieldTypes) => void;
  size?: 'normal' | 'small';
  dayError?: string;
  monthError?: string;
  yearError?: string;
  dateFormat?: 'little-endian' | 'middle-endian' | 'big-endian'; // DD/MM/YYYY | MM/DD/YYYY | YYYY/MM/DD
}

const DobContainer = styled('div')(() => {
  return {
    display: 'flex',
    gap: '15px',
    justifyContent: 'space-between',
  };
});

const RPDoB: FC<RPDoBProps> = (props: RPDoBProps) => {
  const {
    value,
    handleOnChange,
    size = 'normal',
    dayError,
    monthError,
    yearError,
    dateFormat = 'little-endian',
  } = props;

  const onChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    fieldType: fieldTypes
  ) => {
    const inputValue: string = event.target.value;
    const onlyNumRegex: RegExp = /^[0-9\b]+$/;
    (inputValue === '' || onlyNumRegex.test(inputValue)) &&
      handleOnChange(inputValue, fieldType);
  };

  const dateSplitArray: string[] = value ? value.split('/') : [];

  const displayValue = (index: number) =>
    dateSplitArray && dateSplitArray[index] ? dateSplitArray[index] : '';

  const dayInputField = () => (
    <RPInput
      name="dob_day"
      value={displayValue(0)}
      placeholderLabel="DD"
      maxLength={2}
      size={size}
      error={dayError}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeHandler(e, 'day')}
    />
  );

  const monthInputField = () => (
    <RPInput
      name="dob_month"
      value={displayValue(1)}
      placeholderLabel="MM"
      maxLength={2}
      size={size}
      error={monthError}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        onChangeHandler(e, 'month')
      }
    />
  );

  const yearInputField = () => (
    <RPInput
      name="dob_year"
      value={displayValue(2)}
      placeholderLabel="YYYY"
      maxLength={4}
      size={size}
      error={yearError}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        onChangeHandler(e, 'year')
      }
    />
  );

  const displayDobField = () => {
    if (dateFormat === 'little-endian') {
      return (
        <>
          {dayInputField()}
          {monthInputField()}
          {yearInputField()}
        </>
      );
    } else if (dateFormat === 'middle-endian') {
      return (
        <>
          {monthInputField()}
          {dayInputField()}
          {yearInputField()}
        </>
      );
    } else {
      return (
        <>
          {yearInputField()}
          {monthInputField()}
          {dayInputField()}
        </>
      );
    }
  };

  return <DobContainer>{displayDobField()}</DobContainer>;
};

export default RPDoB;

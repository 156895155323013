import { FC } from 'react';
import { styled } from '@mui/material/styles';

import RPText from '../RPText';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface RPValidationErrorMessageProps {
  message: string;
  marginTop?: number; // to be used when you want to apply margin-top other than 10px.
}

interface ErrorMessageProps {
  marginTop?: number;
}

const ErrorMessage = styled(RPText, {
  shouldForwardProp: (prop) => prop !== 'marginTop'
})<ErrorMessageProps>(({ theme, marginTop }) => {
  const {
    colours: {
      text: { textError }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_ERROR: HexColour = textError;

  return {
    marginTop: marginTop || 10,
    color: COLOUR_TEXT_TEXT_ERROR
  };
});

const RPValidationErrorMessage: FC<RPValidationErrorMessageProps> = ({ message, marginTop }) => {
  return <ErrorMessage variant="subtitle2" type="normal" text={message} marginTop={marginTop} />;
};

export default RPValidationErrorMessage;

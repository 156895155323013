import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper, { ObjectMapping } from './axiosWrapper';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
import { CountryCodesWithStateDropdown } from '../../core/types/CountryStateTypes';
import GetBasicDetails from '../../core/utils/GetBasicDetails';
import { COUNTRY_ID_WITH_STATE_DROPDOWN } from '../../core/utils/Constants/Constants';


export type getCountryStateDataRequestDetails = {
  countryCode: CountryCodesWithStateDropdown
  locale: string;
};

const getCountryStateData = createAsyncThunk(
  '/reference-data/country-state',
  async (data: getCountryStateDataRequestDetails, thunkAPI) => {

    const { countryCode, locale } = data;
    const { orgCode, localeId } = GetBasicDetails(locale);

    const dataToInsert: ObjectMapping = {
      orgCode,
      state: COUNTRY_ID_WITH_STATE_DROPDOWN[countryCode] + '',
      localeId: localeId + '',
    };

    try {
      const response = await axiosWrapper('getCountryStateData', 'GET', thunkAPI, null, dataToInsert);

      return {
        countryCode: countryCode,
        stateData: response.data,
      };
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  }
);

export default getCountryStateData;

import { KeyboardEvent } from 'react';

export const isNumericInput = (keyCode: number) => {
  return (
    (keyCode >= 48 && keyCode <= 57) || // Allow number line
    (keyCode >= 96 && keyCode <= 105) // Allow number pad
  );
};

export const isModifierKey = (event: KeyboardEvent<HTMLInputElement>) => {
  const key: number = event.keyCode;
  // Allow Home, End, Backspace, Tab, Enter, Delete
  const allowedSpecialKeyCodes: number[] = [35, 36, 8, 9, 13, 46];
  // A,C,V,X,Z
  const allowedCharKeyCodes: number[] = [65, 67, 86, 88, 90];
  return (
    event.shiftKey === true || // Allow Shift
    allowedSpecialKeyCodes.includes(key) ||
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + allowedCharKeyCodes
    ((event.ctrlKey === true || event.metaKey === true) && allowedCharKeyCodes.includes(key))
  );
};

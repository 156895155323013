import { FC, useContext, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';

// Contexts
import { LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { DocUploadCompleteSizes } from '../../organisms/DocUploadComplete/DocUploadComplete';
import { DocUploadComplete } from '../../organisms/DocUploadComplete';
import { FullLayout } from '../../organisms/FullLayout';
// Hooks
import { useAnimation, usePreventBackAction, useScrollToContentTop } from '../../../core/hooks';
// Types
import { LayoutSize } from '../../../core/types/LayoutTypes';
// Constants
import { CONFETTI_COLOURS } from '../../../core/utils/Constants/Constants';

const LAYOUT_TO_SIZE_MAPPING: { [key in LayoutSize]: DocUploadCompleteSizes['size'] } = {
  desktop: 'large',
  tablet: 'medium',
  mobile: 'small',
  mobileSM: 'small'
};

const DocUploadCompletePage: FC = () => {
  const { layout } = useContext(LayoutContext);

  const size: DocUploadCompleteSizes['size'] = LAYOUT_TO_SIZE_MAPPING[layout];

  const [animateConfetti] = useAnimation(false, 1500);
  const [animateContent, setAnimateContent] = useAnimation(false, 700);

  const cssTransitionRef = useRef(null);
  const location = useLocation();

  // Scrolls to the top of the content area
  useScrollToContentTop();

  // Prevent back action of browser
  usePreventBackAction();

  return (
    <FullLayout>
      <ReactCanvasConfetti
        fire={animateConfetti}
        angle={90}
        className="canvas"
        colors={CONFETTI_COLOURS}
        decay={0.91}
        drift={0}
        gravity={1}
        origin={{ x: 0.5, y: 0.5 }}
        particleCount={400}
        resize
        scalar={0.8}
        shapes={['circle', 'square']}
        spread={80}
        startVelocity={45}
        ticks={300}
        useWorker
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          width: '100%',
          height: '100%',
          top: '0',
          left: '0'
        }}
        zIndex={-1}
      />
      <CSSTransition
        nodeRef={cssTransitionRef}
        in={animateContent}
        timeout={500}
        classNames="slideUp"
        unmountOnExit
        onEnter={() => setAnimateContent}
        onExited={() => setAnimateContent}
      >
        <div ref={cssTransitionRef}>
          <DocUploadComplete size={size} isFromOnFidoPage={location?.state?.fromOnFidoPage} />
        </div>
      </CSSTransition>
    </FullLayout>
  );
};

export default DocUploadCompletePage;

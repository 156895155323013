import { useEffect, useState } from 'react';
import _ from 'lodash';

// Utils
import { GetReferralCookieDataObject, CheckRequiredReferralData } from '../utils/GetCookieDataObject';

type useGetRefererBranchIOInfoAndDataType = {
  shouldShowRefererInfo: boolean;
  refererName: string;
};

const useGetRefererBranchIOInfoAndData = (): useGetRefererBranchIOInfoAndDataType => {
  const [shouldShowRefererInfo, setShouldShowRefererInfo] = useState<boolean>(false);
  const [refererName, setReferName] = useState<string>('');

  useEffect(() => {
    const referralCookieData = GetReferralCookieDataObject();
    const isRequiredBranchIODataAvailable = CheckRequiredReferralData();

    if (isRequiredBranchIODataAvailable) {
      setReferName(referralCookieData['refererName']);
      setShouldShowRefererInfo(true);
    }
  }, []);

  return {
    shouldShowRefererInfo,
    refererName
  };
};

export default useGetRefererBranchIOInfoAndData;

import { useContext, useMemo, MouseEvent, KeyboardEvent } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Contexts
import { LanguageContext, TenantContext, LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { FullLayout } from '../../organisms/FullLayout';
import RPText from '../../atoms/RPText';
import RPLink from '../../atoms/RPLink';
// Hooks
import { useScrollToContentTop } from '../../../core/hooks';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../core/types/LayoutProps';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../core/utils/IconOrgData';
import { getLandingPageURL } from '../../../core/utils/GetOrganizationEnvSpecificURLs';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
// Constants
import { ENTER_KEY } from '../../../core/utils/Constants/Constants';

interface PageNotFoundContainerWrapperProps extends LayoutProps {}
interface HeadingTextWrapperProps extends LayoutProps {}

const PageNotFoundContainerWrapper = styled(Box)<PageNotFoundContainerWrapperProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    maxWidth: isMobileLayout ? '325px' : '550px',
    height: '100%',
    maxHeight: 600,
    margin: isDesktopLayout ? '165px 0px 0px 0px' : isTabletLayout ? '50px 0px 0px 0px' : '130px 0px 30px 0px'
  };
});

const PageNotFoundContainer = styled(Box)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '30px',
    color: COLOUR_TEXT_TEXT_WEAK,
    boxSizing: 'border-box',
    marginTop: '9px',

    '& .MuiTypography-root:not(:last-child)': {
      textAlign: 'center',
      color: COLOUR_TEXT_TEXT_WEAK
    }
  };
});

const HeadingTextWrapper = styled(Box)<HeadingTextWrapperProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    gap: isMobileLayout ? '10px' : '24px',
    flexDirection: 'column'
  };
});

const LinkContainer = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center'
  };
});

const TextWrapper = styled(Box)(() => {
  return {
    lineHeight: '24px'
  };
});

const PageNotFound = () => {
  const { layout } = useContext(LayoutContext);
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);

  const PageNotFoundIcon = useMemo(() => getIcon(tenant, 'pageNotFound'), [tenant]);

  const landingPageURL: string = getLandingPageURL(tenant);

  const headingText: string = translate('pageNotFound.heading');
  const subHeadingLine1Text: string = translate('pageNotFound.subHeadingLine1');
  const subHeadingLine2Text: string = translate('pageNotFound.subHeadingLine2');
  const primaryLinkLabelText: string = translate('pageNotFound.primaryLinkLabel');

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const headerVariant: 'h2' | 'h3' = isMobileLayout ? 'h3' : 'h2';
  const bodyVariant: 'body1' | 'body2' = isMobileLayout ? 'body2' : 'body1';
  const bodyType: 'normal' = 'normal';

  // Scrolls to the top of the content area
  useScrollToContentTop();

  const goHome = (event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    window.open(landingPageURL, '_self');
  };

  return (
    <FullLayout hideAdditionalInteractions>
      <PageNotFoundContainerWrapper layout={layout} data-testid="rp-page-not-found-container-wrapper">
        <PageNotFoundContainer>
          <PageNotFoundIcon />
          <HeadingTextWrapper layout={layout}>
            <RPText variant={headerVariant} type="bold" text={headingText} />
            <TextWrapper>
              <RPText variant={bodyVariant} type={bodyType} text={subHeadingLine1Text} />
              <RPText variant={bodyVariant} type={bodyType} text={subHeadingLine2Text} />
              <LinkContainer>
                <RPLink
                  variant={bodyVariant}
                  underline="none"
                  type={bodyType}
                  text={primaryLinkLabelText}
                  onClick={(e: MouseEvent<HTMLAnchorElement>) => goHome(e)}
                  onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
                    e.key === ENTER_KEY && goHome(e);
                  }}
                  href=""
                  data-testid="rp-link-go-home"
                />
              </LinkContainer>
            </TextWrapper>
          </HeadingTextWrapper>
        </PageNotFoundContainer>
      </PageNotFoundContainerWrapper>
    </FullLayout>
  );
};

export default PageNotFound;

import { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Contexts
import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPText } from '../../atoms/RPText';
import { RPButton } from '../../atoms/RPButton';
import { ActionBtnContainer } from '../RPModals/ServiceModalCommon';
// Types
import { SizeProps } from '../RPModals/RPModal';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { RPInformationalBlurb } from '../RPInformationalBlurb';
import { InformationalBlurbContent } from '../RPInformationalBlurb/RPInformationalBlurb';
import { getInformationalBlurbContentArray } from '../../../core/utils/GetInformationalBlurbContentArray';
// Constants
import { CFSB_USA_NO_SERVICE_ORGANISATION_LIST, INFORMATIONAL_BLURB_CONTENT_ARRAY_DEFINITION } from '../../../core/utils/Constants/Constants';

// Interfaces
interface CFSBNoServiceProps {
  size?: SizeProps['size'];
  handleButtonClick: () => void;
  handleBack?: () => void;
}

interface IconContainerProps extends SizeProps {}

// Styled components
const ContentContainer = styled(Box)(() => {
  return {
    textAlign: 'center',
    marginBottom: '20px',
    '& > *': {
      display: 'inline'
    }
  };
});

export const IconContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size'
})<IconContainerProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';
  return {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: isSmallSize ? '20px' : '40px',
    '> svg': {
      width: '220px',
      height: '60px'
    }
  };
});

const CFSBNoService: FC<CFSBNoServiceProps> = (props: CFSBNoServiceProps) => {
  const { tenant } = useContext(TenantContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { size = 'normal', handleButtonClick, handleBack } = props;

  // Icons
  const CFSBCurrenciesDirectLogo = getIcon(tenant, 'cfsbCurrenciesDirectLogo');

  const isSmallSize: boolean = size === 'small';
  const rpButtonSizeVariant: 'small' | 'large' = isSmallSize ? 'small' : 'large';
  const contentVariant: 'body1' | 'body2' = isSmallSize ? 'body2' : 'body1';

  const isLinkInContent: boolean = CFSB_USA_NO_SERVICE_ORGANISATION_LIST.includes(tenant!);

  // Translations
  const headingPart1: string = translate('registerPage.cfsb.noService.heading.part1');
  const headingPart2: string = translate('registerPage.cfsb.noService.heading.part2');
  const headingPart3: string = translate('registerPage.cfsb.noService.heading.part3');
  const primaryButtonText: string = translate('registerPage.cfsb.noService.buttonText');
  const backButtonText: string = translate('registerPage.cfsb.noService.backButtonText');

  const InformationalBlurbContentArray: Array<InformationalBlurbContent> =
    getInformationalBlurbContentArray(INFORMATIONAL_BLURB_CONTENT_ARRAY_DEFINITION, translate, isLinkInContent);

  return (
    <Box data-testid="rp-cfsb-no-service-body">
      <IconContainer size={size}>
        <CFSBCurrenciesDirectLogo />
      </IconContainer>

      <ContentContainer>
        <RPText variant={contentVariant} type="normal" text={headingPart1} data-testid="rp-text-cfsb-heading-part-1"/>
        <RPText variant={contentVariant} type="bold" text={headingPart2} data-testid="rp-text-cfsb-heading-part-2" />
        <RPText variant={contentVariant} type="normal" text={headingPart3} data-testid="rp-text-cfsb-heading-part-3" />
      </ContentContainer>
      <RPInformationalBlurb contentArray={InformationalBlurbContentArray} />
      <ActionBtnContainer size={size}>
        <RPButton
          onClick={handleButtonClick}
          size={rpButtonSizeVariant}
          name="PartialServicePrimaryModalButton"
          data-testid="rp-button-cfsb-no-service-primary"
        >
          {primaryButtonText}
        </RPButton>
        <RPButton
            btnType="text"
            size={rpButtonSizeVariant}
            onClick={handleBack}
            name={'PartialServicePrimaryModalBackButton'}
            data-testid={'rp-button-cfsb-no-service-back'}
          >
          {backButtonText}
        </RPButton>
      </ActionBtnContainer>
    </Box>
  );
};

export default CFSBNoService;

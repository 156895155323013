import { FC, memo } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

// Components - Atoms, Molecules, Organisms, Pages
import RPLogo from '../../atoms/RPLogo';
// Types
import { IconObject } from '../../../core/types/IconOrgTypes';
import { OrganizationCodeTypes } from '../../../core/types/OrganizationTypes';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { AppDispatch } from '../../../redux/store';
// RTK Slice
import { openLoginDialogModal } from '../../../redux/modules/loginDetailsSlice';

interface RPLogoWrapperProps {
  logoVariant: keyof IconObject;
  tenant: OrganizationCodeTypes | undefined;
  link: string;
  shouldShowCloseConfirmationModal?: boolean;
  disableClick?: boolean;
}

interface StyledIconButtonProps {
  disableClick: boolean;
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'disableClick'
})<StyledIconButtonProps>(({ theme, disableClick }) => {
  const {
    colours: {
      text: { textInputFocus }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INPUT_FOCUS: HexColour = textInputFocus;

  return {
    ...(disableClick && {
      cursor: 'auto'
    }),
    '&.Mui-focusVisible': {
      outline: `${COLOUR_TEXT_TEXT_INPUT_FOCUS} solid 1px`,
      outlineOffset: 1,
      color: COLOUR_TEXT_TEXT_INPUT_FOCUS,
      borderRadius: 4
    }
  };
});

const RPLogoWrapper: FC<RPLogoWrapperProps> = (props: RPLogoWrapperProps) => {
  const { logoVariant, tenant, link, shouldShowCloseConfirmationModal = false, disableClick = false } = props;

  const dispatch: AppDispatch = useDispatch();

  const handleClick = () => {
    if (!disableClick) {
      if (shouldShowCloseConfirmationModal) {
        dispatch(openLoginDialogModal(true));
      } else {
        window.open(link, '_self');
      }
    }
  };

  return (
    <StyledIconButton
      onClick={handleClick}
      disableRipple
      name="logoButton"
      data-testid="rp-button-logo"
      disableClick={disableClick}
    >
      <RPLogo logoVariant={logoVariant} tenant={tenant} />
    </StyledIconButton>
  );
};

export default memo(RPLogoWrapper);

import { useContext, useEffect } from 'react';

// Contexts
import { ContentRefContext } from '../TenantProvider/contexts';
// Utils
import { scrollToContentTop } from '../utils/ScrollToContentTop';

// Scrolls to the top of the ref element
const useScrollToContentTop = () => {
  const { ref } = useContext(ContentRefContext);

  useEffect(() => {
    scrollToContentTop(ref);
  }, [ref]);
};

export default useScrollToContentTop;

import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Types
import { OrganizationCodeTypes } from '../../../core/types/OrganizationTypes';
// Utils
import cdFavIcon from '../../../core/TenantProvider/tenants/currenciesdirect/assets/favicon.ico';
// Constants
import { ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG } from '../../../core/utils/Constants/Constants';

const ORGANIZATION_TITLE_MAPPING: { [k in OrganizationCodeTypes]: string } = {
  currenciesdirect: 'Currencies Direct Online - Join Currencies Direct',
  torfx: 'TorFX Online - Join TorFX',
  torfxoz: 'TorFX Online - Join TorFX',
  fcg: 'Currencies Direct Online - Join Currencies Direct',
  ramsdens: 'Currencies Direct Online - Join Currencies Direct',
  hl: 'Currencies Direct Online - Join Currencies Direct',
  propertypay: 'Property Pay Online - Join Property Pay'
};

const RPDocumentTitleAndFavicon = () => {
  const { tenant } = useContext(TenantContext);

  const [favicon, setFavicon] = useState<string>(cdFavIcon);

  const currentBrandTitle: string =
    ORGANIZATION_TITLE_MAPPING[tenant || ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG[0].organizationCode];

  useEffect(() => {
    if (tenant) {
      import(`../../../core/TenantProvider/tenants/${tenant}/assets/favicon.ico`)
        .then((icon) => {
          setFavicon(icon.default);
        })
        .catch(() => {
          setFavicon(cdFavIcon);
        });
    }
  }, [tenant]);

  return (
    <Helmet>
      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      <title>{currentBrandTitle}</title>
    </Helmet>
  );
};

export default RPDocumentTitleAndFavicon;

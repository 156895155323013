import { useContext } from 'react';
import * as yup from 'yup';

import { LanguageContext } from '../../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';

export const ValidationSchema = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const currencyFromRequired: string = translate(
    'registration.setPreferences.currencyFrom.error.required'
  );
  const currencyFromSameValue: string = translate(
    'registration.setPreferences.currencyFrom.error.sameValue'
  );
  const currencyToRequired: string = translate(
    'registration.setPreferences.currencyTo.error.required'
  );
  const currencyToSameValue: string = translate(
    'registration.setPreferences.currencyTo.error.sameValue'
  );
  const amountRequired: string = translate(
    'registration.setPreferences.amount.error.required'
  );
  const agreeTCRequired: string = translate(
    'registration.setPreferences.agreeTC.error.required'
  );

  return yup
    .object({
      currencyFrom: yup
        .string()
        .trim()
        .notOneOf([yup.ref('currencyTo'), null], currencyFromSameValue)
        .required(currencyFromRequired),
      currencyTo: yup
        .string()
        .trim()
        .notOneOf([yup.ref('currencyFrom'), null], currencyToSameValue)
        .required(currencyToRequired),
      amount: yup.string().trim().required(amountRequired),
      agreeTC: yup
        .bool()
        .required(agreeTCRequired)
        .oneOf([true], agreeTCRequired),
    })
    .required();
};

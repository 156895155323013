import { FC, useContext, useMemo } from 'react';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import { RPText } from '../../atoms/RPText';
import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

const StyledPaper = styled(Paper)(({ theme }) => {
  const {
    colours: { backgrounds, text },
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;
  const COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK: HexColour =
    backgrounds.backgroundAltInverseWeak;
  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;
  const COLOUR_TEXT_TEXT_WEAK: HexColour = text.textWeak;

  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: 15,
    gap: 15,
    backgroundColor: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK,
    boxShadow: 'none',
    borderRadius: 4,
    height: 62,
    boxSizing: 'border-box',

    div: {
      borderRadius: 100,
      width: 32,
      height: 32,
      backgroundColor: COLOUR_BG_BACKGROUND_ALT,
      flex: 'none',
      order: 0,
      flexGrow: 0,

      svg: {
        margin: 5,
        width: 22,
        height: 22,
        color: COLOUR_TEXT_TEXT_LINK,
        path: {
          fill: 'currentColor',
        },
      },
    },

    p: {
      color: COLOUR_TEXT_TEXT_WEAK,
      height: 22,
      flex: 'none',
      order: 1,
      flexGrow: 1,
    },
  };
});

interface MobilePlaceholderWithIconProps {
  mobileNoData: string;
  size?: 'large' | 'medium' | 'small';
}

const MobilePlaceholderWithIcon: FC<MobilePlaceholderWithIconProps> = (
  props: MobilePlaceholderWithIconProps
) => {
  const { mobileNoData, size = 'large' } = props;

  const { tenant } = useContext(TenantContext);

  const ToolsTechnologyMobileIcon = useMemo(
    () => getIcon(tenant, 'technologyMobile'),
    [tenant]
  );

  return (
    <StyledPaper data-testid="rp-mobile-placeholder-with-icon">
      <div>
        <ToolsTechnologyMobileIcon data-testid="rp-tools-technology-mobile-icon" />
      </div>
      <RPText
        variant={size === 'large' ? 'body1' : 'body2'}
        type="medium"
        text={mobileNoData}
      />
    </StyledPaper>
  );
};

export default MobilePlaceholderWithIcon;

import { FC, useContext, useState, useEffect, useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from '@mui/material/IconButton';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../atoms/RPText';
import RPButton, { RPButtonContainer } from '../../../atoms/RPButton';
import RPLinearProgressBar from '../../../atoms/RPLinearProgressBar';
import { RPSelectNew } from '../../../atoms/RPSelect';
import RPLoader from '../../../atoms/RPLoader';
import { RPConfirmationModal } from '../../../molecules/RPModals';
import ControllerElementWrapper from '../../../organisms/ControllerWrapper';
import AgreeTnCPrivacyPolicy from '../../../molecules/AgreeTnCPrivacyPolicy';
// Validation Schema or files in the same folder
import { ValidationSchema } from './ValidationSchema';
// Hooks
import { useScrollToContentTop, useStepTypeData } from '../../../../core/hooks';
// Types
import { HexColour } from '../../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../../core/types/LayoutProps';
import { SetPreferencesType } from '../../../../core/types/RegistrationStepForm';
import { OptionType } from '../../../../core/types/SelectTypes';
// RTK Slice
import {
  addPreferanceData,
  getRegistrationDetails,
  startRegistrationProcess,
  updateZarModalOpenStatus
} from '../../../../redux/modules/registrationDetailsSlice';
import { getReferenceData } from '../../../../redux/modules/referenceDataSlice';
import { disableEditIconForSteps, updateCurrentStep } from '../../../../redux/modules/registrationStepsSlice';
// API Wrappers
import getCurrencyData from '../../../../redux/api/getCurrencyData';
import getEtaData from '../../../../redux/api/getEtaData';
// Utils
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { getTnCPrivacyPolicyLinks } from '../../../../core/utils/GetTnCPrivacyPolicyLinks';
import { getDefaultCurrencyPair } from '../../../../core/utils/GetDefaultCurrencyPair';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';
import { getIcon } from '../../../../core/utils/IconOrgData';
// Constants
import {
  CD_SA_URL,
  LANG_CODE_MAPPING,
  LOCALE_LANG_CODE_MAPPING,
  SHOW_ZAR_MODAL_ORGANIZATIONS_LIST,
  // COMPLIANCE_VALID_CODE,
  STEPS_ROUTE_MAPPING,
  ZAR
} from '../../../../core/utils/Constants/Constants';
import { REGISTRATION_DEFINITIONS } from '../RegistrationDefinitions';
// GA4
import { TriggerGoogleAnalyticsTag } from '../../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';
import { LinkType } from '../../../../core/types/GoogleAnalyticsTypes';

interface CurrencyDropdownsContainerProps extends LayoutProps {}

interface StyledTextProps extends LayoutProps {}

interface AmountContainerProps extends LayoutProps {}

interface StyledHeadingProps extends LayoutProps {}

interface StyledDescriptionProps extends LayoutProps {}

type DropdownType = 'currencyFrom' | 'currencyTo';

const StyledHeading = styled(RPText)<StyledHeadingProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { text }
    }
  } = theme;

  const COLOUR_TEXT_TEXT: HexColour = text;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isMobileLayout ? '15px' : '20px',
    color: COLOUR_TEXT_TEXT
  };
});

const StyledDescription = styled(RPText)<StyledDescriptionProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const isDesktopLayout: boolean = layout === 'desktop';
  return {
    marginBottom: isDesktopLayout ? '40px' : '30px',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const StyledText = styled(RPText)<StyledTextProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isMobileLayout ? '10px' : '15px',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const TransferMoneyText = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    marginBottom: '15px',
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const StyledIconButton = styled(IconButton)(({ theme }) => {
  const {
    colours: {
      text: { textInputFocus }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INPUT_FOCUS: HexColour = textInputFocus;

  return {
    padding: 0,

    '&.Mui-focusVisible': {
      outline: `${COLOUR_TEXT_TEXT_INPUT_FOCUS} solid 1px`,
      borderRadius: '4%',
      outlineOffset: 4
    }
  };
});

const CurrencyDropdownsContainer = styled('div')<CurrencyDropdownsContainerProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  let currencySwapIconStyles: {};

  if (isMobileLayout) {
    currencySwapIconStyles = {
      height: 32,
      width: 32,
      justifySelf: 'center',
      '> button > svg': {
        height: 32,
        width: 32,
        transform: 'rotate(90deg)'
      }
    };
  } else {
    currencySwapIconStyles = {
      height: 38,
      width: 38,
      alignSelf: 'end',
      marginBottom: 10
    };
  }

  return {
    display: 'grid',
    marginBottom: isMobileLayout ? '15px' : '30px',
    ...(isMobileLayout
      ? {
          gridTemplateRows: '1fr 0px 1fr',
          gap: '8px'
        }
      : {
          gridTemplateColumns: `1fr 38px 1fr`,
          gap: '12px'
        }),
    '> div:nth-of-type(2)': currencySwapIconStyles
  };
});

const AmountContainer = styled('div')<AmountContainerProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isMobileLayout ? '15px' : '30px'
  };
});

const SetPreferences: FC = () => {
  const dispatch: any = useDispatch();

  const { translations, language } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const { tenant } = useContext(TenantContext);

  const SwitcherIcon = useMemo(() => getIcon(tenant, 'switcher'), [tenant]);

  const navigate = useNavigate();
  let dataFetchedRef = useRef(false);

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const isDesktopLayout: boolean = layout === 'desktop';

  const { termsAndConditions: tncLink, privacyPolicy: privacyPolicyLink } = getTnCPrivacyPolicyLinks(tenant, language);

  const heading: string = translate('registration.setPreferences.heading');
  const description: string = translate('registration.setPreferences.description');
  const transferMostlyText: string = translate('registration.setPreferences.transferMostly');
  const fromHeading: string = translate('registration.setPreferences.currencyFrom.heading');
  const fromLabel: string = translate('registration.setPreferences.currencyFrom.label');
  const toHeading: string = translate('registration.setPreferences.currencyTo.heading');
  const toLabel: string = translate('registration.setPreferences.currencyTo.label');
  const estimateTransferText: string = translate('registration.setPreferences.estimateTransfer');
  const amountLabel: string = translate('registration.setPreferences.amount.label');
  const continueButtonLabel: string = translate('registration.setPreferences.continueButtonText');
  const backButtonLabel: string = translate('registration.setPreferences.backButtonText');
  const ZARMessage: string = translate('modal.ZAR.heading');
  const ZARAdditionalMessage: string = translate('modal.ZAR.description');
  const ZARPrimaryBtnLabel: string = translate(
    isMobileLayout ? 'modal.ZAR.mobilePrimaryBtnLabel' : 'modal.ZAR.primaryBtnLabel'
  );

  const ZARSecondaryBtnLabel: string = translate(
    isMobileLayout ? 'modal.ZAR.mobileSecondaryBtnLabel' : 'modal.ZAR.secondaryBtnLabel'
  );

  const registrationDetails = useSelector(getRegistrationDetails);
  const { currencyFrom, currencyTo, amount, agreeTC, hasZARModalBeenOpened } = registrationDetails;
  const { currenciesLoading, currenciesOptionData, etvOptionData, etvLoading } = useSelector(getReferenceData);

  const { previousStepType, nextStepType, currentStepProgress } = useStepTypeData('set-preferences');

  const buttonSize: 'medium' | 'large' = isMobileLayout ? 'medium' : 'large';

  const {
    setPreferences: {
      currencyFrom: { name: currencyFromFieldName },
      currencyTo: { name: currencyToFieldName },
      amount: { name: amountFieldName },
      continueButton: { name: continueButtonName, dataTestId: continueButtonDataTestId },
      backButton: { name: backButtonName, dataTestId: backButtonDataTestId }
    }
  } = REGISTRATION_DEFINITIONS;

  // TODO: Added temporarily, needs to be refactored
  const { currencyFrom: currencyFromDefaultValue, currencyTo: currencyToDefaultValue } = getDefaultCurrencyPair(tenant);

  const defaultCurrencyFrom: string = currencyFrom ? currencyFrom : currencyFromDefaultValue;

  const defaultCurrencyTo: string = currencyTo ? currencyTo : currencyToDefaultValue;

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    formState: { isValid }
  } = useForm<SetPreferencesType>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      currencyFrom: defaultCurrencyFrom,
      currencyTo: defaultCurrencyTo,
      amount: amount,
      agreeTC: agreeTC
    },
    resolver: yupResolver(ValidationSchema())
  });

  const {
    eventAction: { click, load, select },
    setYourPreferences: {
      click: { backButton, continueButton, zarTransferSignUpForCD, zarTransferContinueToCD, termLink, privacyLink },
      load: { setYourPreferencesPageLoad, zarTransferLoad },
      select: { estimatedTransfer }
    }
  } = GoogleAnalyticsTagsMapping;

  const { currencyFrom: currencyFromValue, currencyTo: currencyToValue, agreeTC: agreeTCValue } = watch();

  const [isZARModalOpen, setIsZARModalOpen] = useState(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    TriggerGoogleAnalyticsTag(load, setYourPreferencesPageLoad);

    if (currenciesOptionData.length === 0) {
      dispatch(getCurrencyData());
    }
    if (etvOptionData.length === 0) {
      dispatch(
        getEtaData({
          locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
        })
      );
    }
  }, [currenciesOptionData, etvOptionData]);

  // Scrolls to the top of the content area
  useScrollToContentTop();

  const navigateToBackPage = () => {
    TriggerGoogleAnalyticsTag(click, backButton);

    dispatch(
      updateCurrentStep({
        stepType: previousStepType
      })
    );

    navigate(STEPS_ROUTE_MAPPING[previousStepType]);
  };

  const onSubmit: SubmitHandler<SetPreferencesType> = (data) => {
    TriggerGoogleAnalyticsTag(click, continueButton);

    dispatch(addPreferanceData(data));
    dispatch(disableEditIconForSteps());
    dispatch(
      updateCurrentStep({
        stepType: nextStepType,
        isLoading: true
      })
    );
    dispatch(startRegistrationProcess());
    navigate(STEPS_ROUTE_MAPPING['identity']);
  };

  const handleCurrencyChange = (name: DropdownType, value: string) => {
    setValue(name, value);
    if (value === ZAR && !hasZARModalBeenOpened && tenant && SHOW_ZAR_MODAL_ORGANIZATIONS_LIST.includes(tenant)) {
      TriggerGoogleAnalyticsTag(load, zarTransferLoad);
      setIsZARModalOpen(true);
      dispatch(updateZarModalOpenStatus(true));
    }
  };

  const swapCurrenciesHandler = () => {
    setValue('currencyFrom', currencyToValue);
    setValue('currencyTo', currencyFromValue);
  };

  // Exclude the selected option from the adjacent dropdown and show rest of the options.
  // Applicable for both currencyFrom and currencyTo
  const getFilteredOptions = (type: DropdownType) => {
    let excludedValue: string = '';
    if (type === 'currencyFrom') {
      excludedValue = currencyToValue || '';
    } else {
      excludedValue = currencyFromValue || '';
    }
    return currenciesOptionData.filter(
      (option: OptionType) => option.value?.trim().toLowerCase() !== excludedValue?.trim().toLowerCase()
    );
  };

  const navigateToCDSA = () => {
    TriggerGoogleAnalyticsTag(click, zarTransferSignUpForCD);
    window.open(CD_SA_URL, '_self');
  };

  const linkClick = (type: LinkType) => {
    TriggerGoogleAnalyticsTag(click, type === LinkType.PP ? privacyLink : termLink);
  };

  // Used to toggle the checkbox when the label is clicked
  const handleToggleCheckbox = () => {
    setValue('agreeTC', !agreeTCValue);
    trigger();
  };

  const loadingState: boolean = currenciesLoading === 'loading' || etvLoading === 'loading';

  const textVariant: 'body1' | 'body2' = isDesktopLayout ? 'body1' : 'body2';

  return loadingState ? (
    <RPLoader size="medium" type="primary" />
  ) : (
    <>
      <RPConfirmationModal
        isOpen={isZARModalOpen}
        handleClose={() => setIsZARModalOpen(false)}
        message={ZARMessage}
        additionMessage={ZARAdditionalMessage}
        primaryBtnLabel={ZARPrimaryBtnLabel}
        handlePrimaryBtnClick={navigateToCDSA}
        secondaryBtnLabel={ZARSecondaryBtnLabel}
        handleSecondaryBtnClick={() => {
          TriggerGoogleAnalyticsTag(click, zarTransferContinueToCD);
          setIsZARModalOpen(false);
        }}
        modalVariant="ZAR"
        size={isMobileLayout ? 'small' : 'normal'}
      />

      <StyledHeading variant={isDesktopLayout ? 'h2' : 'h3'} type="bold" text={heading} layout={layout} />
      {isMobileLayout && <RPLinearProgressBar value={currentStepProgress} />}
      <StyledDescription
        variant="body2"
        type={isDesktopLayout ? 'medium' : 'normal'}
        text={description}
        layout={layout}
      />
      <TransferMoneyText variant={textVariant} type="medium" text={transferMostlyText} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <CurrencyDropdownsContainer layout={layout}>
          <div>
            <StyledText variant={textVariant} type="medium" text={fromHeading} layout={layout} />
            <ControllerElementWrapper
              name={currencyFromFieldName}
              control={control}
              defaultValue={currencyFrom}
              placeholderLabel={fromLabel}
              toShrinkLabel={false}
              component={RPSelectNew}
              size="normal"
              type="currency"
              options={getFilteredOptions('currencyFrom')}
              handleChange={(value: string) => handleCurrencyChange('currencyFrom', value)}
              showMaxOptions={!isMobileLayoutApplicable(layout)}
            />
          </div>
          <div>
            <StyledIconButton
              aria-label="switcher-icon"
              onClick={swapCurrenciesHandler}
              disableRipple
              name="currencySwitchButton"
              data-testid="rp-button-currency-switch"
            >
              <SwitcherIcon />
            </StyledIconButton>
          </div>
          <div>
            <StyledText variant={textVariant} type="medium" text={toHeading} layout={layout} />
            <ControllerElementWrapper
              name={currencyToFieldName}
              control={control}
              defaultValue={currencyTo}
              placeholderLabel={toLabel}
              toShrinkLabel={false}
              component={RPSelectNew}
              size="normal"
              type="currency"
              options={getFilteredOptions('currencyTo')}
              handleChange={(value: string) => handleCurrencyChange('currencyTo', value)}
              showMaxOptions={!isMobileLayoutApplicable(layout)}
            />
          </div>
        </CurrencyDropdownsContainer>

        <StyledText variant={textVariant} type="medium" text={estimateTransferText} layout={layout} />

        <AmountContainer layout={layout}>
          <ControllerElementWrapper
            name={amountFieldName}
            control={control}
            defaultValue={amount}
            placeholderLabel={amountLabel}
            component={RPSelectNew}
            isSearchable={false}
            size="normal"
            options={etvOptionData}
            handleChange={(value: string) => {
              TriggerGoogleAnalyticsTag(select, estimatedTransfer, { ETV: value });
              setValue('amount', value);
            }}
            showMaxOptions={!isMobileLayoutApplicable(layout)}
          />
        </AmountContainer>
        <AgreeTnCPrivacyPolicy
          control={control}
          defaultValue={agreeTC}
          linkClickHandler={linkClick}
          toggleCheckbox={handleToggleCheckbox}
          termsAndConditionsLink={tncLink}
          privacyPolicyLink={privacyPolicyLink}
        />

        <RPButtonContainer layout={layout}>
          <RPButton
            size={buttonSize}
            type="submit"
            disabled={!isValid}
            name={continueButtonName}
            data-testid={continueButtonDataTestId}
          >
            {continueButtonLabel}
          </RPButton>
          <RPButton
            size={buttonSize}
            btnType="text"
            onClick={navigateToBackPage}
            name={backButtonName}
            data-testid={backButtonDataTestId}
          >
            {backButtonLabel}
          </RPButton>
        </RPButtonContainer>
      </form>
    </>
  );
};

export default SetPreferences;

import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper, { ObjectMapping } from './axiosWrapper';

const getAdditionalInfoFields = createAsyncThunk(
  '/getAdditionalInfoFields',
  async (countryCode: string, thunkAPI) => {

    const dataToInsert: ObjectMapping = {
      countryCode
    };

    try {
      const response = await axiosWrapper(
        'getAdditionalInfoFields',
        'GET',
        thunkAPI,
        null,
        dataToInsert,
      );

      return response && response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  }
);

export default getAdditionalInfoFields;

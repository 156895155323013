import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Types
import { CountryDataInfo } from '../types/CountryDataTypes';
// RTK Slice
import { getReferenceData } from '../../redux/modules/referenceDataSlice';
import { updateCountryCode } from '../../redux/modules/registrationDetailsSlice';
// Utils
import { CheckValidCOR } from '../utils/CheckValidCOR/CheckValidCOR';

const useUpdateCountryCode = (queryCorValue: string, mobileNumberValue: string) => {
  const dispatch: any = useDispatch();

  const [countryCode, setCountryCode] = useState<string>(queryCorValue);
  const { countryData, dialingCodeData, locationLookupLoading, ipBasedCountryCode } = useSelector(getReferenceData);

  useEffect(() => {
    if (locationLookupLoading === 'succeeded' && ipBasedCountryCode !== '' && mobileNumberValue === '') {
      setCountryCode(ipBasedCountryCode);
    }
  }, [locationLookupLoading, ipBasedCountryCode, mobileNumberValue]);

  useEffect(() => {
    if (countryData && countryData.length > 0 && mobileNumberValue === '') {
      // Check if url value is not null, blank and not same as COR value from the store
      const trimmedCountryCodeValue: string = countryCode?.trim().toLowerCase();
      if (countryCode && trimmedCountryCodeValue !== '') {
        // Check if url COR value exists in the reference data
        const validCOR: CountryDataInfo | undefined = CheckValidCOR(countryData, trimmedCountryCodeValue);

        if (validCOR) {
          // get Country code from dialingCodeData on the basis of COR value
          const validCountryCode = dialingCodeData.find(
            (data: any) => data.value.split('*')[1]?.trim().toLowerCase() === validCOR.value?.trim().toLowerCase()
          );
          // Update Country code to store
          if (validCountryCode) {
            const payload = {
              countryCode: validCountryCode.value
            };
            dispatch(updateCountryCode(payload));
          }
        }
      }
    }
  }, [countryData, countryCode, mobileNumberValue]);
};

export default useUpdateCountryCode;

import _ from 'lodash';

export const translateWithVariables = (translations: {[key: string]: string} | null, key: string, variables: any) => {
  if (translations === null) {
    return '';
  }

  let translated: string = _.get(translations, key);

  if (!translated) {
    translated = _.get(translations, 'error.translationMissing');
  }

  if (variables !== undefined) {
    Object.keys(variables).forEach((property) => {
      translated = translated.replace(`{${property}}`, variables[property] !== undefined ? variables[property] : '');
    });
  }

  return translated;
}

export default function TranslateWrapper(translations: {[key: string]: string} | null) {
  const translate = (key: string, variables: any = undefined) => {
    return translateWithVariables(translations, key, variables);
  };

  translate.has = (key: string) => (translations && translations[key] !== undefined);

  return translate;
}
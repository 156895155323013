import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export type ScrollDirectionType = 'down' | 'up' | null;

const useScrollDirection = (ref: React.RefObject<HTMLDivElement>): ScrollDirectionType => {
  const div: HTMLDivElement | null = ref.current;
  const [scrollDirection, setScrollDirection] = useState<ScrollDirectionType>(null);

  useEffect(() => {
    if (div) {
      let lastScrollY: number = div.scrollTop;

      const updateScrollDirection = debounce(() => {
        const scrollY: number = div.scrollTop;
        const direction: ScrollDirectionType = scrollY > lastScrollY ? 'down' : 'up';
        if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      }, 50);

      div.addEventListener('scroll', updateScrollDirection); // add event listener

      return () => {
        div.removeEventListener('scroll', updateScrollDirection); // clean up
      };
    }
  }, [div, scrollDirection]);

  return scrollDirection;
};

export default useScrollDirection;

import { FC, useState, ChangeEvent, useCallback, useRef, memo, useEffect, useContext, useReducer } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';

// Contexts
import { LanguageContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPInput from '../../atoms/RPInput';
import RPAddressLookupPopper from './RPAddressLookupPopper';
import RPSelectionSummary from './RPSelectionSummary';
// Types
import { SelectedAddressType, AddressItemsType, AddressItem, Item } from '../../../core/types/addressLookupTypes';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import {
  addressSearchUrl,
  addressRetrieveUrl,
  additionalAddressSearchUrl,
  isFieldValid,
  initialState,
  stateReducer
} from '../../../core/utils/AddressLookup';

interface RPAddressLookupProps {
  selectedCountry: string;
}

const RPAddressLookup: FC<RPAddressLookupProps> = (props: RPAddressLookupProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const [isManualEntry, setIsManualEntry] = useState<boolean>(false);
  const [isCountryChange, setCountryChange] = useState<boolean>(false);
  const { selectedCountry } = props;
  const { searchText, isLoading, hasSelectedValues, open, address, errors, addressItems, hasAddressResults } = state;
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const placeholder: string = translate('registration.addressLookup.placeholder');
  const line1: string = translate('registration.addressLookup.line1');
  // const city: string = translate('registration.addressLookup.city');
  const county: string = translate('registration.addressLookup.county');
  const postcode: string = translate('registration.addressLookup.postcode');
  const line1Error: string = translate('registration.addressLookup.line1Error');
  // const cityError: string = translate('registration.addressLookup.cityError');
  const countyError: string = translate('registration.addressLookup.countyError');
  const postcodeError: string = translate('registration.addressLookup.postcodeError');

  const onAddressSearch = (evt: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = evt;

    dispatch({ type: 'set-search-text', payload: value });
    dispatch({ type: 'set-open', payload: value.length === 0 ? false : true });
    searchAddress(value);
  };

  //TODO - API calls to be added in RTK after discussion on using normal axios OR RTK query
  const onSelectAddress = (entry: AddressItem) => {
    if (entry && entry?.Id && entry?.Type) {
      const { Text, Id } = entry;
      if (entry.Type === 'Address') {
        axios
          .get<SelectedAddressType>(addressRetrieveUrl(Id))
          .then((resp) => {
            setCountryChange(false);
            dispatch({ type: 'set-has-selectedValues', payload: true });
            const selection: Item = resp?.data && resp?.data?.Items[0];
            if (selection) {
              dispatch({
                type: 'set-address',
                payload: {
                  ...address,
                  addressLine1: selection?.Line1,
                  // city: selection?.City,
                  county: selection?.ProvinceName ? selection?.ProvinceName : '',
                  postcode: selection?.PostalCode
                }
              });
            }
          })
          .catch((error) => {
            return error.response;
          });
      } else {
        dispatch({ type: 'set-is-loading', payload: true });
        axios
          .get<AddressItemsType>(additionalAddressSearchUrl(Text, selectedCountry, Id))
          .then((resp) => {
            dispatch({ type: 'set-address-items', payload: resp?.data });
            dispatch({ type: 'set-is-loading', payload: false });
          })
          .catch((error) => {
            return error.response;
          });
      }
    }
  };

  //TODO - Needs to be done code refactoring
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchAddress = useCallback(
    debounce((value) => {
      if (value.length > 0) {
        dispatch({ type: 'set-is-loading', payload: true });
        axios
          .get<AddressItemsType>(addressSearchUrl(value, selectedCountry))
          .then((resp) => {
            dispatch({ type: 'set-address-items', payload: resp?.data });
            dispatch({ type: 'set-is-loading', payload: false });
          })
          .catch((error) => {
            return error.response;
          });
      }
    }, 200),
    [selectedCountry]
  );

  const onManualChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value }
    } = e;

    dispatch({
      type: 'set-address',
      payload: {
        ...address,
        [name]: value
      }
    });

    dispatch({
      type: 'set-manualInput-error',
      payload: {
        ...errors,
        [name]: !isFieldValid(name, value)
      }
    });
  };

  const onManualBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value }
    } = e;

    dispatch({
      type: 'set-manualInput-error',
      payload: {
        ...errors,
        [name]: !isFieldValid(name, value)
      }
    });
  };

  useEffect(() => {
    setIsManualEntry(false);
    setCountryChange(true);
    dispatch({ type: 'reset' });
  }, [selectedCountry]);

  const anchorEl = ref?.current?.parentElement;

  const addressSearchTemplate = (
    <>
      <RPInput
        name="search-text"
        value={searchText}
        placeholderLabel={placeholder}
        size="normal"
        onChange={onAddressSearch}
        inputRef={ref}
        showLoader={isLoading}
        isPopperOpen={open}
      />
      {addressItems && addressItems?.Items.length > 0 && (
        <RPAddressLookupPopper
          addressItems={addressItems}
          onSelectAddress={onSelectAddress}
          setIsManualEntry={setIsManualEntry}
          setCountryChange={setCountryChange}
          open={open}
          anchorEl={anchorEl}
          hasAddressResults={hasAddressResults}
        />
      )}
    </>
  );

  const manualAddressTemplate = (
    <>
      <RPInput
        name="line1"
        value={address?.addressLine1}
        placeholderLabel={line1}
        size="normal"
        onChange={onManualChange}
        onBlur={onManualBlur}
        error={errors.line1 ? line1Error : ''}
      />

      {/* <RPInput
        name="city"
        value={address?.city}
        placeholderLabel={city}
        size="normal"
        onChange={onManualChange}
        onBlur={onManualBlur}
        error={errors.city ? cityError : ''}
      /> */}

      <RPInput
        name="county"
        value={address?.county}
        placeholderLabel={county}
        size="normal"
        onChange={onManualChange}
        onBlur={onManualBlur}
        error={errors.county ? countyError : ''}
      />

      <RPInput
        name="postcode"
        value={address?.postcode}
        placeholderLabel={postcode}
        size="normal"
        onChange={onManualChange}
        onBlur={onManualBlur}
        error={errors.postcode ? postcodeError : ''}
      />
    </>
  );

  const currentView = isCountryChange ? (
    addressSearchTemplate
  ) : isManualEntry ? (
    manualAddressTemplate
  ) : hasSelectedValues && address ? (
    <RPSelectionSummary
      address={address}
      setIsManualEntry={setIsManualEntry}
      setCountryChange={setCountryChange}
      countryCode={selectedCountry}
    />
  ) : (
    addressSearchTemplate
  );

  return <>{currentView}</>;
};

export default memo(RPAddressLookup);

import { FC, useContext, useEffect, KeyboardEvent, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffectOnce } from 'usehooks-ts';

// Contexts
import { ContentRefContext, LanguageContext, LayoutContext, TenantContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../../atoms/RPText';
import RPButton, { RPButtonContainer } from '../../../../atoms/RPButton';
import RPLink from '../../../../atoms/RPLink';
import RPLinearProgressBar from '../../../../atoms/RPLinearProgressBar';
import RPPinField from '../../../../molecules/RPPinField';
import RequestOTPBySMSOrCall from '../../../../molecules/RequestOTPBySMSOrCall';
import ControllerElementWrapper from '../../../../organisms/ControllerWrapper/ControllerWrapper';
// Validation Schema or files in the same folder
import { ValidationSchema } from './ValidationSchema';
// Hooks
import useStepTypeData from '../../../../../core/hooks/useStepTypeData';
import useScrollToContentTop from '../../../../../core/hooks/useScrollToContentTop';
// Types
import { RequestOTPTypes, SecurityPinType } from '../../../../../core/types/RegistrationStepForm';
import { LayoutSize } from '../../../../../core/types/LayoutTypes';
// RTK Slice
import {
  addSecurityPin,
  resetOtpValidation,
  getRegistrationDetails
} from '../../../../../redux/modules/registrationDetailsSlice';
import { updateCurrentStep } from '../../../../../redux/modules/registrationStepsSlice';
// API Wrappers
import generateOTP from '../../../../../redux/api/generateOTP';
import checkOTP from '../../../../../redux/api/checkOTP';
import updateLead from '../../../../../redux/api/updateLead';
import verifyEtvExist, { etvPayloadData } from '../../../../../redux/api/verifyEtvExist';
// Utils
import TranslateWrapper from '../../../../../core/utils/TranslateWrapper';
import { SetupSecurityViewType } from '../SetupSecurity';
import { extractDialingCodeValue } from '../../../../../core/utils/DialingData';
import { getFormattedMobileNumber } from '../../../../../core/utils/GetFormattedMobileNumber';
import { scrollToContentTop } from '../../../../../core/utils/ScrollToContentTop';
import { isMobileLayoutApplicable } from '../../../../../core/utils/IsMobileLayoutApplicable';
// Constants
import { STEPS_ROUTE_MAPPING, USA, ENTER_KEY, LOCALE_LANG_CODE_MAPPING, LANG_CODE_MAPPING, MASK_CLASS, ORGANIZATION_CODE_MAPPING } from '../../../../../core/utils/Constants/Constants';
import { REGISTRATION_DEFINITIONS } from '../../RegistrationDefinitions';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

interface SecurityPinScreenProps {
  setViewType: (viewType: SetupSecurityViewType) => void;
}

interface LayoutProps {
  layout: LayoutSize;
}

type OTPRequestType = RequestOTPTypes.sms | RequestOTPTypes.call;

interface SMSOrCallSectionContainerProps extends LayoutProps {
  type: OTPRequestType;
}

const StyledHeading = styled(RPText)<LayoutProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';

  return {
    marginBottom: isDesktopLayout ? 40 : isTabletLayout ? 30 : 15
  };
});

const StyledCodeLinkWrapper = styled(Box)<LayoutProps>(({ layout }) => {
  const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: isMobileLayout ? 15 : isTabletLayout ? 20 : 40
  };
});

const SMSOrCallSectionContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'type'
})<SMSOrCallSectionContainerProps>(({ layout, type }) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const isRequestTypeCall: boolean = type === 'call';

  let marginTop: number = 0;

  if (isRequestTypeCall) {
    marginTop = isMobileLayout ? 15 : 24;
  } else {
    marginTop = isDesktopLayout ? 40 : isMobileLayout ? 15 : 20;
  }

  return {
    marginTop: marginTop
  };
});

const SecurityPinScreen: FC<SecurityPinScreenProps> = (props: SecurityPinScreenProps) => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch(); //TODO

  const { layout } = useContext(LayoutContext);

  const { language, translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { ref } = useContext(ContentRefContext);

  const { tenant } = useContext(TenantContext);

  const [showSendOTPOptions, setShowSendOTPOptions] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm<SecurityPinType>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(ValidationSchema())
  });

  const {
    countryCode,
    mobileNumber,
    email,
    isValidOTP,
    updateLeadStatus,
    updateLeadLoading,
    leadId,
    firstName,
    lastName,
    loading,
    otpValidationError,
    queryCOR: queryCORValue,
    verifyETVLoading
  } = useSelector(getRegistrationDetails);

  const { currentStepProgress, nextStepType } = useStepTypeData('setup-security');

  const { setViewType } = props;

  const formattedMobileNumber: string = getFormattedMobileNumber(countryCode, mobileNumber);

  const corIsUSA: boolean = queryCORValue?.toLowerCase() === USA;
  const isLoadingState: boolean = loading === 'loading' || updateLeadLoading === 'loading';

  const brand: string = tenant ? tenant : ORGANIZATION_CODE_MAPPING.currenciesdirect;

  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const buttonSize: 'medium' | 'large' = isMobileLayout ? 'medium' : 'large';
  const headingVariant: 'h2' | 'h3' = isDesktopLayout ? 'h2' : 'h3';
  let pinFieldSize: 'large' | 'medium' | 'small' = isDesktopLayout ? 'large' : isTabletLayout ? 'medium' : 'small';

  const subHeadingSmsText: string = translate('registration.setupSecurity.subheadingSms', {
    mobileNumber: `${extractDialingCodeValue(countryCode) + ' ' + formattedMobileNumber}`
  });
  const subHeadingCallText: string = translate('registration.setupSecurity.subheadingCall', {
    mobileNumber: `${extractDialingCodeValue(countryCode) + ' ' + formattedMobileNumber}`
  });
  const buttonText: string = translate('registration.setupSecurity.submitButtonText');
  const backLinkText: string = translate('registration.links.backToStart');
  const troubleReceivingOTPText: string = translate('registration.setupSecurity.troubleReceivingOTPText');
  const requestBySMSText: string = translate('registration.setupSecurity.requestBySMSText');
  const issueWithSMSText: string = translate('registration.setupSecurity.issueWithSMSText');
  const requestByCallText: string = translate('registration.setupSecurity.requestByCallText');
  const issueWithCallText: string = translate('registration.setupSecurity.issueWithCallText');
  const receiveSMSButtonText: string = translate('registration.setupSecurity.receiveSMSButtonText');
  const resendSMSButtonText: string = translate('registration.setupSecurity.resendSMSButtonText');
  const receiveCallButtonText: string = translate('registration.setupSecurity.receiveCallButtonText');

  const {
    eventAction: { click, load },
    enterSecurityCode: {
      click: { securityCodeSubmit, securityCodeBackButton, recieveNewCode, recieveACall },
      load: { enterSecurityCodePageLoad }
    }
  } = GoogleAnalyticsTagsMapping;

  const {
    setupSecurity: {
      securityPinScreen: {
        securityPin: { name: securityPinFieldName },
        SMSOrCallSection: {
          receiveCallButton: { name: receiveCallButtonName, dataTestId: receiveCallButtonDataTestId },
          receiveSMSButton: { name: receiveSMSButtonName, dataTestId: receiveSMSButtonDataTestId },
          resendSMSButton: { name: resendSMSButtonName, dataTestId: resendSMSButtonDataTestId }
        },
        continueButton: { name: continueButtonName, dataTestId: continueButtonDataTestId },
        backButton: { name: backButtonName, dataTestId: backButtonDataTestId },
        troubleReceivingCode: { dataTestId: troubleReceivingCodeLinkDataTestId }
      }
    }
  } = REGISTRATION_DEFINITIONS;

  const [otpRequestMode, setOTPRequestMode] = useState<OTPRequestType>(RequestOTPTypes.sms);

  const isOTPRequestModeCall = otpRequestMode === RequestOTPTypes.call;

  // Scrolls to the top of the content area
  useScrollToContentTop();

  useEffect(() => {
    if (isValidOTP) {
      if (!updateLeadStatus && updateLeadLoading === 'idle') {
        const payloadData = {
          leadId,
          firstName,
          lastName,
          mobileNumber,
          countryCode: extractDialingCodeValue(countryCode)
        };
        dispatch(updateLead(payloadData));
      }

      if (updateLeadLoading === 'succeeded' && updateLeadStatus && verifyETVLoading === 'idle') {
        const payloadData: etvPayloadData = {
          countryCode: extractDialingCodeValue(countryCode),
          mobileNumber,
          email,
          corIsUSA,
          brand
        };

        dispatch(verifyEtvExist(payloadData));
      }

      if (verifyETVLoading === 'succeeded') {
        dispatch(
          updateCurrentStep({
            stepType: nextStepType
          })
        );

        navigate(STEPS_ROUTE_MAPPING[nextStepType]);
      }
    }
  }, [isValidOTP, updateLeadStatus, updateLeadLoading, verifyETVLoading]);

  useEffectOnce(() => {
    TriggerGoogleAnalyticsTag(load, enterSecurityCodePageLoad);
  });

  const onSubmit: SubmitHandler<SecurityPinType> = (data) => {
    TriggerGoogleAnalyticsTag(click, securityCodeSubmit);

    dispatch(addSecurityPin(data.securityPin.join('')));

    const otpPayloadData = {
      countryCode: extractDialingCodeValue(countryCode),
      mobileNumber,
      securityPin: data.securityPin.join(''),
      locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
    };
    dispatch(checkOTP(otpPayloadData));
  };

  const navigateToBackPage = () => {
    TriggerGoogleAnalyticsTag(click, securityCodeBackButton);

    setViewType('mobile-number-input');
  };

  const handleRequestOTP = (type: OTPRequestType) => {
    requestOTP(type); // dispatch action
    otpRequestMode !== type && setOTPRequestMode(type); // set req mode state
    setShowSendOTPOptions(false); // hide otp req options
    scrollToContentTop(ref);
  };

  const requestOTP = (type: OTPRequestType) => {
    type === RequestOTPTypes.sms
      ? TriggerGoogleAnalyticsTag(click, recieveNewCode)
      : TriggerGoogleAnalyticsTag(click, recieveACall);

    const payloadData = {
      countryCode: extractDialingCodeValue(countryCode),
      mobileNumber,
      type: type,
      locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
    };
    dispatch(generateOTP(payloadData));
  };

  const handlePinChange = () => {
    if (otpValidationError !== '' && !isValidOTP) {
      dispatch(resetOtpValidation(''));
    }
  };

  const handleShowSendOTPOptions = (event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setShowSendOTPOptions(true);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="rp-form-security-pin-screen">
      <StyledHeading
        variant={headingVariant}
        type="bold"
        text={isOTPRequestModeCall ? subHeadingCallText : subHeadingSmsText}
        layout={layout}
        className={MASK_CLASS}
      />
      {isMobileLayout && <RPLinearProgressBar value={currentStepProgress} marginBottom="30px" />}
      <ControllerElementWrapper
        name={securityPinFieldName}
        control={control}
        defaultValue={['', '', '', '', '']}
        component={RPPinField}
        length={5}
        size={pinFieldSize}
        handleChange={handlePinChange}
      />
      <StyledCodeLinkWrapper layout={layout}>
        <RPLink
          underline="hover"
          type="normal"
          text={troubleReceivingOTPText}
          onClick={(e: MouseEvent<HTMLAnchorElement>) => handleShowSendOTPOptions(e)}
          onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => e.key === ENTER_KEY && handleShowSendOTPOptions(e)}
          href=""
          data-testid={troubleReceivingCodeLinkDataTestId}
        />
      </StyledCodeLinkWrapper>

      <Collapse easing="300" in={showSendOTPOptions} data-testid="rp-security-pin-screen-collapsible-section">
        {(Object.keys(RequestOTPTypes) as Array<keyof typeof RequestOTPTypes>).map((requestType) => {
          const isRequestTypeCall: boolean = requestType === 'call';

          let description: string = '';
          let btnText: string = '';
          let buttonName: string = '';
          let btnDataTestId: string = '';

          if (isRequestTypeCall) {
            description = isOTPRequestModeCall ? requestByCallText : issueWithSMSText;
            btnText = receiveCallButtonText;

            buttonName = receiveCallButtonName;
            btnDataTestId = receiveCallButtonDataTestId;
          } else {
            if (isOTPRequestModeCall) {
              description = issueWithCallText;
              btnText = receiveSMSButtonText;

              buttonName = receiveSMSButtonName;
              btnDataTestId = receiveSMSButtonDataTestId;
            } else {
              description = requestBySMSText;
              btnText = resendSMSButtonText;

              buttonName = resendSMSButtonName;
              btnDataTestId = resendSMSButtonDataTestId;
            }
          }

          return (
            <SMSOrCallSectionContainer layout={layout} type={requestType as OTPRequestType}>
              <RequestOTPBySMSOrCall
                description={description}
                handleButtonClick={() =>
                  handleRequestOTP(isRequestTypeCall ? RequestOTPTypes.call : RequestOTPTypes.sms)
                }
                buttonText={btnText}
                buttonName={buttonName}
                dataTestId={btnDataTestId}
              />
            </SMSOrCallSectionContainer>
          );
        })}
      </Collapse>

      <RPButtonContainer layout={layout}>
        <RPButton
          size={buttonSize}
          type="submit"
          disabled={!isValid || isLoadingState || (!isValidOTP && otpValidationError !== '')}
          endIconType={isLoadingState ? 'loader' : ''}
          name={continueButtonName}
          data-testid={continueButtonDataTestId}
        >
          {buttonText}
        </RPButton>

        <RPButton
          size={buttonSize}
          btnType="text"
          onClick={navigateToBackPage}
          name={backButtonName}
          data-testid={backButtonDataTestId}
        >
          {backLinkText}
        </RPButton>
      </RPButtonContainer>
    </form>
  );
};

export default SecurityPinScreen;

import { FC, memo, SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { FilledTextFieldProps } from '@mui/material/TextField';
import Popper, { PopperProps } from '@mui/material/Popper';
import InputAdornment, { InputAdornmentProps } from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { AutocompleteValue } from '@mui/material';

import RPSelectItem from './RPSelectItem';
import {
  getBody2NormalStyles,
  getBodyExtraSmallNormalStyles
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { OptionType, SelectedOptionType } from '../../../core/types/SelectTypes';
import { getCurrencyOptions } from '../../../core/utils/CurrencyData';
import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { useSelector } from 'react-redux';
import { getReferenceData } from '../../../redux/modules/referenceDataSlice';
import { getCountryOptionsFromAPI } from '../../../core/utils/CountryData/GetCountryData';
import RPValidationErrorMessage from '../RPValidationErrorMessage/RPValidationErrorMessage';

export interface SizeVariants {
  variant: 'normal' | 'small' | 'x-small';
}

interface IsExtendedSelectPopper {
  isExtendedPopper?: boolean;
}

interface ExtendedSelectPopperProps {
  popperWidth?: number;
}

interface StyledTextFieldProps extends FilledTextFieldProps, IsExtendedSelectPopper {
  open: boolean;
  toShrinkLabel?: boolean;
  variantSize: SizeVariants['variant'];
}

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== 'open' && prop !== 'toShrinkLabel' && prop !== 'variantSize' && prop !== 'isExtendedPopper'
})<StyledTextFieldProps>(({ theme, open, toShrinkLabel, disabled, variantSize, isExtendedPopper, error }) => {
  const {
    colours: { backgrounds, borders, text }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;
  const COLOUR_BORDER_BORDER_INPUT: HexColour = borders.borderInput;
  const COLOUR_BORDER_BORDER_SELECTION_HOVER: HexColour = borders.borderSelectionHover;
  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borders.borderInputActive;
  const COLOUR_TEXT_TEXT_ERROR: HexColour = text.textError;
  const COLOUR_TEXT_TEXT_INPUT_LABEL: HexColour = text.textInputLabel;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: HexColour = text.textInputDisabled;
  const COLOUR_BG_BACKGROUND_INPUT_DISABLED: HexColour = backgrounds.backgroundInputDisabled;
  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;
  const COLOUR_BORDER_BORDER_ERROR: HexColour = borders.borderError;

  const isSmallSize: boolean = variantSize === 'small';
  const isXSmallSize: boolean = variantSize === 'x-small';
  const borderColor: string | HexColour = error
    ? COLOUR_TEXT_TEXT_ERROR
    : open
    ? COLOUR_BORDER_BORDER_INPUT_ACTIVE
    : disabled
    ? COLOUR_BORDER_BORDER_INPUT
    : 'transparent';

  let currentHeight: number = 60;
  currentHeight = isSmallSize ? 52 : isXSmallSize ? 40 : currentHeight;

  return {
    // Form control
    height: currentHeight,
    boxSizing: 'border-box',
    border: '2px solid transparent',
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    ...(!disabled && {
      boxShadow: `${COLOUR_BORDER_BORDER_INPUT} 0px 0px 0px 1px`
    }),
    borderColor,
    borderRadius: 4,
    transition: 'all 0.3s ease-in-out 0s',

    ...(!open &&
      !disabled && {
        '&:hover': {
          borderColor: error ? COLOUR_BORDER_BORDER_ERROR : COLOUR_BORDER_BORDER_SELECTION_HOVER
        }
      }),

    ...(open && {
      '&': {
        borderColor: error ? COLOUR_BORDER_BORDER_ERROR : COLOUR_BORDER_BORDER_INPUT_ACTIVE,
        boxShadow: 'none',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        ...(isExtendedPopper
          ? {
              borderBottomColor: 'transparent',
              zIndex: 1301,
              background: 'linear-gradient(to left, #0B5FFF 0%, #FFF 0.1%, #FFF 50%, #FFF 100%)'
            }
          : {
              borderBottom: 'none'
            })
      }
    }),

    // Input
    '& .MuiFilledInput-root': {
      height: currentHeight,
      backgroundColor: COLOUR_BG_BACKGROUND_ALT,
      padding: isSmallSize || isXSmallSize ? '0 0 0 14px !important' : '0 0 0 20px !important',

      '& .MuiAutocomplete-endAdornment': {
        right: '0 !important',
        marginRight: isSmallSize || isXSmallSize ? 10 : 20,
        svg: {
          color: error ? COLOUR_TEXT_TEXT_ERROR : COLOUR_TEXT_TEXT_LINK,
          '> path': {
            fill: 'currentColor'
          }
        }
      },

      ':before, :after': {
        borderBottom: 'none',
        transition: 'none'
      },

      '&.Mui-focused': {
        backgroundColor: COLOUR_BG_BACKGROUND_ALT
      },

      '&:hover': {
        backgroundColor: COLOUR_BG_BACKGROUND_ALT,
        '&:not(.Mui-disabled)': {
          '&:before': {
            borderBottom: 'none'
          }
        }
      },

      '&.Mui-disabled': {
        backgroundColor: COLOUR_BG_BACKGROUND_INPUT_DISABLED,
        color: 'transparent',
        '& .MuiAutocomplete-endAdornment': {
          svg: {
            color: COLOUR_TEXT_TEXT_INPUT_DISABLED,
            '> path': {
              fill: 'currentColor'
            }
          }
        }
      },

      '& .MuiAutocomplete-clearIndicator': {
        display: 'none'
      },

      '& .MuiButtonBase-root.MuiIconButton-root:hover': {
        backgroundColor: 'transparent'
      },

      '> input.MuiFilledInput-input.MuiInputBase-input': {
        ...getBody2NormalStyles(theme),
        ...(!toShrinkLabel
          ? {
              padding: `${isSmallSize ? '12px' : isXSmallSize ? '0px' : '16px'} 0px`
            }
          : {
              padding: `
                      ${isSmallSize ? '20px' : isXSmallSize ? '0px' : '25px'} 
                      0 
                      ${isSmallSize ? '5px' : isXSmallSize ? '0px' : '8px'}
                      0
                      `
            }),

        ':disabled': {
          color: COLOUR_TEXT_TEXT_INPUT_DISABLED
        }
      },

      '& .MuiInputAdornment-root .MuiTouchRipple-root': {
        display: 'none'
      }
    },

    // label
    '> label': {
      ...getBody2NormalStyles(theme),
      color: COLOUR_TEXT_TEXT_INPUT_LABEL,
      paddingLeft: isSmallSize || isXSmallSize ? '6px' : '10px',
      ...(isXSmallSize && { top: '-8px' }),
      transform: isSmallSize || isXSmallSize ? 'translate(12px, 14px) scale(1)' : 'translate(12px, 18px) scale(1)',

      '&.MuiInputLabel-shrink': {
        ...getBodyExtraSmallNormalStyles(theme),
        color: COLOUR_TEXT_TEXT_INPUT_LABEL
      },

      ...(toShrinkLabel
        ? {
            '&.MuiFormLabel-filled': {
              transform: 'translate(12px, 7px) scale(0.75)'
            }
          }
        : {
            '&.MuiFormLabel-filled': {
              display: 'none'
            }
          }),

      ...(toShrinkLabel
        ? {
            '&.Mui-focused': {
              color: COLOUR_TEXT_TEXT_INPUT_LABEL,
              paddingLeft: isSmallSize || isXSmallSize ? '6px' : '10px',
              transform: 'translate(12px, 7px) scale(0.75)'
            }
          }
        : {
            '&.Mui-focused': {
              display: 'none'
            }
          }),

      '&.Mui-error': {
        color: COLOUR_TEXT_TEXT_ERROR
      },

      '&.Mui-disabled': {
        color: COLOUR_TEXT_TEXT_INPUT_DISABLED
      }
    }
  };
});

interface StyledAutocompleteProps extends AutocompleteProps<any, any, any, any> {
  variant?: SizeVariants['variant'];
}

const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: (prop) =>
    prop !== 'placeholderLabel' &&
    prop !== 'toShrinkLabel' &&
    prop !== 'selectedValue' &&
    prop !== 'variant' &&
    prop !== 'popperWidth' &&
    prop !== 'isExtendedPopper'
})<StyledAutocompleteProps>(({ theme, variant }) => {
  const currentHeight: string = variant === 'small' ? '52px' : variant === 'x-small' ? '40px' : '60px'; // default applied for medium size

  return {
    height: currentHeight
  };
});

const StyledListBox = styled('ul')(({ theme }) => {
  const {
    colours: { backgrounds, text }
  } = theme;

  //TODO - Colour to be taken from theme object
  const COLOUR_SCROLL_THUMB_BACKGROUND: HexColour = '#C6C6C6';
  const COLOUR_SCROLL_TRACK_BACKGROUND: HexColour = '#F4F4F4';
  const COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK: HexColour = backgrounds.backgroundAltInverseWeak;
  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;
  const COLOUR_TEXT_TEXT_INVERSE: HexColour = text.textInverse;

  return {
    padding: '4px 16px 16px 16px !important',
    maxHeight: '120px !important',
    '&::-webkit-scrollbar': {
      width: 10,
      height: 6
    },
    '&::-webkit-scrollbar-thumb': {
      background: COLOUR_SCROLL_THUMB_BACKGROUND,
      borderRadius: 8,
      zIndex: 20,
      height: 30
    },
    '&::-webkit-scrollbar-track': {
      background: COLOUR_SCROLL_TRACK_BACKGROUND,
      width: 24
    },
    '& li.MuiAutocomplete-option': {
      justifyContent: 'space-between',
      height: 40,
      padding: 8
    },

    '& li.Mui-focused': {
      backgroundColor: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK
    },

    '& li.MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: COLOUR_TEXT_TEXT_LINK,
      borderRadius: 4,
      '& .MuiTypography-root': {
        color: COLOUR_TEXT_TEXT_INVERSE
      }
    },
    '& li.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
      backgroundColor: COLOUR_TEXT_TEXT_LINK,
      borderRadius: 4
    }
  };
});

const StyledInputAdornment = styled(InputAdornment)<InputAdornmentProps>(({ theme }) => {
  return {
    img: {
      width: 18,
      height: 18
    }
  };
});

interface StyledPopperProps extends ExtendedSelectPopperProps, IsExtendedSelectPopper {
  error?: boolean;
}

const StyledPopper = styled(Popper, {
  shouldForwardProp: (prop) => prop !== 'popperWidth' && prop !== 'isExtendedPopper' && prop !== 'error'
})<StyledPopperProps>(({ theme, popperWidth, isExtendedPopper, error }) => {
  const {
    colours: { backgrounds, borders, text }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borders.borderInputActive;
  const COLOUR_TEXT_TEXT_ERROR: HexColour = text.textError;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;

  return {
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    border: `2px solid ${error ? COLOUR_TEXT_TEXT_ERROR : COLOUR_BORDER_BORDER_INPUT_ACTIVE}`,
    ...(isExtendedPopper
      ? {
          ...(popperWidth && {
            width: `${popperWidth}px !important`
          }),
          borderRadius: '0 4px 4px 4px',
          inset: '0 auto auto -2px !important'
        }
      : {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderTop: 'none'
        })
  };
});

interface BaseRPSelectProps extends ExtendedSelectPopperProps, IsExtendedSelectPopper {
  name: string;
  placeholderLabel: string;
  variant?: SizeVariants['variant'];
  toShrinkLabel?: boolean;
  disabled?: boolean;
  value?: string;
  defaultValue?: string;
  error?: string;
  onChange?: Function;
}

interface RPSelectPropsWithType extends BaseRPSelectProps {
  type: 'country' | 'currency' | 'dialingCode';
}

interface RPSelectPropsWithOptions extends BaseRPSelectProps {
  options: readonly OptionType[] | [];
}

type RPSelectProps = RPSelectPropsWithType | RPSelectPropsWithOptions;

const RPSelect: FC<RPSelectProps> = (props: RPSelectProps) => {
  const { dialingCodeData, countryData } = useSelector(getReferenceData);
  const { tenant } = useContext(TenantContext);

  const ChevronDown = useMemo(() => getIcon(tenant, 'navigationChevronDownIcon'), [tenant]);

  const [open, setOpen] = useState(false);
  const [currentSelectedOption, setCurrentSelectedOption] = useState<SelectedOptionType | null>(null);

  const {
    name,
    placeholderLabel,
    toShrinkLabel = true,
    variant = 'normal',
    defaultValue,
    onChange,
    popperWidth,
    isExtendedPopper = false,
    error
  } = props;

  const typeProp = { ...('type' in props && { type: props.type }) };
  const options = useMemo(() => {
    return 'options' in props ? props.options : [];
  }, [props]);

  const CustomPopper = (popperProps: PopperProps) => {
    const placement: string = isExtendedPopper ? 'bottom-start' : 'bottom';

    return (
      <StyledPopper
        {...popperProps}
        placement={isExtendedPopper ? 'bottom-start' : 'bottom'}
        isExtendedPopper={isExtendedPopper}
        popperWidth={popperWidth}
        error={!!error}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'document',
              padding: 10,
              fallbackPlacements: [placement]
            }
          }
        ]}
      />
    );
  };

  const isXSmallSize: boolean = variant === 'x-small';
  const selectOptions = useMemo(() => {
    switch (typeProp.type) {
      case 'country':
        return getCountryOptionsFromAPI(countryData);
      case 'currency':
        return getCurrencyOptions();
      case 'dialingCode':
        return dialingCodeData;
      default:
        return options;
    }
  }, [typeProp.type, options, dialingCodeData]);

  useEffect(() => {
    console.log('dialingCodeData useeffect');
    if (selectOptions && defaultValue && defaultValue.length > 0) {
      const preSelectedOption = selectOptions.find((option: { value: string }) => option.value === defaultValue);
      if (preSelectedOption && preSelectedOption.iconPath) {
        import(`../../../core/CountryMetadata/${preSelectedOption.iconPath}.svg`)
          .then(({ default: icon }) => {
            const updatedOption = {
              ...preSelectedOption,
              icon
            };
            setCurrentSelectedOption(updatedOption);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setCurrentSelectedOption(preSelectedOption || null);
      }
    } else {
      setCurrentSelectedOption(null);
    }
  }, [dialingCodeData]);

  const onChangeHandler = (e: SyntheticEvent, newValueObj: AutocompleteValue<any, any, any, any>) => {
    onChange && onChange(newValueObj.value);
    if (newValueObj && newValueObj.iconPath) {
      import(`../../../core/CountryMetadata/${newValueObj.iconPath}.svg`)
        .then(({ default: icon }) => {
          const updatedOption = {
            ...newValueObj,
            icon
          };
          setCurrentSelectedOption(updatedOption);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setCurrentSelectedOption(newValueObj);
    }
  };

  return (
    <>
      <StyledAutocomplete
        // autoHighlight
        {...props}
        variant={variant}
        id={`rp-select-${name}`}
        open={open}
        value={currentSelectedOption}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={onChangeHandler}
        options={selectOptions}
        getOptionLabel={(option) => (typeProp.type === 'dialingCode' ? option.value : option.label)}
        isOptionEqualToValue={(option, currentSelectionOption) => option.value === currentSelectionOption?.value}
        popupIcon={<ChevronDown />}
        disableClearable={false}
        clearIcon={null}
        disableCloseOnSelect={false}
        blurOnSelect={false}
        componentsProps={{
          paper: {
            sx: {
              padding: '0px',
              border: 'none',
              boxShadow: 'none',
              ...(isXSmallSize && {
                '& ul': {
                  padding: '4px 0 4px 4px !important'
                }
              })
            }
          }
        }}
        PopperComponent={CustomPopper}
        ListboxComponent={StyledListBox}
        renderOption={(renderOptionProps, option) => {
          return (
            <RPSelectItem
              {...renderOptionProps}
              option={option}
              currentSelectedOption={currentSelectedOption}
              key={`${option.label}-${option.value}-${option.iconPath}`}
              variant={variant}
              {...typeProp}
            />
          );
        }}
        renderInput={(params) => {
          return (
            <StyledTextField
              {...params}
              open={open}
              toShrinkLabel={toShrinkLabel && !isXSmallSize}
              variant="filled"
              size="medium"
              label={placeholderLabel}
              error={!!error}
              variantSize={variant}
              isExtendedPopper={isExtendedPopper}
              hiddenLabel={(!toShrinkLabel || variant === 'x-small') && currentSelectedOption?.value.length !== 0}
              data-testid="rp-select-input"
              inputProps={{
                ...params.inputProps,
                autoComplete: `off` // disable autocomplete and autofill
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment:
                  currentSelectedOption && currentSelectedOption.icon ? (
                    <StyledInputAdornment position="start">
                      <img loading="lazy" src={currentSelectedOption.icon} alt={currentSelectedOption.value} />
                    </StyledInputAdornment>
                  ) : null
              }}
            />
          );
        }}
      />
      {!!error && <RPValidationErrorMessage message={error} />}
    </>
  );
};

export default memo(RPSelect);

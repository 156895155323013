import { FC, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';

import { RegistrationStep, StatusTypes, StepTypes } from '../../../core/types/StepProgressTrackerTypes';
import RPText from '../../atoms/RPText';
import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import RPLoader from '../../atoms/RPLoader';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface RPStepProgressTrackerProps {
  steps: RegistrationStep[];
  handleStepClick: (type: StepTypes) => void;
}

interface StatusProps {
  status: StatusTypes;
}

interface EditableProps {
  isEditable: boolean;
}

interface ItemContainerProps extends EditableProps {}

interface LabelProps extends StatusProps {}

interface LeftIconContainerProps extends StatusProps {}

const Container = styled('section')(() => {
  return {
    marginBottom: 16
  };
});

const Heading = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;

  return {
    marginBottom: 30,
    color: COLOUR_TEXT_TEXT_INVERSE
  };
});

const StyledNav = styled('nav')(() => {
  return {
    marginBottom: 16
  };
});

const Steps = styled('div')(({ theme }) => {
  const {
    colours: {
      text: { textInverseWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE_WEAK: HexColour = textInverseWeak;

  return {
    marginLeft: 13,

    '> div': {
      padding: '0 0 28px 38px',
      borderLeft: `3px solid ${COLOUR_TEXT_TEXT_INVERSE_WEAK}`,

      ':last-child': {
        marginBottom: 0,
        borderLeft: '3px solid transparent'
      }
    }
  };
});

const Step = styled('div')(() => {
  return {
    position: 'relative',
    display: 'flex'
  };
});

const ItemContainer = styled('div')<ItemContainerProps>(({ theme, isEditable }) => {
  const {
    colours: {
      text: { textInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;

  return {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: isEditable ? 'pointer' : 'default',

    ...(isEditable && {
      '&:hover': {
        p: {
          color: COLOUR_TEXT_TEXT_INVERSE
        },
        'svg path': {
          fill: COLOUR_TEXT_TEXT_INVERSE
        }
      }
    })
  };
});

const Label = styled(RPText, {
  shouldForwardProp: (prop) => prop !== 'status'
})<LabelProps>(({ theme, status }) => {
  const {
    colours: {
      text: { textInverse, textInverseWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;
  const COLOUR_TEXT_TEXT_INVERSE_WEAK: HexColour = textInverseWeak;

  const isStatusActive: boolean = status === 'active';

  const color: HexColour = isStatusActive ? COLOUR_TEXT_TEXT_INVERSE : COLOUR_TEXT_TEXT_INVERSE_WEAK;

  return {
    color: color
  };
});

const LeftIconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'status'
})<LeftIconContainerProps>(({ theme, status }) => {
  const {
    colours: {
      text: { textInverseWeak },
      backgrounds: { backgroundAlt, backgroundAltInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE_WEAK: HexColour = textInverseWeak;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  const COLOUR_BG_BACKGROUND_ALT_INVERSE: HexColour = backgroundAltInverse;

  const isStatusComplete: boolean = status === 'complete';
  const isStatusActive: boolean = status === 'active';

  return {
    position: 'absolute',
    left: -14.25,
    display: 'flex',

    '> svg': {
      fill: isStatusComplete ? COLOUR_TEXT_TEXT_INVERSE_WEAK : COLOUR_BG_BACKGROUND_ALT_INVERSE,
      '> circle': {
        ':nth-of-type(1)': {
          stroke: COLOUR_TEXT_TEXT_INVERSE_WEAK
        },
        ':nth-of-type(2).active': {
          display: isStatusActive ? 'block' : 'none',
          ...(isStatusActive && {
            fill: COLOUR_BG_BACKGROUND_ALT
          })
        }
      }
    }
  };
});

const RightIconContainer = styled('div')(() => {
  return {
    display: 'flex',
    marginLeft: 8,
    marginTop: 5
  };
});

const EditIconContainer = styled('span')(({ theme }) => {
  const {
    colours: {
      text: { textInverseWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE_WEAK: HexColour = textInverseWeak;

  return {
    display: 'flex',
    '> svg': {
      width: 18,
      height: 18,

      '> path': {
        fill: COLOUR_TEXT_TEXT_INVERSE_WEAK
      }
    }
  };
});

const RPStepProgressTracker: FC<RPStepProgressTrackerProps> = (props: RPStepProgressTrackerProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);

  const EditStepIcon = useMemo(() => getIcon(tenant, 'formattingEdit'), [tenant]);
  const ProgressStepIcon = useMemo(() => getIcon(tenant, 'progressStep'), [tenant]);

  const { steps, handleStepClick } = props;

  return (
    <Container>
      <Heading variant="h3" type="bold" text={translate('sidebar.stepProgressTracker.heading')} />
      <StyledNav>
        <Steps>
          {steps &&
            steps.map((step: RegistrationStep) => {
              const isStatusActive: boolean = step.status === 'active';
              const isStatusComplete: boolean = step.status === 'complete';
              const hasEditIcon: boolean = step.showEditIcon;
              const isLoading: boolean = step.isLoading;
              const isEditable: boolean = hasEditIcon && !isLoading && isStatusComplete;
              const showLoader: boolean = !hasEditIcon && isLoading && isStatusActive;

              return (
                <Step key={step.type}>
                  <ItemContainer
                    isEditable={isEditable}
                    onClick={() => isEditable && handleStepClick(step.type)}
                    data-testid="rp-step-progress-tracker-item-container"
                  >
                    <LeftIconContainer status={step.status}>
                      <ProgressStepIcon />
                    </LeftIconContainer>
                    <Label variant="body1" type="bold" text={step.label} status={step.status} />

                    <RightIconContainer>
                      {/* Show edit icon */}
                      {isEditable && (
                        <EditIconContainer>
                          <EditStepIcon data-testid="edit-icon" />
                        </EditIconContainer>
                      )}

                      {/* Show Loading icon */}
                      {showLoader && <RPLoader type="inverse" size="x-small" />}
                    </RightIconContainer>
                  </ItemContainer>
                </Step>
              );
            })}
        </Steps>
      </StyledNav>
    </Container>
  );
};

export default RPStepProgressTracker;

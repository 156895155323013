import { FC, useContext, useRef, KeyboardEvent, useEffect, useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffectOnce } from 'usehooks-ts';

// Contexts
import { LanguageContext, LayoutContext } from '../../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPInput from '../../../../atoms/RPInput';
import RPButton, { RPButtonContainer } from '../../../../atoms/RPButton';
import { RPSelectNew } from '../../../../atoms/RPSelect';
import RPText from '../../../../atoms/RPText';
import RPLoader from '../../../../atoms/RPLoader';
import RPLinearProgressBar from '../../../../atoms/RPLinearProgressBar';
import ControllerElementWrapper from '../../../../organisms/ControllerWrapper/ControllerWrapper';
// Validation Schema or files in the same folder
import { ValidationSchema } from './ValidationSchema';
// Hooks
import { useScrollToContentTop, useStepTypeData, useUpdateCountryCode } from '../../../../../core/hooks';
// Types
import { MobileNumberType } from '../../../../../core/types/RegistrationStepForm';
import { HexColour } from '../../../../../core/types/ThemeConfigTypes';
import { LayoutSize } from '../../../../../core/types/LayoutTypes';
// RTK Slice
import {
  addMobileNumber,
  getRegistrationDetails,
  resetLoadingValue
} from '../../../../../redux/modules/registrationDetailsSlice';
import { getReferenceData } from '../../../../../redux/modules/referenceDataSlice';
// API Wrappers
import getCountryData from '../../../../../redux/api/getCountryData';
import generateOTP from '../../../../../redux/api/generateOTP';
import getLocationLookup from '../../../../../redux/api/getLocationLookup';
//Utils
import { extractDialingCodeValue } from '../../../../../core/utils/DialingData';
import { isModifierKey, isNumericInput } from '../../../../../core/utils/KeyboardInputChecks';
import { getUSAFormattedNumber } from '../../../../../core/utils/GetUSAFormattedNumber';
import TranslateWrapper from '../../../../../core/utils/TranslateWrapper';
import { isMobileLayoutApplicable } from '../../../../../core/utils/IsMobileLayoutApplicable';
// Constants
import { STEPS_ROUTE_MAPPING, COUNTRY_CODE_USA, LOCALE_LANG_CODE_MAPPING, LANG_CODE_MAPPING } from '../../../../../core/utils/Constants/Constants';
import { REGISTRATION_DEFINITIONS } from '../../RegistrationDefinitions';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

interface MobileNumberScreenProps {
  setViewType: Function;
}

interface LayoutProps {
  layout: LayoutSize;
}

const StyledHeading = styled(RPText)<LayoutProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginBottom: isMobileLayout ? 15 : 20
  };
});

const StyledText = styled(RPText)<LayoutProps>(({ theme, layout }) => {
  const {
    colours: { text }
  } = theme;

  const isDesktopLayout: boolean = layout === 'desktop';

  const COLOUR_TEXT_TEXT_WEAK: HexColour = text.textWeak;

  return {
    marginBottom: isDesktopLayout ? 40 : 30,
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const MobileNumberWrapper = styled(Box)<LayoutProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: isDesktopLayout ? 'row' : 'column'
  };
});

const StyledCountryCodeDropDown = styled(Box)<LayoutProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    width: isDesktopLayout ? '155px' : '100%',
    marginRight: 15,
    ...(!isDesktopLayout && {
      marginBottom: 15
    })
  };
});

const StyledMobileNoInput = styled(Box)<LayoutProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';

  return {
    width: isDesktopLayout ? '250px' : 'auto'
  };
});

const MobileNumberScreen: FC<MobileNumberScreenProps> = (props: MobileNumberScreenProps) => {
  const [boxWidth, setBoxWidth] = useState(0);

  const dispatch: any = useDispatch();
  const { mobileNumber, countryCode, generateOtpSuccess, loading, mobileScreenError, isValidOTP, queryCOR } =
    useSelector(getRegistrationDetails);
  const { countryData, countryLoading, locationLookupLoading, languageForCountryData } =
    useSelector(getReferenceData);
  let dataFetchedRef = useRef(false);
  let locationLookupFetchedRef = useRef(false);

  const { layout } = useContext(LayoutContext);

  const { language, translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const boxRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setBoxWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    trigger,
    formState: { isValid }
  } = useForm<MobileNumberType>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(ValidationSchema()),
    defaultValues: {
      mobileNumber,
      countryCode
    }
  });

  // Custom hook to set Country Code to store from URL or IP
  useUpdateCountryCode(queryCOR, mobileNumber);

  const navigate = useNavigate();

  const { currentStepProgress } = useStepTypeData('setup-security');

  const { setViewType } = props;

  const { countryCode: countryCodeValue, mobileNumber: mobileNumberValue } = watch();

  const isCountryUSA: boolean = countryCodeValue === COUNTRY_CODE_USA;

  const headingText: string = translate('registration.setupSecurity.heading');
  const descText: string = translate('registration.setupSecurity.setupSecurityText');
  const buttonText: string = translate('registration.setupSecurity.confirmButtonText');
  const countryCodeText: string = translate('registration.setupSecurity.countryCode');
  const mobileNumberText: string = translate('registration.setupSecurity.mobileNumber');
  const mobileNumberUSAText: string = translate('registration.setupSecurity.mobileNumberUSA');
  const backLinkText: string = translate('registration.links.backToStart');

  const isDesktopLayout: boolean = layout === 'desktop';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const buttonSize: 'medium' | 'large' = isMobileLayout ? 'medium' : 'large';
  const headingVariant: 'h2' | 'h3' = isDesktopLayout ? 'h2' : 'h3';
  const textSize: 'medium' | 'normal' = isDesktopLayout ? 'medium' : 'normal';

  const {
    eventAction: { click, load },
    setupSecurityNumber: {
      click: { confirmMobileNumber, setupSecurityNumberBackButton },
      load: { setupSecurityPageLoad }
    }
  } = GoogleAnalyticsTagsMapping;

  const {
    setupSecurity: {
      mobileNumberScreen: {
        countryCode: {
          name: countryCodeFieldName
        },
        mobileNumber: {
          name: mobileNumberFieldName
        },
        continueButton: {
          name: continueButtonName,
          dataTestId: continueButtonDataTestId
        },
        backButton: {
          name: backButtonName,
          dataTestId: backButtonDataTestId
        }
      }
    }
  } = REGISTRATION_DEFINITIONS;

  useEffectOnce(() => {
    TriggerGoogleAnalyticsTag(load, setupSecurityPageLoad);
  });

  useEffect(() => {
    if (countryData.length > 0 && languageForCountryData === (language || LANG_CODE_MAPPING.EN)) {
      return;
    }
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    dispatch(getCountryData(
      {
        locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
      }
    ));
  }, [countryLoading]);

  // country code from location-lookup if queryCOR not available
  useEffect(() => {
    if (locationLookupFetchedRef.current) return;
    locationLookupFetchedRef.current = true;
    if (!queryCOR && mobileNumber === '') {
      dispatch(getLocationLookup());
    }
  }, [mobileNumber]);

  useEffect(() => {
    setValue('countryCode', countryCode);
  }, [countryCode]);

  useEffect(() => {
    // Check if country code is USA, then
    // format the number as (nnn) nnn - nnnn
    if (isCountryUSA) {
      setValue('mobileNumber', getUSAFormattedNumber(mobileNumberValue));
      trigger();
    }
  }, [isCountryUSA, mobileNumberValue, setValue, trigger]);

  // Scrolls to the top of the content area
  useScrollToContentTop();

  const onSubmit: SubmitHandler<MobileNumberType> = (data) => {
    TriggerGoogleAnalyticsTag(click, confirmMobileNumber);
    const mobileNumberVal: string =
      data.countryCode === COUNTRY_CODE_USA ? data.mobileNumber.replace(/\D/g, '') : data.mobileNumber;

    const addMobileNumPayload: MobileNumberType = {
      countryCode: data.countryCode,
      mobileNumber: parseInt(mobileNumberVal, 10) + ''
    };

    dispatch(addMobileNumber(addMobileNumPayload));
    const payloadData = {
      ...addMobileNumPayload,
      countryCode: extractDialingCodeValue(addMobileNumPayload.countryCode),
      type: 'sms',
      locale: LOCALE_LANG_CODE_MAPPING[language || LANG_CODE_MAPPING.EN]
    };
    dispatch(generateOTP(payloadData));
  };

  const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // Input must be of a valid number format or a modifier key and shift key should not be pressed
    if ((!isNumericInput(event.keyCode) && !isModifierKey(event)) || event.shiftKey === true) {
      event.preventDefault();
    }
  };

  const navigateToBackPage = () => {
    TriggerGoogleAnalyticsTag(click, setupSecurityNumberBackButton);

    navigate(STEPS_ROUTE_MAPPING['create-login']);
  };

  if (mobileNumber !== '' && isValidOTP) {
    setViewType('mobile-number-confirmation');
  }

  if (generateOtpSuccess && loading === 'succeeded' && mobileScreenError === '' && !isValidOTP) {
    dispatch(resetLoadingValue('idle'));
    setViewType('security-pin');
  }

  const handleNumberChange = () => {
    if (loading === 'failed' && mobileScreenError !== '') {
      dispatch(resetLoadingValue('idle'));
    }
  };

  return (
    <>
      {countryLoading === 'loading' || locationLookupLoading === 'loading' ? (
        <RPLoader size="medium" type="primary" />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} data-testid="rp-form-mobile-number-screen">
          <StyledHeading variant={headingVariant} type="bold" text={headingText} layout={layout} />
          {isMobileLayout && <RPLinearProgressBar value={currentStepProgress} />}
          <StyledText variant="body2" type={textSize} text={descText} layout={layout} />
          <MobileNumberWrapper ref={boxRef} layout={layout}>
            <StyledCountryCodeDropDown layout={layout}>
              <ControllerElementWrapper
                name={countryCodeFieldName}
                control={control}
                value={countryCode}
                placeholderLabel={countryCodeText}
                component={RPSelectNew}
                size="normal"
                type="dialingCode"
                toShrinkLabel={true}
                shouldUseCustomFilter={true}
                isExtendedPopper={isDesktopLayout}
                popperWidth={isDesktopLayout && boxWidth}
                handleChange={() => {
                  resetField('mobileNumber');
                  setValue('mobileNumber', '');
                }}
                showMaxOptions={!isMobileLayoutApplicable(layout)}
              />
            </StyledCountryCodeDropDown>
            <StyledMobileNoInput layout={layout}>
              <ControllerElementWrapper
                name={mobileNumberFieldName}
                control={control}
                defaultValue={mobileNumber}
                placeholderLabel={isCountryUSA ? mobileNumberUSAText : mobileNumberText}
                component={RPInput}
                size="normal"
                type="number"
                handleOnKeyDown={handleOnKeyDown}
                handleChange={handleNumberChange}
                {...(isCountryUSA && {
                  maxLength: 16
                })}
                isCoralogixMasked={true}
              />
            </StyledMobileNoInput>
          </MobileNumberWrapper>
          <RPButtonContainer layout={layout}>
            <RPButton
              size={buttonSize}
              type="submit"
              disabled={!isValid || loading === 'loading' || (loading === 'failed' && mobileScreenError !== '')}
              endIconType={loading === 'loading' ? 'loader' : ''}
              name={continueButtonName}
              data-testid={continueButtonDataTestId}
            >
              {buttonText}
            </RPButton>
            <RPButton
              size={buttonSize}
              btnType="text"
              onClick={navigateToBackPage}
              name={backButtonName}
              data-testid={backButtonDataTestId}
            >
              {backLinkText}
            </RPButton>
          </RPButtonContainer>
        </form>
      )}
    </>
  );
};

export default MobileNumberScreen;

import {
  BaseRegistrationStep,
  StepTypes
} from '../../types/StepProgressTrackerTypes';

const REGISTRATION_STEP_TYPES: StepTypes[] = [
  'create-login',
  'setup-security',
  'enter-details',
  'set-preferences',
  'confirming-you'
];

/*const steps = [
  {
    type: 'create-login',
    status: 'active',
    showEditIcon: true,
    isLoading: false,
  },
  {
    type: 'setup-security',
    status: 'incomplete',
    showEditIcon: false,
    isLoading: false,
  },
  {
    type: 'enter-details',
    status: 'incomplete',
    showEditIcon: false,
    isLoading: false,
  },
  {
    type: 'set-preferences',
    status: 'incomplete',
    showEditIcon: false,
    isLoading: false,
  },
  {
    type: 'confirming-you',
    status: 'incomplete',
    showEditIcon: false,
    isLoading: false,
  },
];*/

export const generateStepObject = (stepType: StepTypes): BaseRegistrationStep => ({
  type: stepType,
  status: stepType === 'create-login' ? 'active' : 'incomplete',
  showEditIcon: (stepType === 'create-login'),
  isLoading: false,
});

const GenerateDefaultRegistrationSteps = () => (REGISTRATION_STEP_TYPES.map(stepType => generateStepObject(stepType)));

export default GenerateDefaultRegistrationSteps;
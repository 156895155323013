import { FC, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import { RPText } from '../../atoms/RPText';
// Types
import { CountryDataInfo } from '../../../core/types/CountryDataTypes';
// RTK Slice
import { getReferenceData } from '../../../redux/modules/referenceDataSlice';
// Utils
import { getCountryOptionByCode } from '../../../core/utils/CountryData';

const StyledOptionContainer = styled('div')(() => {
  return {
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: '5px',
    img: {
      width: 22,
      height: 22
    }
  };
});

const StyledRPText = styled(RPText)(() => {
  return {
    marginLeft: '10px'
  };
});

interface RPSelectionSummaryCountryDetailsProps {
  countryCode: string;
}

const RPSelectionSummaryCountryDetails: FC<RPSelectionSummaryCountryDetailsProps> = ({
  countryCode
}: RPSelectionSummaryCountryDetailsProps) => {
  const { countryData } = useSelector(getReferenceData);

  const option: CountryDataInfo | undefined = getCountryOptionByCode(countryData, countryCode);

  const [icon, setIcon] = useState('');

  useEffect(() => {
    if (option && option.iconPath) {
      import(`../../../core/CountryMetadata/${option.iconPath}.svg`)
        .then(({ default: currentIcon }) => {
          setIcon(currentIcon);
        })
        .catch((err) => {
          console.log('err: ', err);
        });
    }
  }, [option]);

  return (
    <>
      {option && (
        <StyledOptionContainer>
          {option.iconPath && icon && <img loading="lazy" alt={option?.value} src={icon} />}
          {option.label && <StyledRPText text={`${option.label}`} variant="body2" type="normal" />}
        </StyledOptionContainer>
      )}
    </>
  );
};

export default memo(RPSelectionSummaryCountryDetails);

import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper from './axiosWrapper';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';

export type GenerateSDKTokenData = {
  firstName: string;
  lastName: string;
  email: string;
  parentOrganizationCode: string;
  crmAccountID: string;
  crmContactID: string;
  countryOfResidence: string;
};

const generateSDKToken = createAsyncThunk(
  'v3.6/sdk-token/create',
  async (generateSDKTokenData: GenerateSDKTokenData, thunkAPI) => {
    const { firstName, lastName, email, parentOrganizationCode, crmAccountID, crmContactID, countryOfResidence } =
      generateSDKTokenData;

    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      org_code: parentOrganizationCode,
      salesforce_id: crmAccountID,
      salesforce_contact_id: crmContactID,
      referrer: '*',
      country_code: countryOfResidence
    };

    try {
      const response = await axiosWrapper('generateSDKToken', 'POST', thunkAPI, payload);
      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  }
);

export default generateSDKToken;

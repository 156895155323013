import { FC, memo, ReactElement, useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import RPText from '../../atoms/RPText';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

export interface SkipDocsSizes {
  size: 'large' | 'medium' | 'small';
}

interface SkipDocsContainerProps extends SkipDocsSizes {}
interface StyledHeadingProps extends SkipDocsSizes {}
interface StyledSubHeadingWrapperProps extends SkipDocsSizes {}
interface SkipDocsProps extends SkipDocsSizes {}

const SkipDocsContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size'
})<SkipDocsContainerProps>(({ theme, size }) => {
  const {
    colours: {
      text: { textInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;
  const isSmallSize: boolean = size === 'small';

  return {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    color: COLOUR_TEXT_TEXT_INVERSE,
    padding: isSmallSize ? '0 15px' : '0px',
    boxSizing: 'border-box',
    ...(isSmallSize && {
      '> svg': {
        width: '130px',
        height: '130px'
      }
    }),

    '& .MuiTypography-root': {
      color: COLOUR_TEXT_TEXT_INVERSE,
      textAlign: 'center'
    },

    '& .MuiButton-root': {
      width: '100%'
    }
  };
});

const StyledHeading = styled(RPText, {
  shouldForwardProp: (prop) => prop !== 'size'
})<StyledHeadingProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    marginTop: isSmallSize ? '20px' : '25px'
  };
});

const StyledSubHeadingWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<StyledSubHeadingWrapperProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    marginTop: isSmallSize ? '10px' : '25px'
  };
});

const SkipDocs: FC<SkipDocsProps> = (props: SkipDocsProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);

  const WelcomeEmailDarkIcon = useMemo(() => getIcon(tenant, 'welcomeEmailDark'), [tenant]);

  const headingText: string = translate('skipDocs.heading');
  const subHeadingLine1Text: string = translate('skipDocs.subHeadingLine1');
  const subHeadingLine2Text: string = translate('skipDocs.subHeadingLine2');

  const { size } = props;

  const isSmallSize: boolean = size === 'small';

  const headerVariant: 'h2' | 'h3' = size === 'large' ? 'h2' : 'h3';
  const bodyVariant: 'body1' | 'body2' = size === 'large' ? 'body1' : 'body2';
  const bodyType: 'bold' | 'normal' = size === 'small' ? 'normal' : 'bold';

  const subHeading: ReactElement = isSmallSize ? (
    <RPText variant={bodyVariant} type={bodyType} text={`${subHeadingLine1Text} ${subHeadingLine2Text}`} />
  ) : (
    <>
      <RPText variant={bodyVariant} type={bodyType} text={subHeadingLine1Text} />
      <RPText variant={bodyVariant} type={bodyType} text={subHeadingLine2Text} />
    </>
  );

  return (
    <SkipDocsContainer size={size}>
      <WelcomeEmailDarkIcon />
      <StyledHeading size={size} variant={headerVariant} type="bold" text={headingText} />
      <StyledSubHeadingWrapper size={size}>{subHeading}</StyledSubHeadingWrapper>
    </SkipDocsContainer>
  );
};

export default memo(SkipDocs);

import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper, { ObjectMapping } from './axiosWrapper';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';

type getProofOfAddressRequestData = {
  legalEntity: string;
  locale: string;
};

const getProofOfAddress = createAsyncThunk(
  '/reference-data/proof-of-address', async (data: getProofOfAddressRequestData, thunkAPI) => {

    const { locale, legalEntity } = data;
    const dataToReplace: ObjectMapping = {
      legalEntity,
      locale
    };

    try {
      const response = await axiosWrapper('getProofOfAddress', 'GET', thunkAPI, null, dataToReplace);

      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  });

export default getProofOfAddress;
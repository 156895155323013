import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPConfirmationModal } from '../../molecules/RPModals';
// RTK Slice
import { AppDispatch, removePersistedStoreData } from '../../../redux/store';
import { getLoginDetails, openLoginDialogModal } from '../../../redux/modules/loginDetailsSlice';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getLandingPageURL } from '../../../core/utils/GetOrganizationEnvSpecificURLs';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

const RPCloseConfirmationModal = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isLoginDialogShow } = useSelector(getLoginDetails);

  const { layout } = useContext(LayoutContext);
  const { translations } = useContext(LanguageContext);
  const { tenant } = useContext(TenantContext);
  const translate = TranslateWrapper(translations);

  const landingPageURL: string = getLandingPageURL(tenant);

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const modalHeadingText: string = translate('modal.closeConfirm.heading');
  const modalDescriptionText: string = translate('modal.closeConfirm.description');
  const modalPrimaryButtonText: string = translate('modal.closeConfirm.primaryBtnLabel');
  const modalSecondaryButtonText: string = translate('modal.closeConfirm.secondaryBtnLabel');

  const handleClose = () => {
    dispatch(openLoginDialogModal(false));
  };

  const handleConfirmation = () => {
    handleClose();
    removePersistedStoreData();
    window.open(landingPageURL, '_self');
  };

  return (
    <RPConfirmationModal
      isOpen={isLoginDialogShow}
      additionMessage={modalDescriptionText}
      handleClose={handleClose}
      handlePrimaryBtnClick={handleConfirmation}
      handleSecondaryBtnClick={handleClose}
      message={modalHeadingText}
      modalVariant="default"
      primaryBtnLabel={modalPrimaryButtonText}
      secondaryBtnLabel={modalSecondaryButtonText}
      size={isMobileLayout ? 'small' : 'normal'}
    />
  );
};

export default RPCloseConfirmationModal;

import { FC, memo, useContext, MouseEvent, KeyboardEvent } from 'react';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import RPText from '../../atoms/RPText';
import RPSelectionSummaryCountryDetails from './RPSelectionSummaryCountryDetails';
import { AddressFieldTypes, AddressType } from '../../../core/types/addressLookupTypes';
import { LanguageContext } from '../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import RPLink from '../../atoms/RPLink';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { ENTER_KEY, MASK_CLASS } from '../../../core/utils/Constants/Constants';
import { CountryFieldMapping } from '../../../core/utils/AddressLookup/CountryFieldsMatrix';

interface RPSelectionSummaryProps {
  address: AddressType;
  setIsManualEntry: (entry: boolean) => void;
  setCountryChange: (value: boolean) => void;
  countryCode: string;
}

type CitySuburb = 'suburbCity' | 'townCity';
type ProvinceState = 'province' | 'state' | 'county';

const SelectionSummary = styled(Box)<BoxProps>(({ theme }) => {
  const {
    colours: {
      backgrounds: { background }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND: HexColour = background;

  return {
    padding: '25px',
    background: COLOUR_BG_BACKGROUND,
    borderRadius: '4px',
    lineHeight: '24px'
  };
});

const SelectionSummaryTitle = styled(RPText)(() => {
  return {
    marginBottom: '10px'
  };
});

const SelectionSummaryAddress = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const SelectionSummaryEditAddress = styled(RPLink)(({ theme }) => {
  const {
    colours: {
      borders: { borderInputActive }
    }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borderInputActive;

  return {
    color: COLOUR_BORDER_BORDER_INPUT_ACTIVE,
    marginTop: '10px',
    display: 'inline-block'
  };
});

const extractAddressFields = (
  keysArray: CitySuburb[] | ProvinceState[],
  addressFields: AddressFieldTypes,
  address: AddressType
) => {
  const combinedArray: string[] = [];
  keysArray.forEach((key: CitySuburb | ProvinceState) => {
    addressFields[key].visible && address[key] !== '' && combinedArray.push(address[key]);
  });
  return combinedArray;
};

const RPSelectionSummary: FC<RPSelectionSummaryProps> = ({
  address,
  setIsManualEntry,
  setCountryChange,
  countryCode
}: RPSelectionSummaryProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const addressLabel: string = translate('registration.addressLookup.addressLabel');
  const editAddress: string = translate('registration.addressLookup.editAddress');

  const handleEditAddressClick = (event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setIsManualEntry(true);
    setCountryChange(false);
  };

  const addressFields: AddressFieldTypes = CountryFieldMapping[countryCode]
    ? CountryFieldMapping[countryCode]
    : CountryFieldMapping['default'];

  const citySuburbArray: string[] = extractAddressFields(['suburbCity', 'townCity'], addressFields, address);
  const provinceStateCountyArray: string[] = extractAddressFields(
    ['province', 'state', 'county'],
    addressFields,
    address
  );

  const getAddressSummary = () => {
    const addressData: string[] = [];
    addressData.push(
      address.addressLine1,
      address.addressLine2,
      address.addressLine3,
      citySuburbArray.join(', '),
      provinceStateCountyArray.join(', '),
      address.postcode
    );

    const addressSummary: JSX.Element[] = [];
    addressData.forEach((data: string) => {
      if (data && data.length) {
        addressSummary.push(<SelectionSummaryAddress variant="body2" type="normal" text={`${data},`} key={data} className={MASK_CLASS} />);
      }
    });
    return addressSummary;
  };

  return (
    <SelectionSummary>
      <SelectionSummaryTitle variant="body2" type="medium" text={addressLabel} />
      <>{getAddressSummary()}</>

      <RPSelectionSummaryCountryDetails countryCode={countryCode} />
      <div>
        <SelectionSummaryEditAddress
          underline="none"
          onClick={(e: MouseEvent<HTMLAnchorElement>) => handleEditAddressClick(e)}
          onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
            e.key === ENTER_KEY && handleEditAddressClick(e);
          }}
          href=""
          type="medium"
          text={editAddress}
          data-testid="rp-link-edit-address"
        />
      </div>
    </SelectionSummary>
  );
};

export default memo(RPSelectionSummary);

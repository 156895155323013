import {RPButton} from "../../atoms/RPButton";
import React from "react";

const RPButtonVariants = () => {
  return (
    <>
      <hr/>
      <br/>
      <RPButton size='large' startIconType='start'>Continue</RPButton>
      <RPButton size='large' disabled>Continue (Disabled)</RPButton>
      <hr/>
      <br/>
      <RPButton size='medium'>Continue - medium</RPButton>
      <RPButton size='medium' disabled>Continue - medium (Disabled)</RPButton>
      <hr/>
      <br/>
      <RPButton size='small'>Continue - small</RPButton>
      <RPButton size='small' disabled>Continue - small (Disabled)</RPButton>
      <hr/>
      <br/>
      <RPButton size='large' btnType='secondary'>Continue</RPButton>
      <RPButton size='large' btnType='secondary' disabled>Continue (Disabled)</RPButton>
      <hr/>
      <br/>
      <RPButton size='medium' btnType='secondary'>Continue - medium</RPButton>
      <RPButton size='medium' btnType='secondary' disabled>Continue - medium (Disabled)</RPButton>
      <hr/>
      <br/>
      <RPButton size='small' btnType='secondary'>Continue - small</RPButton>
      <RPButton size='small' btnType='secondary' disabled>Continue - small (Disabled)</RPButton>
      <hr/>
      <br/>
      <RPButton size='large' btnType='outlined'>Continue</RPButton>
      <RPButton size='large' btnType='outlined' disabled>Continue (Disabled)</RPButton>
      <hr/>
      <br/>
      <RPButton size='medium' btnType='outlined'>Continue - medium</RPButton>
      <RPButton size='medium' btnType='outlined' disabled>Continue - medium (Disabled)</RPButton>
      <hr/>
      <br/>
      <RPButton size='small' btnType='outlined'>Continue - small</RPButton>
      <RPButton size='small' btnType='outlined' disabled>Continue - small (Disabled)</RPButton>
      <hr/>
      <br/>
      <RPButton size='large' btnType='text' startIconType='start'>Continue</RPButton>
      <RPButton size='large' btnType='text' startIconType='start' disabled>Continue (Disabled)</RPButton>
      <hr/>
      <br/>
      <RPButton size='medium' btnType='text' startIconType='start'>Continue - medium</RPButton>
      <RPButton size='medium' btnType='text' disabled>Continue - medium (Disabled)</RPButton>
      <hr/>
      <br/>
      <RPButton size='small' btnType='text' startIconType='start'>Continue - small</RPButton>
      <RPButton size='small' btnType='text' disabled>Continue - small (Disabled)</RPButton>
    </>
  );
};

export default RPButtonVariants;

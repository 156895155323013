import {
  FC,
  useState,
  MouseEvent,
  KeyboardEvent,
  SyntheticEvent,
  FocusEvent,
  useMemo,
  useEffect,
  useContext,
  useCallback
} from 'react';
import { useSelector } from 'react-redux';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { AutocompleteValue, FilterOptionsState } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';

// Contexts
import { LanguageContext, LayoutContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPSelectItem from './RPSelectItem';
import RPSelectInput from './RPSelectInput';
import FullScreenDialog from '../FullScreenDialog';
import FullScreenDialogAutocomplete from './FullScreenDialogAutocomplete';
import RPValidationErrorMessage from '../RPValidationErrorMessage/RPValidationErrorMessage';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { OptionType, SelectedOptionType } from '../../../core/types/SelectTypes';
// RTK Slice
import { getReferenceData } from '../../../redux/modules/referenceDataSlice';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getCurrencyOptions } from '../../../core/utils/CurrencyData';
import { isMobileOrTabletDevice } from '../../../core/utils/CheckMobileOrTabletDevice';
import { extractDialingCodeValue } from '../../../core/utils/DialingData/GetDialingData';
import { getCountryOptionsFromAPI } from '../../../core/utils/CountryData/GetCountryData';
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
// Constants
import { ENTER_KEY } from '../../../core/utils/Constants/Constants';

export interface SizeVariants {
  variant: 'normal' | 'small' | 'x-small';
}

export type TypeProps = 'country' | 'currency' | 'dialingCode' | 'state';

interface RPSelectPropsWithType extends BaseRPSelectProps {
  type: TypeProps;
}

interface RPSelectPropsWithOptions extends BaseRPSelectProps {
  options: readonly OptionType[] | [];
}

interface ExtendedSelectPopperProps {
  popperWidth?: number;
}

interface IsExtendedPopperProps {
  isExtendedPopper?: boolean;
}

export interface IsExtendedSelectPopper extends IsExtendedPopperProps {
  isFixedHeight?: boolean;
}

interface IsSearchableProps {
  isSearchable?: boolean;
}

interface ErrorProps {
  error?: string;
}

interface BaseRPSelectProps extends ExtendedSelectPopperProps, IsExtendedSelectPopper, IsSearchableProps, ErrorProps {
  name: string;
  placeholderLabel: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  variant?: SizeVariants['variant'];
  toShrinkLabel?: boolean;
  disabled?: boolean;
  value?: string;
  defaultValue?: string;
  onChange?: Function;
  shouldUseCustomFilter?: boolean;
  showMaxOptions?: boolean;
  isMobileOptimised?: boolean;
}

interface StyledAutocompleteProps
  extends AutocompleteProps<any, any, any, any>,
    IsSearchableProps,
    IsExtendedPopperProps {
  variant?: SizeVariants['variant'];
}

interface StyledPopperProps extends IsSearchableProps, IsExtendedPopperProps {}

interface MainPopperProps extends ExtendedSelectPopperProps, IsExtendedSelectPopper, IsSearchableProps, ErrorProps {
  width: number;
}

interface StyledInputProps extends IsSearchableProps, IsExtendedSelectPopper {}

interface PopperComponentProps extends IsSearchableProps, IsExtendedSelectPopper {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

type RPSelectNewProps = RPSelectPropsWithType | RPSelectPropsWithOptions;

const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'isSearchable' && prop !== 'isExtendedPopper'
})<StyledAutocompleteProps>(({ isExtendedPopper }) => {
  return {
    width: '100%',
    ...(isExtendedPopper && {
      borderTopRightRadius: 'inherit'
    })
  };
});

const StyledPopper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSearchable' && prop !== 'isExtendedPopper'
})<StyledPopperProps>(({ theme, isSearchable, isExtendedPopper }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  return {
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    ...(!isSearchable && {
      marginTop: '-21px'
    }),
    borderTop: 'none',
    borderBottomRightRadius: 'inherit',
    borderBottomLeftRadius: 'inherit',
    ...(!isExtendedPopper && {
      width: 'inherit !important'
    })
  };
});

interface StyledListBoxProps {
  isFixedHeight: boolean;
  showMaxOptions: boolean;
}

const StyledListBox = styled('ul', {
  shouldForwardProp: (prop) => prop !== 'isFixedHeight' && prop !== 'showMaxOptions'
})<StyledListBoxProps>(({ theme, isFixedHeight, showMaxOptions }) => {
  const {
    colours: {
      backgrounds: { backgroundAltInverseWeak },
      text: { textLink, textInverse },
      scroll: { thumbBackground, trackBackground }
    }
  } = theme;

  const COLOUR_SCROLL_THUMB_BACKGROUND: HexColour = thumbBackground;
  const COLOUR_SCROLL_TRACK_BACKGROUND: HexColour = trackBackground;
  const COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK: HexColour = backgroundAltInverseWeak;
  const COLOUR_TEXT_TEXT_LINK: HexColour = textLink;
  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;
  const maxHeight: string = isFixedHeight
    ? 'none !important'
    : showMaxOptions
    ? '190px !important'
    : '120px !important';

  return {
    padding: '4px 16px 16px 16px !important',
    maxHeight: maxHeight,
    overflow: isFixedHeight ? 'hidden !important' : 'auto',
    '&::-webkit-scrollbar': {
      width: 10,
      height: 6
    },
    '&::-webkit-scrollbar-thumb': {
      background: COLOUR_SCROLL_THUMB_BACKGROUND,
      borderRadius: 8,
      zIndex: 20,
      height: 30
    },
    '&::-webkit-scrollbar-track': {
      background: COLOUR_SCROLL_TRACK_BACKGROUND,
      width: 24
    },
    '& li.MuiAutocomplete-option': {
      justifyContent: 'space-between',
      minHeight: 40,
      height: 'auto',
      padding: 8
    },

    '& li.Mui-focused': {
      backgroundColor: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK
    },

    '& li.MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: COLOUR_TEXT_TEXT_LINK,
      borderRadius: 4,
      '& .MuiTypography-root': {
        color: COLOUR_TEXT_TEXT_INVERSE
      }
    },
    '& li.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
      backgroundColor: COLOUR_TEXT_TEXT_LINK,
      borderRadius: 4
    }
  };
});

const DropdownContainer = styled('div')(() => {
  return {
    position: 'relative',
    width: '100%'
  };
});

const MainPopper = styled(Popper, {
  shouldForwardProp: (prop) =>
    prop !== 'popperWidth' &&
    prop !== 'isExtendedPopper' &&
    prop !== 'width' &&
    prop !== 'isSearchable' &&
    prop !== 'error'
})<MainPopperProps>(({ theme, isExtendedPopper, popperWidth, width, error }) => {
  const {
    colours: {
      borders: { borderInputActive, borderError }
    }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borderInputActive;
  const COLOUR_BORDER_BORDER_ERROR: HexColour = borderError;

  return {
    border: `2px solid ${error ? COLOUR_BORDER_BORDER_ERROR : COLOUR_BORDER_BORDER_INPUT_ACTIVE}`,
    zIndex: 100,
    boxSizing: 'border-box',
    ...(isExtendedPopper
      ? {
          ...(popperWidth && {
            width: `${popperWidth}px !important`
          }),
          borderRadius: '0 4px 4px 4px',
          inset: '-2px auto auto 0 !important'
        }
      : {
          width: 'inherit',
          borderRadius: '0 0 4px 4px',
          borderTop: 'none',
          marginTop: '-2px !important',
          zIndex: 1301
        })
  };
});

const StyledInput = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'isSearchable' && prop !== 'isExtendedPopper'
})<StyledInputProps>(({ theme, isSearchable, isExtendedPopper }) => {
  const {
    colours: {
      borders: { borderInput },
      backgrounds: { backgroundAlt },
      text: { textInputLabel }
    }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT: HexColour = borderInput;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  return {
    padding: isSearchable ? `${isExtendedPopper ? '15' : '1'}px 16px 15px` : 0,
    width: '100%',
    borderRadius: 'inherit',
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    '& input': {
      ...(!isSearchable && { display: 'none' }),
      ...getBody2NormalStyles(theme),
      height: isSearchable ? 24 : 0,
      borderRadius: 4,
      backgroundColor: COLOUR_BG_BACKGROUND_ALT,
      padding: isSearchable ? '14px 16px' : 0,
      border: isSearchable ? `1px solid ${COLOUR_BORDER_BORDER_INPUT}` : 'none',

      '&:: placeholder': {
        color: textInputLabel,
        paddingLeft: 26
      },

      '&::-moz-placeholder': {
        color: textInputLabel,
        paddingLeft: 26
      }
    }
  };
});

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: {
      text: { textInputLabel }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INPUT_LABEL: HexColour = textInputLabel;

  return {
    position: 'absolute',
    paddingLeft: 16,
    display: 'flex',
    alignItems: 'center',

    '> svg > path': {
      fill: COLOUR_TEXT_TEXT_INPUT_LABEL
    }
  };
});

const RPSelectNew: FC<RPSelectNewProps> = (props: RPSelectNewProps) => {
  const {
    name,
    placeholderLabel,
    toShrinkLabel = true,
    onBlur,
    variant = 'normal',
    value,
    onChange,
    popperWidth,
    isExtendedPopper = false,
    isSearchable = true,
    disabled = false,
    shouldUseCustomFilter = false,
    isFixedHeight = false,
    error,
    showMaxOptions = false,
    isMobileOptimised = false
  } = props;

  let timeout: ReturnType<typeof setTimeout>;

  const isXSmallSize: boolean = variant === 'x-small';
  const typeProp = { ...('type' in props && { type: props.type }) };

  const options = useMemo(() => {
    return 'options' in props ? props.options : [];
  }, [props]);

  const { dialingCodeData, countryData } = useSelector(getReferenceData);
  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const SearchIcon = useMemo(() => getIcon(tenant, 'magnifyingGlass'), [tenant]);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const searchLabel: string = translate('selectDropdown.searchLabel');
  const noResultsLabel: string = translate('selectDropdown.noResultsLabel');

  const isDialingCodeType: boolean = typeProp.type === 'dialingCode';
  const isMobileSmallLayout: boolean = layout === 'mobileSM';

  const [openState, setOpen] = useState(false);
  const [anchorElState, setAnchorEl] = useState<null | HTMLDivElement>(null);
  const [currentSelectedOption, setCurrentSelectedOption] = useState<SelectedOptionType | null>(null);

  const selectOptions = useMemo(() => {
    switch (typeProp.type) {
      case 'country':
        return getCountryOptionsFromAPI(countryData);
      case 'currency':
        return options.length > 0 ? options : getCurrencyOptions();
      case 'dialingCode':
        return dialingCodeData;
      default:
        return options;
    }
  }, [typeProp.type, options, dialingCodeData]);

  const isMobileOrTabletDevices: boolean = !!isMobileOrTabletDevice.any();

  const shouldShowMobileOptimisedLayout: boolean = useMemo(() => {
    if (isMobileOptimised) {
      return true;
    } else {
      switch (typeProp.type) {
        case 'country':
        case 'currency':
        case 'dialingCode':
        case 'state':
          return true;
        default:
          return false;
      }
    }
  }, [typeProp.type, isMobileOptimised]);

  useEffect(() => {
    if (!isMobileOrTabletDevices) {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (selectOptions && value && value.length > 0) {
      const preSelectedOption =
        selectOptions && selectOptions.find((option: { value: string }) => option.value === value);

      if (preSelectedOption && preSelectedOption.iconPath) {
        import(`../../../core/CountryMetadata/${preSelectedOption.iconPath}.svg`)
          .then(({ default: icon }) => {
            const preSelOption = isDialingCodeType
              ? {
                  ...preSelectedOption,
                  value: extractDialingCodeValue(preSelectedOption.value)
                }
              : preSelectedOption;
            const updatedOption = {
              ...preSelOption,
              icon
            };
            setCurrentSelectedOption(updatedOption);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setCurrentSelectedOption(preSelectedOption || null);
      }
    } else {
      setCurrentSelectedOption(null);
    }
  }, [dialingCodeData, value, selectOptions]);

  const handleResize = () => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      handleClickAway();
    }, 100);
  };

  const toggleDropdown = useCallback(
    (event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
      if (!disabled) {
        if (openState) {
          setAnchorEl(null);
          setOpen(false);
        } else {
          setAnchorEl(event.currentTarget);
          setOpen(true);
        }
      }
    },
    [disabled, openState]
  );

  const handleClick = (event: MouseEvent<HTMLDivElement>) => toggleDropdown(event);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.key === ENTER_KEY && toggleDropdown(event);
  };

  const handleClickAway = () => {
    if (anchorElState) {
      anchorElState.focus();
    }
    setAnchorEl(null);
    setOpen(false);
  };

  const onChangeHandler = (e: SyntheticEvent, newValueObj: AutocompleteValue<any, any, any, any>) => {
    onChange && onChange(newValueObj.value);
    setOpen(false);
    setAnchorEl(null);
    if (newValueObj && newValueObj.iconPath) {
      import(`../../../core/CountryMetadata/${newValueObj.iconPath}.svg`)
        .then(({ default: icon }) => {
          const valueObj = isDialingCodeType
            ? {
                ...newValueObj,
                value: extractDialingCodeValue(newValueObj.value)
              }
            : newValueObj;
          const updatedOption = {
            ...valueObj,
            icon
          };
          setCurrentSelectedOption(updatedOption);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setCurrentSelectedOption(newValueObj);
    }
  };

  const CustomPopper = (popperProps: PopperComponentProps) => {
    const { disablePortal, anchorEl, open, ...other } = popperProps;
    return <StyledPopper {...other} isSearchable={isSearchable} isExtendedPopper={isExtendedPopper} />;
  };

  const filterOptions = (currentOptions: any, state: FilterOptionsState<OptionType>): OptionType[] => {
    const optionList: OptionType[] = currentOptions as OptionType[];
    const searchValue: string = state.inputValue.trim().toLowerCase();

    if (searchValue) {
      const filteredOptions: OptionType[] = [];
      optionList.forEach((option: OptionType) => {
        if (
          option.label?.trim().toLowerCase().includes(searchValue) ||
          option.value?.trim().toLowerCase().includes(searchValue)
        )
          filteredOptions.push(option);
      });
      return filteredOptions;
    }
    return optionList;
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <DropdownContainer>
        <RPSelectInput
          onBlur={onBlur}
          placeholderLabel={placeholderLabel}
          open={openState}
          variant={variant}
          handleClick={handleClick}
          handleKeyDown={handleKeyDown}
          currentSelectedOption={currentSelectedOption}
          isExtendedPopper={isExtendedPopper}
          toShrinkLabel={toShrinkLabel}
          disabled={disabled}
          {...typeProp}
          hasError={!!error}
        />
        {!!error && <RPValidationErrorMessage message={error} />}
        {isMobileSmallLayout && shouldShowMobileOptimisedLayout ? (
          <FullScreenDialog open={openState}>
            <FullScreenDialogAutocomplete
              handleClose={handleClickAway}
              selectOptions={selectOptions}
              currentSelectedOption={currentSelectedOption}
              onChangeHandler={onChangeHandler}
              shouldUseCustomFilter={shouldUseCustomFilter}
              placeholder={searchLabel}
              {...typeProp}
            />
          </FullScreenDialog>
        ) : (
          <MainPopper
            open={openState}
            anchorEl={anchorElState}
            placement="bottom-start"
            width={anchorElState ? anchorElState.clientWidth : 300}
            isExtendedPopper={isExtendedPopper}
            popperWidth={popperWidth}
            isSearchable={isSearchable}
            error={error}
            disablePortal={true} //The `children` will be under the DOM hierarchy of the parent component.
            modifiers={[
              {
                name: 'flip',
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: 'document',
                  padding: 10,
                  fallbackPlacements: ['bottom-start']
                }
              }
            ]}
          >
            <StyledAutocomplete
              isSearchable={isSearchable}
              isExtendedPopper={isExtendedPopper}
              variant={variant}
              filterOptions={shouldUseCustomFilter ? filterOptions : undefined}
              id={`rp-select-${name}`}
              open={openState}
              value={isSearchable ? null : currentSelectedOption} // value is passed as null when dropdown is searchable to avoid filling the search field with selected value whenever currentSelectionOption is not null.
              onChange={onChangeHandler}
              options={selectOptions}
              getOptionLabel={(option) =>
                option !== '' ? (isDialingCodeType ? option.diallingCode : option.label) : ''
              }
              isOptionEqualToValue={(option, currentSelectionOption) => option.value === currentSelectionOption?.value}
              noOptionsText={noResultsLabel}
              clearIcon={null}
              componentsProps={{
                paper: {
                  sx: {
                    padding: '0px',
                    border: 'none',
                    boxShadow: 'none',
                    ...(isXSmallSize && {
                      '& ul': {
                        padding: `4px ${isFixedHeight ? '4' : '0'}px 4px 4px !important`
                      }
                    })
                  }
                }
              }}
              PopperComponent={CustomPopper}
              ListboxComponent={(listBoxProps) => (
                <StyledListBox {...listBoxProps} isFixedHeight={isFixedHeight} showMaxOptions={showMaxOptions} />
              )}
              renderOption={(renderOptionProps, option) => {
                return (
                  <RPSelectItem
                    {...renderOptionProps}
                    option={option}
                    currentSelectedOption={currentSelectedOption}
                    key={`${option.label}-${option.value}-${option.iconPath}`}
                    variant={variant}
                    {...typeProp}
                  />
                );
              }}
              renderInput={(params: AutocompleteRenderInputParams) => {
                return (
                  <StyledInput
                    data-testid="rp-select-search-field"
                    ref={params.InputProps.ref}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: !isSearchable
                    }}
                    // If the device is a mobile or a tablet device,
                    // then the search field should not be focusable
                    autoFocus={!isMobileOrTabletDevices && isSearchable}
                    placeholder={searchLabel}
                    isSearchable={isSearchable}
                    isExtendedPopper={isExtendedPopper}
                    startAdornment={
                      !params.inputProps.value &&
                      isSearchable && (
                        <IconContainer>
                          <SearchIcon />
                        </IconContainer>
                      )
                    }
                  />
                );
              }}
            />
          </MainPopper>
        )}
      </DropdownContainer>
    </ClickAwayListener>
  );
};

export default RPSelectNew;

import { useContext } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import {
  getBody2NormalStyles,
  getBodySmallNormalStyles,
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

type Sizes = 'small' | 'normal' | 'large';

interface StyledCheckboxProps extends CheckboxProps {
  type?: Sizes;
}

interface StyledFormControlLabelProps {
  type: Sizes;
}

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'type',
})<StyledFormControlLabelProps>(({ theme, type }) => {
  return {
    '> span:nth-of-type(2)': {
      ...(type === 'small'
        ? {
            ...getBodySmallNormalStyles(theme),
          }
        : {
            ...getBody2NormalStyles(theme),
          }),
    },
  };
});

const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'type',
})<StyledCheckboxProps>(({ theme, type }) => {
  const iconDimension: number = type === 'large' ? 25 : type === 'small' ? 16 : 18;

  const {
    colours: {
      borders: { borderInput, borderInputActive, borderSelectionHover },
      backgrounds: { backgroundInputDisabled, backgroundAlt },
      text: { textInputDisabled, textInputFocus },
    },
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borderInputActive;
  const COLOUR_BORDER_BORDER_SELECTION_HOVER: HexColour = borderSelectionHover;
  const COLOUR_BG_BACKGROUND_INPUT_DISABLED: HexColour = backgroundInputDisabled;
  const COLOUR_BORDER_BORDER_INPUT: HexColour = borderInput;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: HexColour = textInputDisabled;
  const COLOUR_TEXT_TEXT_INPUT_FOCUS: HexColour = textInputFocus;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  return {
    padding: 0,
    paddingRight: 8,
    '&, svg': {
      transition: 'all 500ms',
    },
    '> svg': {
      width: iconDimension,
      height: iconDimension,
    },

    '&.Mui-focusVisible+ span': {
      color: COLOUR_TEXT_TEXT_INPUT_FOCUS,
    },

    '&:not(.Mui-checked)': {
      '> svg': {
        '> rect:nth-of-type(2), > path': {
          display: 'none',
        },
        '> rect:nth-of-type(1)': {
          fill: 'none',
          x: 1,
          y: 1,
          rx: 1,
          stroke: COLOUR_BORDER_BORDER_INPUT,
          strokeWidth: 2,
          width: iconDimension - 2,
          height: iconDimension - 2,
        },
      },
      ':hover': {
        '> svg > rect:nth-of-type(1)': {
          stroke: COLOUR_BORDER_BORDER_SELECTION_HOVER,
        },
      },
      '&.Mui-focusVisible': {
        '> svg > rect:nth-of-type(1)': {
          stroke: COLOUR_TEXT_TEXT_INPUT_FOCUS,
        },
      },

      '&.Mui-disabled': {
        '> svg > rect:nth-of-type(1)': {
          fill: COLOUR_BG_BACKGROUND_INPUT_DISABLED,
        },
      },
    },

    // Checked
    '&.Mui-checked': {
      '> svg': {
        '> rect': {
          x: 0.5,
          y: 0.5,
          rx: 1.5,
          width: iconDimension - 1,
          height: iconDimension - 1,

          ':nth-of-type(1)': {
            fill: COLOUR_BORDER_BORDER_INPUT_ACTIVE,
          },
          ':nth-of-type(2)': {
            stroke: COLOUR_BORDER_BORDER_INPUT_ACTIVE,
          },
        },
        '> path': {
          display: 'none',
          fill: COLOUR_BG_BACKGROUND_ALT,

          ...(type === 'large'
            ? {
                '&.checkbox-large': {
                  display: 'initial',
                },
              }
            : type === 'normal'
            ? {
                '&.checkbox-normal': {
                  display: 'initial',
                },
              }
            : {
                '&.checkbox-small': {
                  display: 'initial',
                },
              }),
        },
      },
      '&.Mui-disabled': {
        '> svg': {
          '> rect:nth-of-type(1)': {
            fill: COLOUR_BG_BACKGROUND_INPUT_DISABLED,
          },
          '> rect:nth-of-type(2)': {
            stroke: COLOUR_BORDER_BORDER_INPUT,
          },
          '> path': {
            fill: COLOUR_TEXT_TEXT_INPUT_DISABLED,
          },
        },
      },
    },
  };
});

interface RPCheckboxProps extends StyledCheckboxProps {
  label: string;
}

const RPCheckbox = (props: RPCheckboxProps) => {
  const { label, type = 'normal', disabled = false, value, onChange, onBlur } = props;

  const { tenant } = useContext(TenantContext);

  const CheckedCheckboxIcon = getIcon(tenant, 'checkedCheckbox');

  return (
    <StyledFormControlLabel
      type={type}
      label={label}
      control={
        <StyledCheckbox
          disableRipple
          type={type}
          icon={<CheckedCheckboxIcon data-testid="unchecked-checkbox-icon" />}
          checkedIcon={<CheckedCheckboxIcon data-testid="checked-checkbox-icon" />}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          checked={value as boolean}
          // {...props}
        />
      }
    />
  );
};

export default RPCheckbox;

import { FC } from 'react';

// Components - Atoms, Molecules, Organisms, Pages
import RPModal from './RPModal';
import { NoService } from '../NoService';
// Types
import { ServiceModalProps } from './ServiceModalCommon';

// Interfaces
interface NoServiceModalProps extends ServiceModalProps {
  isOpen: boolean;
  selectedCountry: string;
}

const NoServiceModal: FC<NoServiceModalProps> = (props: NoServiceModalProps) => {
  const { isOpen, handleClose, selectedState, size = 'normal', selectedCountry } = props;

  const body = (
    <NoService handleClose={handleClose} size={size} selectedState={selectedState} selectedCountry={selectedCountry} />
  );

  return <RPModal isOpen={isOpen} handleClose={handleClose} body={body} size={size} />;
};

export default NoServiceModal;

import { FC, FocusEvent, MouseEvent, KeyboardEvent, useContext, useMemo, useRef } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment, { InputAdornmentProps } from '@mui/material/InputAdornment';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { SelectedOptionType } from '../../../core/types/SelectTypes';
import { IsExtendedSelectPopper, SizeVariants, TypeProps } from './RPSelectNew';
// Utils
import {
  getBody2NormalStyles,
  getBodyExtraSmallNormalStyles
} from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import { getIcon } from '../../../core/utils/IconOrgData';

interface RPSelectInputProps extends IsExtendedSelectPopper {
  placeholderLabel: string;
  open: boolean;
  variant: SizeVariants['variant'];
  handleClick: (event: MouseEvent<HTMLDivElement>) => void;
  handleKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  currentSelectedOption: SelectedOptionType | null;
  toShrinkLabel: boolean;
  disabled: boolean;
  type?: TypeProps;
  hasError: boolean;
}

interface StyledTextFieldProps extends IsExtendedSelectPopper {
  open: boolean;
  toShrinkLabel?: boolean;
  variantSize: SizeVariants['variant'];
  hasError: boolean;
}

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== 'open' &&
    prop !== 'toShrinkLabel' &&
    prop !== 'variantSize' &&
    prop !== 'isExtendedPopper' &&
    prop !== 'hasError'
})<StyledTextFieldProps>(({ theme, open, toShrinkLabel, disabled, variantSize, isExtendedPopper, hasError }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt, backgroundInputDisabled },
      borders: { borderInput, borderSelectionHover, borderInputActive, borderError },
      text: { textInputLabel, textInputDisabled }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  const COLOUR_BORDER_BORDER_INPUT: HexColour = borderInput;
  const COLOUR_BORDER_BORDER_SELECTION_HOVER: HexColour = borderSelectionHover;
  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borderInputActive;
  const COLOUR_TEXT_TEXT_INPUT_LABEL: HexColour = textInputLabel;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: HexColour = textInputDisabled;
  const COLOUR_BG_BACKGROUND_INPUT_DISABLED: HexColour = backgroundInputDisabled;
  const COLOUR_BORDER_BORDER_ERROR: HexColour = borderError;

  const borderColor: string | HexColour = hasError
    ? COLOUR_BORDER_BORDER_ERROR
    : open
    ? COLOUR_BORDER_BORDER_INPUT_ACTIVE
    : disabled
    ? COLOUR_BORDER_BORDER_INPUT
    : 'transparent';

  const isSmallSize: boolean = variantSize === 'small';
  const isXSmallSize: boolean = variantSize === 'x-small';
  const isSmallOrXSmallSize: boolean = isSmallSize || isXSmallSize;

  const currentHeight: number = isSmallSize ? 52 : isXSmallSize ? 40 : 60;
  return {
    // Form control
    height: currentHeight,
    width: '100%',
    boxSizing: 'border-box',
    border: '2px solid transparent',
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    ...(!disabled && {
      boxShadow: `${COLOUR_BORDER_BORDER_INPUT} 0px 0px 0px 1px`
    }),
    borderColor,
    borderRadius: 4,
    transition: 'all 0.3s ease-in-out 0s',
    ...(!open &&
      !disabled && {
        '&:hover': {
          borderColor: hasError ? COLOUR_BORDER_BORDER_ERROR : COLOUR_BORDER_BORDER_SELECTION_HOVER
        }
      }),

    '&:focus-within': {
      border: `2px solid ${hasError ? COLOUR_BORDER_BORDER_ERROR : COLOUR_BORDER_BORDER_INPUT_ACTIVE}`,
      boxShadow: 'none',
      ...(open && {
        borderBottomColor: 'transparent'
      })
    },

    ...(open && {
      '&': {
        borderColor: hasError ? COLOUR_BORDER_BORDER_ERROR : COLOUR_BORDER_BORDER_INPUT_ACTIVE,
        boxShadow: 'none',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomColor: 'transparent',
        ...(isExtendedPopper && {
          zIndex: 1301,
          background: 'linear-gradient(to left, #0B5FFF 0%, #FFF 0.1%, #FFF 50%, #FFF 100%)'
        })
      }
    }),

    // Input
    '& .MuiFilledInput-root': {
      cursor: disabled ? 'default' : 'pointer',
      backgroundColor: COLOUR_BG_BACKGROUND_ALT,
      padding: isSmallOrXSmallSize ? '0 0 0 14px !important' : '0 0 0 18px !important',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,

      ':before, :after': {
        borderBottom: 'none',
        transition: 'none'
      },

      '&.Mui-focused': {
        backgroundColor: COLOUR_BG_BACKGROUND_ALT
      },

      '&:focus-within ::selection': {
        color: 'inherit',
        background: 'transparent'
      },

      '&:hover': {
        backgroundColor: COLOUR_BG_BACKGROUND_ALT,
        '&:not(.Mui-disabled)': {
          '&:before': {
            borderBottom: 'none'
          }
        }
      },

      '&.Mui-disabled': {
        backgroundColor: COLOUR_BG_BACKGROUND_INPUT_DISABLED,
        color: 'transparent',
        '& .MuiAutocomplete-endAdornment': {
          svg: {
            color: COLOUR_TEXT_TEXT_INPUT_DISABLED,
            '> path': {
              fill: 'currentColor'
            }
          }
        }
      },

      '& .MuiAutocomplete-clearIndicator': {
        display: 'none'
      },

      '& .MuiButtonBase-root.MuiIconButton-root:hover': {
        backgroundColor: 'transparent'
      },

      '> input.MuiFilledInput-input.MuiInputBase-input': {
        cursor: disabled ? 'default' : 'pointer',
        ...getBody2NormalStyles(theme),
        height: 24,
        ...(!toShrinkLabel
          ? {
              padding: `${isSmallSize ? '12px' : isXSmallSize ? '6px' : '16px'} 0px`
            }
          : {
              padding: `${isSmallSize ? '20px' : isXSmallSize ? '6px' : '25px'} 0 ${isSmallSize ? '5px' : '6px'} 0`
            }),

        ':disabled': {
          color: COLOUR_TEXT_TEXT_INPUT_DISABLED
        }
      }
    },

    // label
    '> label': {
      ...getBody2NormalStyles(theme),
      color: COLOUR_TEXT_TEXT_INPUT_LABEL,
      paddingLeft: '6px',
      ...(isXSmallSize && { top: '-8px' }),
      transform: isSmallOrXSmallSize ? 'translate(12px, 14px) scale(1)' : 'translate(12px, 18px) scale(1)',

      '&.MuiInputLabel-shrink': {
        ...getBodyExtraSmallNormalStyles(theme),
        color: COLOUR_TEXT_TEXT_INPUT_LABEL
      },

      ...(toShrinkLabel
        ? {
            '&.MuiFormLabel-filled': {
              transform: 'translate(12px, 7px)'
            }
          }
        : {
            '&.MuiFormLabel-filled': {
              display: 'none'
            }
          }),

      ...(toShrinkLabel
        ? {
            '&.Mui-focused': {
              color: COLOUR_TEXT_TEXT_INPUT_LABEL,
              paddingLeft: '6px',
              transform: 'translate(12px, 7px)'
            }
          }
        : {
            '&.Mui-focused': {
              display: 'none'
            }
          }),

      '&.Mui-disabled': {
        color: COLOUR_TEXT_TEXT_INPUT_DISABLED
      }
    }
  };
});

const StyledInputAdornment = styled(InputAdornment)<InputAdornmentProps>(() => {
  return {
    img: {
      width: 18,
      height: 18
    }
  };
});

interface IconContainerProps {
  open: boolean;
  disabled: boolean;
  variantSize: SizeVariants['variant'];
}

const IconContainer = styled('span', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'disabled' && prop !== 'variantSize'
})<IconContainerProps>(({ theme, open, disabled, variantSize }) => {
  const {
    colours: {
      text: { textLink, textInputDisabled }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = textLink;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: HexColour = textInputDisabled;

  const isSmallSize: boolean = variantSize === 'small';
  const isXSmallSize: boolean = variantSize === 'x-small';
  const isSmallOrXSmallSize: boolean = isSmallSize || isXSmallSize;

  return {
    paddingRight: isSmallOrXSmallSize ? 10 : 20,
    display: 'flex',

    ':hover': {
      backgroundColor: 'transparent'
    },

    '> svg': {
      ...(open && {
        transform: 'rotate(180deg)'
      }),

      '> path': {
        fill: disabled ? COLOUR_TEXT_TEXT_INPUT_DISABLED : COLOUR_TEXT_TEXT_LINK
      }
    }
  };
});

const RPSelectInput: FC<RPSelectInputProps> = (props: RPSelectInputProps) => {
  const {
    placeholderLabel,
    open,
    variant,
    isExtendedPopper,
    handleClick,
    handleKeyDown,
    onBlur,
    currentSelectedOption,
    toShrinkLabel,
    disabled,
    type,
    hasError
  } = props;

  const isXSmallSize: boolean = variant === 'x-small';
  const { tenant } = useContext(TenantContext);

  const ChevronDown = useMemo(() => getIcon(tenant, 'navigationChevronDownIcon'), [tenant]);

  const inputRef = useRef<any>(null);

  return (
    <StyledTextField
      open={open}
      toShrinkLabel={toShrinkLabel && !isXSmallSize}
      variant="filled"
      size="medium"
      value={
        currentSelectedOption
          ? type === 'dialingCode'
            ? currentSelectedOption.value
            : currentSelectedOption.label
          : ''
      }
      label={placeholderLabel}
      disabled={disabled}
      hasError={hasError}
      variantSize={variant}
      isExtendedPopper={isExtendedPopper}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      hiddenLabel={(!toShrinkLabel || variant === 'x-small') && currentSelectedOption?.value.length !== 0}
      data-testid="rp-select-input"
      inputProps={{
        autoComplete: `off` // disable autocomplete and autofill
      }}
      inputRef={inputRef}
      InputProps={{
        readOnly: true,
        startAdornment: currentSelectedOption && currentSelectedOption.icon && (
          <StyledInputAdornment position="start">
            <img
              data-testid="rp-start-adornment-img"
              loading="lazy"
              src={currentSelectedOption.icon}
              alt={currentSelectedOption.value}
            />
          </StyledInputAdornment>
        ),
        endAdornment: (
          <IconContainer
            open={open}
            disabled={disabled}
            variantSize={variant}
            onClick={() => inputRef?.current?.focus()}
            data-testid="rp-end-adornment-icon"
          >
            <ChevronDown />
          </IconContainer>
        )
      }}
    />
  );
};

export default RPSelectInput;

import { ImageObject } from '../../../types/ImageOrgTypes';

// CD specific images
import MobileAppImg from './assets/images/mobile-app-img.png';
import LandingPageImg from './assets/images/landing-page.png';
import LandingPageUSAImg from './assets/images/landing-page-usa.png';

export const CDImageObject: ImageObject = {
  mobileApp: MobileAppImg,
  landingPage: LandingPageImg,
  landingPageUSA: LandingPageUSAImg
};

import { FC, memo, useContext } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { RPText } from '../../atoms/RPText';
import { RPButton } from '../../atoms/RPButton';
import { LanguageContext } from '../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface StyledBoxProps extends BoxProps, NeedHelpSectionProps {}

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size'
})<StyledBoxProps>(({ theme, size }) => {
  const isLargeSize: boolean = size === 'large';
  const isMediumSize: boolean = size === 'medium';

  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE: HexColour = text.textInverse;

  return {
    width: '100%',
    boxSizing: 'border-box',
    alignSelf: 'center',
    ...(isLargeSize && { maxWidth: 380 }),
    ...(isMediumSize && { maxWidth: 320 }),
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: '15px',
    transition: 'all 0.2s ease 0s',
    padding: '25px 30px',
    background: 'rgba(0, 0, 0, 0.1)',

    '& .MuiTypography-root': {
      color: COLOUR_TEXT_TEXT_INVERSE,
      flexGrow: 0
    },

    '& .MuiButton-root.MuiButton-contained': {
      alignSelf: 'stretch',
      flexGrow: 1
    }
  };
});

interface NeedHelpSectionProps {
  size?: 'large' | 'medium';
}

const NeedHelpSection: FC<NeedHelpSectionProps> = ({ size = 'large' }: NeedHelpSectionProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  return (
    <StyledBox size={size}>
      <RPText variant="body1" type="medium" text={translate('sidebar.needsHelpSection.heading')} />
      <RPText variant="body2" type="normal" text={translate('sidebar.needsHelpSection.subHeading')} />
      <RPButton
        size="medium"
        btnType="secondary"
        startIconType="start"
        name="onlineChatButton"
        data-testid="rp-button-online-chat"
      >
        {translate('sidebar.needsHelpSection.onlineChatBtnText')}
      </RPButton>
    </StyledBox>
  );
};

export default memo(NeedHelpSection);

import cookie from 'cookie';

import { OrganizationCodeTypes } from '../../types/OrganizationTypes';
import { ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG, ORGANIZATION_CODE_MAPPING } from '../Constants/Constants';

export const GetOrganizationFromURL = (currentURL: string): string => {
  /*
  * Obtaining cdhCompany from the cookie, to be used if no match is obtained from URL,
  * else defaulting to Currencies Direct
  */
  const defaultOrganizationCode: OrganizationCodeTypes = ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG[0].organizationCode;
  const cookieOrganizationValue: string = (cookie.parse(document.cookie).cdhCompany !== 'undefined')
    ? cookie.parse(document.cookie).cdhCompany : '';

  let currentOrganization: OrganizationCodeTypes | '' = '';
  let cookieOrganizationCode: OrganizationCodeTypes | '' = '';


  ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG.forEach(({ organizationSearchValue, organizationCode }) => {
    if (cookieOrganizationValue === organizationCode) {
      cookieOrganizationCode = organizationCode;
    }
    if (currentURL?.toLowerCase().includes(organizationSearchValue)) {
      currentOrganization = organizationCode;
    }
  });

  console.log('Current Organization: ', currentOrganization ? currentOrganization : cookieOrganizationCode
    ? cookieOrganizationCode : defaultOrganizationCode);

  return currentOrganization ? currentOrganization : cookieOrganizationCode
          ? cookieOrganizationCode : defaultOrganizationCode;
};

export const GetOrganizationFromURLUsingSplit = (currentURL: string): string => {
  let orgName: OrganizationCodeTypes;

  const currentEnvironment: string = 'uat'.toLowerCase();

  let orgNameIndex: number;
  let torFXCheckIndex: number;

  switch(currentEnvironment) {
    case 'production':
    case 'prod':
      orgNameIndex = 1;
      torFXCheckIndex = 2;
      break;

    case 'uat':
    case 'sit':
    default:
      orgNameIndex = 2;
      torFXCheckIndex = 4;
      break;
  }

  orgName = currentURL?.split('.')[orgNameIndex] as OrganizationCodeTypes;

  const isTorFXOz: string = currentURL?.split('.')[torFXCheckIndex];

  return (isTorFXOz === 'au' && orgName === 'torfx') ?
    ORGANIZATION_CODE_MAPPING['torfxoz'] : ORGANIZATION_CODE_MAPPING[orgName];
};
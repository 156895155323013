import { createContext } from 'react';

import { LayoutSize } from '../../types/LayoutTypes';

type LayoutContext = {
  layout: LayoutSize,
  updateLayout: Function
};

export default createContext<LayoutContext>({
  layout: 'desktop',
  updateLayout: () => {
    // Intentionally empty
  }
});
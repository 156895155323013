import { useContext, useState, MouseEvent, KeyboardEvent } from 'react';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';

import RPText from '../../atoms/RPText';
import RPLink from '../../atoms/RPLink';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LanguageContext, LayoutContext } from '../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { ENTER_KEY } from '../../../core/utils/Constants/Constants';

interface DescriptionVisibleProps {
  isNeedThisDescVisible: boolean;
}
interface WhyDoWeNeedThisSectionProps {
  handleClick: () => void;
}

const WhyNeedThisHeading = styled(RPLink, {
  shouldForwardProp: (prop) => prop !== 'isNeedThisDescVisible'
})<DescriptionVisibleProps>(({ isNeedThisDescVisible }) => {
  return {
    display: 'inline-block',

    ...(isNeedThisDescVisible && {
      marginBottom: '15px'
    })
  };
});

const WhyNeedThisDescription = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

const WhyDoWeNeedThisSection = (props: WhyDoWeNeedThisSectionProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layout } = useContext(LayoutContext);

  const [isNeedThisDescVisible, setIsNeedThisDescVisible] = useState(false);

  const whyNeedThisHeading: string = translate('registration.enterDetails.whyNeedThis.heading');
  const whyNeedThisDescription: string = translate('registration.enterDetails.whyNeedThis.description');

  const textVariant: 'body1' | 'body2' = layout === 'desktop' ? 'body1' : 'body2';

  const { handleClick } = props;

  return (
    <>
      <WhyNeedThisHeading
        variant={textVariant}
        type="medium"
        text={whyNeedThisHeading}
        isNeedThisDescVisible={isNeedThisDescVisible}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          setIsNeedThisDescVisible(!isNeedThisDescVisible);
          handleClick();
        }}
        onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
          if (e.key === ENTER_KEY) {
            e.preventDefault();
            setIsNeedThisDescVisible(!isNeedThisDescVisible);
          }
        }}
        underline="none"
        href=""
        data-testid="rp-link-why-need-this"
      />
      <Collapse easing="300" in={isNeedThisDescVisible}>
        <WhyNeedThisDescription variant={textVariant} type="normal" text={whyNeedThisDescription} />
      </Collapse>
    </>
  );
};

export default WhyDoWeNeedThisSection;

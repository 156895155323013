import { useRef } from 'react';
import Box from '@mui/material/Box';

import { RPSelectNew } from '../../atoms/RPSelect';
import { OptionType } from '../../../core/types/SelectTypes';
import RPInput from '../../atoms/RPInput';

const RPSelectVariants = () => {
  const boxRef = useRef<HTMLDivElement>(null);

  const CUSTOM_OPTIONS: readonly OptionType[] = [
    {
      value: 'AD',
      label: 'Andorra',
    },
    {
      value: 'AE',
      label: 'United Arab Emirates',
    },
    {
      value: 'AF',
      label: 'Afghanistan',
    },
    {
      value: 'AG',
      label: 'Antigua and Barbuda',
    },
  ];

  const LANGUAGE_DROPDOWN_OPTIONS: readonly OptionType[] = [
    {
      value: 'EN',
      label: 'EN',
      iconPath: 'assets/united-kingdom',
    },
    {
      value: 'SN',
      label: 'SN',
      iconPath: 'assets/united-kingdom',
    },
    {
      value: 'GB',
      label: 'GB',
      iconPath: 'assets/united-kingdom',
    },
    {
      value: 'FN',
      label: 'FN',
      iconPath: 'assets/united-kingdom',
    },
  ];

  return (
    <>
      <Box>
        {/* Drop-down without Search and Placeholder */}
        <RPSelectNew
          name="country-drop-down-1"
          type="country"
          placeholderLabel="Country of residence"
          variant="normal"
          isSearchable={false}
        />
        <br />

        {/* Drop-down with Search and Placeholder */}
        <RPSelectNew
          name="country-drop-down-1"
          type="country"
          placeholderLabel="Country of residence"
          variant="normal"
        />
        <br />
        {/* Drop-down with custom options, Search and Placeholder */}
        <RPSelectNew
          name="custom-country-drop-down"
          placeholderLabel="Country of residence"
          defaultValue="AE"
          options={CUSTOM_OPTIONS}
          variant="normal"
        />
        <br />
        {/* Drop-down with Search and without Placeholder */}
        <RPSelectNew
          name="country-drop-down-2"
          type="country"
          placeholderLabel="Country of residence"
          toShrinkLabel={false}
          variant="normal"
        />
        <br />
        {/* Disabled Drop-down with Search and with Placeholder */}
        <RPSelectNew
          name="country-drop-down-disabled-1"
          type="country"
          placeholderLabel="Country of residence"
          disabled={true}
          variant="normal"
        />
        <br />
        {/* Disabled Drop-down with Search and without Placeholder */}
        <RPSelectNew
          name="country-drop-down-disabled-selected-1"
          type="country"
          defaultValue="FRA"
          placeholderLabel="Country of residence"
          toShrinkLabel={false}
          disabled={true}
          variant="normal"
        />
        <br />
        {/* Disabled Drop-down with Search and with Placeholder */}
        <RPSelectNew
          name="country-drop-down-disabled-selected-2"
          type="country"
          defaultValue="FRA"
          placeholderLabel="Country of residence"
          disabled={true}
          variant="normal"
        />
        <br />
      </Box>
      <Box
        sx={{
          width: '100px',
        }}
      >
        <RPSelectNew
          name="language-drop-down-1"
          placeholderLabel="Country of residence"
          defaultValue="EN"
          toShrinkLabel={false}
          variant="x-small"
          options={LANGUAGE_DROPDOWN_OPTIONS}
          isSearchable={false}
        />
      </Box>

      <Box
        sx={{
          width: '420px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        ref={boxRef}
      >
        <Box
          sx={{
            width: '155px',
          }}
        >
          <RPSelectNew
            name="country-code"
            placeholderLabel="Country code"
            defaultValue=""
            toShrinkLabel={false}
            isExtendedPopper={true}
            options={LANGUAGE_DROPDOWN_OPTIONS}
            popperWidth={boxRef?.current?.clientWidth}
          />
        </Box>
        <Box
          sx={{
            width: 'auto',
          }}
        >
          <RPInput
            name="mobileNum"
            placeholderLabel="Mobile number"
            value={''}
          />
        </Box>
      </Box>
      <br />
    </>
  );
};

export default RPSelectVariants;

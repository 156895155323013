import { FC, useContext, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { styled } from '@mui/material/styles';

// Contexts
import { LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import SkipDocs from '../../organisms/SkipDocs';
import { FullLayout } from '../../organisms/FullLayout';
import { SkipDocsSizes } from '../../organisms/SkipDocs/SkipDocs';
// Types
import { LayoutSize } from '../../../core/types/LayoutTypes';
// Hooks
import { useAnimation, useScrollToContentTop, useResetStore, usePreventBackAction } from '../../../core/hooks';

const LAYOUT_TO_SIZE_MAPPING: { [key in LayoutSize]: SkipDocsSizes['size'] } = {
  desktop: 'large',
  tablet: 'medium',
  mobile: 'small',
  mobileSM: 'small'
};

interface SkipDocsContainerWrapperProps extends SkipDocsSizes {}

const SkipDocsContainerWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<SkipDocsContainerWrapperProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';

  return {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    maxWidth: isSmallSize ? '325px' : '500px',
    height: '100%',
    maxHeight: 600,
    margin: isSmallSize ? '10% 0px 0px 0px' : '2% 0px 0px 0px'
  };
});

const SkipDocsPage: FC = () => {
  const { layout } = useContext(LayoutContext);
  const size: SkipDocsSizes['size'] = LAYOUT_TO_SIZE_MAPPING[layout];

  const [animateContent, setAnimateContent] = useAnimation(false, 500);
  const cssTransitionRef = useRef(null);

  // reset redux store state manually
  useResetStore();

  // Scrolls to the top of the content area
  useScrollToContentTop();

  // Prevent back action of browser
  usePreventBackAction();

  return (
    <CSSTransition
      nodeRef={cssTransitionRef}
      in={animateContent}
      timeout={500}
      classNames="slideUp"
      unmountOnExit
      onEnter={() => setAnimateContent}
      onExited={() => setAnimateContent}
    >
      <FullLayout enableMobileFooter>
        <SkipDocsContainerWrapper size={size} ref={cssTransitionRef} data-testid="rp-skip-docs-container">
          <SkipDocs size={size} />
        </SkipDocsContainerWrapper>
      </FullLayout>
    </CSSTransition>
  );
};

export default SkipDocsPage;

import { RefObject, useContext} from 'react';
import { useSelector } from 'react-redux';

// Contexts
import { LanguageContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPSnackbar } from '../../molecules/RPSnackbar';
import { RPSlider } from '../../molecules/RPSlider';
import { RPNotification } from '../../molecules/RPNotification';
// Types
import { LayoutSize } from '../../../core/types/LayoutTypes';
// RTK Slice
import { getErrorDetails } from '../../../redux/modules/errorDetailsSlice';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getErrorTextKey } from '../../../core/utils/GetErrorTextKey';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';


interface RPErrorNotificationProps {
  layoutVariant: 'full' | 'partial';
  layout: LayoutSize;
  sideBarRef: RefObject<HTMLDivElement>;
  containerRef: RefObject<HTMLDivElement>;
}

const RPErrorNotification = (props: RPErrorNotificationProps) => {
  const { apiError, errorCode } = useSelector(getErrorDetails);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { layoutVariant, layout, sideBarRef, containerRef } = props;

  const errorNotificationText: string = translate(getErrorTextKey(errorCode));

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const errorNotification: JSX.Element = <RPNotification variant="error" icon={true} text={errorNotificationText} />;
  const notificationComponent: JSX.Element = isMobileLayout
    ? (<RPSlider error={apiError} containerRef={containerRef} children={errorNotification} />)
    : (<RPSnackbar hasError={apiError} message={errorNotificationText} variant={layoutVariant}
                   size={layout} offset={sideBarRef?.current?.offsetWidth}/>);

  return (
    <>{notificationComponent}</>
  );
};

export default RPErrorNotification;
import { AddressFieldKeys, AddressFieldTypes, FieldDetail } from '../../../types/addressLookupTypes';

/*export const countries = [
  'default',
  'AUS',
  'GBR',
  'NZ',
  'USA',
  'AFG',
  'ALB',
  'AUT',
  'BEL',
  'BGR',
  'KHM',
  'CAN',
  'CRI',
  'CYP',
  'DNK',
  'FRA',
  'DEU',
  'GIB',
  'GRC',
  'GRD',
  'GGY',
  'HKG',
  'HUN',
  'IND',
  'IRL',
  'IMN',
  'ISR',
  'ITA',
  'JPN',
  'JEY',
  'MLT',
  'NLD',
  'NOR',
  'OMN',
  'PAN',
  'PHL',
  'POL',
  'PRT',
  'ROU',
  'RUS',
  'SAU',
  'SGP',
  'SVN',
  'ZAF',
  'ESP',
  'SWE',
  'CHE',
  'THA',
  'ARE',
  'BRA',
  'LUX',
  'MEX',
] as const;*/

export type Countries =
  | 'default'
  | 'AUS'
  | 'GBR'
  | 'NZL'
  | 'USA'
  | 'AFG'
  | 'ALB'
  | 'AUT'
  | 'BEL'
  | 'BGR'
  | 'KHM'
  | 'CAN'
  | 'CRI'
  | 'CYP'
  | 'DNK'
  | 'FRA'
  | 'DEU'
  | 'GIB'
  | 'GRC'
  | 'GRD'
  | 'GGY'
  | 'HKG'
  | 'HUN'
  | 'IND'
  | 'IRL'
  | 'IMN'
  | 'ISR'
  | 'ITA'
  | 'JPN'
  | 'JEY'
  | 'MLT'
  | 'NLD'
  | 'NOR'
  | 'OMN'
  | 'PAN'
  | 'PHL'
  | 'POL'
  | 'PRT'
  | 'ROU'
  | 'RUS'
  | 'SAU'
  | 'SGP'
  | 'SVN'
  | 'ZAF'
  | 'ESP'
  | 'SWE'
  | 'CHE'
  | 'THA'
  | 'ARE'
  | 'BRA'
  | 'LUX'
  | 'MEX';

// export type CountriesType = typeof countries[number];

const VISIBLE_INPUT: FieldDetail = {
  visible: true,
  type: 'input'
};

const VISIBLE_DROPDOWN: FieldDetail = {
  visible: true,
  type: 'dropdown'
};

const NOT_VISIBLE_FIELD: FieldDetail = {
  visible: false,
  type: ''
};

export const MANDATORY_FIELDS: Exclude<AddressFieldKeys, 'addressLine2' | 'addressLine3' | 'county'>[] = [
  'addressLine1',
  'suburbCity',
  'province',
  'townCity',
  'state',
  'postcode'
];

export const CountryFieldMapping: {
  [key in Countries | string]: AddressFieldTypes;
} = {
  default: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: VISIBLE_INPUT,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  AUS: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: VISIBLE_INPUT,
    province: NOT_VISIBLE_FIELD,
    townCity: NOT_VISIBLE_FIELD,
    county: NOT_VISIBLE_FIELD,
    state: VISIBLE_DROPDOWN,
    postcode: VISIBLE_INPUT
  },
  NZL: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: VISIBLE_INPUT,
    province: NOT_VISIBLE_FIELD,
    townCity: NOT_VISIBLE_FIELD,
    county: NOT_VISIBLE_FIELD,
    state: VISIBLE_DROPDOWN,
    postcode: VISIBLE_INPUT
  },
  GBR: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  USA: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: VISIBLE_INPUT,
    state: VISIBLE_DROPDOWN,
    postcode: VISIBLE_INPUT
  },
  AFG: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  ALB: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  AUT: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: NOT_VISIBLE_FIELD,
    county: NOT_VISIBLE_FIELD,
    state: VISIBLE_INPUT,
    postcode: VISIBLE_INPUT
  },
  BEL: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  BGR: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  KHM: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  CAN: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: VISIBLE_DROPDOWN,
    postcode: VISIBLE_INPUT
  },
  CRI: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  CYP: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  DNK: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  FRA: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  DEU: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  GIB: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  GRC: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  GRD: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  GGY: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  HKG: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  HUN: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  IND: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  IRL: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  IMN: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  ISR: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  ITA: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  JPN: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  JEY: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  MLT: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  NLD: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  NOR: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  OMN: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  PAN: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  PHL: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  POL: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  PRT: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  ROU: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  RUS: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  SAU: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  SGP: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  SVN: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  ZAF: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: VISIBLE_INPUT,
    province: VISIBLE_INPUT,
    townCity: NOT_VISIBLE_FIELD,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  ESP: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  SWE: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  CHE: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  THA: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  ARE: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: NOT_VISIBLE_FIELD,
    townCity: VISIBLE_INPUT,
    county: NOT_VISIBLE_FIELD,
    state: NOT_VISIBLE_FIELD,
    postcode: VISIBLE_INPUT
  },
  BRA: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: VISIBLE_INPUT,
    province: NOT_VISIBLE_FIELD,
    townCity: NOT_VISIBLE_FIELD,
    county: NOT_VISIBLE_FIELD,
    state: VISIBLE_DROPDOWN,
    postcode: VISIBLE_INPUT
  },
  LUX: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: VISIBLE_INPUT,
    townCity: NOT_VISIBLE_FIELD,
    county: NOT_VISIBLE_FIELD,
    state: VISIBLE_INPUT,
    postcode: VISIBLE_INPUT
  },
  MEX: {
    addressLine1: VISIBLE_INPUT,
    addressLine2: VISIBLE_INPUT,
    addressLine3: VISIBLE_INPUT,
    suburbCity: NOT_VISIBLE_FIELD,
    province: VISIBLE_INPUT,
    townCity: NOT_VISIBLE_FIELD,
    county: NOT_VISIBLE_FIELD,
    state: VISIBLE_DROPDOWN,
    postcode: VISIBLE_INPUT
  }
};

import { FC, useContext } from 'react';
import { styled } from '@mui/material/styles';

// Contexts
import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
import RPButton from '../../atoms/RPButton';
import {
  ActionBtnContainer,
  IconContainer,
  StyledBody,
  ServiceModalProps,
  StyledHeading
} from '../RPModals/ServiceModalCommon';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { SizeProps } from '../RPModals/RPModal';

// Interfaces
interface PartialServiceProps extends ServiceModalProps {}

interface ServiceDetailsContainerProps extends SizeProps {}

interface ServiceDetailProps {
  type: 'allowed' | 'not-allowed';
}

// Styled components
const ServiceDetailsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size'
})<ServiceDetailsContainerProps>(({ size }) => {
  const isSmallSize: boolean = size === 'small';
  return {
    margin: '0 auto',
    marginBottom: '20px',
    width: isSmallSize ? '100%' : '270px'
  };
});

const ServiceDetail = styled('div', {
  shouldForwardProp: (prop) => prop !== 'type'
})<ServiceDetailProps>(({ theme, type }) => {
  const {
    colours: {
      text: { textSuccess, textError }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_SUCCESS: HexColour = textSuccess;
  const COLOUR_TEXT_TEXT_ERROR: HexColour = textError;
  const isTypeAllowed: boolean = type === 'allowed';
  return {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '15px',
    ...(isTypeAllowed && {
      marginBottom: 20
    }),

    '> div > svg ': {
      marginTop: '5px',

      '> path': {
        fill: isTypeAllowed ? COLOUR_TEXT_TEXT_SUCCESS : COLOUR_TEXT_TEXT_ERROR
      }
    }
  };
});

const OfferServiceSoonText = styled(RPText)(() => {
  return {
    textAlign: 'center'
  };
});

const PartialService: FC<PartialServiceProps> = (props: PartialServiceProps) => {
  const { tenant } = useContext(TenantContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { handleClose, handleBack, selectedState, size = 'normal', isFromLandingOrRegister = false } = props;

  const isSmallSize: boolean = size === 'small';

  // Icons
  const LocationWarningIcon = getIcon(tenant, 'locationWarning');
  const StatusCheckmarkFilledIcon = getIcon(tenant, 'statusCheckmarkIcon');
  const NavigationCloseFilledIcon = getIcon(tenant, 'navigationCloseFilled');

  // Translations
  const headingLine1: string = translate('modal.partialService.heading.line1');
  const headingLine2: string = translate('modal.partialService.heading.line2', {
    state: selectedState
  });
  const personalAccountText: string = translate('modal.partialService.personalAccountText');
  const otherAccountText: string = translate('modal.partialService.otherAccountText');
  const offerServiceSoonText: string = translate('modal.partialService.offerServiceSoonText');
  const continueButtonText: string = translate('modal.partialService.continueButtonText');

  const registerPageBackBtnText: string = translate('registerPage.backButtonText');

  const headingVariant: 'body1' = 'body1';
  const headingType: 'bold' = 'bold';

  const heading = isSmallSize ? (
    <StyledHeading
      variant={headingVariant}
      type={headingType}
      text={`${headingLine1} ${headingLine2}`}
      size={size}
      hasMarginBottom={true}
    />
  ) : (
    <>
      <StyledHeading
        variant={headingVariant}
        type={headingType}
        text={headingLine1}
        size={size}
        hasMarginBottom={false}
      />
      <StyledHeading
        variant={headingVariant}
        type={headingType}
        text={headingLine2}
        size={size}
        hasMarginBottom={true}
      />
    </>
  );

  return (
    <StyledBody
      size={size}
      isFromLandingOrRegister={isFromLandingOrRegister}
      data-testid="rp-partial-service-modal-body"
    >
      <IconContainer size={size}>
        <LocationWarningIcon />
      </IconContainer>
      {heading}
      <ServiceDetailsContainer size={size}>
        <ServiceDetail type="allowed">
          <div>
            <StatusCheckmarkFilledIcon />
          </div>
          <div>
            <RPText variant="body2" type="normal" text={personalAccountText} />
          </div>
        </ServiceDetail>
        <ServiceDetail type="not-allowed">
          <div>
            <NavigationCloseFilledIcon />
          </div>
          <div>
            <RPText variant="body2" type="normal" text={otherAccountText} />
          </div>
        </ServiceDetail>
      </ServiceDetailsContainer>
      <OfferServiceSoonText variant="body1" type="normal" text={offerServiceSoonText} />
      <ActionBtnContainer size={size}>
        <RPButton
          onClick={handleClose}
          size={isSmallSize ? 'small' : 'large'}
          name="PartialServicePrimaryModalButton"
          data-testid="rp-button-modal-partial-service-primary"
        >
          {continueButtonText}
        </RPButton>
        {isFromLandingOrRegister && (
          <RPButton
            size={isSmallSize ? 'small' : 'large'}
            btnType="text"
            onClick={handleBack}
            name={'PartialServicePrimaryModalBackButton'}
            data-testid={'rp-button-modal-partial-service-back'}
          >
            {registerPageBackBtnText}
          </RPButton>
        )}
      </ActionBtnContainer>
    </StyledBody>
  );
};

export default PartialService;

import { useEffectOnce } from 'usehooks-ts';
import { useDispatch } from 'react-redux';

// RTK
import { resetAuthorisationDetails } from '../../redux/modules/authorisationDetailsSlice';
import { resetErrorDetails } from '../../redux/modules/errorDetailsSlice';
import { resetLoginDetails } from '../../redux/modules/loginDetailsSlice';
import { resetRegistrationDetails } from '../../redux/modules/registrationDetailsSlice';
import { resetRegistrationStepsDetails } from '../../redux/modules/registrationStepsSlice';
//CONSTANTS
import {
  RESET_AUTHORISATION_DETAILS,
  RESET_ERROR_DETAILS,
  RESET_LOGIN_DETAILS,
  RESET_REGISTRATION_DETAILS,
  RESET_REGISTRATION_STEPS_DETAILS
} from '../../redux/ActionTypeConstants';

const useResetStore = () => {
  const dispatch: any = useDispatch();

  useEffectOnce(() => {
    dispatch(resetAuthorisationDetails(RESET_AUTHORISATION_DETAILS));
    dispatch(resetErrorDetails(RESET_ERROR_DETAILS));
    dispatch(resetLoginDetails(RESET_LOGIN_DETAILS));
    dispatch(resetRegistrationDetails(RESET_REGISTRATION_DETAILS));
    dispatch(resetRegistrationStepsDetails(RESET_REGISTRATION_STEPS_DETAILS));
  });
};

export default useResetStore;

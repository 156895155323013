// Types
import { MobileAppLinkType } from '../../../components/organisms/MobileAppLinksWithIcons/MobileAppLinksWithIcons';
import { OrganizationCodeTypes } from '../../types/OrganizationTypes';
// Constants
import {
  ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG,
  CD_ANDROID_PLAY_STORE_URL,
  CD_IOS_APP_STORE_URL,
  TORFX_ANDROID_PLAY_STORE_URL,
  TORFX_IOS_APP_STORE_URL,
  TORFXOZ_ANDROID_PLAY_STORE_URL,
  TORFXOZ_IOS_APP_STORE_URL
} from '../Constants/Constants';

export const MOBILE_APP_LINKS_ORG_MAPPING: { [K in OrganizationCodeTypes]: MobileAppLinkType } = {
  currenciesdirect: {
    iosAppStoreURL: CD_IOS_APP_STORE_URL,
    androidPlayStoreURL: CD_ANDROID_PLAY_STORE_URL
  },
  torfx: {
    iosAppStoreURL: TORFX_IOS_APP_STORE_URL,
    androidPlayStoreURL: TORFX_ANDROID_PLAY_STORE_URL
  },
  torfxoz: {
    iosAppStoreURL: TORFXOZ_IOS_APP_STORE_URL,
    androidPlayStoreURL: TORFXOZ_ANDROID_PLAY_STORE_URL
  },
  fcg: {
    iosAppStoreURL: '',
    androidPlayStoreURL: ''
  },
  ramsdens: {
    iosAppStoreURL: '',
    androidPlayStoreURL: ''
  },
  hl: {
    iosAppStoreURL: '',
    androidPlayStoreURL: ''
  },
  propertypay: {
    iosAppStoreURL: '',
    androidPlayStoreURL: ''
  }
};

const GetMobileAppLinks = (organizationCode: OrganizationCodeTypes | undefined): MobileAppLinkType => {
  const DEFAULT_ORGANIZATION_CODE: OrganizationCodeTypes = ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG[0].organizationCode;
  const CURRENT_ORGANIZATION_CODE: OrganizationCodeTypes = organizationCode || DEFAULT_ORGANIZATION_CODE;

  return MOBILE_APP_LINKS_ORG_MAPPING[CURRENT_ORGANIZATION_CODE];
};

export default GetMobileAppLinks;

import { FC } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface RPLinearProgressBarProps {
  value: number;
  marginBottom?: string;
}

const StyledLinearProgressBar = styled(LinearProgress)<RPLinearProgressBarProps>(({ theme, marginBottom }) => {

  const {
    colours: {
      backgrounds,
      text
    },
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT_INVERSE_STRONG: HexColour =
    backgrounds.backgroundAltInverseStrong;
  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;

  return {
    height: '6px',
    backgroundColor: COLOUR_BG_BACKGROUND_ALT_INVERSE_STRONG,
    borderRadius: '8px',
    marginBottom: marginBottom ? marginBottom : '15px',
    '& .MuiLinearProgress-bar': {
      backgroundColor: COLOUR_TEXT_TEXT_LINK,
      borderRadius: '8px',
    },
  };
});

const RPLinearProgressBar: FC<RPLinearProgressBarProps> = (
  props: RPLinearProgressBarProps
) => {

  return (
    <div>
      <StyledLinearProgressBar variant="determinate" {...props} />
    </div>
  );
};

export default RPLinearProgressBar;
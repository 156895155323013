// Types
import { OrganizationCodeTypes } from '../../types/OrganizationTypes';
// Constants
import { ORGANIZATION_CODE_MAPPING } from '../Constants/Constants';

interface GetCurrentTenantType {
  currentTenant: OrganizationCodeTypes;
  isCurrentTenantPropertyPay: boolean;
}

const GetCurrentTenant = (tenant: OrganizationCodeTypes | undefined): GetCurrentTenantType => {
  const currentTenant: OrganizationCodeTypes = (
    tenant ? tenant : ORGANIZATION_CODE_MAPPING.currenciesdirect
  ) as OrganizationCodeTypes;
  const isCurrentTenantPropertyPay: boolean = currentTenant === ORGANIZATION_CODE_MAPPING.propertypay;
  return {
    currentTenant,
    isCurrentTenantPropertyPay
  };
};


export default GetCurrentTenant;

import { FC, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';

import RPText from '../RPText';
import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

type VariantType = 'twoLineNormal' | 'oneLineSmall';

interface RadioListButtonProps {
  label: string;
  name: string;
  value: string;
  isChecked: boolean;
  handleChange: (value: string) => void;
  icon?: any;
  description?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  variantType?: VariantType;
}

interface VariantProps {
  isChecked: boolean;
  isDisabled: boolean;
  variantType: VariantType;
}

interface LabelProps extends VariantProps {
  hasError: boolean;
}

const RadioContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isChecked' && prop !== 'isDisabled' && prop !== 'variantType',
})<VariantProps>(({ theme, isChecked, isDisabled, variantType }) => {
  const {
    colours: {
      borders: { borderInput, borderInputActive },
      text: { textLink },
      backgrounds: { backgroundAlt, background },
    },
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT: HexColour = borderInput;
  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borderInputActive;
  const COLOUR_TEXT_TEXT_LINK: HexColour = textLink;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  const COLOUR_BG_BACKGROUND: HexColour = background;

  const isOneLineSmall: boolean = variantType === 'oneLineSmall';

  return {
    display: 'flex',
    border: `1px solid  ${isChecked ? COLOUR_TEXT_TEXT_LINK : COLOUR_BORDER_BORDER_INPUT}`,
    marginBottom: '16px',
    padding: `${isOneLineSmall ? '15px 12px' : '15px 20px'}`,
    backgroundColor: isChecked ? COLOUR_TEXT_TEXT_LINK : isDisabled ? COLOUR_BG_BACKGROUND : COLOUR_BG_BACKGROUND_ALT,
    ...(!isDisabled && {
      cursor: 'pointer',
    }),
    borderRadius: '5px',

    ':hover': {
      border: `1px solid ${
        isDisabled
          ? COLOUR_BORDER_BORDER_INPUT
          : isOneLineSmall
          ? COLOUR_TEXT_TEXT_LINK
          : COLOUR_BORDER_BORDER_INPUT_ACTIVE
      }`,
    },
  };
});

const IconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isChecked' && prop !== 'isDisabled' && prop !== 'variantType',
})<VariantProps>(({ theme, isChecked, isDisabled, variantType }) => {
  const {
    colours: {
      text: { textLink },
      backgrounds: { backgroundAlt },
    },
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = textLink;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  const isOneLineSmall: boolean = variantType === 'oneLineSmall';

  return {
    marginRight: `${isOneLineSmall ? '10px' : '12px'}`,
    display: 'flex',
    alignItems: 'center',

    svg: {
      ...(isChecked && {
        '> path:nth-of-type(1)': {
          fill: COLOUR_BG_BACKGROUND_ALT,
        },
        '> path:nth-of-type(2)': {
          fill: COLOUR_TEXT_TEXT_LINK,
        },
      }),
      ...(isDisabled && {
        opacity: 0.5,
      }),
    },
  };
});

const TextContainer = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
  };
});

const StyledLabel = styled(RPText, {
  shouldForwardProp: (prop) =>
    prop !== 'isChecked' && prop !== 'isDisabled' && prop !== 'variantType' && prop !== 'hasError',
})<LabelProps>(({ theme, isChecked, isDisabled, variantType, hasError }) => {
  const isOneLineSmall: boolean = variantType === 'oneLineSmall';

  const {
    colours: {
      text: { text, textWeak, textInverse, textInputDisabled },
    },
  } = theme;

  const COLOUR_TEXT_TEXT: HexColour = text;
  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: HexColour = textInputDisabled;

  return {
    color: isChecked
      ? COLOUR_TEXT_TEXT_INVERSE
      : isDisabled && hasError
      ? COLOUR_TEXT_TEXT
      : isDisabled
      ? COLOUR_TEXT_TEXT_INPUT_DISABLED
      : isOneLineSmall
      ? COLOUR_TEXT_TEXT_WEAK
      : COLOUR_TEXT_TEXT,

    ...(isOneLineSmall &&
      isDisabled && {
        opacity: '0.5',
      }),
  };
});

const StyledDescription = styled(RPText, {
  shouldForwardProp: (prop) => prop !== 'isChecked' && prop !== 'isDisabled' && prop !== 'variantType',
})<VariantProps>(({ theme, isChecked, isDisabled }) => {
  const {
    colours: { text },
  } = theme;

  const COLOUR_TEXT_TEXT_WEAKEST: HexColour = text.textWeakest;
  const COLOUR_TEXT_TEXT_INVERSE: HexColour = text.textInverse;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: HexColour = text.textInputDisabled;

  return {
    color: isChecked
      ? COLOUR_TEXT_TEXT_INVERSE
      : isDisabled
      ? COLOUR_TEXT_TEXT_INPUT_DISABLED
      : COLOUR_TEXT_TEXT_WEAKEST,
  };
});

const RadioButtonContainer = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

const StyledRadio = styled(Radio)(({ theme }) => {
  const {
    colours: {
      borders: { borderInput, borderInputFocus },
      text: { textLink, textInverse },
      backgrounds: { backgroundAlt },
    },
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT: HexColour = borderInput;
  const COLOUR_BORDER_BORDER_INPUT_FOCUS: HexColour = borderInputFocus;
  const COLOUR_TEXT_TEXT_LINK: HexColour = textLink;
  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  return {
    color: COLOUR_BORDER_BORDER_INPUT,
    padding: '0',

    '&.Mui-checked > svg': {
      backgroundColor: COLOUR_TEXT_TEXT_LINK,
      height: '24px',
      width: '24px',
      '> path': {
        fill: COLOUR_TEXT_TEXT_INVERSE,
      },
    },

    ':hover': {
      backgroundColor: 'inherit',
    },

    '&.Mui-focusVisible:not(.Mui-checked) > span > svg > circle': {
      stroke: COLOUR_BORDER_BORDER_INPUT_FOCUS,
      strokeWidth: 2,
    },

    '&.Mui-focusVisible.Mui-checked > svg': {
      outline: ` ${COLOUR_BG_BACKGROUND_ALT} solid 1px`,
      borderRadius: '50%',
    },
  };
});

const ErrorIconContainer = styled('span')(({ theme }) => {
  const {
    colours: {
      text: { textError },
    },
  } = theme;

  const COLOUR_TEXT_TEXT_ERROR: HexColour = textError;

  return {
    display: 'flex',

    '> svg': {
      height: '24px',
      width: '24px',

      '> path': {
        fill: COLOUR_TEXT_TEXT_ERROR,
      },
    },
  };
});

const UnCheckedIconContainer = styled('span')(({ theme }) => {
  const {
    colours: {
      borders: { borderInput },
    },
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT: HexColour = borderInput;

  return {
    display: 'flex',

    '> svg': {
      height: '24px',
      width: '24px',

      '> circle': {
        stroke: COLOUR_BORDER_BORDER_INPUT,
      },
    },
  };
});

const RPRadioListButton: FC<RadioListButtonProps> = (props: RadioListButtonProps) => {
  const {
    label,
    name,
    value,
    isChecked,
    handleChange,
    icon,
    description,
    isDisabled = false,
    hasError = false,
    variantType = 'twoLineNormal',
  } = props;

  const { tenant } = useContext(TenantContext);

  const ErrorIcon = useMemo(() => getIcon(tenant, 'statusWarning'), [tenant]);
  const UnCheckedIcon = useMemo(() => getIcon(tenant, 'checkmarkOff'), [tenant]);
  const CheckedIcon = useMemo(() => getIcon(tenant, 'checkmarkFilled'), [tenant]);

  const otherProps: VariantProps = {
    ...{ isDisabled },
    ...{ isChecked },
    ...{ variantType },
  };

  return (
    <RadioContainer
      onClick={() => !isDisabled && handleChange(value)}
      data-testid={`radio-container-${value}`}
      {...otherProps}
    >
      {icon && <IconContainer {...otherProps}>{icon}</IconContainer>}
      <TextContainer>
        <StyledLabel variant="body2" type="medium" text={label} hasError={hasError} {...otherProps} />
        {description && <StyledDescription variant="subtitle2" type="normal" text={description} {...otherProps} />}
      </TextContainer>
      <RadioButtonContainer>
        <StyledRadio
          disableRipple
          name={name}
          value={value}
          checked={isChecked}
          checkedIcon={<CheckedIcon data-testid="checked-icon" />}
          icon={
            hasError ? (
              <ErrorIconContainer>
                <ErrorIcon data-testid="error-icon" />
              </ErrorIconContainer>
            ) : (
              <UnCheckedIconContainer>
                <UnCheckedIcon data-testid="unchecked-icon" />
              </UnCheckedIconContainer>
            )
          }
          disabled={isDisabled}
        />
      </RadioButtonContainer>
    </RadioContainer>
  );
};

export default RPRadioListButton;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import { getServicesErrorObj } from '../modules/errorDetailsSlice';
import { OrganizationCodeTypes, OrganizationDetailsEnvSpecific } from '../../core/types/OrganizationTypes';
import { getConfigProperty } from '../../core/EnvironmentConfig/EnvironmentConfig';
import { GetBasicDetails } from '../../core/utils/GetBasicDetails';
import GenerateQueryParamsFromObject from '../../core/utils/GenerateQueryParamsFromObject';
import { AUTH_SERVICE_MAX_ATTEMPTS } from '../../core/utils/Constants/Constants';

const registerDevice = createAsyncThunk(
  'registerDevice', async (attempt: number, thunkAPI) => {

    try {

      const deviceIdInLS: string | null = localStorage.getItem('deviceId');
      console.log('registerDevice deviceIdInLS: ', deviceIdInLS);
      let deviceId: string | null = deviceIdInLS;

      if (!deviceIdInLS) {
        const DEVICE_ID: string = uuidv4();
        console.log('registerDevice - new generated device id: ', DEVICE_ID);
        localStorage.setItem('deviceId', DEVICE_ID);
        deviceId = DEVICE_ID;
      }

      const { referringCompany, urlEncodedOrganizationCode } = GetBasicDetails();
      const data = {
        org_code: urlEncodedOrganizationCode,
        app_type: 'PFX',
        device_id: deviceId,
        device_type: 'desktop',
        device_name: deviceId,
        os_type: 'Browser',
        locale: 'en',
      };
      const queryParams: string = GenerateQueryParamsFromObject(data);
      const basicAuthToken: string =
        (getConfigProperty('basicAuthToken') as OrganizationDetailsEnvSpecific)[referringCompany as OrganizationCodeTypes];

      const response = await axios({
        url: '/v2/oauth/access/register-device?' + queryParams,
        method: 'POST',
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "accept": "*/*",
          "Authorization": `Basic ${basicAuthToken}`,
        },
      });

      return response && response.data;
    } catch (error: any) {
      if (attempt < AUTH_SERVICE_MAX_ATTEMPTS) {
        const increaseAttempt = attempt + 1;
        thunkAPI.dispatch(registerDevice(increaseAttempt));
      } else {
        thunkAPI.dispatch(getServicesErrorObj(error));
        return thunkAPI.rejectWithValue({ error: error.response.data.message });
      }
    }
  });

export default registerDevice;
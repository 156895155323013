export const CurrenciesFlagMapping = {
    "GBP": "assets/united-kingdom",
    "ESP": "assets/spain",
    "NZD": "assets/new-zealand",
    "AUD": "assets/australia",
    "USD": "assets/united-states-of-america",
    "HKD": "assets/hong-kong",
    "JPY": "assets/japan",
    "BHD": "assets/bahrain",
    "BWP": "assets/botswana",
    "BRL": "assets/brazil",
    "BGN": "assets/bulgaria",
    "CAD": "assets/canada",
    "CNH": "assets/china",
    "HRK": "assets/croatia",
    "CZK": "assets/czech-republic",
    "DKK": "assets/denmark",
    "EGP": "assets/egypt",
    "FJD": "assets/fiji",
    "GHC": "assets/ghana",
    "GIP": "assets/gibraltar",
    "HUF": "assets/hungary",
    "INR": "assets/india",
    "ILS": "assets/israel",
    "KES": "assets/kenya",
    "KWD": "assets/kuwait",
    "MUR": "assets/mauritius",
    "MXN": "assets/mexico",
    "MAD": "assets/morocco",
    "NAD": "assets/namibia",
    "NOK": "assets/norway",
    "OMR": "assets/oman",
    "PKR": "assets/pakistan",
    "PHP": "assets/philippines",
    "PLN": "assets/republic-of-poland",
    "QAR": "assets/qatar",
    "ROL": "assets/romania",
    "RUB": "assets/russia",
    "SAR": "assets/saudi-arabia",
    "SGD": "assets/singapore",
    "SKK": "assets/slovakia",
    "ZAR": "assets/south-africa",
    "LKR": "assets/sri-lanka",
    "XCD": "assets/saint-kitts-and-nevis",
    "SEK": "assets/sweden",
    "CHF": "assets/switzerland",
    "THB": "assets/thailand",
    "TND": "assets/tunisia",
    "TRY": "assets/turkey",
    "UGX": "assets/uganda",
    "AED": "assets/united-arab-emirates",
    "ZMW": "assets/zambia",
    "EUR": "assets/european-union",
    "CNY": "assets/china",
    "RON": "assets/romania",
    "LTL": "assets/lithuania"
};
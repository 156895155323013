import { FC, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Control } from 'react-hook-form';

// Contexts
import { LanguageContext, LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
import RPCheckbox from '../../atoms/RPCheckbox';
import RPLink from '../../atoms/RPLink';
import ControllerElementWrapper from '../../organisms/ControllerWrapper';
// Types
import { LayoutProps } from '../../../core/types/LayoutProps';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LinkType } from '../../../core/types/GoogleAnalyticsTypes';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

interface AgreeTnCPrivacyPolicyProps {
  control: Control<any, any>;
  defaultValue: boolean;
  toggleCheckbox: () => void;
  termsAndConditionsLink: string;
  privacyPolicyLink: string;
  linkClickHandler?: (type: LinkType) => void;
}

interface TnCTextProps extends LayoutProps {}

const TnCContainer = styled('div')(() => {
  return {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '5px',
    alignItems: 'center'
  };
});

const TnCText = styled(RPText)<TnCTextProps>(({ theme, layout }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';

  return {
    fontSize: isDesktopLayout ? '16px' : isTabletLayout ? '14px' : '12px',
    color: COLOUR_TEXT_TEXT_WEAK,
    cursor: 'pointer'
  };
});

const CheckBoxContainer = styled('div')(() => {
  return {
    '> label': {
      marginRight: '5px',
      marginLeft: 0,

      '> span': {
        paddingRight: 0
      }
    }
  };
});

const AgreeTnCPrivacyPolicy: FC<AgreeTnCPrivacyPolicyProps> = (props: AgreeTnCPrivacyPolicyProps) => {
  const { layout } = useContext(LayoutContext);
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { control, defaultValue, linkClickHandler, toggleCheckbox, termsAndConditionsLink, privacyPolicyLink } = props;

  const tncInitialText: string = translate('agreeTC.initialText');
  const tncLinkText: string = translate('agreeTC.tncLinkText');
  const andText: string = translate('agreeTC.and');
  const PPLinkText: string = translate('agreeTC.privacyPolicyLinkText');

  const isDesktopLayout: boolean = layout === 'desktop';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const agreeTCVariant: 'body2' | 'subtitle2' | 'subtitle1' = isDesktopLayout
    ? 'body2'
    : isMobileLayout
    ? 'subtitle2'
    : 'subtitle1';

  const agreeTCType: 'medium' | 'normal' = isDesktopLayout ? 'medium' : 'normal';

  return (
    <TnCContainer>
      <CheckBoxContainer>
        <ControllerElementWrapper
          name="agreeTC"
          control={control}
          defaultValue={defaultValue}
          component={RPCheckbox}
          label=""
          type={isMobileLayout ? 'large' : 'normal'}
        />
      </CheckBoxContainer>
      <TnCText
        variant={agreeTCVariant}
        type={agreeTCType}
        text={tncInitialText}
        layout={layout}
        onClick={toggleCheckbox}
      />
      <RPLink
        text={tncLinkText}
        href={termsAndConditionsLink}
        variant={agreeTCVariant}
        type={agreeTCType}
        target="_blank"
        onClick={() => linkClickHandler && linkClickHandler(LinkType.TNC)}
        data-testid="rp-link-terms-and-conditions"
      />
      <TnCText variant={agreeTCVariant} type={agreeTCType} text={andText} layout={layout} onClick={toggleCheckbox} />
      <RPLink
        text={PPLinkText}
        href={privacyPolicyLink}
        variant={agreeTCVariant}
        type={agreeTCType}
        target="_blank"
        onClick={() => linkClickHandler && linkClickHandler(LinkType.PP)}
        data-testid="rp-link-privacy-policy"
      />
    </TnCContainer>
  );
};

export default AgreeTnCPrivacyPolicy;

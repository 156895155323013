import { useContext, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';

import { RPRadioButton, RPRadioListButton } from '../../atoms/RPRadioButton';
import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

const TimerIconContainer = styled('span')(({ theme }) => {

  const {
    colours: {
      text
    },
  } = theme;

  const COLOUR_TEXT_TEXT_INPUT_ICON: HexColour = text.textInputIcon;

  return {
    display: 'flex',

    '> svg > path': {
      fill: COLOUR_TEXT_TEXT_INPUT_ICON,
    },
  };
});

const UploadIconContainer = styled('span')(({ theme }) => {

  const {
    colours: {
      text
    },
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;

  return {
    display: 'flex',

    '> svg': {
      width: '24px',
      height: '24px',

      ' > path': {
        fill: COLOUR_TEXT_TEXT_LINK,
      },
    },
  };
});

const RPRadioVariants = () => {

  const { tenant } = useContext(TenantContext);

  const TimerIcon = useMemo(
    () => getIcon(tenant, 'planningTimer'), [tenant]);
  const UploadDesktopIcon = useMemo(
    () => getIcon(tenant, 'fileFolderAdd'), [tenant]);
  const UploadMobileIcon = useMemo(
    () => getIcon(tenant, 'technologyMobile'), [tenant]);

  const radioOptions = [
    {
      label: 'No',
      value: 'no',
    },
    {
      label: 'Yes',
      value: 'yes',
    },
  ];

  const defaultValue: string = 'no';

  const [radioBigValue, setRadioBigValue] = useState('');
  const [radioMediumValue, setRadioMediumValue] = useState('');

  return (
    <>
      {/* Simple radio button normal */}
      <RPRadioButton
        name="referred"
        radioOptions={radioOptions}
        defaultValue={defaultValue}
        label="Were you referred to us?"
      />

      <RPRadioButton
        name="referred"
        radioOptions={radioOptions}
        defaultValue={defaultValue}
        label="Were you referred to us?"
        size="small"
      />

      {/* 2 line normal */}
      {/* with icon */}
      <RPRadioListButton
        label="Social Security Number"
        name="idProof"
        value="socialSecurityNumber"
        isChecked={radioBigValue === 'socialSecurityNumber'}
        handleChange={setRadioBigValue}
        icon={
          <TimerIconContainer>
            <TimerIcon />
          </TimerIconContainer>
        }
        description="The fastest way to verify your identity."
      />

      {/* Without icon */}
      <RPRadioListButton
        label="Driver's License"
        name="idProof"
        value="driverLicense"
        isChecked={radioBigValue === 'driverLicense'}
        handleChange={setRadioBigValue}
        description="It can take up to couple of  hours to verify your identity."
      />

      {/* Disabled */}
      <RPRadioListButton
        label="Driver's License"
        name="idProof"
        value="driverLicense"
        isChecked={false}
        handleChange={setRadioBigValue}
        icon={
          <TimerIconContainer>
            <TimerIcon />
          </TimerIconContainer>
        }
        description="It can take up to couple of  hours to verify your identity."
        isDisabled={true}
      />

      {/* Disabled with error */}
      <RPRadioListButton
        label="Driver's License"
        name="idProof"
        value="driverLicense"
        isChecked={false}
        handleChange={setRadioBigValue}
        icon={
          <TimerIconContainer>
            <TimerIcon />
          </TimerIconContainer>
        }
        description="It can take up to couple of  hours to verify your identity."
        isDisabled={true}
        hasError={true}
      />

      {/* 1 line small */}
      <RPRadioListButton
        label="Upload now"
        name="documentUpload"
        value="desktopUpload"
        icon={
          <UploadIconContainer>
            <UploadDesktopIcon />
          </UploadIconContainer>
        }
        isChecked={radioMediumValue === 'desktopUpload'}
        handleChange={setRadioMediumValue}
        variantType="oneLineSmall"
      />

      <RPRadioListButton
        label="Use mobile app"
        name="documentUpload"
        value="mobileUpload"
        icon={
          <UploadIconContainer>
            <UploadMobileIcon />
          </UploadIconContainer>
        }
        isChecked={radioMediumValue === 'mobileUpload'}
        handleChange={setRadioMediumValue}
        variantType="oneLineSmall"
      />

      {/* disabled */}
      <RPRadioListButton
        label="Use mobile app"
        name="documentUpload"
        value="mobileUpload"
        icon={
          <UploadIconContainer>
            <UploadMobileIcon />
          </UploadIconContainer>
        }
        isChecked={false}
        handleChange={setRadioMediumValue}
        variantType="oneLineSmall"
        isDisabled={true}
        // hasError={true}
      />

      {/* disabled with error */}
      <RPRadioListButton
        label="Use mobile app"
        name="documentUpload"
        value="mobileUpload"
        icon={
          <UploadIconContainer>
            <UploadMobileIcon />
          </UploadIconContainer>
        }
        isChecked={false}
        handleChange={setRadioMediumValue}
        variantType="oneLineSmall"
        isDisabled={true}
        hasError={true}
      />
    </>
  );
};

export default RPRadioVariants;

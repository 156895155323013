import { FC, memo, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { List, ListItem, ListItemText, ListItemProps } from '@mui/material';

import { LanguageContext } from '../../../core/TenantProvider/contexts';
import RPAddressDetails from './RPAddressDetails';
import { AddressItemsType, AddressItem } from '../../../core/types/addressLookupTypes';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';

interface RPAddressListProps {
  addressList: AddressItemsType;
  onSelectAddress: (entry: AddressItem) => void;
  hasAddressResults: boolean;
}
interface CustomListItemTextProps {
  isNoResultFound?: boolean;
}

const CustomListItem = styled(ListItem)<ListItemProps>(({ theme }) => {
  const {
    colours: { backgrounds }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;

  return {
    height: 'max-contexts',
    background: COLOUR_BG_BACKGROUND_ALT,
    borderRadius: '4px',
    '& .MuiListItemText-root': {
      margin: 0
    }
  };
});

const CustomListItemText = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isNoResultFound'
})<CustomListItemTextProps>(({ theme, isNoResultFound }) => {
  const {
    colours: { backgrounds, text }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;
  const COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK: HexColour = backgrounds.backgroundAltInverseWeak;
  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;

  return {
    ...getBody2NormalStyles(theme),
    background: COLOUR_BG_BACKGROUND_ALT,
    borderRadius: '4px',
    padding: '8px 10px 8px 10px',
    display: 'flex',
    justifyContent: 'space-between',

    ...(isNoResultFound && {
      background: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK,
      borderRadius: '4px'
    }),

    '&:hover': {
      background: COLOUR_BG_BACKGROUND_ALT_INVERSE_WEAK,
      borderRadius: '4px',
      cursor: isNoResultFound ? 'default' : 'pointer',
      '& svg': {
        '& path': {
          fill: COLOUR_TEXT_TEXT_LINK
        }
      }
    }
  };
});

const RPAddressList: FC<RPAddressListProps> = ({
  addressList,
  onSelectAddress,
  hasAddressResults
}: RPAddressListProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const noResultsFound: string = translate('registration.addressLookup.noResultsFound');
  return (
    <List sx={{ width: '100%' }}>
      {hasAddressResults ? (
        addressList?.Items?.map((entry, index) => (
          <CustomListItem
            key={index}
            disableGutters
            disablePadding
            onClick={() => onSelectAddress(entry)}
            data-testid="rp-address-list-item"
          >
            <ListItemText>
              <CustomListItemText>
                <RPAddressDetails entry={entry} />
              </CustomListItemText>
            </ListItemText>
          </CustomListItem>
        ))
      ) : (
        <CustomListItem disableGutters disablePadding data-testid="rp-address-list-item">
          <ListItemText>
            <CustomListItemText isNoResultFound>{noResultsFound}</CustomListItemText>
          </ListItemText>
        </CustomListItem>
      )}
    </List>
  );
};

export default memo(RPAddressList);

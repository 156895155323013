import { ChangeEvent, FC, useContext, useMemo, MouseEvent, KeyboardEvent } from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

// Contexts
import { TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPLoader from '../../atoms/RPLoader';
import RPEnterManualAddressLink from './RPEnterManualAddressLink';
import RPAddressList from './RPAddressList';
import { FullScreenDialog } from '../../atoms/FullScreenDialog';
import { RPMobileOptimisedNoOptionUI } from '../../atoms/RPMobileOptimisedNoOptionUI';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { AddressItem, AddressItemsType } from '../../../core/types/addressLookupTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';
import { getBody2NormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

interface RPMobileOptimisedAddressLookupProps {
  open: boolean;
  isLoading: boolean;
  addressItems: AddressItemsType;
  hasAddressResults: boolean;
  placeholder: string;
  searchText: string;
  onAddressSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  setIsManualEntry: (entry: boolean) => void;
  setCountryChange: (value: boolean) => void;
  handleCloseDialog: () => void;
  setShowMobileOptimisedView: (value: boolean) => void;
  onSelectAddress: (entry: AddressItem) => void;
  handleMobileOptimisedClearText: () => void;
}

const StyledInput = styled(InputBase)(({ theme }) => {
  const {
    colours: {
      borders: { borderInput, borderInputFocus },
      backgrounds: { backgroundAlt },
      text: { textInputLabel }
    }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT: HexColour = borderInput;
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  const COLOUR_TEXT_TEXT_INPUT_LABEL: HexColour = textInputLabel;
  const COLOUR_BORDER_BORDER_INPUT_FOCUS: HexColour = borderInputFocus;

  return {
    width: '100%',
    borderRadius: 'inherit',
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    borderBottom: `1px solid ${COLOUR_BORDER_BORDER_INPUT}`,
    padding: '0px 8px',
    '&.Mui-focused': {
      borderBottomColor: COLOUR_BORDER_BORDER_INPUT_FOCUS
    },
    '& input': {
      backgroundColor: COLOUR_BG_BACKGROUND_ALT,
      padding: '18px 0px 18px 8px',
      ...getBody2NormalStyles(theme),
      '&:: placeholder': {
        color: COLOUR_TEXT_TEXT_INPUT_LABEL
      }
    }
  };
});

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: {
      text: { textLink }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = textLink;

  return {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    width: '24px',
    '> svg': {
      cursor: 'pointer',
      height: '24px',
      width: '24px',
      ' > path': {
        fill: COLOUR_TEXT_TEXT_LINK
      }
    }
  };
});

const AddressListContainer = styled('div')(({ theme }) => {
  const {
    colours: {
      borders: { borderInput }
    }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT: HexColour = borderInput;

  return {
    padding: 16,
    '> ul': {
      paddingTop: 0,
      paddingBottom: 0,

      '> li': {
        marginBottom: 4,
        border: `1px solid ${COLOUR_BORDER_BORDER_INPUT}`,
        borderRadius: 4,
        '> div > span > div': {
          padding: '12px 16px'
        },

        ':last-child': {
          marginBottom: 0
        }
      }
    }
  };
});

const NoAddressViewContainer = styled('div')(() => {
  return {
    padding: 16
  };
});

const RPMobileOptimisedAddressLookup: FC<RPMobileOptimisedAddressLookupProps> = (
  props: RPMobileOptimisedAddressLookupProps
) => {
  const {
    open,
    isLoading,
    addressItems,
    hasAddressResults,
    placeholder,
    searchText,
    handleCloseDialog,
    onAddressSearch,
    setIsManualEntry,
    setCountryChange,
    setShowMobileOptimisedView,
    onSelectAddress,
    handleMobileOptimisedClearText
  } = props;

  const { tenant } = useContext(TenantContext);
  const LeftArrowIcon = useMemo(() => getIcon(tenant, 'navigationChevronLeftIcon'), [tenant]);
  const CloseIcon = useMemo(() => getIcon(tenant, 'navigationClose'), [tenant]);

  const handleManualAddressClick = (event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setIsManualEntry(true);
    setCountryChange(false);
    setShowMobileOptimisedView(false);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    onAddressSearch(event);
  };

  const handleClearText = () => {
    handleMobileOptimisedClearText();
  };

  return (
    <FullScreenDialog open={open}>
      <StyledInput
        data-testid="full-screen-search-input"
        name="mobileOptimisedSearchField"
        autoFocus={true}
        placeholder={placeholder}
        onChange={handleOnChange}
        value={searchText}
        startAdornment={
          <IconContainer>
            <LeftArrowIcon onClick={handleCloseDialog} data-testid="rp-left-arrow-icon" />
          </IconContainer>
        }
        endAdornment={
          <IconContainer>
            {isLoading ? (
              <RPLoader type="secondary" size="small" />
            ) : (
              <CloseIcon data-testid="rp-clear-text-icon" onClick={handleClearText} />
            )}
          </IconContainer>
        }
      />
      <RPEnterManualAddressLink handleManualAddressClick={handleManualAddressClick} isMobileOptimised={true} />
      {!hasAddressResults && (
        <NoAddressViewContainer data-testid="rp-no-address-container">
          <RPMobileOptimisedNoOptionUI />
        </NoAddressViewContainer>
      )}
      {addressItems && addressItems?.Items.length > 0 && hasAddressResults && (
        <AddressListContainer>
          <RPAddressList
            addressList={addressItems}
            onSelectAddress={onSelectAddress}
            hasAddressResults={hasAddressResults}
          />
        </AddressListContainer>
      )}
    </FullScreenDialog>
  );
};

export default RPMobileOptimisedAddressLookup;

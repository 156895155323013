const checkValidation = (currentVal: string, regex: RegExp) => {
  return currentVal.trim().length === 0 ? false : regex.test(currentVal);
};

export const isFieldValid = (field: string, value: string) => {
  let isValid: boolean = true;
  let currentVal: string = value;

  switch (field) {
    case 'line1':
      isValid = checkValidation(currentVal, /^[\S\s]{2,40}$/);
      break;
    case 'city':
      isValid = checkValidation(currentVal, /^[\S\s]{1,20}$/);
      break;
    case 'county':
      isValid = checkValidation(currentVal, /^[\S\s]{1,50}$/);
      break;
    case 'postcode':
      isValid = checkValidation(currentVal, /^([0-9a-zA-Z- \S\s]{3,15})$/);
      break;
  }
  return isValid;
};

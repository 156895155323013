import { State, Action } from '../../../types/addressLookupTypes';

export const initialState: State = {
  searchText: '',
  isLoading: false,
  hasSelectedValues: false,
  open: false,
  address: {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    suburbCity: '',
    province: '',
    townCity: '',
    county: '',
    state: '',
    postcode: ''
  },
  errors: {
    line1: false,
    city: false,
    county: false,
    postcode: false
  },
  addressItems: {
    Items: []
  },
  hasAddressResults: true
};

export const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'set-search-text': {
      return { ...state, searchText: action.payload };
    }
    case 'set-is-loading': {
      return { ...state, isLoading: action.payload };
    }
    case 'set-has-selectedValues': {
      return { ...state, hasSelectedValues: action.payload };
    }
    case 'set-open': {
      return { ...state, open: action.payload };
    }
    case 'set-address': {
      return { ...state, address: action.payload };
    }
    case 'set-manualInput-error': {
      return { ...state, errors: action.payload };
    }
    case 'set-address-items': {
      return { ...state, addressItems: action.payload };
    }
    case 'reset-address-items': {
      return { ...state, addressItems: initialState.addressItems };
    }
    case 'set-has-address-results': {
      return { ...state, hasAddressResults: action.payload };
    }
    case 'reset': {
      return initialState;
    }
    default:
      return initialState;
  }
};

import {
  BasicDetails,
  LocaleIdMapping,
  OrganizationCodeTypes,
  OrganizationDetails
} from '../../types/OrganizationTypes';
import { GetOrganizationFromURL } from '../GetOrganizationFromURL';
import { LANG_CODE_MAPPING, LOCALE_LANG_CODE_MAPPING } from '../Constants/Constants';

const ORGANIZATION_DETAILS: OrganizationDetails = {
  currenciesdirect: {
    organizationCodeId: 2,
    organizationCode: 'Currencies Direct',
    parentOrganizationCode: 'Currencies Direct',
    urlEncodedOrganizationCode: 'Currencies%20Direct',
    siteId: 2,
    leadSource: 'NGOP',
    source: 'NGOP',
    countryCode: '+44*GBR',
    countryOfResidence: 'GBR',
    countryOfResidenceName: 'United Kingdom',
    businessRegistrationCFXURL: 'https://registration.currenciesdirect.com/b2b/?bu=CFX&type=full',
    isUpdateDocLaterInactiveDashboardFlowEnabled: true,
    channel: null
  },
  torfx: {
    organizationCodeId: 1,
    organizationCode: 'TorFX',
    parentOrganizationCode: 'TorFX',
    urlEncodedOrganizationCode: 'TorFX',
    siteId: 1,
    leadSource: 'Web',
    source: 'NGOP',
    countryCode: '+44*GBR',
    countryOfResidence: 'GBR',
    countryOfResidenceName: 'United Kingdom',
    businessRegistrationCFXURL: 'https://registration.torfx.com/b2b/',
    isUpdateDocLaterInactiveDashboardFlowEnabled: false,
    channel: null
  },
  torfxoz: {
    organizationCodeId: 3,
    organizationCode: 'TorFXOz',
    parentOrganizationCode: 'TorFXOz',
    urlEncodedOrganizationCode: 'TorFXOz',
    siteId: 4,
    leadSource: 'Web',
    source: 'NGOP',
    countryCode: '+61*AUS',
    countryOfResidence: 'AUS',
    countryOfResidenceName: 'Australia',
    businessRegistrationCFXURL: 'https://registration.torfx.com.au/b2b/',
    isUpdateDocLaterInactiveDashboardFlowEnabled: false,
    channel: null
  },
  fcg: {
    organizationCodeId: 19,
    organizationCode: 'FCG',
    parentOrganizationCode: 'FCG',
    urlEncodedOrganizationCode: 'FCG',
    siteId: 8,
    leadSource: 'NGOP',
    source: 'NGOP',
    countryCode: '+44*GBR',
    countryOfResidence: 'GBR',
    countryOfResidenceName: 'United Kingdom',
    businessRegistrationCFXURL: 'https://registration.currenciesdirect.com/b2b/?bu=CFX&type=full',
    isUpdateDocLaterInactiveDashboardFlowEnabled: false,
    channel: null
  },
  ramsdens: {
    organizationCodeId: 18,
    organizationCode: 'Ramsdens',
    parentOrganizationCode: 'TorFX',
    urlEncodedOrganizationCode: 'Ramsdens',
    siteId: 7,
    leadSource: 'NGOP',
    source: 'NGOP',
    countryCode: '+44*GBR',
    countryOfResidence: 'GBR',
    countryOfResidenceName: 'United Kingdom',
    businessRegistrationCFXURL: 'https://registration.currenciesdirect.com/b2b/?bu=CFX&type=full',
    isUpdateDocLaterInactiveDashboardFlowEnabled: false,
    channel: null
  },
  hl: {
    organizationCodeId: -1,
    organizationCode: 'HL',
    parentOrganizationCode: 'Currencies Direct',
    urlEncodedOrganizationCode: 'HL',
    siteId: 14,
    leadSource: 'NGOP',
    source: 'NGOP',
    countryCode: '+44*GBR',
    countryOfResidence: 'GBR',
    countryOfResidenceName: 'United Kingdom',
    businessRegistrationCFXURL: 'https://registration.currenciesdirect.com/b2b/?bu=CFX&type=full',
    isUpdateDocLaterInactiveDashboardFlowEnabled: false,
    channel: null
  },
  propertypay: {
    organizationCodeId: 2,
    organizationCode: 'Currencies Direct',
    parentOrganizationCode: 'Currencies Direct',
    urlEncodedOrganizationCode: 'Currencies%20Direct',
    siteId: 2,
    leadSource: 'NGOP',
    source: 'NGOP',
    countryCode: '+44*GBR',
    countryOfResidence: 'GBR',
    countryOfResidenceName: 'United Kingdom',
    businessRegistrationCFXURL: 'https://registration.currenciesdirect.com/b2b/?bu=CFX&type=full',
    isUpdateDocLaterInactiveDashboardFlowEnabled: false,
    channel: 'Property Pay'
  }
};

const GetBasicDetails = (locale: string = LOCALE_LANG_CODE_MAPPING[LANG_CODE_MAPPING.EN]): BasicDetails => {
  const referringCompany: string = GetOrganizationFromURL(window.location.hostname);
  const LOCALE_ID_MAPPING: LocaleIdMapping = {
    en: 1,
    us: 1,
    fr: 3,
    no: 7,
    se: 9
  };

  const {
    organizationCodeId: orgCodeID,
    organizationCode: orgCode,
    parentOrganizationCode,
    urlEncodedOrganizationCode,
    siteId,
    source,
    leadSource,
    countryCode,
    countryOfResidence,
    countryOfResidenceName,
    businessRegistrationCFXURL,
    isUpdateDocLaterInactiveDashboardFlowEnabled,
    channel
  } = ORGANIZATION_DETAILS[referringCompany as OrganizationCodeTypes];
  const localeId: number = LOCALE_ID_MAPPING[locale as keyof LocaleIdMapping];

  return {
    referringCompany,
    orgCodeID,
    orgCode,
    locale,
    localeId,
    source,
    leadSource,
    countryCode,
    countryOfResidence,
    countryOfResidenceName,
    accountType: 'PFX',
    parentOrganizationCode,
    urlEncodedOrganizationCode,
    siteId,
    businessRegistrationCFXURL,
    isUpdateDocLaterInactiveDashboardFlowEnabled,
    channel
  };
};

export default GetBasicDetails;

import { FC, useMemo, memo } from 'react';

// Types
import { IconObject } from '../../../core/types/IconOrgTypes';
import { OrganizationCodeTypes } from '../../../core/types/OrganizationTypes';
// Utils
import { getIcon } from '../../../core/utils/IconOrgData';

interface RPLogoProps {
  logoVariant: keyof IconObject;
  tenant: OrganizationCodeTypes | undefined;
}

const RPLogo: FC<RPLogoProps> = (props: RPLogoProps) => {
  const { logoVariant, tenant } = props;

  const Logo = useMemo(() => getIcon(tenant, logoVariant), [tenant, logoVariant]);

  return <Logo data-testid="rp-logo-icon" />;
};

export default memo(RPLogo);

export const getUSAFormattedNumber = (number: string) => {
  const cleanedNumber: string = number.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
  const zip: string = cleanedNumber.substring(0, 3);
  const middle: string = cleanedNumber.substring(3, 6);
  const last: string = cleanedNumber.substring(6, 10);

  if (cleanedNumber.length > 6) {
    return `(${zip}) ${middle} - ${last}`;
  } else if (cleanedNumber.length > 3) {
    return `(${zip}) ${middle}`;
  } else if (cleanedNumber.length > 0) {
    return `(${zip}`;
  } else {
    return '';
  }
};

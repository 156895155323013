const GenerateQueryParamsFromObject = (incomingObject: any): string => {

  //org_code=Currencies%20Direct&
  // app_type=PFX&
  // device_id=Testing-0987654321&
  // device_type=ipad&
  // device_name=Testing-0987654321&
  // os_type=Android

  const INCOMING_OBJECT_LENGTH: number = Object.keys(incomingObject).length;
  let queryParams: string = '';

  Object.keys(incomingObject).forEach((key, index) => {
    const isLastObject: boolean = index === (INCOMING_OBJECT_LENGTH - 1);

    // console.log('INCOMING_OBJECT_LENGTH: ', INCOMING_OBJECT_LENGTH);
    // console.log('index: ', index);
    // console.log('isLastObject: ', isLastObject);

    queryParams += key + '=' + incomingObject[key] + (!isLastObject ? '&' : '');
  });

  console.log('queryParams: ', queryParams);

  return queryParams;
};

export default GenerateQueryParamsFromObject;
import { FC } from 'react';
import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
import RPButton from '../../atoms/RPButton';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface RequestOTPBySMSOrCallProps {
  description: string;
  handleButtonClick: () => void;
  buttonText: string;
  buttonName: string;
  dataTestId: string;
}

const Container = styled('div')(({ theme }) => {
  const {
    colours: {
      backgrounds: { background }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND: HexColour = background;

  return {
    padding: 24,
    borderRadius: 8,
    backgroundColor: COLOUR_BG_BACKGROUND
  };
});

const Description = styled(RPText)(() => {
  return {
    marginBottom: 16,
    textAlign: 'center'
  };
});

const ButtonContainer = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center'
  };
});

const StyledRPButton = styled(RPButton)(() => {
  return {
    minWidth: 220
  };
});

const RequestOTPBySMSOrCall: FC<RequestOTPBySMSOrCallProps> = (props: RequestOTPBySMSOrCallProps) => {
  const { description, handleButtonClick, buttonText, buttonName, dataTestId } = props;

  return (
    <Container>
      <Description type="normal" variant="body2" text={description} />
      <ButtonContainer>
        <StyledRPButton
          size="medium"
          btnType="outlined"
          onClick={handleButtonClick}
          name={buttonName}
          data-testid={dataTestId}
        >
          {buttonText}
        </StyledRPButton>
      </ButtonContainer>
    </Container>
  );
};

export default RequestOTPBySMSOrCall;

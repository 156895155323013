import { useContext } from 'react';
import * as yup from 'yup';

import { LanguageContext } from '../../../../../core/TenantProvider/contexts';
import { COUNTRY_CODE_USA } from '../../../../../core/utils/Constants/Constants';
import TranslateWrapper from '../../../../../core/utils/TranslateWrapper';

export const ValidationSchema = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const countryCodeRequiredText: string = translate('registration.setupSecurity.countryCodeRequired');

  const mobileNumberRequiredText: string = translate('registration.setupSecurity.mobileNumberRequired');

  const mobileNumberInvalidText: string = translate('registration.setupSecurity.mobileNumberInvalid');

  const mobileNumberUSAValidationText: string = translate('registration.setupSecurity.mobileNumberUSAValidation');

  return yup
    .object({
      countryCode: yup.string().required(countryCodeRequiredText),

      mobileNumber: yup.string().when('countryCode', {
        is: (countryCode: string) => {
          return countryCode === COUNTRY_CODE_USA;
        },
        then: yup
          .string()
          .trim()
          .required(mobileNumberRequiredText)
          .test('validate-usa-number', mobileNumberUSAValidationText, function (mobileNumber) {
            return mobileNumber?.replace(/\D/g, '').length === 10;
          }),
        otherwise: yup
          .string()
          .trim()
          .required(mobileNumberRequiredText)
          .min(6, mobileNumberInvalidText)
          .max(12, mobileNumberInvalidText)
          .test('validate-only-number', mobileNumberInvalidText, function (mobileNumber) {
            return !!mobileNumber?.match(/^[0-9]*$/);
          })
      })
    })
    .required();
};

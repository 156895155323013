import _ from 'lodash';

// Types
import { OptionType } from '../../types/SelectTypes';
// Constants
import {
  ORGANIZATION_CODE_MAPPING,
  PROPERTY_PAY_CURRENCY_CODE_BASED_ETV_VALUE_MAPPING,
  PROPERTY_PAY_CURRENCY_ETV_DEFAULT_VALUE,
  USA,
  USA_COR_ETV_DEFAULT_VALUE
} from '../Constants/Constants';
import { countryMetadata } from '../../CountryMetadata/CountryMetadata';

interface CurrencyPairAndETVData {
  currencyFrom: string;
  currencyTo: string;
  amount: string;
  isETVExist: boolean;
}

const GetCurrencyPairAndEtvAmount = (
  countryOfResidence: string,
  brand: string,
  currentData: CurrencyPairAndETVData | {},
  currenciesOptionData?: OptionType[]
): {
  currencyFrom: string;
  currencyTo: string;
  amount: string;
  isETVExistFlag: boolean;
} => {
  let currencyData: OptionType | undefined;
  let currencyBasedEtvValue: string;
  let currencyFrom: string = '';
  let currencyTo: string = '';
  let amount: string = '';
  let isETVExistFlag: boolean = false;

  const corIsUSA: boolean = countryOfResidence.toLocaleLowerCase() === USA;
  const isCurrentBrandPropertyPay: boolean = brand === ORGANIZATION_CODE_MAPPING.propertypay;

  if (!_.isEmpty(currentData)) {
    const { currencyFrom, currencyTo, amount, isETVExist } = currentData as CurrencyPairAndETVData;
    if (isETVExist) {
      return {
        currencyFrom,
        currencyTo,
        amount,
        isETVExistFlag: isETVExist
      };
    } else if (corIsUSA && !isCurrentBrandPropertyPay) {
      return {
        currencyFrom: USA_COR_ETV_DEFAULT_VALUE.currencyFrom,
        currencyTo: USA_COR_ETV_DEFAULT_VALUE.currencyTo,
        amount: USA_COR_ETV_DEFAULT_VALUE.amount,
        isETVExistFlag: corIsUSA
      };
    }
  }

  const countryInfoBasedOnCOR = countryMetadata.find((el) => el.value === countryOfResidence);
  if (isCurrentBrandPropertyPay && currenciesOptionData && currenciesOptionData.length) {
    if (countryInfoBasedOnCOR) {
      currencyData = currenciesOptionData.find((el) => el.value === countryInfoBasedOnCOR.currencyCode);

      currencyFrom = currencyData ? currencyData.value : PROPERTY_PAY_CURRENCY_ETV_DEFAULT_VALUE.currencyFrom;
      currencyTo = PROPERTY_PAY_CURRENCY_ETV_DEFAULT_VALUE.currencyTo;

      currencyBasedEtvValue = PROPERTY_PAY_CURRENCY_CODE_BASED_ETV_VALUE_MAPPING[currencyFrom];
      amount = currencyBasedEtvValue ? currencyBasedEtvValue : PROPERTY_PAY_CURRENCY_ETV_DEFAULT_VALUE.amount;

      isETVExistFlag = true;
    } else {
      currencyFrom = PROPERTY_PAY_CURRENCY_ETV_DEFAULT_VALUE.currencyFrom;
      currencyTo = PROPERTY_PAY_CURRENCY_ETV_DEFAULT_VALUE.currencyTo;
      amount = PROPERTY_PAY_CURRENCY_ETV_DEFAULT_VALUE.amount;
      isETVExistFlag = true;
    }
  }

  return {
    currencyFrom,
    currencyTo,
    amount,
    isETVExistFlag
  };
};

export default GetCurrencyPairAndEtvAmount;

import { useEffect } from 'react';
import { Update, createBrowserHistory } from 'history';

function usePreventBackAction() {
  const history = createBrowserHistory();

  useEffect(() => {
    history.listen((update: Update) => {
      update.action === 'POP' && history.go(1);
    });
  }, [history]);
}

export default usePreventBackAction;

import { createAsyncThunk } from '@reduxjs/toolkit';

// Types
import { CustomError } from '../../core/types/RegistrationStepForm';
// RTK Slice
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
// API Wrappers
import axiosWrapper, { ObjectMapping } from './axiosWrapper';
// Utils
import GetBasicDetails from '../../core/utils/GetBasicDetails';

const { orgCode } = GetBasicDetails();

type otpPayloadData = {
  countryCode: string;
  mobileNumber: string;
  securityPin: string;
  locale: string;
};

const checkOTP = createAsyncThunk('communication-service/checkOTP', async (data: otpPayloadData, thunkAPI) => {

  const { parentOrganizationCode } = GetBasicDetails();

  const payload: ObjectMapping = {
    orgCode: orgCode,
    parentOrgCode: parentOrganizationCode,
    phoneNumber: `${data.countryCode}-${data.mobileNumber}`,
    otp: data.securityPin,
    locale: data.locale
  };

  try {
    const response = await axiosWrapper('checkOTP', 'POST', thunkAPI, payload);
    return response && response.data;
  } catch (error: any) {
    const { responseMessage, responseCode } = error?.response?.data?.data;

    /*
     * We are creating a custom error object with type as 'checkOTP'
     * The API will return HTTP error code as 500, but there can be multiple Twilio error codes for this
     * C003 and C006 are two such examples for which we need to show separate error messages
     * Therefore these error codes are being sent to getServicesErrorObj() instead of the HTTP error code
     * */
    const customErrorObj: CustomError = {
      type: 'checkOTP',
      response: {
        data: {
          message: responseMessage
        },
        status: responseCode
      }
    };

    thunkAPI.dispatch(getServicesErrorObj(responseCode ? customErrorObj : error));
    return thunkAPI.rejectWithValue({ error: responseMessage });
  }
});

export default checkOTP;

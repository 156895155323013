import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// RTK Slice
import { getAuthorisationDetails } from '../../redux/modules/authorisationDetailsSlice';
// API Wrappers
import generateToken, { GenerateTokenData } from '../../redux/api/generateToken';
import registerDevice from '../../redux/api/registerDevice';

const useRegisterDeviceAndGenerateToken = () => {
  const dispatch: any = useDispatch();
  let dataFetchedRef = useRef(false);

  const { registerDeviceLoading, generateTokenLoading, clientId, clientSecret } = useSelector(getAuthorisationDetails);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (registerDeviceLoading === 'idle' && (clientId === '' || clientSecret === '')) {
      dispatch(registerDevice(1));
    }
  }, [registerDeviceLoading, clientId, clientSecret]);

  useEffect(() => {
    if (registerDeviceLoading === 'succeeded' && generateTokenLoading !== 'loading') {
      const generateTokenData: GenerateTokenData = {
        clientId,
        clientSecret,
        attempt: 1
      };

      dispatch(generateToken(generateTokenData));
    }
  }, [registerDeviceLoading, generateTokenLoading]);
};

export default useRegisterDeviceAndGenerateToken;

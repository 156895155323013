import { useContext, useEffect, useState } from 'react';

import RPSelectDropdown from '../../atoms/RPSelectDropdown/RPSelectDropdown';
import { LanguageContext } from '../../../core/TenantProvider/contexts';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { LANGUAGE_DROPDOWN_DATA } from '../../../core/utils/Constants/Constants';

function RPLanguageTranslation() {
  const { setLanguage, translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);
  const [statementInputValue, setStatementInputValue] = useState<
    string | number | null
  >('en-gb');

  useEffect(() => {
    setLanguage(statementInputValue);
  }, [statementInputValue, setLanguage]);

  const onChangeStatement = (evt: any) => {
    evt.preventDefault();
    const {
      target: { value }
    } = evt;
    setStatementInputValue(value);
    setLanguage(value);
  };

  return (
    <RPSelectDropdown
      label={translate('landing.languageSelectDropDown')}
      onChange={onChangeStatement}
      data={LANGUAGE_DROPDOWN_DATA}
      value={statementInputValue}
      data-testid="rp-select-dropdown"
    />
  );
}

export default RPLanguageTranslation;

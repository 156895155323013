import { FC, ChangeEvent, KeyboardEvent, ClipboardEvent, useRef, useLayoutEffect, memo } from 'react';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FilledInput from '@mui/material/FilledInput';

import { usePreviousValue } from '../../../core/hooks';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { getH2MediumStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';

export type PinInputSize = 'small' | 'medium' | 'large';

export interface SizeTypes {
  customSize: 'small' | 'medium' | 'large';
}

interface RPPinInputProps {
  name: string;
  value: string;
  error?: boolean;
  autocomplete?: 'on' | 'off';
  isDisabled?: boolean;
  maxLength?: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  onPaste: (e: ClipboardEvent<HTMLInputElement>) => void;
  focus?: boolean;
  size?: SizeTypes['customSize'];
}

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (props) => props !== 'customSize'
})<SizeTypes>(({ theme, error, disabled, customSize }) => {
  const isSmallSize: boolean = customSize === 'small';
  const isMediumSize: boolean = customSize === 'medium';

  const {
    colours: { borders }
  } = theme;

  const COLOUR_BORDER_BORDER_SELECTION_HOVER: HexColour = borders.borderSelectionHover;
  const COLOUR_BORDER_BORDER_ERROR: HexColour = borders.borderError;
  const COLOUR_BORDER_BORDER_INPUT: HexColour = borders.borderInput;
  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borders.borderInputActive;

  let borderColor: string = 'transparent';
  let borderHoverColor: HexColour = COLOUR_BORDER_BORDER_SELECTION_HOVER;

  if (error) {
    borderColor = COLOUR_BORDER_BORDER_ERROR;
    borderHoverColor = COLOUR_BORDER_BORDER_ERROR;
  } else if (disabled) {
    borderColor = COLOUR_BORDER_BORDER_INPUT;
    borderHoverColor = COLOUR_BORDER_BORDER_INPUT;
  }
  return {
    border: `${disabled ? '1px' : '2px'} solid transparent`,
    boxSizing: 'border-box',
    ...(!disabled &&
      !error && {
        boxShadow: `${COLOUR_BORDER_BORDER_INPUT} 0px 0px 0px 1px`
      }),
    borderColor: borderColor,
    borderRadius: '4px',
    width: isSmallSize ? '53px' : isMediumSize ? '58px' : '72px',
    height: isSmallSize ? '70px' : isMediumSize ? '72px' : '88px',
    transition: 'all 0.3s ease-in-out 0s',

    '&:hover': {
      borderColor: borderHoverColor
    },

    '&:focus-within': {
      border: `2px solid ${error ? COLOUR_BORDER_BORDER_ERROR : COLOUR_BORDER_BORDER_INPUT_ACTIVE}`
    }
  };
});

const StyledInput = styled(FilledInput)(({ theme }) => {
  const {
    colours: { backgrounds, text }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;
  const COLOUR_TEXT_TEXT_INPUT_DISABLED: HexColour = text.textInputDisabled;
  const COLOUR_BG_BACKGROUND_INPUT_DISABLED: HexColour = backgrounds.backgroundInputDisabled;

  return {
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    height: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'inherit',

    ':before, :after': {
      borderBottom: 'none',
      transition: 'none'
    },

    '&.Mui-focused': {
      backgroundColor: COLOUR_BG_BACKGROUND_ALT
    },

    '&:hover': {
      backgroundColor: COLOUR_BG_BACKGROUND_ALT,
      '&:not(.Mui-disabled)': {
        '&:before': {
          borderBottom: 'none'
        }
      }
    },

    '&.Mui-disabled': {
      backgroundColor: COLOUR_BG_BACKGROUND_INPUT_DISABLED,
      color: 'transparent'
    },

    '> input': {
      ...getH2MediumStyles(theme),
      padding: 0,
      textAlign: 'center',
      height: '38px',
      width: '18px',

      ':disabled': {
        color: COLOUR_TEXT_TEXT_INPUT_DISABLED
      },

      // To hide the up and down arrow buttons for input type number
      '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },

      '&[type=number]': {
        MozAppearance: 'textfield'
      }
    }
  };
});

const RPPinInput: FC<RPPinInputProps> = (props: RPPinInputProps) => {
  const { error, autocomplete = 'off', isDisabled = false, maxLength = 1, focus, size = 'large', ...rest } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePreviousValue(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus) {
        inputRef.current.focus();
      }
      if (focus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [focus, prevFocus]);

  return (
    <StyledFormControl
      variant="filled"
      error={!!error}
      disabled={isDisabled}
      data-testid="pin-form-control"
      customSize={size}
    >
      <StyledInput
        type="number"
        autoComplete={autocomplete}
        disabled={isDisabled}
        inputProps={{
          maxLength: maxLength
        }}
        inputRef={inputRef}
        {...rest}
      />
    </StyledFormControl>
  );
};

export default memo(RPPinInput);

import { FC, memo, ReactElement, useContext, useMemo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { RPText } from '../../atoms/RPText';
import { BorderColours, HexColour } from '../../../core/types/ThemeConfigTypes';
import { TenantContext } from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';

type NotificationSizes = 'normal' | 'small';

type NotificationVariants = 'error' | 'warning' | 'success';

type NotificationIconMapping = {
  [key in NotificationVariants]?: ReactElement;
};

type NotificationBorderColours = {
  [key in NotificationVariants]?: HexColour;
};

const getNotificationBorderColours = (
  borders: BorderColours
): NotificationBorderColours => {
  return {
    error: borders.borderError,
    warning: borders.borderWarning,
    success: borders.borderSuccess,
  };
};

interface StyledNotificationProps extends BoxProps {
  variant: NotificationVariants;
  size?: NotificationSizes;
}

const StyledNotification = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'size',
})<StyledNotificationProps>(({ theme, variant, size }) => {
  const {
    colours: { backgrounds, borders, text },
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;

  const NOTIFICATION_BORDER_COLOUR_MAPPING: NotificationBorderColours =
    getNotificationBorderColours(borders);

  const COLOUR_TEXT_TEXT_ERROR: HexColour = text.textError;
  const COLOUR_TEXT_TEXT_WARNING: HexColour = text.textWarning;
  const COLOUR_TEXT_TEXT_SUCCESS: HexColour = text.textSuccess;

  const borderLeftColour: string | undefined =
    NOTIFICATION_BORDER_COLOUR_MAPPING[variant];
  const isSmallSize: boolean = size === 'small';

  return {
    ...(isSmallSize && { maxWidth: '325px' }),
    display: 'flex',
    flexDirection: 'row',
    alignItems: isSmallSize ? 'flex-start' : 'center',
    gap: isSmallSize ? '10px' : '15px',
    background: COLOUR_BG_BACKGROUND_ALT,
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: 4,
    borderLeft: `5px solid ${borderLeftColour}`,
    padding: isSmallSize ? '15px 10px 15px 25px' : '20px 25px 20px 15px',

    '& div': {
      ...(isSmallSize && { order: 1 }),

      '> svg > path': {
        fill:
          variant === 'error'
            ? COLOUR_TEXT_TEXT_ERROR
            : variant === 'warning'
            ? COLOUR_TEXT_TEXT_WARNING
            : COLOUR_TEXT_TEXT_SUCCESS,
      },
    },
  };
});

interface RPNotificationProps extends BoxProps {
  variant: NotificationVariants;
  icon?: boolean;
  size?: NotificationSizes;
  text: string;
}

const RPNotification: FC<RPNotificationProps> = (
  props: RPNotificationProps
) => {
  const {
    variant,
    icon = false,
    size = 'normal',
    text: notificationText,
  } = props;

  const { tenant } = useContext(TenantContext);

  const OrganizationStatusWarning = useMemo(
    () => getIcon(tenant, 'statusWarning'),
    [tenant]
  );
  const OrganizationStatusWarningAlt = useMemo(
    () => getIcon(tenant, 'statusWarningAlt'),
    [tenant]
  );
  const OrganizationStatusCheckmarkOutline = useMemo(
    () => getIcon(tenant, 'statusCheckmarkOutlineIcon'),
    [tenant]
  );

  const NOTIFICATION_VARIANT_ICON_MAPPING: NotificationIconMapping = {
    error: <OrganizationStatusWarning />,
    warning: <OrganizationStatusWarningAlt />,
    success: <OrganizationStatusCheckmarkOutline />,
  };

  const notificationIcon = NOTIFICATION_VARIANT_ICON_MAPPING[variant];

  return (
    <StyledNotification variant={variant} size={size}>
      <div>{icon && notificationIcon}</div>
      <RPText
        variant="subtitle2"
        type="normal"
        text={notificationText ? notificationText : ''}
      />
    </StyledNotification>
  );
};

export default memo(RPNotification);

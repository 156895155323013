import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper from './axiosWrapper';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
import GetBasicDetails from '../../core/utils/GetBasicDetails';


const { orgCode } = GetBasicDetails();

type MobileDownloadSMSData = {
  countryCode: string;
  mobileNumber: string;
  legalEntity: string;
  locale: string;
};

const mobileDownloadSMS = createAsyncThunk(
  "communication-service/mobileDownloadSms", async (data: MobileDownloadSMSData, thunkAPI) => {

    const { countryCode, mobileNumber, locale, legalEntity } = data;

    const payload = {
      "mobileNumber": `${countryCode}-${mobileNumber}`,
      orgCode,
      locale,
      legalEntity,
    };

    try {
      const response = await axiosWrapper('mobileDownloadSMS', 'PUT', thunkAPI, payload);

      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  });

export default mobileDownloadSMS;
import RPInput from '../../atoms/RPInput';

const RPInputVariants = () => {
  return (
    <>
      {/* Input type text normal */}
      <RPInput name="inputText" value="" placeholderLabel="First name" />

      {/* Input type text small */}
      <RPInput
        name="inputText"
        value=""
        placeholderLabel="First name"
        size="small"
      />

      {/* Input type text with error */}
      <RPInput
        name="inputTextWithError"
        value=""
        placeholderLabel="First name"
        error="Required"
      />

      {/* Input type text disabled */}
      <RPInput
        name="inputTextWithError"
        value=""
        placeholderLabel="First name"
        isDisabled={true}
      />

      {/* Input type password */}
      <RPInput
        name="inputPassword"
        value=""
        placeholderLabel="Password"
        type="password"
      />

      {/* Input type password with error */}
      <RPInput
        name="inputPasswordWithError"
        value=""
        placeholderLabel="Password"
        type="password"
        error="Required"
      />

      {/* Input type email */}
      <RPInput
        name="inputEmail"
        value=""
        placeholderLabel="Email"
        type="email"
      />

      {/* Input with loader */}
      <RPInput
        name="inputText"
        value=""
        placeholderLabel="First name"
        showLoader={true}
      />
    </>
  );
};

export default RPInputVariants;

import { FC, useContext, useMemo, useRef, MouseEvent, KeyboardEvent } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { CSSTransition } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Contexts
import { LanguageContext, TenantContext, LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
import RPLink from '../../atoms/RPLink';
import { FullLayout } from '../../organisms/FullLayout';
// Hooks
import { useAnimation, useScrollToContentTop } from '../../../core/hooks';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../core/types/LayoutProps';
import { IconObject } from '../../../core/types/IconOrgTypes';
import { RegistrationReturnInactiveProps } from '../../../core/types/RegistrationReturnTypes';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../core/utils/IconOrgData';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import GetContactUsLink from '../../../core/utils/GetContactUsLink';
import { getLoginPageURL } from '../../../core/utils/GetOrganizationEnvSpecificURLs';
// Constants
import { ENTER_KEY, STEPS_ROUTE_MAPPING } from '../../../core/utils/Constants/Constants';
// Feature Flag
import { featureFlags } from '../../../core/FeatureFlags/FeatureFlags';
//redux
import { resetStoreToInitialState } from '../../../redux/actions/resetStore';
import { RESET_STORE_TO_INITIAL_STATE } from '../../../redux/ActionTypeConstants';
import { removePersistedStoreData } from '../../../redux/store';

interface GenericErrorPageProps extends RegistrationReturnInactiveProps {
  variant: 'errorBoundary' | 'generic' | 'account';
}

interface GenericErrorContainerWrapperProps extends LayoutProps, GenericErrorPageProps {
  isLayoutTypeOld: boolean;
}

interface HeadingTextWrapperProps extends LayoutProps, GenericErrorPageProps {}

interface LinkContainerProps extends GenericErrorPageProps {}

type ErrorPageConfigObj = {
  heading: string;
  subHeadingLine1: string;
  subHeadingLine2: string;
  primaryLinkLabel: string;
  errorIcon: keyof IconObject;
};

const GenericErrorContainerWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'isLayoutTypeOld'
})<GenericErrorContainerWrapperProps>(({ layout, variant, isLayoutTypeOld }) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const isAccountError: boolean = variant === 'account';

  const currentMaxWidth: string = isAccountError ? '660px' : '550px';
  const containerDefaultMargin: string = isDesktopLayout
    ? '165px 0px 0px 0px'
    : isTabletLayout
    ? '50px 0px 0px 0px'
    : '130px 0px 30px 0px';
  const accountErrorPageMargin: string = isDesktopLayout
    ? '120px 0px 0px 0px'
    : isTabletLayout
    ? '70px 0px 0px 0px'
    : '30px 0px 30px 0px';

  const currentMargin: string = isAccountError || isLayoutTypeOld ? accountErrorPageMargin : containerDefaultMargin;

  return {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    maxWidth: isMobileLayout ? '325px' : currentMaxWidth,
    height: '100%',
    maxHeight: 600,
    margin: currentMargin
  };
});

const GenericErrorContainer = styled(Box)(({ theme }) => {
  const {
    colours: {
      text: { textWeak }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;

  return {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '30px',
    color: COLOUR_TEXT_TEXT_WEAK,
    boxSizing: 'border-box',

    '& .MuiTypography-root:not(:last-child)': {
      textAlign: 'center',
      color: COLOUR_TEXT_TEXT_WEAK
    }
  };
});

const HeadingTextWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant'
})<HeadingTextWrapperProps>(({ layout, variant }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);
  const isNotAccountError: boolean = variant !== 'account';

  return {
    display: 'flex',
    gap: isMobileLayout && isNotAccountError ? '10px' : '24px',
    flexDirection: 'column'
  };
});

const TextWrapper = styled(Box)<LayoutProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    lineHeight: '24px',
    whiteSpace: isMobileLayout ? 'normal' : 'pre-line'
  };
});

const LinkContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant'
})<LinkContainerProps>(({ theme, variant }) => {
  const {
    colours: {
      text: { textLink }
    }
  } = theme;
  const isAccountError: boolean = variant === 'account';

  return {
    display: 'flex',
    justifyContent: 'center',
    marginTop: isAccountError ? 24 : 0,
    textDecoration: isAccountError ? 'underline' : 'none',
    textDecorationColor: isAccountError ? textLink : 'inherit'
  };
});

const ErrorPageConfigValue: { [key: string]: ErrorPageConfigObj } = {
  errorBoundary: {
    heading: 'errorBoundary.heading',
    subHeadingLine1: 'errorBoundary.subHeadingLine1',
    subHeadingLine2: 'errorBoundary.subHeadingLine2',
    primaryLinkLabel: 'errorBoundary.primaryLinkLabel',
    errorIcon: 'genericError'
  },
  generic: {
    heading: 'genericError.heading',
    subHeadingLine1: 'genericError.subHeadingLine1',
    subHeadingLine2: '',
    primaryLinkLabel: 'genericError.primaryLinkLabel',
    errorIcon: 'genericError'
  },
  account: {
    heading: 'accountError.heading',
    subHeadingLine1: 'accountError.subHeadingLine1',
    subHeadingLine2: 'accountError.subHeadingLine2',
    primaryLinkLabel: 'accountError.primaryLinkLabel',
    errorIcon: 'accountError'
  }
};

const GenericErrorPage: FC<GenericErrorPageProps> = (props: GenericErrorPageProps) => {
  const { layout } = useContext(LayoutContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { tenant } = useContext(TenantContext);
  const navigate = useNavigate();

  const [animateContent, setAnimateContent] = useAnimation(false, 500);
  const cssTransitionRef = useRef(null);

  const dispatch = useDispatch();

  const { variant, isRegistrationReturnInactive = false } = props;

  const { heading, subHeadingLine1, subHeadingLine2, primaryLinkLabel, errorIcon } = ErrorPageConfigValue[
    variant
  ] as ErrorPageConfigObj;

  const GenericErrorIcon = useMemo(() => getIcon(tenant, errorIcon), [tenant]);

  const contactUsURL: string = GetContactUsLink(tenant);
  const loginPageURL: string = getLoginPageURL(tenant);

  const isErrorBoundary: boolean = variant === 'errorBoundary';
  const isAccountError: boolean = variant === 'account';
  const showHeaderTextLine2: boolean = isErrorBoundary && !isRegistrationReturnInactive;

  const headingText: string = translate(heading);
  const subHeadingLine1Text: string = translate(subHeadingLine1);
  const subHeadingLine1InactiveDashboardText: string = translate('errorBoundary.subHeadingLine1InactiveDashboard');
  const subHeadingLine2Text: string = showHeaderTextLine2 ? translate(subHeadingLine2) : '';
  const primaryLinkLabelText: string = translate(primaryLinkLabel);

  const isMobileLayoutAndNotAccountErrorPage: boolean = isMobileLayoutApplicable(layout) && !isAccountError;

  const headerVariant: 'h2' | 'h3' = isMobileLayoutAndNotAccountErrorPage ? 'h3' : 'h2';
  const bodyVariant: 'body1' | 'body2' = isMobileLayoutAndNotAccountErrorPage ? 'body2' : 'body1';
  const bodyType: 'normal' = 'normal';

  const isLayoutTypeOld: boolean = isRegistrationReturnInactive;
  const fullLayoutType: 'old' | 'normal' = isLayoutTypeOld ? 'old' : 'normal';
  const shouldStickHeaderAtTop: boolean = !isRegistrationReturnInactive;

  // Scrolls to the top of the content area
  useScrollToContentTop();

  const {
    landingPage: { useReactLandingPage }
  } = featureFlags;

  const refreshBrowser = (event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (isRegistrationReturnInactive) {
      removePersistedStoreData();
      dispatch(resetStoreToInitialState(RESET_STORE_TO_INITIAL_STATE));
    }

    const navigateToStart: string = useReactLandingPage ? '/landing-page' : STEPS_ROUTE_MAPPING['create-login'];

    isAccountError
      ? window.open(contactUsURL, '_blank')
      : isErrorBoundary
      ? isRegistrationReturnInactive
        ? window.open(loginPageURL, '_self')
        : navigate(navigateToStart)
      : navigate(0);
  };

  return (
    <FullLayout
      layoutType={fullLayoutType}
      stickHeaderAtTop={shouldStickHeaderAtTop}
      hideAdditionalInteractions
      expandContainerWidth={!isRegistrationReturnInactive}
    >
      <CSSTransition
        nodeRef={cssTransitionRef}
        in={animateContent}
        timeout={500}
        classNames="slideUp"
        unmountOnExit
        onEnter={() => setAnimateContent}
        onExited={() => setAnimateContent}
      >
        <GenericErrorContainerWrapper
          isLayoutTypeOld={isLayoutTypeOld}
          layout={layout}
          ref={cssTransitionRef}
          variant={variant}
        >
          <GenericErrorContainer>
            <GenericErrorIcon />
            <HeadingTextWrapper layout={layout} variant={variant}>
              <RPText variant={headerVariant} type="bold" text={headingText} />
              <TextWrapper layout={layout}>
                <RPText
                  variant={bodyVariant}
                  type={bodyType}
                  text={isRegistrationReturnInactive ? subHeadingLine1InactiveDashboardText : subHeadingLine1Text}
                />
                {showHeaderTextLine2 && <RPText variant={bodyVariant} type={bodyType} text={subHeadingLine2Text} />}
                <LinkContainer variant={variant}>
                  <RPLink
                    variant={bodyVariant}
                    underline="none"
                    type={bodyType}
                    text={primaryLinkLabelText}
                    onClick={(e: MouseEvent<HTMLAnchorElement>) => refreshBrowser(e)}
                    onKeyDown={(e: KeyboardEvent<HTMLAnchorElement>) => {
                      e.key === ENTER_KEY && refreshBrowser(e);
                    }}
                    href=""
                    data-testid="rp-link-start-over"
                  />
                </LinkContainer>
              </TextWrapper>
            </HeadingTextWrapper>
          </GenericErrorContainer>
        </GenericErrorContainerWrapper>
      </CSSTransition>
    </FullLayout>
  );
};

export default GenericErrorPage;

import { useWindowSize } from 'usehooks-ts';
import { LayoutSize } from '../types/LayoutTypes';

const useLayoutSize = (): LayoutSize => {
  const { width } = useWindowSize();

  // console.log('width: ', width, 'height: ', height);
  // console.log('window width: ', window.innerWidth, ' window height: ', window.innerHeight);

  /*const layoutObj = {
    layoutSize
  }*/

  /*if(width >= 1194) {
    return 'desktop';
  } else if(width < 1194 && width >= 990) {
    return 'tablet';
  } else if(width < 990) {
    return 'mobile'
  }

  if(height >= 700) {
    return 'desktop';
  } else if(height < 700) {
    return 'tablet'
  }*/

  if (width <= 480) {
    return 'mobileSM';
  }

  if (width <= 990) {
    return 'mobile';
  }

  if (width <= 1194 && width >= 991) {
    return 'tablet';
  }

  if (width > 1194) {
    return 'desktop';
  }

  return 'desktop';
};

export default useLayoutSize;

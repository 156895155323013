import { FC, useContext, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { CSSTransition } from 'react-transition-group';
import { useEffectOnce } from 'usehooks-ts';

// Contexts
import { LanguageContext, LayoutContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
import { FullLayout } from '../../organisms/FullLayout';
// Hooks
import { useAnimation, useScrollToContentTop, useResetStore, usePreventBackAction } from '../../../core/hooks';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { RegistrationReturnProps } from '../../../core/types/RegistrationReturnTypes';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { LayoutProps } from '../../../core/types/LayoutProps';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

interface RegistrationFailContainerWrapperProps extends LayoutProps {}
interface RegistrationFailContainerProps extends LayoutProps {}
interface StyledRegistrationFailHeadingProps extends LayoutProps {}

const RegistrationFailContainerWrapper = styled(Box)<RegistrationFailContainerWrapperProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';

  const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    maxWidth: isMobileLayout ? '325px' : '500px',
    height: '100%',
    maxHeight: 600,
    margin: isDesktopLayout ? '170px 0px 0px 0px' : isTabletLayout ? '120px 0px 0px 0px' : '130px 0px 30px 0px'
  };
});

const RegistrationFailContainer = styled(Box)<RegistrationFailContainerProps>(({ theme, layout }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE: HexColour = text.textInverse;
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: isMobileLayout ? '10px' : '25px',
    color: COLOUR_TEXT_TEXT_INVERSE,
    boxSizing: 'border-box',

    '& .MuiTypography-root': {
      color: COLOUR_TEXT_TEXT_INVERSE,
      textAlign: 'center'
    },

    '& .MuiButton-root': {
      width: '100%'
    }
  };
});

const StyledRegistrationFailHeading = styled(RPText)<StyledRegistrationFailHeadingProps>(({ layout }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    ...(!isMobileLayout && {
      marginTop: '146px'
    })
  };
});

interface RegistrationFailProps extends RegistrationReturnProps {}
const RegistrationFail: FC<RegistrationFailProps> = ({ isRegistrationReturn }: RegistrationFailProps) => {
  const { layout } = useContext(LayoutContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const [animateContent, setAnimateContent] = useAnimation(false);
  const cssTransitionRef = useRef(null);

  const headingText: string = translate('registrationPending.heading');
  const subHeadingLine1Text: string = translate('registrationPending.subHeading');

  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  const {
    eventAction: { load },
    registrationPending: {
      load: { registrationFailed, registrationFailedReturn }
    }
  } = GoogleAnalyticsTagsMapping;

  const headingVariant: 'h2' | 'h3' = isMobileLayout ? 'h3' : 'h2';
  const subHeadingVariant: 'body1' | 'body2' = isMobileLayout ? 'body2' : 'body1';
  const subHeadingType: 'normal' | 'bold' = isMobileLayout ? 'normal' : 'bold';

  // reset redux store state manually
  useResetStore();

  useEffectOnce(() => {
    TriggerGoogleAnalyticsTag(load, isRegistrationReturn ? registrationFailedReturn : registrationFailed);
  });

  // Scrolls to the top of the content area
  useScrollToContentTop();

  // Prevent back action of browser
  usePreventBackAction();

  return (
    <FullLayout enableMobileFooter={false}>
      <CSSTransition
        nodeRef={cssTransitionRef}
        in={animateContent}
        timeout={500}
        classNames="slideUp"
        unmountOnExit
        onEnter={() => setAnimateContent}
        onExited={() => setAnimateContent}
      >
        <RegistrationFailContainerWrapper layout={layout} ref={cssTransitionRef}>
          <RegistrationFailContainer layout={layout}>
            <StyledRegistrationFailHeading layout={layout} variant={headingVariant} type="bold" text={headingText} />
            <RPText variant={subHeadingVariant} type={subHeadingType} text={subHeadingLine1Text} />
          </RegistrationFailContainer>
        </RegistrationFailContainerWrapper>
      </CSSTransition>
    </FullLayout>
  );
};

export default RegistrationFail;

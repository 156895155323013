import { FC, useContext, useMemo } from 'react';
import { styled, keyframes } from '@mui/material/styles';

import RPText from '../../atoms/RPText';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import {
  LanguageContext,
  TenantContext,
} from '../../../core/TenantProvider/contexts';
import { getIcon } from '../../../core/utils/IconOrgData';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface StateProps {
  state: 'active' | 'inactive' | 'next';
}

interface FactCardProps {
  handleCardTransition: () => void;
  getCardState: (cardIndex: number) => StateProps['state'];
  index: number;
  translationKey: string;
}

const StyledFactCard = styled('div', {
  shouldForwardProp: (prop) => prop !== 'state',
})<StateProps>(({ theme, state }) => {

  const {
    colours: {
      backgrounds
    },
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;

  return {
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    borderRadius: '20px',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
    width: '260px',
    height: '366px',
    padding: '30px',
    overflow: 'hidden',
    position: 'absolute',
    transformOrigin: 'top',
    transition: '0.6s cubic-bezier(0.28, 0.55, 0.385, 1.65)',
    transform: 'scale(0.8)',
    cursor: 'pointer',

    ...(state === 'active' && {
      opacity: 1,
      zIndex: 2,
      transformOrigin: 'top',
      boxShadow: '0px 0px 16px 0px rgba(0, 0, 0, 0.1)',
      transform: 'scale(1)',
      top: 0,
    }),

    ...(state === 'inactive' && {
      opacity: 0,
      zIndex: 0,
      top: 0,
    }),

    ...(state === 'next' && {
      opacity: 0.9,
      zIndex: 1,
      transformOrigin: 'top',
      transform: 'rotate(0.91deg)',
      top: '-6px',
      marginLeft: '22px',
      transition: 'all 0.6s cubic-bezier(.02, .01, .47, 1)',
    }),
  };
});

const OuterArc = keyframes`
  0% { 
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% { 
    transform: rotate(0deg);
  }
`;

const InnerArc = keyframes`
  0% { 
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% { 
    transform: rotate(0deg);
  }
`;

const IconContainer = styled('div')(() => {
  return {
    width: '190px',
    margin: '0 auto',
    animation: `${OuterArc} 3s ease-in-out infinite`,

    '> svg': {
      animation: `${InnerArc} 3s ease-in-out infinite`,
    },
  };
});

const Heading = styled(RPText)(() => {
  return {
    marginBottom: '8px',
  };
});

const FactText = styled(RPText)(({ theme }) => {

  const {
    colours: {
      text,
    },
  } = theme;

  const COLOUR_TEXT_TEXT_LINK_LIGHT: HexColour = text.textLinkLight;

  return {
    color: COLOUR_TEXT_TEXT_LINK_LIGHT,
  };
});

const RPFactCard: FC<FactCardProps> = (props: FactCardProps) => {

  const { handleCardTransition, getCardState, index, translationKey } = props;

  const { translations } = useContext(LanguageContext);
  const { tenant } = useContext(TenantContext);
  const translate = TranslateWrapper(translations);

  const headingText: string = translate('factCards.heading');
  const factText: string = translate(translationKey);

  const SearchIcon = useMemo(() => getIcon(tenant, 'search'), [tenant]);

  return (
    <StyledFactCard
      onClick={handleCardTransition}
      data-testid="fact-card"
      state={getCardState(index)}
    >
      <IconContainer>
        <SearchIcon />
      </IconContainer>
      <div>
        <Heading variant="body1" type="medium" text={headingText} />
        <FactText variant="h1" type="display-medium-norm" text={factText} />
      </div>
    </StyledFactCard>
  );
};
export default RPFactCard;

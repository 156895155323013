import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper from './axiosWrapper';

const getLocationLookup = createAsyncThunk('/reference-data/location-lookup', async (_, thunkAPI) => {
  try {
    const response = await axiosWrapper('getLocationLookup', 'GET', thunkAPI, null, null);

    return response && response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue({ error: error.response.data.message });
  }
});

export default getLocationLookup;

// Utils
import { CheckIfAllKeysExistsInObject } from '../CheckIfAllKeysExistsInObject';
// Constants
import { BRANCH_IO_DEEP_LINK_DATA_KEYS } from '../Constants/Constants';

type GetBranchIODataIfAvailableType = {
  isRequiredBranchIODataAvailable: boolean;
  branchIOData: { [key: string]: string };
};

export const GetBranchIODataIfAvailable = (
  branchIODataResponse: { [key: string]: string } | undefined
): GetBranchIODataIfAvailableType => {
  const isRequiredBranchIODataAvailable: boolean = !!(
    branchIODataResponse &&
    CheckIfAllKeysExistsInObject(branchIODataResponse, Object.keys(BRANCH_IO_DEEP_LINK_DATA_KEYS))
  );

  const branchIOData: { [key: string]: string } = Object.keys(BRANCH_IO_DEEP_LINK_DATA_KEYS).reduce(
    (acc: { [key: string]: string }, key: string) => {
      acc[BRANCH_IO_DEEP_LINK_DATA_KEYS[key]] =
        branchIODataResponse && isRequiredBranchIODataAvailable ? branchIODataResponse[key] : '';
      return acc;
    },
    {}
  );

  return {
    isRequiredBranchIODataAvailable,
    branchIOData
  };
};

// Types
import { OrganizationCodeTypes, LiveChatEnvSpecificType } from '../../types/OrganizationTypes';
// Utils
import { getConfigProperty } from '../../EnvironmentConfig/EnvironmentConfig';
// Constants
import { ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG } from '../Constants/Constants';

const defaultOrganizationCode: OrganizationCodeTypes = ALL_ORGANIZATIONS_DOMAIN_CODE_CONFIG[0].organizationCode;

export const getLiveChatConfig = (tenant: OrganizationCodeTypes | undefined) => {
  return (getConfigProperty('liveChatConfig') as LiveChatEnvSpecificType)[tenant ?? defaultOrganizationCode];
};

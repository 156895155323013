import branch, { BranchError, SessionData } from 'branch-sdk';
import _ from 'lodash';

// Utils
import { GetCookieDataKeysForType } from '../GetCookieDataObject';
import { getConfigProperty } from '../../EnvironmentConfig/EnvironmentConfig';
import { getBranchIODataIfAvailable } from '../GetBranchIODataIfAvailable';
import { CreateCookie as createCookie } from '../CreateCookie';
// Constants
import { BRANCH_IO_REFERRAL_COOKIE_EXPIRY_IN_DAYS } from '../Constants/Constants';

const branchIOKey = getConfigProperty('branchIOKey') as string;

export type branchIODataResponseType = {
  $cd_referer_name: string;
  $cd_referral_id: string;
  '~referring_link': string;
  $cd_source: string;
  $cd_sub_source: string;
};

export type branchIODataType = { [keyName: string]: string };

// @ts-ignore
export const initBranch = (setBranchIOData: (data) => void, setBranchIOLoadingStatus: (data) => void) => {
  try {
    setBranchIOLoadingStatus('loading');
    branch.init(branchIOKey, {}, (initErr: BranchError, initData: SessionData | null) => {
      if (initErr) {
        setBranchIOLoadingStatus('failed');
      } else {
        branch.data(function (err: BranchError, data: SessionData | null) {
          console.log('branchData', data);
          const branchIODataResponse: branchIODataResponseType | undefined = data?.data_parsed as
            | branchIODataResponseType
            | undefined;

          const { isRequiredBranchIODataAvailable, branchIOData } = getBranchIODataIfAvailable(branchIODataResponse);

          if (isRequiredBranchIODataAvailable) {
            const referralCookieNamesArray: string[] = GetCookieDataKeysForType('referralCookieData');

            referralCookieNamesArray.forEach((cookieName) =>
              createCookie(cookieName, branchIOData[cookieName], { expires: BRANCH_IO_REFERRAL_COOKIE_EXPIRY_IN_DAYS, priority: 'high' })
            );
            setBranchIOLoadingStatus('succeeded');
            setBranchIOData(branchIOData);
          } else {
            setBranchIOLoadingStatus('failed');
          }
        });
      }
    });
  } catch (e) {
    setBranchIOLoadingStatus('failed');
  }
};

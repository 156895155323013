import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper, { ObjectMapping } from './axiosWrapper';
import GetBasicDetails from '../../core/utils/GetBasicDetails';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';

type getCountryDataRequest = {
  locale: string;
};

const getCountryData = createAsyncThunk(
  '/reference-data/country', async (data: getCountryDataRequest,thunkAPI) => {
    const { locale } = data;
    const { orgCodeID, localeId } = GetBasicDetails(locale);

    const dataToInsert: ObjectMapping = {
      orgCodeID: orgCodeID + '',
      localeId: localeId + '',
    };

    try {
      const response = await axiosWrapper('getCountryDataV2', 'GET', thunkAPI, null, dataToInsert);

      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  });

export default getCountryData;
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

// Components - Atoms, Molecules, Organisms, Pages
import { RPText } from '../../atoms/RPText';
import { RPLink } from '../../atoms/RPLink';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
// Constants
import { MASK_CLASS } from '../../../core/utils/Constants/Constants';

interface ContainerProps {
  shouldShowRefererHeading: boolean;
}

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'shouldShowRefererHeading'
})<ContainerProps>(({ theme, shouldShowRefererHeading }) => {
  const {
    colours: {
      backgrounds: { backgroundActiveLightest }
    }
  } = theme;

  const COLOUR_BACKGROUND_ACTIVE_LIGHTEST: HexColour = backgroundActiveLightest;

  return {
    background: COLOUR_BACKGROUND_ACTIVE_LIGHTEST,
    padding: 20,
    borderRadius: '4px',
    '& > *': {
      display: shouldShowRefererHeading ? 'block' : 'inline'
    }
  };
});

const RefererHeadingContainer = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: '5px',
    gap: '5px'
  };
});

const RefererHeadingTextWrapper = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '4px'
  };
});

const StyledRefererHeading = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { text: COLOUR_TEXT_TEXT }
    }
  } = theme;

  return {
    color: COLOUR_TEXT_TEXT
  };
});

const StyledRefererName = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textActiveDark }
    }
  } = theme;

  const COLOUR_TEXT_ACTIVE_DARK: HexColour = textActiveDark;

  return {
    color: COLOUR_TEXT_ACTIVE_DARK
  };
});

const ContentText = styled(RPText)(({ theme }) => {
  const {
    colours: {
      text: { textWeak: COLOUR_TEXT_TEXT_WEAK }
    }
  } = theme;

  return {
    color: COLOUR_TEXT_TEXT_WEAK
  };
});

export enum InformationalBlurbContentType {
  text = 'text',
  link = 'link'
}

interface InformationalBlurbContentBase {
  content: string;
}

export interface InformationalBlurbContentText extends InformationalBlurbContentBase {
  type: InformationalBlurbContentType.text;
}

export interface InformationalBlurbContentLink extends InformationalBlurbContentBase {
  type: InformationalBlurbContentType.link;
  href: string;
}

export type InformationalBlurbContent = InformationalBlurbContentText | InformationalBlurbContentLink;

interface RPInformationalBlurbProps {
  contentArray: Array<InformationalBlurbContentText | InformationalBlurbContentLink>;
  headingContent?: string;
  headingData?: string;
  icon?: JSX.Element;
}

const RPInformationalBlurb: FC<RPInformationalBlurbProps> = ({
  contentArray,
  headingContent,
  headingData,
  icon
}: RPInformationalBlurbProps) => {
  const shouldShowRefererHeading: boolean = !!(headingData && headingContent && headingData !== '');
  const currentView = (
    <>
      {headingData && headingContent && (
        <>
          <RefererHeadingContainer>
            {icon}
            <RefererHeadingTextWrapper>
              <StyledRefererHeading variant="body2" type="medium" text={headingContent} />
              <StyledRefererName variant="body2" type="medium" text={headingData} className={MASK_CLASS}/>
            </RefererHeadingTextWrapper>
          </RefererHeadingContainer>
        </>
      )}
      {contentArray.map((contentObject: InformationalBlurbContent, index) => {
        if (contentObject.type === InformationalBlurbContentType.text) {
          return (
            <ContentText
              key={`content-${index}`}
              variant="subtitle2"
              type="normal"
              text={contentObject.content}
              data-testid={`rp-text-informational-blurb-${index}`}
            />
          );
        } else if (contentObject.type === InformationalBlurbContentType.link) {
          return (
            <RPLink
              key={`content-${index}`}
              text={contentObject.content}
              href={(contentObject as InformationalBlurbContentLink).href}
              variant="subtitle2"
              type="normal"
              target="_blank"
              data-testid={`rp-link-informational-blurb-${index}`}
            />
          );
        }
        return;
      })}
    </>
  );

  return <Container shouldShowRefererHeading={shouldShowRefererHeading}>{currentView}</Container>;
};

export default RPInformationalBlurb;
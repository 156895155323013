// POA and POI
import { PoaPoiDocType, DocType } from '../../types/PoaPoiDocsTypes';
//GA4
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
import {
  GoogleAnalyticsTagsMapping,
  poaTagsMappingWithKey,
  poiTagsMappingWithKey
} from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

const {
  eventAction: { select },
  kycFailedDocument: { select: PoiPoaTags }
} = GoogleAnalyticsTagsMapping;

export const getPoiPoaDropdownOptions = (docData: PoaPoiDocType[]) => {
  return docData.map((data: PoaPoiDocType) => {
    return {
      label: data?.displayName,
      value: data?.key
    };
  });
};

const getSideStructure = (numberOfSides: number, docType: DocType) => {
  let array: string[] = [];
  for (let i = 1; i <= numberOfSides; i++) {
    array.push(`${docType}-file-${i}`);
  }
  return array;
};

export const getPoiPoaDocSides = (docData: PoaPoiDocType[], docType: DocType) => {
  return docData.map((data: PoaPoiDocType) => {
    return {
      key: data?.key,
      numberOfSides: parseInt(data?.numberOfSides),
      structure: getSideStructure(parseInt(data?.numberOfSides), docType)
    };
  });
};

export const triggerPoiPoaDocGATags = (docType: DocType, value: string) => {
  const mappingTypeWithKey: any = docType === DocType.poi ? poiTagsMappingWithKey : poaTagsMappingWithKey;
  return (
    value &&
    mappingTypeWithKey[value] &&
    PoiPoaTags[mappingTypeWithKey[value]] &&
    TriggerGoogleAnalyticsTag(select, PoiPoaTags[mappingTypeWithKey[value]])
  );
};

export const getUploadDocSides = (value: string, data: any) => {
  return data.find((item: any) => item.key === value);
};

import { FC, useContext, useEffect, useMemo, useRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';

// Contexts
import {
  ContentRefContext,
  LanguageContext,
  LayoutContext,
  TenantContext
} from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import { RPHeaderRight } from '../../molecules/RPHeaderRight';
import RPMobileMenuContainer from '../RPMobileMenuContainer';
import { RPErrorNotification } from '../RPErrorNotification';
import RPFooter from '../RPFooter';
import SideBar from '../SideBar';
import RPText from '../../atoms/RPText';
import MobileAppLinksWithIcons from '../MobileAppLinksWithIcons';
// Hooks
import { useScrollDirection, useStepParams } from '../../../core/hooks';
import { ScrollDirectionType } from '../../../core/hooks/useScrollDirection';
// Types
import { AppDispatch } from '../../../redux/store';
import { LayoutSize } from '../../../core/types/LayoutTypes';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
import { OrganizationCodeTypes } from '../../../core/types/OrganizationTypes';
import { OptionType } from '../../../core/types/SelectTypes';
import { LinkType } from '../../../core/types/GoogleAnalyticsTypes';
// RTK Slice
import { getAuthorisationDetails, resetAuthorisationState } from '../../../redux/modules/authorisationDetailsSlice';
import { openLoginDialogModal } from '../../../redux/modules/loginDetailsSlice';
import { getRegistrationDetails } from '../../../redux/modules/registrationDetailsSlice';
// Utils
import { getLanguageOptions } from '../../../core/utils/LanguageData';
import { getIcon } from '../../../core/utils/IconOrgData';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
// Constants
import {
  ORGANIZATION_CODE_MAPPING,
  LANDING_PAGE_SPECIFIC_CHANGES_ORGANIZATIONS_LIST,
  DISABLE_MOBILE_RELATED_FEATURES
} from '../../../core/utils/Constants/Constants';
// GA
import { TriggerGoogleAnalyticsTag } from '../../../core/utils/GoogleAnalyticTag';
import { GoogleAnalyticsTagsMapping } from '../../../core/utils/GoogleAnalyticTag/GoogleAnalyticsTagsMapping';

type ContainerWrapperProps = {
  isFromLandingOrRegister: boolean;
};

const ContainerWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isFromLandingOrRegister'
})<ContainerWrapperProps>(({ isFromLandingOrRegister }) => {
  return {
    ...(isFromLandingOrRegister && {
      overflowY: 'scroll',
      height: '100vh'
    })
  };
});

type ContainerProps = {
  size: LayoutSize;
  isAnimating?: boolean;
  isFromLandingOrRegister: boolean;
};

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'isAnimating' && prop !== 'isFromLandingOrRegister'
})<ContainerProps>(({ theme, isAnimating, size, isFromLandingOrRegister }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt, backgroundLandingPage }
    }
  } = theme;

  const isMobileLayout: boolean = isMobileLayoutApplicable(size);
  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  const COLOUR_BG_BACKGROUND_LANDING_PAGE: HexColour = backgroundLandingPage;

  return {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
    transition: 'all 0.2s ease 0s',
    ...(isFromLandingOrRegister
      ? {
          minHeight: '100vh',
          alignItems: 'normal',
          backgroundColor: isMobileLayout ? COLOUR_BG_BACKGROUND_ALT : COLOUR_BG_BACKGROUND_LANDING_PAGE
        }
      : {
          height: '100vh',
          alignItems: 'center',
          backgroundColor: COLOUR_BG_BACKGROUND_ALT
        }),
    ...(!isMobileLayout && {
      overflow: isAnimating ? 'hidden' : 'auto'
    }),
    ...(isFromLandingOrRegister && {
      justifyContent: 'center',
      margin: 'auto'
    })
  };
});

interface CustomBoxProps extends BoxProps {
  size: LayoutSize;
  isNotificationShow?: boolean;
  isFromLandingOrRegister: boolean;
}

interface ContentContainerProps extends CustomBoxProps {
  shouldShowMobileHeader: boolean;
  isRegisterPage: boolean;
}

const ContentContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'size' &&
    prop !== 'shouldShowMobileHeader' &&
    prop !== 'isFromLandingOrRegisterPage' &&
    prop !== 'isRegisterPage'
})<ContentContainerProps>(({ theme, size, shouldShowMobileHeader, isFromLandingOrRegister, isRegisterPage }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;

  const isDesktopLayout: boolean = size === 'desktop';
  const isTabletLayout: boolean = size === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(size);

  const mobilePadding = shouldShowMobileHeader
    ? isFromLandingOrRegister
      ? '87px 25px 88px 25px'
      : '87px 25px 15px 25px'
    : '0px 25px 15px 25px';

  return {
    flex: 1,
    padding:
      isFromLandingOrRegister && !isMobileLayout
        ? '40px 40px 40px 0px'
        : isDesktopLayout
        ? '45px 40px 40px'
        : isTabletLayout
        ? '30px'
        : mobilePadding,
    ...(!isFromLandingOrRegister && {
      height: '100vh',
      overflow: 'auto'
    }),
    boxSizing: 'border-box',
    display: 'grid',
    gridAutoRows: 'auto 40px',
    ...(isFromLandingOrRegister && {
      overflow: 'hidden'
    }),
    ...(isMobileLayout && {
      display: 'flex',
      flexDirection: 'column'
    }),
    ...(isRegisterPage && {
      backgroundColor: COLOUR_BG_BACKGROUND_ALT
    })
  };
});

interface HeaderContainerProps {
  isFromLandingOrRegister: boolean;
}

const Header = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFromLandingOrRegister'
})<HeaderContainerProps>(({ isFromLandingOrRegister }) => {
  return {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 'fit-content',
    height: '40px',
    ...(isFromLandingOrRegister && {
      zIndex: 1
    })
  };
});

const CloseIconContainer = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute', // Added to stop jumping between animations
    top: 0,
    right: 0
  };
});

interface StyledContentAreaWrapperProps {
  size: LayoutSize;
  isFromLandingOrRegister: boolean;
}

const StyledContentAreaWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'isFromLandingOrRegister'
})<StyledContentAreaWrapperProps>(({ size, isFromLandingOrRegister }) => {
  const isTabletLayout: boolean = size === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(size);

  return {
    ...(isFromLandingOrRegister
      ? {
          position: 'relative',
          height: '100%',
          flexDirection: 'column',
          display: 'flex',
          ...(!isMobileLayout && {
            maxWidth: isTabletLayout ? '523px' : '704px'
          }),
          maxHeight: '944px',
          justifyContent: 'space-between'
        }
      : {
          position: 'relative'
        })
  };
});

interface ContentAreaProps {
  isFromLandingOrRegister: boolean;
}

const ContentArea = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFromLandingOrRegister'
})<ContentAreaProps>((isFromLandingOrRegister) => {
  return {
    position: 'relative',
    width: '100%',
    height: isFromLandingOrRegister ? 'auto' : '100%'
  };
});

const Footer = styled('footer')(() => {
  return {
    bottom: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  };
});

const Content = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'size' && prop !== 'isFromLandingOrRegister'
})<CustomBoxProps>(({ size, isFromLandingOrRegister }) => {
  const isMobileLayout: boolean = isMobileLayoutApplicable(size);
  const isDesktopLayout: boolean = size === 'desktop';

  return {
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    ...(!isFromLandingOrRegister && {
      margin: '0px auto',
      maxWidth: isDesktopLayout ? '420px' : '350px'
    }),
    ...(isFromLandingOrRegister
      ? {
          margin: 0,
          paddingTop: isMobileLayout ? '' : '209px'
        }
      : !isMobileLayout
      ? {
          marginTop: '18vh',
          marginBottom: '10vh',
          height: 'fit-content'
        }
      : {
          marginTop: 0,
          paddingTop: 0,
          height: '100%'
        })
  };
});

const StyledIconButton = styled(IconButton)(({ theme }) => {
  const {
    colours: {
      text: { textWeak, textInputFocus, textInputIcon }
    }
  } = theme;
  const COLOUR_TEXT_TEXT_WEAK: HexColour = textWeak;
  const COLOUR_TEXT_TEXT_INPUT_FOCUS: HexColour = textInputFocus;
  const COLOUR_TEXT_TEXT_INPUT_ICON: HexColour = textInputIcon;

  return {
    padding: 0,

    ':hover': {
      backgroundColor: 'transparent'
    },

    '> svg': {
      width: '40px',
      height: '40px',

      '> path': {
        fill: COLOUR_TEXT_TEXT_WEAK
      }
    },

    '&.Mui-focusVisible': {
      outline: `${COLOUR_TEXT_TEXT_INPUT_FOCUS} solid 1px`,
      borderRadius: '4%',
      outlineOffset: 4,

      '> svg > path': {
        fill: COLOUR_TEXT_TEXT_INPUT_ICON
      }
    }
  };
});

interface StoreIconsWrapperProps {
  isFromLandingOrRegister: boolean;
  shouldChangeLabelColour: boolean;
}

const StoreIconsWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFromLandingOrRegister' && prop !== 'shouldChangeLabelColour'
})<StoreIconsWrapperProps>(({ theme, isFromLandingOrRegister, shouldChangeLabelColour }) => {
  const {
    colours: {
      text: { text, textLandingPage }
    }
  } = theme;
  const COLOUR_TEXT_TEXT_LANDING_PAGE: HexColour = textLandingPage;
  const COLOUR_TEXT_TEXT: HexColour = text;

  return {
    display: 'flex',
    gap: '18px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...(isFromLandingOrRegister && {
      marginTop: '80px',
      '>p': {
        color: shouldChangeLabelColour ? COLOUR_TEXT_TEXT_LANDING_PAGE : COLOUR_TEXT_TEXT
      }
    })
  };
});

interface SplitLayoutProps {
  children?: any;
  layoutVariant: 'full' | 'partial';
  isAnimating?: boolean;
  hideAdditionalInteractions?: boolean;
  isLandingPage?: boolean;
  isRegisterPage?: boolean;
  isReferAFriendPage?: boolean;
}

const SplitLayout: FC<SplitLayoutProps> = (props: SplitLayoutProps) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { layout } = useContext(LayoutContext);
  console.log('SplitLayout layout: ', layout);
  const { tenant } = useContext(TenantContext);
  const { updateRef } = useContext(ContentRefContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const storeIconsText: string = translate('landingPage.storeIconsText');

  const containerRef = useRef<HTMLDivElement>(null);
  const sideBarRef = useRef<HTMLDivElement>(null);

  const scrollDirection: ScrollDirectionType = useScrollDirection(containerRef);

  const { loading, shouldShowMobileHeader } = useSelector(getRegistrationDetails);
  const { registerDeviceLoading, generateTokenLoading, authorisationError } = useSelector(getAuthorisationDetails);

  const { stepNumber } = useStepParams();

  const LANGUAGE_OPTIONS: OptionType[] | [] = getLanguageOptions('cd');

  const CloseIcon = useMemo(() => getIcon(tenant, 'navigationClose'), [tenant]);

  const {
    layoutVariant = 'partial',
    isAnimating = true,
    children,
    hideAdditionalInteractions,
    isLandingPage = false,
    isRegisterPage = false,
    isReferAFriendPage = false
  } = props;

  const isPageOneLoading: boolean =
    (registerDeviceLoading === 'loading' || generateTokenLoading === 'loading' || loading === 'loading') &&
    stepNumber === 1;

  const isNotMobileLayout: boolean = !isMobileLayoutApplicable(layout);
  const isFullLayout: boolean = layoutVariant === 'full';

  const isHideAdditionalInteractions: boolean = !!hideAdditionalInteractions || isPageOneLoading;

  const notificationComponent: JSX.Element = (
    <RPErrorNotification
      containerRef={containerRef}
      layoutVariant={layoutVariant}
      layout={layout}
      sideBarRef={sideBarRef}
    />
  );

  const isFromLandingOrRegister: boolean = isLandingPage || isRegisterPage;

  const shouldChangeLabelColour: boolean =
    isLandingPage &&
    LANDING_PAGE_SPECIFIC_CHANGES_ORGANIZATIONS_LIST.includes(tenant || ORGANIZATION_CODE_MAPPING.currenciesdirect as OrganizationCodeTypes);

  const showMobileAppSection: boolean =
    isLandingPage &&
    isNotMobileLayout &&
    !isReferAFriendPage &&
    !DISABLE_MOBILE_RELATED_FEATURES.includes(tenant as OrganizationCodeTypes);

  const {
    eventAction: { click, load, select },
    landingPage: {
      click: { appStoreIcon, googlePlayIcon },
      load: {},
      select: {}
    }
  } = GoogleAnalyticsTagsMapping;

  useEffect(() => {
    if (authorisationError) {
      dispatch(resetAuthorisationState());
      navigate('/generic-error');
    }
  }, [authorisationError]);

  useEffect(() => {
    containerRef && containerRef.current && updateRef(containerRef);
  }, [containerRef]);

  const handleClose = () => {
    dispatch(openLoginDialogModal(true));
  };

  const linkClickHandler = (type: LinkType) => {
    TriggerGoogleAnalyticsTag(click, type === LinkType.AndroidStore ? googlePlayIcon : appStoreIcon);
  };

  return (
    <ContainerWrapper isFromLandingOrRegister={isFromLandingOrRegister}>
      <Container
        size={layout}
        isAnimating={isAnimating}
        isFromLandingOrRegister={isFromLandingOrRegister}
        data-testid="rp-split-layout-container"
      >
        {isNotMobileLayout ? (
          <SideBar
            isFromLandingOrRegister={isFromLandingOrRegister}
            variant={layoutVariant}
            size={layout}
            sideBarRef={sideBarRef}
          />
        ) : (
          shouldShowMobileHeader && (
            <RPMobileMenuContainer
              isFromLandingOrRegister={isFromLandingOrRegister}
              languageOptions={LANGUAGE_OPTIONS}
              scrollDirection={scrollDirection}
              hideAdditionalInteractions={isHideAdditionalInteractions}
            />
          )
        )}

        <ContentContainer
          size={layout}
          ref={containerRef}
          shouldShowMobileHeader={shouldShowMobileHeader}
          isFromLandingOrRegister={isFromLandingOrRegister}
          data-testid="rp-split-layout-content-container"
          isRegisterPage={isRegisterPage}
        >
          {/* adding .toFixed(2) so we don't get a long decimal value */}

          <StyledContentAreaWrapper size={layout} isFromLandingOrRegister={isFromLandingOrRegister}>
            {isNotMobileLayout && isFullLayout && !isHideAdditionalInteractions && (
              <Header isFromLandingOrRegister={isFromLandingOrRegister}>
                <RPHeaderRight
                  isLandingPage={isLandingPage}
                  isRegisterPage={isRegisterPage}
                  languageOptions={LANGUAGE_OPTIONS}
                />
              </Header>
            )}
            {isNotMobileLayout && stepNumber > 1 && (
              <CloseIconContainer>
                <StyledIconButton onClick={handleClose} disableRipple name="closeButton" data-testid="rp-button-close">
                  <CloseIcon />
                </StyledIconButton>
              </CloseIconContainer>
            )}
            <ContentArea isFromLandingOrRegister={isFromLandingOrRegister} id="rp-split-layout-content-area">
              {notificationComponent}

              <Content isFromLandingOrRegister={isFromLandingOrRegister} size={layout}>
                {children ? children : <p>Main Content section</p>}
              </Content>
            </ContentArea>
            {showMobileAppSection && (
              <StoreIconsWrapper
                shouldChangeLabelColour={shouldChangeLabelColour}
                isFromLandingOrRegister={isFromLandingOrRegister}
              >
                <RPText variant="body1" type="medium" text={storeIconsText} />
                <MobileAppLinksWithIcons linkClickHandler={linkClickHandler} />
              </StoreIconsWrapper>
            )}
          </StyledContentAreaWrapper>
          {isNotMobileLayout && !isHideAdditionalInteractions && !isFromLandingOrRegister && (
            <Footer>
              <RPFooter />
            </Footer>
          )}
        </ContentContainer>
      </Container>
    </ContainerWrapper>
  );
};

export default SplitLayout;
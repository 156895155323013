import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';

// Contexts
import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../atoms/RPText';
import RPButton from '../../atoms/RPButton';
import { RPSelectNew } from '../../atoms/RPSelect';
import RPFooter from '../../organisms/RPFooter';
// Hooks
import { useCurrentStepFromLocation, useStepParams } from '../../../core/hooks';
// Types
import { OptionType } from '../../../core/types/SelectTypes';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
// RTK Slice
import { openLoginDialogModal } from '../../../redux/modules/loginDetailsSlice';
import { setSelectedLanguage } from '../../../redux/modules/referenceDataSlice';
import { AppDispatch } from '../../../redux/store';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { CreateCookie as createCookie } from '../../../core/utils/CreateCookie';
// Constants
import {
  LANGUAGE_COOKIE_NAME,
  LANGUAGE_COOKIE_OPTION,
  LOCALE_LANG_CODE_MAPPING,
  SHOW_LANGUAGE_DROPDOWN_ORGANIZATIONS_LIST
} from '../../../core/utils/Constants/Constants';
// Feature Flag
import { featureFlags } from '../../../core/FeatureFlags/FeatureFlags';

interface RPMobileMenuProps {
  selectedLanguage?: string;
  languageOptions?: OptionType[];
  setShowMenu: (showMenu: boolean) => void;
  isFromLandingOrRegister: boolean;
}

const Container = styled('div')(({ theme }) => {
  const {
    colours: { backgrounds }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgrounds.backgroundAlt;

  return {
    padding: '30px 50px 50px',
    maxWidth: '275px',
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,

    '& > div': {
      display: 'flex',
      flexDirection: 'column'
    }
  };
});

const StyledText = styled(RPText)(() => {
  return {
    marginBottom: '10px',
    textAlign: 'center'
  };
});

const LoginBtn = styled(RPButton)(() => {
  return {
    marginBottom: '40px'
  };
});

const Divider = styled('hr')(({ theme }) => {
  const {
    colours: { borders }
  } = theme;

  const COLOUR_BORDER_BORDER_INPUT: HexColour = borders.borderInput;

  return {
    color: COLOUR_BORDER_BORDER_INPUT,
    marginBottom: '50px',
    height: '1px',
    width: '100%'
  };
});

const RPMobileMenu: FC<RPMobileMenuProps> = (props: RPMobileMenuProps) => {
  const dispatch: AppDispatch = useDispatch();

  const { language, translations, setLanguage } = useContext(LanguageContext);
  const { tenant } = useContext(TenantContext);

  const translate = TranslateWrapper(translations);

  const { stepNumber } = useStepParams();

  const { languageOptions, setShowMenu, isFromLandingOrRegister } = props;

  const showLanguageDropdown: boolean = tenant
    ? SHOW_LANGUAGE_DROPDOWN_ORGANIZATIONS_LIST.includes(tenant) && (stepNumber === 1 || isFromLandingOrRegister)
    : false;

  const {
    landingPage: { useReactLandingPage }
  } = featureFlags;

  const handleLoginClick = () => {
    setShowMenu(false);
    dispatch(openLoginDialogModal(true));
  };

  const handleLanguageChange = (value: string) => {
    setLanguage(value);
    dispatch(setSelectedLanguage(value));
    if (useReactLandingPage) {
      createCookie(LANGUAGE_COOKIE_NAME, LOCALE_LANG_CODE_MAPPING[value], LANGUAGE_COOKIE_OPTION);
    }
  };

  return (
    <Container>
      <div>
        <StyledText variant="body2" type="medium" text={translate('registration.header.alreadyHaveAccountText')} />
        <LoginBtn size="medium" onClick={handleLoginClick} name="loginButton" data-testid="rp-button-login">
          {translate('registration.header.loginInText')}
        </LoginBtn>
        {languageOptions && languageOptions.length > 0 && showLanguageDropdown && (
          <RPSelectNew
            name="language-drop-down-mobile"
            placeholderLabel={translate('registration.header.placeholder')}
            value={language}
            toShrinkLabel={false}
            options={languageOptions}
            isSearchable={false}
            isFixedHeight={true}
            onChange={handleLanguageChange}
          />
        )}
      </div>
      <div>
        <Divider />
        <RPFooter size="small" />
      </div>
    </Container>
  );
};

export default RPMobileMenu;

import { FC, memo, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';

// Contexts
import { LanguageContext, TenantContext } from '../../../core/TenantProvider/contexts';
// Types
import { AddressItem } from '../../../core/types/addressLookupTypes';
import { HexColour } from '../../../core/types/ThemeConfigTypes';
// Utils
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import { getIcon } from '../../../core/utils/IconOrgData';
// Constants
import { TEXT_OR_CONTAINER_REQUIRED, ADDRESS, MASK_CLASS } from '../../../core/utils/Constants/Constants';

interface RPAddressDetailsProps {
  entry: AddressItem;
}

const IconContainer = styled('span')(({ theme }) => {
  const {
    colours: { text }
  } = theme;

  const COLOUR_TEXT_TEXT_LINK: HexColour = text.textLink;

  return {
    '> svg > path': {
      fill: COLOUR_TEXT_TEXT_LINK
    }
  };
});

const RPAddressDetails: FC<RPAddressDetailsProps> = ({ entry }: RPAddressDetailsProps) => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const noResultsFound: string = translate('registration.addressLookup.noResultsFound');

  const { tenant } = useContext(TenantContext);

  const ChevronRightIcon = useMemo(() => getIcon(tenant, 'navigationChevronRightIcon'), [tenant]);

  if (entry?.Description === TEXT_OR_CONTAINER_REQUIRED) {
    return <span data-testid="rp-no-results-found-text">{noResultsFound}</span>;
  } else {
    const addressText = entry?.Text ? entry?.Text + ', ' : '';
    const addressDescription = entry?.Description ? entry?.Description : '';

    const addressDetails = (
      <span className={MASK_CLASS}>
        {addressText} {addressDescription}
      </span>
    );
    return (
      <>
        {addressDetails}
        {entry?.Type !== ADDRESS && (
          <IconContainer data-testid="rp-right-chevron-icon"> {<ChevronRightIcon />} </IconContainer>
        )}
      </>
    );
  }
};

export default memo(RPAddressDetails);

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider, Theme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';

import { LanguageContext, TenantContext } from './contexts';
import { getTenantConfig, getTranslations } from './tenants';
import { loadFont } from '../utils/GetFonts';
import { FontFaceDetails } from '../types/ThemeConfigTypes';
import { getReferenceData } from '../../redux/modules/referenceDataSlice';
import { LANG_CODE_MAPPING } from '../utils/Constants/Constants';
import { OrganizationCodeTypes } from '../types/OrganizationTypes';


const TenantProvider = (props: any) => {

  const { selectedLanguage } = useSelector(getReferenceData);

  const { children } = props;

  const [tenantTheme, setTheme] = useState<Theme>();
  const [tenant, setTenant] = useState<OrganizationCodeTypes>();
  const [translations, setTranslationsForTenant] = useState<{
    [key: string]: string;
  } | null>(null);
  const [globalStyles, setGlobalStyles] = useState({});

  const defaultLanguage: string = selectedLanguage !== '' ? selectedLanguage : LANG_CODE_MAPPING['EN'];
  const [language, setLanguageForTenant] = useState<string | undefined>(defaultLanguage);

  useEffect(() => {
    getTenantData();
  }, []);

  useEffect(() => {
    if (tenant && tenantTheme) {
      const fontFaceDetails = tenantTheme.fontFace;
      loadAllFonts(tenant, fontFaceDetails);
      setGlobalStyles(tenantTheme.globalStyles);
    }
  }, [tenant, tenantTheme]);

  const getTenantData = () => {
    getTenantConfig().then((config: any) => {
      const { tenant: currentTenant, theme }: { tenant: OrganizationCodeTypes; theme: any } = config;
      setTheme(theme);
      setTenant(currentTenant);
    });
  };

  const loadAllFonts = (currentTenant: OrganizationCodeTypes, fontFaceDetails: FontFaceDetails[]) => {
    fontFaceDetails.forEach((details: FontFaceDetails) =>
      loadFont(currentTenant, details.fontName, details.fileName, details.fontWeight, details.fontStyle)
    );
  };

  // For Language setting
  const setLanguage = (languageFromDropDown: string) => {
    languageFromDropDown !== language &&
    setLanguageForTenant(languageFromDropDown);
  };

  // For updating tenant
  const updateTenant = (updatedTenant: OrganizationCodeTypes) => {
    tenant !== updatedTenant && setTenant(updatedTenant);
  };

  useEffect(() => {
    getTranslations(language).then((translationsData: any) => {
      setTranslationsForTenant(translationsData);
    });
  }, [language]);

  return tenantTheme ? (
    <ThemeProvider theme={tenantTheme}>
      <GlobalStyles styles={globalStyles} />
      <LanguageContext.Provider
        value={{ language, translations, setLanguage: setLanguage }}
      >
        <TenantContext.Provider
          value={{
            settings: {},
            tenant: tenant,
            updateTenant: updateTenant,
          }}
        >
          {children}
        </TenantContext.Provider>
      </LanguageContext.Provider>
    </ThemeProvider>
  ) : (
    <></>
  );
};

export default TenantProvider;

import { useContext } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { LanguageContext } from '../../../core/TenantProvider/contexts';
import { RegistrationStep } from '../../../core/types/StepProgressTrackerTypes';
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import RPStepProgressTracker from '../../molecules/RPStepProgressTracker';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

const StyledBox = styled(Box)(({ theme }) => {
  const {
    colours: { backgrounds },
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT_INVERSE: HexColour =
    backgrounds.backgroundAltInverse;

  return {
    backgroundColor: COLOUR_BG_BACKGROUND_ALT_INVERSE,
    padding: '20px',
  };
});

const RPStepProgressTrackerVariants = () => {
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  /*const steps: RegistrationStep[] = useMemo(
    () => GetRegistrationStepsData(translate),
    [translate]
  );*/

  const steps: RegistrationStep[] = [
    {
      type: 'create-login',
      status: 'complete',
      label: translate('sidebar.stepProgressTracker.create-login'),
      showEditIcon: true,
      isLoading: false,
    },
    {
      type: 'setup-security',
      status: 'active',
      label: translate('sidebar.stepProgressTracker.setup-security'),
      showEditIcon: false,
      isLoading: false,
    },
    {
      type: 'enter-details',
      status: 'incomplete',
      label: translate('sidebar.stepProgressTracker.enter-details'),
      showEditIcon: false,
      isLoading: false,
    },
    {
      type: 'set-preferences',
      status: 'incomplete',
      label: translate('sidebar.stepProgressTracker.set-preferences'),
      showEditIcon: false,
      isLoading: false,
    },
    {
      type: 'confirming-you',
      status: 'incomplete',
      label: translate('sidebar.stepProgressTracker.confirming-you'),
      showEditIcon: false,
      isLoading: false,
    },
  ];

  const handleStepClick = (stepType: string) => {
    // Should be used to handle the click event.
    console.log(stepType);
  };

  return (
    <StyledBox>
      <RPStepProgressTracker steps={steps} handleStepClick={handleStepClick} />
    </StyledBox>
  );
};

export default RPStepProgressTrackerVariants;

import { FC, memo, MouseEvent, KeyboardEvent } from 'react';
import Popper, { PopperProps } from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

// Components - Atoms, Molecules, Organisms, Pages
import RPAddressList from './RPAddressList';
import RPEnterManualAddressLink from './RPEnterManualAddressLink';
// Types
import { AddressItemsType, AddressItem } from '../../../core/types/addressLookupTypes';
import { HexColour } from '../../../core/types/ThemeConfigTypes';

interface RPAddressLookupPopperProps {
  addressItems: AddressItemsType;
  onSelectAddress: (entry: AddressItem) => void;
  setIsManualEntry: (entry: boolean) => void;
  setCountryChange: (value: boolean) => void;
  open: boolean;
  anchorEl: HTMLElement | null | undefined;
  hasAddressResults: boolean;
}

const StyledPopper = styled(Popper)<PopperProps>(({ theme }) => {
  const {
    colours: {
      backgrounds: { backgroundAlt },
      borders: { borderInputActive }
    }
  } = theme;

  const COLOUR_BG_BACKGROUND_ALT: HexColour = backgroundAlt;
  const COLOUR_BORDER_BORDER_INPUT_ACTIVE: HexColour = borderInputActive;

  return {
    backgroundColor: COLOUR_BG_BACKGROUND_ALT,
    border: `2px solid ${COLOUR_BORDER_BORDER_INPUT_ACTIVE}`,
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    zIndex: 2
  };
});

const PopperWindow = (props: PopperProps) => {
  return (
    <StyledPopper
      {...props}
      placement="bottom"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 10,
            fallbackPlacements: ['bottom']
          }
        }
      ]}
    />
  );
};

const StyledPopperWindow = styled(PopperWindow)(() => {
  return {
    width: '100%',
    inset: '-2px auto auto 0px !important',
    boxSizing: 'border-box'
  };
});

const AddressLookupContainer = styled(Box)<BoxProps>(({ theme }) => {
  const {
    colours: {
      scroll: { thumbBackground, trackBackground }
    }
  } = theme;

  const COLOUR_SCROLL_THUMB_BACKGROUND: string = thumbBackground;
  const COLOUR_SCROLL_TRACK_BACKGROUND: string = trackBackground;

  return {
    padding: '15px 15px 0px',
    maxHeight: '204px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 10,
      height: 6
    },
    '&::-webkit-scrollbar-thumb': {
      background: COLOUR_SCROLL_THUMB_BACKGROUND,
      borderRadius: 8,
      zIndex: 20,
      height: 30
    },
    '&::-webkit-scrollbar-track': {
      background: COLOUR_SCROLL_TRACK_BACKGROUND,
      width: 24,
      borderRadius: 8
    },
    '& .MuiList-padding': {
      padding: 0
    }
  };
});

const RPAddressLookupPopper: FC<RPAddressLookupPopperProps> = ({
  addressItems,
  onSelectAddress,
  setIsManualEntry,
  setCountryChange,
  open,
  anchorEl,
  hasAddressResults
}: RPAddressLookupPopperProps) => {
  const handleManualAddressClick = (event: MouseEvent<HTMLAnchorElement> | KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setIsManualEntry(true);
    setCountryChange(false);
  };

  return (
    <StyledPopperWindow
      open={open && !!anchorEl}
      anchorEl={anchorEl}
      disablePortal={true} //The `children` will be under the DOM hierarchy of the parent component.
    >
      <AddressLookupContainer>
        <RPAddressList
          addressList={addressItems}
          onSelectAddress={onSelectAddress}
          hasAddressResults={hasAddressResults}
        />
      </AddressLookupContainer>
      <RPEnterManualAddressLink handleManualAddressClick={handleManualAddressClick} />
    </StyledPopperWindow>
  );
};

export default memo(RPAddressLookupPopper);

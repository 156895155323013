import { FC } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { ComponentChildrenType } from '../../organisms/RPLayout/RPLayout';
import { LayoutProps } from '../../../core/types/LayoutProps';
import { isMobileLayoutApplicable } from '../../../core/utils/IsMobileLayoutApplicable';

interface RPButtonContainerProps extends LayoutProps {
  children: ComponentChildrenType;
}

interface ButtonContainerProps extends LayoutProps {}

const ButtonContainer = styled(Box)<ButtonContainerProps>(({ layout }) => {
  const isDesktopLayout: boolean = layout === 'desktop';
  const isTabletLayout: boolean = layout === 'tablet';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    marginTop: isDesktopLayout ? 60 : isTabletLayout ? 40 : 30,
    '& button': {
      width: '100%',

      ':nth-of-type(1)': {
        marginBottom: isMobileLayout ? 15 : 10,
      },

      ':nth-of-type(2)': {
        height: 60,
      },
    },
  };
});

const RPButtonContainer: FC<RPButtonContainerProps> = (props: RPButtonContainerProps) => {
  const { children, layout } = props;

  return (
    <ButtonContainer layout={layout} data-testid="rp-button-container">
      {children}
    </ButtonContainer>
  );
};

export default RPButtonContainer;

import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { LanguageContext } from '../TenantProvider/contexts';
import useGetUrlParamValue from './useGetUrlParamValue';
import { AppDispatch } from '../../redux/store';
import { setSelectedLanguage } from '../../redux/modules/referenceDataSlice';
import { LANG_CODE_MAPPING } from '../utils/Constants/Constants';

const useSetLanguageContextFromUrl = (name: string) => {
  const dispatch: AppDispatch = useDispatch();

  const { setLanguage } = useContext(LanguageContext);

  const urlLangValue: string | null = useGetUrlParamValue(name);

  useEffect(() => {
    // Check if URL param for language exists.
    // If yes and is valid, set language to lang context
    urlLangValue &&
      urlLangValue?.trim() !== '' &&
      setLanguageToContext(urlLangValue);
  }, []);

  const setLanguageToContext = (lang: string) => {
    const langValue: string | undefined =
      LANG_CODE_MAPPING[lang?.trim().toUpperCase()];

    if (langValue && langValue !== '') {
      setLanguage(langValue);
      dispatch(setSelectedLanguage(langValue));
    }
  };
};

export default useSetLanguageContextFromUrl;

import cookie from 'cookie';

// Feature Flags
import { featureFlags } from '../../FeatureFlags/FeatureFlags';
// Constants
import { CFSB_BETA_TESTING_OVERRIDE_COOKIE_NAME } from '../Constants/Constants';

const CheckIfCFSBIsEnabled = (): boolean => {

  const { landingPage: { isCFSBEnabled } } = featureFlags;
  const isCFSBCookieOverride: boolean = cookie.parse(document.cookie)[CFSB_BETA_TESTING_OVERRIDE_COOKIE_NAME] === 'true';

  return isCFSBEnabled || isCFSBCookieOverride;
};

export default CheckIfCFSBIsEnabled;
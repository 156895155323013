import { FC, useContext } from 'react';
import { Slide, SlideProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LayoutSize } from '../../../core/types/LayoutTypes';
import { LayoutContext } from '../../../core/TenantProvider/contexts';

interface CustomSliderProps extends SlideProps {
  size: LayoutSize;
}

const CustomSlider = styled(Slide, {
  shouldForwardProp: (prop) => prop !== 'size',
})<CustomSliderProps>(({ in: hasError}) => {

  return {
    marginTop: '15px',
    marginBottom: '30px',
    position: 'relative',
    width: '100%',
    display: hasError ? 'block' : 'none'
  };
});

interface RPSlideProps extends SlideProps {
  error: boolean;
  containerRef: any;
}

const RPSlider: FC<RPSlideProps> = (props: RPSlideProps) => {
  const { layout } = useContext(LayoutContext);
  const { error, containerRef, children } = props;

  return (
    <CustomSlider
      timeout={{
        enter: 600,
        exit: 600
      }}
      easing={{
        enter: 'ease-in',
        exit: 'ease-out'
      }}
      container={containerRef.current}
      direction={'down'}
      in={error}
      size={layout}
      data-testid="rp-custom-slider"
    >
      <div>{children ? children : ''}</div>
    </CustomSlider>
  );
};

export default RPSlider;

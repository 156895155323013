import { FC, useState } from 'react';
import getRoundedFileSize from '../../../core/utils/GetRoundedFileSize';

import { RPUploadedFileDetails } from '../RPUploadedFileDetails';
import { RPUploadFileSection } from '../RPUploadFileSection';

const RPDocumentUpload: FC = () => {
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [uploadedFileSize, setUploadedFileSize] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const handleFileUpload = (files: FileList) => {
    const fileUploaded = files[0];
    const fileName = fileUploaded.name;
    const roundedFileSize: string = getRoundedFileSize(fileUploaded.size);

    setUploadedFileName(fileName);
    setUploadedFileSize(roundedFileSize);
    setUploadedFile(fileUploaded);
  };

  const removeUploadedFile = () => {
    setUploadedFileName('');
    setUploadedFileSize('');
    setUploadedFile(null);
  };

  return uploadedFileName && uploadedFileName.length > 0 ? (
    <RPUploadedFileDetails
      fileName={uploadedFileName}
      fileSize={uploadedFileSize}
      removeUploadedFileHandler={removeUploadedFile}
    />
  ) : (
    <RPUploadFileSection handleFileUpload={handleFileUpload} value={uploadedFile} />
  );
};

export default RPDocumentUpload;

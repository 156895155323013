import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper from './axiosWrapper';
import GetBasicDetails from '../../core/utils/GetBasicDetails';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
import { MAX_ATTEMPTS, POLL_INTERVAL } from '../../core/utils/Constants/Constants';

const { orgCode, parentOrganizationCode } = GetBasicDetails();

type compliancePayload = {
  crmAccountID: string,
  email: string,
  attempt?: number
};

const getComplianceAndAccountStatus = createAsyncThunk(
  "registration-service/getComplianceAndAccountStatus",
  async (data: compliancePayload, thunkAPI) => {
    const { crmAccountID, email, attempt = 1 } = data;

    const payload = {
      "crmAccountID": crmAccountID,
      "email": email,
      "organizationCode": orgCode,
      "parentOrganizationCode": parentOrganizationCode
    }

    try {
      const response = await axiosWrapper('getComplianceAndAccountStatus', 'POST', thunkAPI, payload);

      const { complianceCode } = response?.data?.data;

      if (complianceCode) {
        return response && response.data;
      } else if (attempt < MAX_ATTEMPTS) {
        // TODO Refactor
        setTimeout(() => {
          const increaseAttempt = {
            ...data,
            attempt: attempt + 1
          }
          thunkAPI.dispatch(getComplianceAndAccountStatus(increaseAttempt));
        }, POLL_INTERVAL);
      } else {
        return thunkAPI.rejectWithValue({ error: 'showErrorPage' });
      }

    } catch (error: any) {
      // TODO Refactor
      if (attempt < MAX_ATTEMPTS) {
        setTimeout(() => {
          const increaseAttempt = {
            ...data,
            attempt: attempt + 1
          }
          thunkAPI.dispatch(getComplianceAndAccountStatus(increaseAttempt));
        }, POLL_INTERVAL);
      } else {
        thunkAPI.dispatch(getServicesErrorObj(error));
        return thunkAPI.rejectWithValue({ error: error.response.data.message });
      }
    }
  });

export default getComplianceAndAccountStatus;
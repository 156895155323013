export const countryFlagMapping: { [name: string]: string } = {
  ABW: 'assets/aruba',
  AFG: 'assets/afghanistan',
  AGO: 'assets/angola',
  AIA: 'assets/anguilla',
  ALA: 'assets/aland-islands',
  ALB: 'assets/albania',
  AND: 'assets/andorra',
  ANT: 'assets/netherlands-antilles',
  ARE: 'assets/united-arab-emirates',
  ARG: 'assets/argentina',
  ARM: 'assets/armenia',
  ASM: 'assets/american-samoa',
  ATF: 'assets/french-southern-territories',
  ATG: 'assets/antigua-and-barbuda',
  AUS: 'assets/australia',
  AUT: 'assets/austria',
  AZE: 'assets/azerbaijan',
  BDI: 'assets/burundi',
  BEL: 'assets/belgium',
  BEN: 'assets/benin',
  BES: 'assets/bonaire',
  BFA: 'assets/burkina-faso',
  BGD: 'assets/bangladesh',
  BGR: 'assets/bulgaria',
  BHR: 'assets/bahrain',
  BHS: 'assets/bahamas',
  BIH: 'assets/bosnia-and-herzegovina',
  BLM: 'assets/st-barts',
  BLR: 'assets/belarus',
  BLZ: 'assets/belize',
  BMU: 'assets/bermuda',
  BOL: 'assets/bolivia',
  BRA: 'assets/brazil',
  BRB: 'assets/barbados',
  BRN: 'assets/brunei',
  BTN: 'assets/bhutan',
  BWA: 'assets/botswana',
  CAN: 'assets/canada',
  CHE: 'assets/switzerland',
  CHL: 'assets/chile',
  CHN: 'assets/china',
  CIV: 'assets/ivory-coast',
  CMR: 'assets/cameroon',
  COD: 'assets/democratic-republic-of-congo',
  COG: 'assets/republic-of-the-congo',
  COK: 'assets/cook-islands',
  COL: 'assets/colombia',
  COM: 'assets/comoros',
  CPV: 'assets/cape-verde',
  CRI: 'assets/costa-rica',
  CUB: 'assets/cuba',
  CUW: 'assets/curacao',
  CXR: 'assets/christmas-island',
  CYM: 'assets/cayman-islands',
  CYP: 'assets/cyprus',
  CZE: 'assets/czech-republic',
  DEU: 'assets/germany',
  DJI: 'assets/djibouti',
  DMA: 'assets/dominica',
  DNK: 'assets/denmark',
  DOM: 'assets/dominican-republic',
  DZA: 'assets/algeria',
  ECU: 'assets/ecuador',
  EGY: 'assets/egypt',
  ERI: 'assets/eritrea',
  ESP: 'assets/spain',
  EST: 'assets/estonia',
  ETH: 'assets/ethiopia',
  FIN: 'assets/finland',
  FJI: 'assets/fiji',
  FLK: 'assets/falkland-islands',
  FRA: 'assets/france',
  FRO: 'assets/faroe-islands',
  FSM: 'assets/micronesia',
  GAB: 'assets/gabon',
  GBA: 'assets/united-kingdom',
  GBR: 'assets/united-kingdom',
  GEA: 'assets/abkhazia',
  GEO: 'assets/georgia',
  GGY: 'assets/guernsey',
  GHA: 'assets/ghana',
  GIB: 'assets/gibraltar',
  GIN: 'assets/guinea',
  GMB: 'assets/gambia',
  GNB: 'assets/guinea-bissau',
  GNQ: 'assets/equatorial-guinea',
  GRC: 'assets/greece',
  GRD: 'assets/grenada',
  GRL: 'assets/greenland',
  GTM: 'assets/guatemala',
  GUF: 'assets/guyana',
  GUM: 'assets/guam',
  GUY: 'assets/guyana',
  HKG: 'assets/hong-kong',
  HND: 'assets/honduras',
  HRV: 'assets/croatia',
  HTI: 'assets/haiti',
  HUN: 'assets/hungary',
  IDN: 'assets/indonesia',
  IMN: 'assets/isle-of-man',
  IND: 'assets/india',
  IRL: 'assets/ireland',
  IRN: 'assets/iran',
  IRQ: 'assets/iraq',
  ISL: 'assets/iceland',
  ISR: 'assets/israel',
  ITA: 'assets/italy',
  JAM: 'assets/jamaica',
  JEY: 'assets/jersey',
  JOR: 'assets/jordan',
  JPN: 'assets/japan',
  KAZ: 'assets/kazakhstan',
  KEN: 'assets/kenya',
  KGZ: 'assets/kyrgyzstan',
  KHM: 'assets/cambodia',
  KIR: 'assets/kiribati',
  KNA: 'assets/saint-kitts-and-nevis',
  KOR: 'assets/south-korea',
  KWT: 'assets/kuwait',
  LAO: 'assets/laos',
  LBN: 'assets/lebanon',
  LBR: 'assets/liberia',
  LBY: 'assets/libya',
  LCA: 'assets/st-lucia',
  LIE: 'assets/liechtenstein',
  LKA: 'assets/sri-lanka',
  LSO: 'assets/lesotho',
  LTU: 'assets/lithuania',
  LUX: 'assets/luxembourg',
  LVA: 'assets/latvia',
  MAC: 'assets/macao',
  MAR: 'assets/morocco',
  MCO: 'assets/monaco',
  MDA: 'assets/moldova',
  MDG: 'assets/madagascar',
  MDV: 'assets/maldives',
  MEX: 'assets/mexico',
  MHL: 'assets/marshall-island',
  MKD: 'assets/macedonia',
  MLI: 'assets/mali',
  MLT: 'assets/malta',
  MMR: 'assets/myanmar',
  MNE: 'assets/montenegro',
  MNG: 'assets/mongolia',
  MOZ: 'assets/mozambique',
  MRT: 'assets/mauritania',
  MSR: 'assets/montserrat',
  MUS: 'assets/mauritius',
  MWI: 'assets/malawi',
  MYS: 'assets/malaysia',
  NAM: 'assets/namibia',
  NCL: 'assets/france',
  NER: 'assets/niger',
  NFK: 'assets/norfolk-island',
  NGA: 'assets/nigeria',
  NIC: 'assets/nicaragua',
  NIU: 'assets/niue',
  NLD: 'assets/netherlands',
  NOR: 'assets/norway',
  NPL: 'assets/nepal',
  NRU: 'assets/nauru',
  NZL: 'assets/new-zealand',
  OMN: 'assets/oman',
  PAK: 'assets/pakistan',
  PAN: 'assets/panama',
  PCN: 'assets/pitcairn-islands',
  PER: 'assets/peru',
  PHL: 'assets/philippines',
  PLW: 'assets/palau',
  POL: 'assets/republic-of-poland',
  PRI: 'assets/puerto-rico',
  PRK: 'assets/north-korea',
  PRT: 'assets/portugal',
  PRY: 'assets/paraguay',
  PSE: 'assets/palestine',
  PYF: 'assets/french-polynesia',
  QAT: 'assets/qatar',
  REU: 'assets/france',
  RKS: 'assets/kosovo',
  ROU: 'assets/romania',
  RUS: 'assets/russia',
  RWA: 'assets/rwanda',
  SAU: 'assets/saudi-arabia',
  SDN: 'assets/sudan',
  SEN: 'assets/senegal',
  SGP: 'assets/singapore',
  SLB: 'assets/solomon-islands',
  SLE: 'assets/sierra-leone',
  SLV: 'assets/salvador',
  SMR: 'assets/san-marino',
  SOM: 'assets/somalia',
  SRB: 'assets/serbia',
  SUR: 'assets/suriname',
  SVK: 'assets/slovakia',
  SVN: 'assets/slovenia',
  SWE: 'assets/sweden',
  SWZ: 'assets/swaziland',
  SXM: 'assets/sint-maarten',
  SYC: 'assets/seychelles',
  SYR: 'assets/syria',
  TCA: 'assets/turks-and-caicos',
  TCD: 'assets/chad',
  TGO: 'assets/togo',
  THA: 'assets/thailand',
  TJK: 'assets/tajikistan',
  TKL: 'assets/tokelau',
  TKM: 'assets/turkmenistan',
  TLS: 'assets/east-timor',
  TON: 'assets/tonga',
  TTO: 'assets/trinidad-and-tobago',
  TUN: 'assets/tunisia',
  TUR: 'assets/turkey',
  TUV: 'assets/tuvalu',
  TWN: 'assets/taiwan',
  TZA: 'assets/tanzania',
  UGA: 'assets/uganda',
  UKR: 'assets/ukraine',
  URY: 'assets/uruguay',
  USA: 'assets/united-states-of-america',
  UZB: 'assets/uzbekistn',
  VAT: 'assets/vatican-city',
  VCT: 'assets/st-vincent-and-the-grenadines',
  VEN: 'assets/venezuela',
  VGB: 'assets/british-virgin-islands',
  VIR: 'assets/virgin-islands',
  VNM: 'assets/vietnam',
  VUT: 'assets/vanuatu',
  WSM: 'assets/samoa',
  YEM: 'assets/yemen',
  ZAF: 'assets/south-africa',
  ZMB: 'assets/zambia',
  ZWE: 'assets/zimbabwe'
};

import { PCA_API_KEY } from '../../Constants/Constants';

export const addressSearchUrl: Function = (
  text: string,
  countries: string,
  limit: number = 10,
  key: string = PCA_API_KEY
) =>
  `https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.00/json3ex.ws?Key=${key}&Text=${text}&Countries=${countries}&Limit=${limit}`;

export const addressRetrieveUrl: Function = (
  id: string,
  key: string = PCA_API_KEY
) =>
  `https://services.postcodeanywhere.co.uk/Capture/Interactive/Retrieve/v1.00/json3ex.ws?Key=${key}&Id=${id}`;

export const additionalAddressSearchUrl: Function = (
  text: string,
  countries: string,
  container: string,
  limit: number = 10,
  key: string = PCA_API_KEY
) =>
  `https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.00/json3ex.ws?Key=${key}&Text=${text}&Countries=${countries}&Container=${container}&Limit=${limit}`;

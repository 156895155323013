import { Helmet } from 'react-helmet';

import { LoadGoogleAnalyticsScript } from '../../../core/utils/GoogleAnalyticTag';

const RPGoogleHeaderScript = () => {
  const googleAnalyticsHeaderScript = LoadGoogleAnalyticsScript('header');
  return (
    <Helmet>
      <script type="text/javascript">{googleAnalyticsHeaderScript}</script>
    </Helmet>
  );
};

export default RPGoogleHeaderScript;

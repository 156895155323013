import { createAction, createSlice } from '@reduxjs/toolkit';

import { ReduxState } from '../../core/types/ReduxStoreTypes';
import { initialState } from '../initialState';
import { RESET_LOGIN_DETAILS } from '../ActionTypeConstants';

export const resetLoginDetails = createAction<string>(RESET_LOGIN_DETAILS);

const loginDetailsSlice = createSlice({
  name: 'loginDetails',
  initialState: initialState.loginDetails,
  reducers: {
    openLoginDialogModal(state, action) {
      state.isLoginDialogShow = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetLoginDetails, () => initialState.loginDetails);
  }
});

export const getLoginDetails = (state: ReduxState) => state.loginDetails;

export const { openLoginDialogModal } = loginDetailsSlice.actions;

export default loginDetailsSlice.reducer;

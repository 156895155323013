import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { LanguageContext } from '../../../core/TenantProvider/contexts'
import TranslateWrapper from '../../../core/utils/TranslateWrapper';
import RPLanguageTranslation from '../../organisms/RPLanguageTranslation';

const CustomizeButton = styled(Button)(({theme}) => {
  return {
    backgroundColor: theme?.palette.background.default,
    color: theme?.palette.text.primary,
    marginTop: 20,
    '&:hover': {
      backgroundColor: theme?.palette.background.default,
      borderColor: theme?.palette.primary.main,
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: theme?.palette.primary.main,
      borderColor: theme?.palette.primary.main,
      boxShadow: 'none',
    },
    '&:focus': {
      boxShadow: 'none',
    },
  }
});

// const ColorButton = styled(Button)<ButtonProps>(({theme}) => ({
//   color: theme.palette.getContrastText(purple[500]),
//   backgroundColor: purple[500],
//   '&:hover': {
//     backgroundColor: purple[700],
//   },
// }));

// TODO - Sample Button component - NOT TO BE USED FOR VIEWS
const RPButton1: React.FC<any> = (props: any) => {
  // console.log('RPButton1 props: ', props);
  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  return (
    <>
      <RPLanguageTranslation />
      <CustomizeButton variant="contained" size="large">{translate('landing.button')}</CustomizeButton>
    </>
  );
};

export default React.memo(RPButton1);
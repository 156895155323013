import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosWrapper, { ObjectMapping } from './axiosWrapper';
import { getServicesErrorObj } from '../modules/errorDetailsSlice';
import GetBasicDetails from '../../core/utils/GetBasicDetails';

const { orgCode, accountType } = GetBasicDetails();

const getCurrencyData = createAsyncThunk(
  '/reference-data/currencies', async (_, thunkAPI) => {

    const dataToInsert: ObjectMapping = {
      orgCode,
      accountType
    };

    try {
      const response = await axiosWrapper('getCurrencyData', 'GET', thunkAPI, null, dataToInsert);

      return response && response.data;
    } catch (error: any) {
      thunkAPI.dispatch(getServicesErrorObj(error));
      return thunkAPI.rejectWithValue({ error: error.response.data.message });
    }
  });

export default getCurrencyData;
import React from 'react';
import { Box, SxProps } from '@mui/material';

import NeedHelpSection from '../../molecules/NeedHelpSection';

const styleObj: SxProps = {
  backgroundColor: '#0530AD',
  padding: '40px 60px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  alignItems: 'center',
};

const RPNeedHelpSectionVariants = () => {
  return (
    <Box sx={styleObj}>
      <NeedHelpSection size="large" />
      <br />
      <NeedHelpSection size="medium" />
    </Box>
  );
};

export default RPNeedHelpSectionVariants;

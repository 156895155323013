import { GoogleAnalyticsTagsMappingType, PoiTagsMapping, PoaTagsMapping } from '../../types/GoogleAnalyticsTypes';

export const GoogleAnalyticsTagsMapping: GoogleAnalyticsTagsMappingType = {
  eventAction: {
    click: {
      UA: 'Click',
      GA4: 'click'
    },
    load: {
      UA: 'Load',
      GA4: 'load'
    },
    select: {
      UA: 'Select',
      GA4: 'select'
    }
  },
  eventActionKey: {
    click: 'click_item',
    load: 'load_item',
    select: 'selected_item'
  },
  eventName: {
    pfx_registration_name: {
      UA: 'PFX_Registration_2.0',
      GA4: 'pfx_registration_2.0'
    }
  },
  createYourLogin: {
    click: {
      referredNo: {
        UA: 'Create your login - referred_no',
        GA4: 'create_your_login_referred_no'
      },
      referredYes: {
        UA: 'Create your login - referred_yes',
        GA4: 'create_your_login_referred_yes'
      },
      continueButton: {
        UA: 'Create your login - continue_cta',
        GA4: 'create_your_login_continue_cta'
      },
      alreadyHaveAccountLoginButton: {
        UA: 'Create your login - already_have_an_account_lightbox_log_in_cta',
        GA4: 'create_your_login_already_have_an_account_lightbox_log_in_cta'
      },
      alreadyHaveAccountResetPassword: {
        UA: 'Create your login - already_have_an_account_lightbox_reset_pwd_link',
        GA4: 'create_your_login_already_have_an_account_lightbox_reset_pwd_link'
      },
      alreadyHaveAccountPhoneNumber: {
        UA: 'Create your login - already_have_an_account_lightbox_phone_number_link',
        GA4: 'create_your_login_already_have_an_account_lightbox_phone_number_link'
      },
      alreadyHaveCFXAccountLoginButton: {
        UA: 'Create your login - already_have_a_cfx_account_lightbox_log_in_cta',
        GA4: 'create_your_login_already_have_a_cfx_account_lightbox_log_in_cta'
      },
      alreadyHaveCFXAccountResetPassword: {
        UA: 'Create your login - already_have_a_cfx_account_lightbox_reset_pwd_link',
        GA4: 'create_your_login_already_have_a_cfx_account_lightbox_reset_pwd_link'
      },
      alreadyHaveCFXAccountPhoneNumber: {
        UA: 'Create your login - already_have_a_cfx_account_lightbox_phone_number_link',
        GA4: 'create_your_login_already_have_a_cfx_account_lightbox_phone_number_link'
      },
      //TODO - Below two tag will be get deleted after confirmation, as the ZAR modal is not present in this flow
      zarTransferSignUp: {
        UA: 'Create your login - zar_transfer_sign_up_for_cd_cta',
        GA4: 'create_your_login_zar_transfer_sign_up_for_cd_cta'
      },
      zarTransferContinue: {
        UA: 'Create your login - zar_transfer_continue_to_cd_text_link',
        GA4: 'create_your_login_zar_transfer_continue_to_cd_text_link'
      }
    },
    load: {
      pageLoad: {
        UA: 'Create your login 2.0 - loaded',
        GA4: 'create_your_login_2.0_loaded'
      },
      alreadyHaveAccount: {
        UA: 'Create your login - already_have_an_account_lightbox_loaded',
        GA4: 'create_your_login_already_have_an_account_lightbox_loaded'
      },
      alreadyHaveCFXAccount: {
        UA: 'Create your login - already_have_a_cfx_account_lightbox_loaded',
        GA4: 'create_your_login_already_have_a_cfx_account_lightbox_loaded'
      },
      //TODO - Below tag will be get deleted after confirmation, as the ZAR modal is not present in this flow
      zarTransfer: {
        UA: 'Create your login - zar_transfer_lightbox_loaded',
        GA4: 'create_your_login_zar_transfer_lightbox_loaded'
      }
    },
    select: {}
  },
  setupSecurityNumber: {
    click: {
      confirmMobileNumber: {
        UA: 'Set up security number - confirm_my_mobile_cta',
        GA4: 'set_up_security_number_confirm_mobile_cta'
      },
      setupSecurityNumberBackButton: {
        UA: 'Set up security number - back_cta',
        GA4: 'set_up_security_number_back_cta'
      }
    },
    load: {
      setupSecurityPageLoad: {
        UA: 'Set up security number 2.0 - loaded',
        GA4: 'set_up_security_number_2.0_loaded'
      }
    },
    select: {}
  },
  enterSecurityCode: {
    click: {
      recieveNewCode: {
        UA: 'Enter security code - request_new_code_clicked',
        GA4: 'enter_security_code_new_code_clicked'
      },
      recieveACall: {
        UA: 'Enter security code - receive_a_call_clicked',
        GA4: 'enter_security_code_receive_a_call_clicked'
      },
      securityCodeSubmit: {
        UA: 'Enter security code - submit_cta',
        GA4: 'enter_security_code_submit_cta'
      },
      securityCodeBackButton: {
        UA: 'Enter security code - back_cta',
        GA4: 'enter_security_code_back_cta'
      }
    },
    load: {
      enterSecurityCodePageLoad: {
        UA: 'Enter security code 2.0 - loaded',
        GA4: 'enter_security_code_2.0_loaded'
      }
    },
    select: {}
  },
  enterYourDetails: {
    click: {
      continueButton: {
        UA: 'Enter your details - continue_cta',
        GA4: 'enter_your_details_continue_cta'
      },
      backButton: {
        UA: 'Enter your details - back_cta',
        GA4: 'enter_your_details_back_cta'
      },
      whyDoWeNeedThis: {
        UA: 'Enter your details - why_do_we_need_this',
        GA4: 'enter_your_details_why_do_we_need_this'
      },
      alreadyHaveAnAccountLogin: {
        UA: 'Enter your details - already_have_an_account_lightbox_log_in_cta',
        GA4: 'enter_your_details_already_have_an_account_lightbox_log_in_cta'
      },
      alreadyHaveAnAccountResetPassword: {
        UA: 'Enter your details - already_have_an_account_lightbox_reset_pwd_link',
        GA4: 'enter_your_details_already_have_an_account_lightbox_reset_pwd_link'
      },
      alreadyHaveAccountPhonNumber: {
        UA: 'Enter your details - already_have_an_account_lightbox_phone_number_link',
        GA4: 'enter_your_details_already_have_an_account_lightbox_phone_number_link'
      },
      //TODO - Below two tags will be get deleted after confirmation, as the ZAR modal is not present in this flow
      zarTransferSignUpForCD: {
        UA: 'Enter your details - zar_transfer_sign_up_for_cd_cta',
        GA4: 'enter_your_details_zar_transfer_sign_up_for_cd_cta'
      },
      zarTransferContinueToCD: {
        UA: 'Enter your details - zar_transfer_continue_to_cd_text_link',
        GA4: 'enter_your_details_zar_transfer_continue_to_cd_text_link'
      }
    },
    load: {
      enterYourDetailsPageLoad: {
        UA: 'Enter your details 2.0 - loaded',
        GA4: 'enter_your_details_2.0_loaded'
      },
      alreadyHaveAnAccountLoad: {
        UA: 'Enter your details - already_have_an_account_lightbox_loaded',
        GA4: 'enter_your_details_already_have_an_account_lightbox_loaded'
      },
      //TODO - Below tag will be get deleted after confirmation, as the ZAR modal is not present in this flow
      zarTransferLoad: {
        UA: 'Enter your details - zar_transfer_lightbox_loaded',
        GA4: 'enter_your_details_zar_transfer_lightbox_loaded'
      }
    },
    select: {
      occupation: {
        UA: 'Enter your details - occupation_{{occupation}}',
        GA4: 'enter_your_details_occupation_{{occupation}}'
      },
      countryOfResidence: {
        UA: 'Enter your details - country_of_residence_{{COR}}',
        GA4: 'enter_your_details_country_of_residence_{{COR}}'
      }
    }
  },
  setYourPreferences: {
    click: {
      continueButton: {
        UA: 'Set your preferences - continue_cta',
        GA4: 'set_your_preferences_continue_cta'
      },
      backButton: {
        UA: 'Set your preferences - back_cta',
        GA4: 'set_your_preferences_back_cta'
      },
      termLink: {
        UA: 'Set your preferences - terms_link',
        GA4: 'set_your_preferences_terms_link'
      },
      privacyLink: {
        UA: 'Set your preferences - privacy_link',
        GA4: 'set_your_preferences_privacy_link'
      },
      zarTransferSignUpForCD: {
        UA: 'Set your preferences - zar_transfer_sign_up_for_cd_cta',
        GA4: 'set_your_preferences_zar_transfer_sign_up_for_cd_cta'
      },
      zarTransferContinueToCD: {
        UA: 'Set your preferences - zar_transfer_continue_to_cd_text_link',
        GA4: 'set_your_preferences_zar_transfer_continue_to_cd_text_link'
      }
    },
    load: {
      setYourPreferencesPageLoad: {
        UA: 'Set your preferences 2.0 - loaded',
        GA4: 'set_your_preferences_2.0_loaded'
      },
      zarTransferLoad: {
        UA: 'Set your preferences - zar_transfer_lightbox_loaded',
        GA4: 'set_your_preferences_zar_transfer_lightbox_loaded'
      }
    },
    select: {
      estimatedTransfer: {
        UA: 'Set your preferences - estimated_transfer_{{ETV}}',
        GA4: 'set_your_preferences_estimated_transfer_{{ETV}}'
      }
    }
  },
  verificationTimer: {
    click: {},
    load: {
      verificationTimerPageLoad: {
        UA: 'Verification timer 2.0 - loaded',
        GA4: 'verification_timer_2.0_loaded'
      },
      captureTitanIdAndLegalEntity: {
        UA: 'Verified customer capture 2.0',
        GA4: 'verified_customer_capture_2.0'
      }
    },
    select: {}
  },
  kycFailedDocument: {
    click: {
      uploadNow: {
        UA: 'Kyc failed - document_upload_options_upload_now_cta',
        GA4: 'kyc_failed_document_upload_options_upload_now_cta'
      },
      uploadNowReturn: {
        UA: 'Kyc failed - return_document_upload_options_upload_now_cta',
        GA4: 'Kyc_failed_return_document_upload_options_upload_now_cta'
      },
      useMobileApp: {
        UA: 'Kyc failed - document_upload_options_use_mobile_app_cta_clicked',
        GA4: 'kyc_failed_document_upload_options_use_mobile_app_cta_clicked'
      },
      useMobileAppReturn: {
        UA: 'Kyc failed - return_document_upload_options_use_mobile_app_cta_clicked',
        GA4: 'Kyc_failed_return_document_upload_options_use_mobile_app_cta_clicked'
      },
      uploadDocsLater: {
        UA: 'Kyc failed - document_upload_options_upload_docs_later_text_link_clicked',
        GA4: 'kyc_failed_document_upload_options_upload_docs_later_text_link'
      },
      uploadDocsLaterReturn: {
        UA: 'Kyc failed - return_document_upload_options_upload_docs_later_text_link_clicked',
        GA4: 'Kyc_failed_return_document_upload_documents_later_text_link_clicked'
      },
      mobileUploadContinue: {
        UA: 'Kyc failed - mobile_document_upload_field_continue_cta_clicked',
        GA4: 'kyc_failed_mobile_document_upload_field_continue_cta_clicked'
      },
      seeDocumentRequirementsLink: {
        UA: 'Kyc failed - see_document_requirements_text_link_clicked',
        GA4: 'kyc_failed_see_document_requirements_text_link_clicked'
      },
      seeDocumentRequirementsLinkReturn: {
        UA: 'Kyc failed - return_see_document_requirements_text_link_clicked',
        GA4: 'Kyc_failed_return_see_document_requirements_text_link_clicked'
      },
      seeDocumentRequirementsLinkReturnInactive: {
        UA: 'kyc_skipped_poi_and_poa_see_document_requirements_text_link',
        GA4: 'kyc_skipped_poi_and_poa_see_document_requirements_text_link'
      },
      appStoreIcon: {
        UA: 'Kyc failed - sms_sent_confirmation_app_store _icon_clicked',
        GA4: 'kyc_failed_sms_sent_confirmation_app_store_icon_clicked'
      },
      googlePlayIcon: {
        UA: 'Kyc failed - sms_sent_confirmation_google_play _icon_clicked',
        GA4: 'kyc_failed_sms_sent_confirmation_google_play_icon_clicked'
      },
      closeWindow: {
        UA: 'Kyc failed - sms_sent_confirmation_close_window_cta_clicked',
        GA4: 'kyc_failed_sms_sent_confirmation_close_window_cta_clicked'
      },
      backTextLink: {
        UA: 'Kyc failed - sms_sent_confirmation_back_text_link_clicked',
        GA4: 'kyc_failed_sms_sent_confirmation_back_text_link_clicked'
      },
      skipDocumentUploadNowCTA: {
        UA: 'Kyc failed - are_you_sure_to_skip_document_upload_now_cta',
        GA4: 'kyc_failed_are_you_sure_to_skip_document_upload_now_cta'
      },
      skipDocumentDoItLater: {
        UA: 'Kyc failed - are_you_sure_to_skip_document_do_it_later_text_link',
        GA4: 'kyc_failed_are_you_sure_to_skip_document_do_it_later_text_link'
      },
      poiOnfidoCTAClicked: {
        UA: 'Kyc failed - poi_only_2.0_onfido_verify_identify_cta',
        GA4: 'kyc_failed_poi_2.0_onfido_verify_identify_cta'
      },
      poiOnFidoCTAClickedReturn: {
        UA: 'Kyc failed - return_poi_only_2.0_onfido_verify_identify_cta',
        GA4: 'kyc_failed_return_poi_2.0_onfido_verify_identify_cta'
      },
      poiOnFidoCTAClickedReturnInactive: {
        UA: 'kyc_skipped_onfido_verify_identity_cta',
        GA4: 'kyc_skipped_onfido_verify_identity_cta'
      }
    },
    load: {
      smsSendConfirmation: {
        UA: 'Kyc failed - sms_sent_confirmation_2.0_pageload',
        GA4: 'kyc_failed_sms_sent_confirmation_2.0_loaded'
      },
      poiAndPoaLoaded: {
        UA: 'Kyc failed - poi_&_poa_2.0_loaded',
        GA4: 'kyc_failed_poi_and_poa_2.0_loaded'
      },
      poiAndPoaLoadedReturn: {
        UA: 'Kyc failed - return_poi_&_poa_2.0_loaded',
        GA4: 'kyc_failed_return_poi_and_poa_2.0_loaded'
      },
      poiAndPoaLoadedReturnInactive: {
        UA: 'kyc_skipped_poi_and_poa_loaded',
        GA4: 'kyc_skipped_poi_and_poa_loaded'
      },
      poiLoaded: {
        UA: 'Kyc failed - poi_only_2.0_loaded',
        GA4: 'kyc_failed_poi_2.0_loaded'
      },
      poiLoadedReturn: {
        UA: 'Kyc failed - return_poi_only_2.0_loaded',
        GA4: 'kyc_failed_return_poi_2.0_loaded'
      },
      poaLoaded: {
        UA: 'Kyc failed - poa_only_2.0_loaded',
        GA4: 'kyc_failed_poa_2.0_loaded'
      },
      poaLoadedReturn: {
        UA: 'Kyc failed - return_poa_only_2.0_loaded',
        GA4: 'kyc_failed_return_poa_2.0_loaded'
      },
      poaLoadedReturnInactive: {
        UA: 'kyc_skipped_poa_loaded',
        GA4: 'kyc_skipped_poa_loaded'
      },
      skipDocumentPopUp: {
        UA: 'Kyc failed - are_you_sure_to_skip_document_pop_up',
        GA4: 'kyc_failed_are_you_sure_to_skip_document_pop_up'
      },
      poiDocumentUploadLoading: {
        UA: 'Kyc failed - poi_2.0_document_upload_loading',
        GA4: 'kyc_failed_poi_2.0_document_upload_loading'
      },
      poaDocumentUploadLoading: {
        UA: 'Kyc failed - poa_2.0_document_upload_loading',
        GA4: 'kyc_failed_poa_2.0_document_upload_loading'
      },
      poaDocumentUploadLoadingReturnInactive: {
        UA: 'kyc_skipped_poa_document_upload_loading',
        GA4: 'kyc_skipped_poa_document_upload_loading'
      },
      poaPoiDocumentUploadLoading: {
        UA: 'Kyc failed - poi_&_poa_2.0_document_upload_loading',
        GA4: 'kyc_failed_poi_and_poa_2.0_document_upload_loading'
      },
      poaPoiDocumentUploadLoadingReturnInactive: {
        UA: 'kyc_skipped_poi_and_poa_document_upload_loading',
        GA4: 'kyc_skipped_poi_and_poa_document_upload_loading'
      },
      documentUploadedSuccess: {
        UA: 'Kyc failed - document_uploaded_success',
        GA4: 'kyc_failed_document_uploaded_2.0_success'
      },
      poiDocumentUploadComplete: {
        UA: 'Kyc failed - poi_2.0_document_upload_complete',
        GA4: 'kyc_failed_poi_2.0_document_upload_complete'
      },
      poaDocumentUploadComplete: {
        UA: 'Kyc failed - poa_2.0_document_upload_complete',
        GA4: 'kyc_failed_poa_2.0_document_upload_complete'
      },
      poaDocumentUploadCompleteReturnInactive: {
        UA: 'kyc_skipped_poi_and_poa_document_upload_complete',
        GA4: 'kyc_skipped_poi_and_poa_document_upload_complete'
      },
      poaPoiDocumentUploadComplete: {
        UA: 'Kyc failed - poi_&_poa_2.0_document_upload_complete',
        GA4: 'kyc_failed_poi_and_poa_2.0_document_upload_complete'
      },
      poaPoiDocumentUploadCompleteReturnInactive: {
        UA: 'kyc_skipped_poi_and_poa_document_upload_complete',
        GA4: 'kyc_skipped_poi_and_poa_document_upload_complete'
      },
      mobileDocumentUploadField: {
        UA: 'Kyc failed - mobile_document_upload_field_shown',
        GA4: 'Kyc_failed_mobile_document_upload_field_shown'
      },
      poiOnfidoLoaded: {
        UA: 'Kyc failed - poi_only_2.0_onfido_loaded',
        GA4: 'kyc_failed_poi_2.0_onfido_loaded'
      },
      poiOnFidoLoadedReturn: {
        UA: 'Kyc failed - return_poi_only_2.0_onfido_loaded',
        GA4: 'kyc_failed_return_poi_2.0_onfido_loaded'
      },
      poiOnFidoLoadedReturnInactive: {
        UA: 'kyc_skipped_onfido_loaded',
        GA4: 'kyc_skipped_onfido_loaded'
      },
      poiOnfidoDocUploaded: {
        UA: 'Kyc failed - poi_2.0_onfido_documents_successfully_uploaded',
        GA4: 'Kyc_failed_poi_2.0_onfido_documents_successfully_uploaded'
      },
      poiOnFidoDocUploadedReturn: {
        UA: 'Kyc failed - return_poi_2.0_onfido_documents_successfully_uploaded',
        GA4: 'Kyc_failed_return_poi_2.0_onfido_documents_successfully_uploaded'
      },
      poiOnFidoDocUploadedReturnInactive: {
        UA: 'kyc_skipped_onfido_poi_documents_successfully_uploaded',
        GA4: 'kyc_skipped_onfido_poi_documents_successfully_uploaded'
      },
      poiOnfidoDocUploadComplete: {
        UA: 'Kyc failed - poi_onfido_2.0_document_upload_complete',
        GA4: 'kyc_failed_poi_onfido_2.0_document_upload_complete'
      },
      poiOnFidoDocUploadCompleteReturn: {
        UA: 'Kyc failed - return_poi_onfido_2.0_document_upload_complete',
        GA4: 'kyc_failed_return_poi_onfido_2.0_document_upload_complete'
      },
      poiOnFidoDocUploadCompleteReturnInactive: {
        UA: 'kyc_skipped_onfido_poi_document_upload_complete',
        GA4: 'kyc_skipped_onfido_poi_document_upload_complete'
      },
      nonOnfidoDocumentReviewPendingReturnInactive: {
        UA: 'kyc_skipped_document_review_pending',
        GA4: 'kyc_skipped_document_review_pending'
      },
      poiOnFidoDocUploadLoadedReturn: {
        UA: 'Kyc failed - return_poi_onfido_2.0_document_upload_loaded',
        GA4: 'kyc_failed_return_poi_2.0_onfido_document_upload_loaded'
      }
    },
    select: {
      poiPassportSelected: {
        UA: 'kyc failed - poi_documents_options_passport_selected',
        GA4: 'kyc_failed_poi_documents_passport_selected'
      },
      poiNationalIdentityCardSelected: {
        UA: 'kyc failed - poi_documents_options_national_identity_card_selected',
        GA4: 'kyc_failed_poi_documents_national_identity_card_selected'
      },
      poiDrivingLicenseSelected: {
        UA: 'kyc failed - poi_documents_options_driving_licence_selected',
        GA4: 'kyc_failed_poi_documents_driving_licence_selected'
      },
      poiResidencePermitSelected: {
        UA: 'kyc failed - poi_documents_options_residence_permit_selected',
        GA4: 'kyc_failed_poi_documents_residence_permit_selected'
      },
      poiFirearmsCertificateSelected: {
        UA: 'kyc failed - poi_documents_options_fireams_certificate_selected',
        GA4: 'kyc_failed_poi_documents_fireams_certificate_selected'
      },
      poiResidencePermitGreenCard: {
        UA: 'kyc failed - poi_documents_options_residence_permit_green_card_selected',
        GA4: 'kyc_failed_poi_documents_residence_permit_green_card_selected'
      },
      poiFullStateDrivingLicence: {
        UA: 'kyc failed - poi_documents_options_full_state_driving_licence_selected',
        GA4: 'kyc_failed_poi_documents_full_state_driving_licence_selected'
      },
      poiFullDriversLicence: {
        UA: 'kyc failed - poi_documents_options_full_drivers_licence_selected',
        GA4: 'kyc_failed_poi_documents_full_drivers_licence_selected'
      },
      poiGovernmentIssuedIdCard: {
        UA: 'kyc failed - poi_documents_options_government_issued_id_card_selected',
        GA4: 'kyc_failed_poi_documents_government_issued_id_card_selected'
      },
      poiForeignIdentityCard: {
        UA: 'kyc failed - poi_documents_options_foreign_identity_card_selected',
        GA4: 'kyc_failed_poi_documents_foreign_identity_card_selected'
      },
      poiResidenceCard: {
        UA: 'kyc failed - poi_documents_options_residence_card_selected',
        GA4: 'kyc_failed_poi_documents_residence_card_selected'
      },
      poiFullDriversProbLicense: {
        UA: 'kyc failed - poi_documents_options_full_drivers_prob_license_selected',
        GA4: 'kyc_failed_poi_documents_full_drivers_prob_license_selected'
      },
      poiInternationalDrivingPermit: {
        UA: 'kyc failed - poi_documents_options_international_driving_permit_selected',
        GA4: 'kyc_failed_poi_documents_international_driving_permit_selected'
      },
      poiCitizensCertificate: {
        UA: 'kyc failed - poi_documents_options_citizens_certificate_selected',
        GA4: 'kyc_failed_poi_documents_citizens_certificate_selected'
      },
      poiAgeProofCard: {
        UA: 'kyc failed - poi_documents_options_age_proof_card_selected',
        GA4: 'kyc_failed_poi_documents_age_proof_card_selected'
      },
      poaDrivingLicenseSelected: {
        UA: 'kyc failed - poa_documents_options_driving_licence_selected',
        GA4: 'kyc_failed_poa_documents_driving_licence_selected'
      },
      poaUtilityBillSelected: {
        UA: 'kyc failed - poa_documents_options_utility_bill_selected',
        GA4: 'kyc_failed_poa_documents_utility_bill_selected'
      },
      poaLandlineBillSelected: {
        UA: 'kyc failed - poa_documents_options_landline_bill_selected',
        GA4: 'kyc_failed_poa_documents_landline_bill_selected'
      },
      poaBankStatementSelected: {
        UA: 'kyc failed - poa_documents_options_bank_statement_selected',
        GA4: 'kyc_failed_poa_documents_bank_statement_selected'
      },
      poaPensionLetterSelected: {
        UA: 'kyc failed - poa_documents_options_pension_letter_selected',
        GA4: 'kyc_failed_poa_documents_pension_letter_selected'
      },
      poaMortgageStatementSelected: {
        UA: 'kyc failed - poa_documents_options_mortgage_statement_selected',
        GA4: 'kyc_failed_poa_documents_mortgage_statement_selected'
      },
      poaGovDocSelected: {
        UA: 'kyc failed - poa_documents_options_gov_document_selected',
        GA4: 'kyc_failed_poa_documents_gov_document_selected'
      },
      poaVoterCardSelected: {
        UA: 'kyc failed - poa_documents_options_NI_voters_card_selected',
        GA4: 'kyc_failed_poa_documents_NI_voters_card_selected'
      },
      poaProofInsuranceSelected: {
        UA: 'kyc failed - poa_documents_options_proof_of_insurance_selected',
        GA4: 'kyc_failed_poa_documents_proof_of_insurance_selected'
      },
      poaPropertyLeaseSelected: {
        UA: 'kyc failed - poa_documents_options_property_lease_selected',
        GA4: 'kyc_failed_poa_documents_property_lease_selected'
      },
      poaMobileBillSelected: {
        UA: 'kyc failed - poa_documents_options_mobile_bill_selected',
        GA4: 'kyc_failed_poa_documents_mobile_bill_selected'
      },
      poaPoBoxSelected: {
        UA: 'kyc failed - poa_documents_options_po_box_ref_selected',
        GA4: 'kyc_failed_poa_documents_po_box_ref_selected'
      },
      poaCouncilTaxSelected: {
        UA: 'kyc failed - poa_documents_options_council_tax_or_local_auth_bill_selected',
        GA4: 'kyc_failed_poa_documents_council_tax_selected'
      },
      poaStatementOfBenefitsSelected: {
        UA: 'kyc failed - poa_documents_options_statement_of_benefits_selected',
        GA4: 'kyc_failed_poa_documents_statement_of_benefits_selected'
      },
      poaFullStateDrivingLicence: {
        UA: 'kyc failed - poa_documents_options_full_state_driving_licence_selected',
        GA4: 'kyc_failed_poa_documents_full_state_driving_licence_selected'
      },
      poaBankStatement: {
        UA: 'kyc failed - poa_documents_options_bank_statement_selected',
        GA4: 'kyc_failed_poa_documents_bank_statement_selected'
      },
      poaGovBill: {
        UA: 'kyc failed - poa_documents_options_gov_bill_selected',
        GA4: 'kyc_failed_poa_documents_gov_bill_selected'
      },
      poaVehicleRegistration: {
        UA: 'kyc failed - poa_documents_options_vehicle_registration_selected',
        GA4: 'kyc_failed_poa_documents_vehicle_registration_selected'
      },
      poaTelephoneBillLandline: {
        UA: 'kyc failed - poa_documents_options_telephone_bill_landline_selected',
        GA4: 'kyc_failed_poa_documents_telephone_bill_landline_selected'
      },
      poaTelephoneBillMobile: {
        UA: 'kyc failed - poa_documents_options_telephone_bill_mobile_selected',
        GA4: 'kyc_failed_poa_documents_telephone_bill_mobile_selected'
      },
      poaVisa: {
        UA: 'kyc failed - poa_documents_options_visa_selected',
        GA4: 'kyc_failed_poa_documents_visa_selected'
      },
      poaCertifiedBirthCertificate: {
        UA: 'kyc failed - poa_documents_options_certified_birth_certificate_selected',
        GA4: 'kyc_failed_poa_documents_certified_birth_certificate_selected'
      },
      poaBenefitsStatement: {
        UA: 'kyc failed - poa_documents_options_benefits_statement_selected',
        GA4: 'kyc_failed_poa_documents_benefits_statement_selected'
      },
      poaPensionsDocument: {
        UA: 'kyc failed - poa_documents_options_pensions_document_selected',
        GA4: 'kyc_failed_poa_documents_pensions_document_selected'
      },
      poaFirearmsLicense: {
        UA: 'kyc failed - poa_documents_options_firearms_license_selected',
        GA4: 'kyc_failed_poa_documents_firearms_license_selected'
      },
      poaTaxationAssessment: {
        UA: 'kyc failed - poa_documents_options_taxation_assessment_selected',
        GA4: 'kyc_failed_poa_documents_taxation_assessment_selected'
      },
      poaRentalAgreement: {
        UA: 'kyc failed - poa_documents_options_rental_agreement_selected',
        GA4: 'kyc_failed_poa_documents_rental_agreement_selected'
      },
      poaTaxCorrOrGovIssuedDoc: {
        UA: 'kyc failed - poa_documents_options_tax_corr_or_gov_issued_doc_selected',
        GA4: 'kyc_failed_poa_documents_tax_corr_or_gov_issued_doc_selected'
      }
    }
  },
  registrationPending: {
    click: {},
    load: {
      registrationFailed: {
        UA: 'Registration - 2.0_failed',
        GA4: 'registration_2.0_failed'
      },
      registrationFailedReturn: {
        UA: 'Registration - return_2.0_blacklisted_loaded',
        GA4: 'kyc_failed_return_2.0_blacklisted_loaded'
      }
    },
    select: {}
  },
  stp: {
    click: {},
    load: {
      stpComplete: {
        UA: 'stp - 2.0_complete',
        GA4: 'stp_2.0_complete'
      },
      stpCompleteReturnInactive: {
        UA: 'account_ready_confirmation_loaded',
        GA4: 'account_ready_confirmation_loaded'
      }
    },
    select: {}
  },
  landingPage: {
    click: {
      landingPageOpenAccountCTAClicked: {
        GA4: 'landing_page_open_account_cta_clicked'
      },
      appStoreIcon: {
        GA4: 'app_store _icon_clicked'
      },
      googlePlayIcon: {
        GA4: 'google_play_icon_clicked'
      },
      selectAccountTypeIndividual: {
        GA4: 'select_account_type_screen_an_individual'
      },
      selectAccountTypeBusiness: {
        GA4: 'select_account_type_screen_a_business'
      }
    },
    load: {
      landingPageLoad: {
        GA4: 'landing_page_load'
      },
      selectAccountTypeScreenLoaded: {
        GA4: 'select_account type_screen_2.0_loaded'
      }
    },
    select: {
      landingPageLanguageSelection: {
        GA4: 'landing_page_language_{{language}}'
      },
      accountTypeCOR: {
        GA4: 'select_account_type_screen_country_of_residence_{{COR}}'
      },
      accountTypeState: {
        GA4: 'select_account_type_state_{{state}}'
      },
      accountTypeLanguage: {
        GA4: 'select_account_type_language_{{language}}'
      }
    }
  },
  referAFriendPage: {
    click: {
      rafOpenAccountCTAClicked: {
        GA4: 'raf_landing_page_open_account_cta_clicked'
      },
      rafTermsAndConditionsClicked: {
        GA4: 'raf_landing_page_ts_and_cs_link_clicked'
      }
    },
    load: {
      referFriendPageLoad: {
        GA4: 'raf_landing_page_load'
      }
    },
    select: {}
  }
};

export const poiTagsMappingWithKey: { [key in PoiTagsMapping]: string } = {
  passport: 'poiPassportSelected',
  nationalIdCard: 'poiNationalIdentityCardSelected',
  fullUkDrivingLicence: 'poiDrivingLicenseSelected',
  residencePermit: 'poiResidencePermitSelected',
  firearmsCertificate: 'poiFirearmsCertificateSelected',
  residencePermitGreenCard: 'poiResidencePermitGreenCard',
  fullStateDrivingLicence: 'poiFullStateDrivingLicence',
  fullDriversLicence: 'poiFullDriversLicence',
  governmentIssuedIdCard: 'poiGovernmentIssuedIdCard',
  foreignIdentityCard: 'poiForeignIdentityCard',
  residenceCard: 'poiResidenceCard',
  fullDriversProbLicense: 'poiFullDriversProbLicense',
  internationalDrivingPermit: 'poiInternationalDrivingPermit',
  citizensCertificate: 'poiCitizensCertificate',
  ageProofCard: 'poiAgeProofCard'
};

export const poaTagsMappingWithKey: { [key in PoaTagsMapping]: string } = {
  fullUkDrivingLicence: 'poaDrivingLicenseSelected',
  householdUtilityBill: 'poaUtilityBillSelected',
  telephoneBill: 'poaLandlineBillSelected',
  bankBuildingSocietyStatement: 'poaBankStatementSelected',
  councilTaxOrLocalAuthorityBill: 'poaCouncilTaxSelected',
  statePensionLetter: 'poaPensionLetterSelected',
  mortgageStatement: 'poaMortgageStatementSelected',
  statementOfBenefitsEntitlement: 'poaStatementOfBenefitsSelected',
  taxOrGovIssuedDocument: 'poaGovDocSelected',
  northernIrelandVotersCard: 'poaVoterCardSelected',
  proofOfInsurance: 'poaProofInsuranceSelected',
  propertyLease: 'poaPropertyLeaseSelected',
  mobilePhoneBill: 'poaMobileBillSelected',
  poBoxReference: 'poaPoBoxSelected',
  fullStateDrivingLicence: 'poaFullStateDrivingLicence',
  bankStatement: 'poaBankStatement',
  govBill: 'poaGovBill',
  vehicleRegistration: 'poaVehicleRegistration',
  telephoneBillLandline: 'poaTelephoneBillLandline',
  telephoneBillMobile: 'poaTelephoneBillMobile',
  visa: 'poaVisa',
  certifiedBirthCertificate: 'poaCertifiedBirthCertificate',
  benefitsStatement: 'poaBenefitsStatement',
  pensionsDocument: 'poaPensionsDocument',
  firearmsLicense: 'poaFirearmsLicense',
  taxationAssessment: 'poaTaxationAssessment',
  rentalAgreement: 'poaRentalAgreement',
  taxCorrOrGovIssuedDoc: 'poaTaxCorrOrGovIssuedDoc'
};

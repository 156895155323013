import { useState } from 'react';
import { styled } from '@mui/material/styles';

import { OptionType } from '../../../core/types/SelectTypes';
import { getLanguageOptions } from '../../../core/utils/LanguageData';
import RPMobileMenu from '../../molecules/RPMobileMenu';
import RPMobileHeader from '../../molecules/RPMobileHeader';

interface VisibleProps {
  showMenu: boolean;
}

interface MobileMenuContainerProps extends VisibleProps {}

const Container = styled('div')(() => {
  return {
    position: 'relative',
    height: '700px'
  };
});

const MobileHeaderContainer = styled('div')(() => {
  return {
    maxWidth: '300px',
    margin: '0 auto'
  };
});

const MobileMenuContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showMenu'
})<MobileMenuContainerProps>(({ showMenu }) => {
  return {
    transitionProperty: 'all',
    transitionDuration: '.2s',
    transitionTimingFunction: 'cubic-bezier(0.175,0.885,0.32,1.275)',
    transform: showMenu ? 'translate3d(0,0,0)' : 'translate3d(0,-1em,0)',
    visibility: showMenu ? 'visible' : 'hidden',
    opacity: showMenu ? 1 : 0,
    height: showMenu ? 'auto' : 0
  };
});

const RPMobileMenuVariants = () => {
  const LANGUAGE_OPTIONS: OptionType[] | [] = getLanguageOptions('cd');

  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <hr />
      <Container>
        <MobileHeaderContainer>
          <RPMobileHeader showMenu={showMenu} setShowMenu={setShowMenu} />
        </MobileHeaderContainer>
        <MobileMenuContainer showMenu={showMenu} data-testid="mobile-menu-container">
          <RPMobileMenu
            selectedLanguage={'en-gb'}
            languageOptions={LANGUAGE_OPTIONS}
            setShowMenu={setShowMenu}
            isFromLandingOrRegister={false}
          />
        </MobileMenuContainer>
      </Container>
      <hr />
    </>
  );
};

export default RPMobileMenuVariants;

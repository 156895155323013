import Box from '@mui/material/Box';

import RPNotification from '../../molecules/RPNotification';

const notificationText: string = 'Either your email or your password is incorrect. ' +
  'Please re-enter your correct credentials. If you are not registered with us already, please click here to register with us.';

const RPNotificationVariants = () => {
  return (
    <Box>
      <RPNotification
        variant='error'
        icon={true}
        text={notificationText}
      />
      <br/>
      <RPNotification
        variant='warning'
        icon={true}
        text={notificationText}
      />
      <br/>
      <RPNotification
        variant='success'
        icon={true}
        text={notificationText}
      />
      <br/>
      <RPNotification
        variant='error'
        icon={false}
        text={notificationText}
      />
      <br/>
      <RPNotification
        variant='warning'
        icon={false}
        text={notificationText}
      />
      <br/>
      <RPNotification
        variant='success'
        icon={false}
        text={notificationText}
      />
      <br/>
      <RPNotification
        variant='error'
        size='small'
        icon={true}
        text={notificationText}
      />
      <br/>
      <RPNotification
        variant='warning'
        size='small'
        icon={true}
        text={notificationText}
      />
      <br/>
      <RPNotification
        variant='success'
        size='small'
        icon={true}
        text={notificationText}
      />
      <br/>
      <RPNotification
        variant='error'
        size='small'
        icon={false}
        text={notificationText}
      />
      <br/>
      <RPNotification
        variant='warning'
        size='small'
        icon={false}
        text={notificationText}
      />
      <br/>
      <RPNotification
        variant='success'
        size='small'
        icon={false}
        text={notificationText}
      />
      <br/>
    </Box>
  );
};

export default RPNotificationVariants;

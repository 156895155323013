import { createAction } from '@reduxjs/toolkit';

import {
  RESET_STORE_TO_INITIAL_STATE,
  RESET_PARTIAL_STORE_TO_INITIAL_STATE_FROM_LANDING_PAGE
} from '../ActionTypeConstants';

export const resetStoreToInitialState = createAction<string>(RESET_STORE_TO_INITIAL_STATE);
export const resetStoreToInitialStateFromLandingPage = createAction<string>(
  RESET_PARTIAL_STORE_TO_INITIAL_STATE_FROM_LANDING_PAGE
);

import { FC, useState, ChangeEvent, FocusEvent, useRef, RefObject } from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

// Components - Atoms, Molecules, Organisms, Pages
import RPInput from '../../atoms/RPInput';
// Types
import { HexColour } from '../../../core/types/ThemeConfigTypes';
// Utils
import { getBodySmallNormalStyles } from '../../../core/utils/GetTypographyStyles/GetTypographyStyles';


interface RPPasswordInputProps {
  name: string;
  value: string;
  placeholderLabel: string;
  isDisabled?: boolean;
  maxLength?: number;
  error?: string;
  toolTipText?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

interface ArrowTooltipProps {
  containerRef: RefObject<HTMLDivElement>
}

const ArrowTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))<ArrowTooltipProps>(({ theme, containerRef }) => {
  const {
    colours: {
      backgrounds: { backgroundInfo },
      text: { textInverse }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_INVERSE: HexColour = textInverse;
  const COLOUR_BG_BACKGROUND_INFO: HexColour = backgroundInfo;

  return {
    [`& .${tooltipClasses.arrow}`]: {
      color: COLOUR_BG_BACKGROUND_INFO
    },
    [`& .${tooltipClasses.tooltip}`]: {
      boxSizing: 'border-box',
      backgroundColor: COLOUR_BG_BACKGROUND_INFO,
      maxWidth: containerRef?.current?.clientWidth,
      padding: 12,
      ...getBodySmallNormalStyles(theme),
      color: COLOUR_TEXT_TEXT_INVERSE
    },
  };
});

const RPPasswordInput: FC<RPPasswordInputProps> = (props: RPPasswordInputProps) => {

  const [showTooltip, setShowTooltip] = useState(false);

  const { value, onChange, onFocus, toolTipText } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => onChange && onChange(e);

  const handleOnFocus = (e: FocusEvent<HTMLInputElement>) => onFocus && onFocus(e);

  const handleClose = () => setShowTooltip(false);
  const handleOpen = () => setShowTooltip(true);

  return (
    <div ref={containerRef}>
      <ArrowTooltip
        open={showTooltip}
        onClose={handleClose}
        onOpen={handleOpen}
        title={toolTipText}
        disableHoverListener
        placement="top"
        containerRef={containerRef}
        PopperProps={{
          popperOptions: {
            modifiers: [
              {
                name: "preventOverflow",
                options: {
                  boundary: containerRef.current
                }
              }
            ]
          }
        }}
      >
        <RPInput
          {...props}
          type="password"
          value={value}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          maxLength={50}
          autocomplete="off"
        />
      </ArrowTooltip>
    </div>
  );
};

export default RPPasswordInput;

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import { RPSnackbar } from '../../molecules/RPSnackbar';
import { RPButton } from '../../atoms/RPButton';


const RPSnackbarVariants = () => {

  const [showSnackbarPartial, setShowSnackbarPartial] = useState(false);
  const [showSnackbarFull, setShowSnackbarFull] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSnackbarPartial(false);
      setShowSnackbarFull(false);
    }, 10000);
  })

  return (
    <>
      <Box>
        <RPButton onClick={() => setShowSnackbarPartial(true)} size="medium">
          RPSnackbar - Partial variant, mobile size
        </RPButton>
        <RPSnackbar
          hasError={showSnackbarPartial}
          message="Custom error message"
          offset={0}
          variant="partial"
          size="mobile"
        />
        <br />
        <br />
        <RPButton onClick={() => setShowSnackbarFull(true)} size="medium">
          RPSnackbar - Full variant, desktop size
        </RPButton>
        <RPSnackbar
          hasError={showSnackbarFull}
          message="Custom error message"
          offset={0}
          variant="full"
          size="desktop"
        />
        <br />
      </Box>
    </>
  );
};

export default RPSnackbarVariants;

import { useEffectOnce } from 'usehooks-ts';
import { CookieAttributes } from 'js-cookie';

// Hooks
import { useGetUrlParamValue } from '.';
// Utils
import { CreateCookie as createCookie } from '../utils/CreateCookie';
// Constants
import { AFFILIATE_ASSETID_COOKIE_OPTION, AFFILIATE_ASSETID_COOKIES } from '../utils/Constants/Constants';

const useSetAffiliateCookie = () => {
  const { afflNoCookieName, assetIdCookieName } = AFFILIATE_ASSETID_COOKIES;
  const affiliateCookieOnRegister: { name: string; value: string | null; options: CookieAttributes }[] = [
    {
      name: afflNoCookieName,
      value: useGetUrlParamValue(afflNoCookieName),
      options: AFFILIATE_ASSETID_COOKIE_OPTION
    },
    {
      name: assetIdCookieName,
      value: useGetUrlParamValue(assetIdCookieName),
      options: AFFILIATE_ASSETID_COOKIE_OPTION
    }
  ];

  useEffectOnce(() => {
    affiliateCookieOnRegister.forEach((cookie) => {
      if (cookie.value !== null) {
        createCookie(cookie.name, cookie.value, cookie.options);
      }
    });
  });
};

export default useSetAffiliateCookie;
import { useState } from 'react';

import RPDoB from '../../molecules/RPDoB';
import { fieldTypes } from '../../molecules/RPDoB/RPDoB';

const RPDoBVariants = () => {
  const [dateValue, setDateValue] = useState('');
  const [monthValue, setMonthValue] = useState('');
  const [yearValue, setYearValue] = useState('');

  const onChangeHandler = (value: string, fieldType: fieldTypes) => {
    switch (fieldType) {
      case 'day':
        setDateValue(value);
        break;
      case 'month':
        setMonthValue(value);
        break;
      case 'year':
        setYearValue(value);
        break;
    }
  };

  return (
    <>
      {/* Normal Dob */}
      <RPDoB
        handleOnChange={onChangeHandler}
        value={`${dateValue}/${monthValue}/${yearValue}`}
      />

      {/* middle-endian DoB */}
      <RPDoB
        handleOnChange={onChangeHandler}
        value={`${dateValue}/${monthValue}/${yearValue}`}
        dateFormat="middle-endian"
      />

      {/* big-endian DoB */}
      <RPDoB
        handleOnChange={onChangeHandler}
        value={`${dateValue}/${monthValue}/${yearValue}`}
        dateFormat="big-endian"
      />

      {/* Normal Dob with date, month and year error */}
      <RPDoB
        handleOnChange={onChangeHandler}
        value={`${dateValue}/${monthValue}/${yearValue}`}
        dayError="Invalid date"
        monthError="Invalid month"
        yearError="Invalid year"
      />

      {/* Small Dob */}
      <RPDoB
        handleOnChange={onChangeHandler}
        value={`${dateValue}/${monthValue}/${yearValue}`}
        size="small"
      />

      {/* Small Dob with date, month and year error */}
      <RPDoB
        handleOnChange={onChangeHandler}
        value={`${dateValue}/${monthValue}/${yearValue}`}
        size="small"
        dayError="Invalid date"
        monthError="Invalid month"
        yearError="Invalid year"
      />
    </>
  );
};

export default RPDoBVariants;

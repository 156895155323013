import { FC, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';

// Contexts
import {
  LanguageContext,
  LayoutContext,
  TenantContext,
} from '../../../../core/TenantProvider/contexts';
// Components - Atoms, Molecules, Organisms, Pages
import RPText from '../../../atoms/RPText';
// Types
import { HexColour } from '../../../../core/types/ThemeConfigTypes';
import { LayoutProps } from '../../../../core/types/LayoutProps';
// Utils
import { getIcon } from '../../../../core/utils/IconOrgData';
import TranslateWrapper from '../../../../core/utils/TranslateWrapper';
import { isMobileLayoutApplicable } from '../../../../core/utils/IsMobileLayoutApplicable';

export interface MessageType {
  type: 'warning' | 'error';
}

interface LocationStatusMessageProps extends MessageType {
  selectedState: string;
}

interface ContainerProps extends MessageType, LayoutProps {}

const Container = styled('section', {
  shouldForwardProp: (prop) => prop !== 'type'
})<ContainerProps>(({ theme, type, layout }) => {
  const {
    colours: {
      text: { textError, textWarning }
    }
  } = theme;

  const COLOUR_TEXT_TEXT_ERROR: HexColour = textError;
  const COLOUR_TEXT_TEXT_WARNING: HexColour = textWarning;
  const isTypeWarning: boolean = type === 'warning';
  const isMobileLayout: boolean = isMobileLayoutApplicable(layout);

  return {
    display: 'flex',
    columnGap: '10px',
    marginBottom: isMobileLayout ? '15px' : '30px',

    '> div': {
      display: 'flex',

      '> svg ': {
        height: '24px',
        width: '24px',

        '> path': {
          fill: isTypeWarning ? COLOUR_TEXT_TEXT_WARNING : COLOUR_TEXT_TEXT_ERROR
        }
      }
    }
  };
});

const LocationStatusMessage: FC<LocationStatusMessageProps> = (props: LocationStatusMessageProps) => {
  const { tenant } = useContext(TenantContext);
  const { layout } = useContext(LayoutContext);

  const { translations } = useContext(LanguageContext);
  const translate = TranslateWrapper(translations);

  const { selectedState, type } = props;

  const isTypeWarning: boolean = type === 'warning';

  const partialLocationServiceText: string = translate('registration.enterDetails.partialLocationServiceText', {
    state: selectedState
  });
  const noLocationServiceText: string = translate('registration.enterDetails.noLocationServiceText', {
    state: selectedState
  });

  const OrganizationStatusWarningAltIcon = useMemo(() => getIcon(tenant, 'statusWarningAlt'), [tenant]);

  const StatusErrorIcon = useMemo(() => getIcon(tenant, 'statusError'), [tenant]);

  return (
    <Container type={type} layout={layout}>
      <div>
        {isTypeWarning ? (
          <OrganizationStatusWarningAltIcon data-testid="rp-warning-icon" />
        ) : (
          <StatusErrorIcon data-testid="rp-error-icon" />
        )}
      </div>
      <RPText
        type={isTypeWarning ? 'normal' : 'medium'}
        variant="body2"
        text={isTypeWarning ? partialLocationServiceText : noLocationServiceText}
      />
    </Container>
  );
};

export default LocationStatusMessage;

import { useState } from 'react';

import { SelectedOptionType } from '../../../core/types/SelectTypes';
import RPAddressLookup from '../../molecules/RPAddressLookup';
import { RPSelectNew } from '../../atoms/RPSelect';

const RPAddressLookupVariants = () => {
  const [selectedCountry, setSelectedCountry] = useState('GBR');

  const onChange = (selected: SelectedOptionType) => {
    setSelectedCountry(selected?.value);
  };

  return (
    <>
      <RPSelectNew
        name="country-drop-down-1"
        type="country"
        placeholderLabel="Country of residence"
        variant="normal"
        onChange={onChange}
        defaultValue={selectedCountry}
      />
      <br />
      <RPAddressLookup selectedCountry={selectedCountry} />
    </>
  );
};

export default RPAddressLookupVariants;
